import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypepageActions {
  signInPageString = "[SIGNIN] set pages",
}

/**
 * Action to set data page
 */
export const signInSetPage = createAction(
  TypepageActions.signInPageString,
  (pages: IPage[]) => ({ pages }),
);
