<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'secondTemplate'"
  exit="true" headerType="secondary" [showfooterBallsRight]="false" [actionType]="'logout'" urlBack="/">

  <ng-template #tplRef>
    <section class="seccion-vehicles">
      <div class="container-vehicles">
        <h1 class="fp-h1 card-title">Transacciones con tarjeta
        </h1>
        <div class="container-accounts fp-fadeIn" *ngIf="accounts">
          <div class="accounts-row headers">
            <h4>Seleccione la cuenta que desea revisar</h4>
            <div class="subtitle-vehicles" (click)="expand = !expand" id="vehicles-hide-balance">Ver
              saldo <img [class.rotar]="expand" src="assets/images/home/accounts/arrow.svg" alt="icono currency"
                id="expand-info-accounts">
            </div>
          </div>
          <div class="container-accounts-carousel">
            <button class="fp-btn-white btn-control-carousel" [class.expand]="expand" (click)="carrouselAccount.prev()"
              *ngIf="accounts?.length >= 4" type="button" id="vehicles-carrousel-account-prev">
              <img src="assets/images/home/arrow-carousel.svg" alt="control carousel">
            </button>
            <owl-carousel-o #infoDocumentCarousel [options]="customOptions" #carrouselAccount>
              <ng-container *ngFor="let account of accounts; let i = index">
                <ng-template carouselSlide [id]="i">
                  <div class="items-account">
                    <fp-account-card [formAccount]="formAccount"
                      [select]="formAccount.controls.account.value === account.accountId" [account]="account"
                      [expand]="!expand" (onClickItem)="chooseAccount($event)" template="templateTwo" small="true">
                    </fp-account-card>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
            <button class="fp-btn-white btn-control-carousel right" [class.expand]="expand"
              (click)="carrouselAccount.next()" *ngIf="accounts?.length >= 4" type="button"
              id="vehicles-carrousel-account-next">
              <img class="rotar" src="assets/images/common/arrow-paginator.svg" alt="control carousel">
            </button>
          </div>
        </div>


        <ng-container *ngIf="!accounts">
          <div class="container-accounts fake">
            <div class="accounts-row headers">
              <div class="skeleton shadow" style="height: 16px; width: 16rem; margin-bottom: 1rem;"></div>
              <div style="display: flex;">
                <div class="skeleton shadow" style="height: 16px; width: 4rem;"></div>
                <div class="skeleton shadow" style="height: 16px; width: 1rem; margin-left: 0.3rem;">
                </div>
              </div>
            </div>
            <div class="container-accounts-carousel-fake">
              <div class="accounts-card fake" *ngFor="let item of [1,2]">
                <div class="accounts-header-card fake ">
                  <div class="text-header-card" style="color: transparent;">
                    <p class="skeleton big shadow"></p>
                    <p class="skeleton small shadow">
                    </p>
                  </div>
                  <div class="skeleton shadow" style="width: 24px; height: 24px; border-radius: 50%;">
                  </div>
                </div>
                <div class="accounts-body-card fake expand">
                  <div class="skeleton shadow" style="width: 45px; height: 24px">
                  </div>
                  <div class="skeleton shadow"
                    style="width: 100px; height: 24px; margin-top: 0.5rem; margin-bottom: 1rem;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <form novalidate *ngIf="!loading" [formGroup]="formAccount" class="container-detail-vehicles fp-fadeIn">
          <div class="list-vehicles-container-filters space-vehicles-header">
            <div class="vehicles-btn-filter transaction">
              Transacción
              <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-license-plate">
                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.licensePlate"
                  alt="icono organizar">
              </button>
            </div>
            <div class="vehicles-btn-filter date">
              Fecha
              <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-numberTag">
                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.numberTag"
                  alt="icono organizar">
              </button>
            </div>
            <div class="vehicles-btn-filter method">
              Metodo de pago
              <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                  alt="icono organizar">
              </button>
            </div>
            <div class="vehicles-btn-filter status">
              Estado
              <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                  alt="icono organizar">
              </button>
            </div>
            <div class="vehicles-btn-filter action">
              Valor
              <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                  alt="icono organizar">
              </button>
            </div>
          </div>

          <div class="list-vehicles-container">
            <ng-container *ngIf="!loading && transactionError">
              <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'" [notifyError]="'true'">
              </fp-item-list-empty>
              <button class="fp-btn-green btn-empty" type="button" id="btn-list-get-vehicles" (click)="false">
                Volver a intentar
              </button>
            </ng-container>
            <ng-container *ngIf="!loading && transactions.length === 0 && !transactionError">
              <fp-item-list-empty iconEmpety="assets/images/report/file.svg"
                [descriptionEmpety]="'Aun no tiene reportes'"></fp-item-list-empty>
            </ng-container>
            <ng-container *ngIf="!loading && transactions.length > 0 && !transactionError">
              <div class="fp-item-row j-center"
                *ngFor="let report of transactionsPaginator[ipageActive - 1];; let indexEven = even">
                <div class="fp-item-transaction">
                  {{ report.transactionType | titleCase}}
                </div>
                <div class="fp-item-date">
                  {{ report.confirmedDateFormatted }}
                </div>

                <div class="fp-item-method">
                  <fp-transaction-method [paymentMethod]="report.paymentMethod" [brand]="report.brand"
                    [cardMask]="report.cardMask" [status]="getStatusCategory(report.statusView)">
                  </fp-transaction-method>
                </div>

                <div class="fp-item-status">
                  <fp-transaction-status [status]="getStatusCategory(report.statusView)">
                  </fp-transaction-status>
                </div>

                <div class="fp-item-action" [ngStyle]="{'--action-color': getStatusCategory(report.statusView) === 'approved' ? '#20710D': ''}" >
                  {{ report.totalAmount | currency : 'COP' : 'symbol' : '1.0-0'}}
                </div>
              </div>
            </ng-container>
          </div>
          <div class="status-line">
            <hr>
          </div>
          <div class="footer-detail-vehicles" *ngIf="!loading">
            <div class="item-info">
              <img src="assets/images/common/icon-info.svg" alt="info">
              <span>
                {{transactions.length}} Transacciones
              </span>
            </div>
            <div style="height: 24px; width: 2.813rem;">
              <fp-select [items]="filterLimit" [disabled]="transactions.length <= 10" (itemPageActive)="changeFilterLimit($event)"></fp-select>
            </div>

            <div class="paginator">
              <img class="controls previos" [class.disabled]="ipageActive === 1"
                src="assets/images/common/arrow-paginator.svg" alt="previos" (click)="prev()">
              <div class="pages">
                <span *ngFor="let page of pages" [class.selected]="page === ipageActive" (click)="changeIndexPage(page)">
                  {{ page }}
                </span>
              </div>
              <img class="controls" [class.disabled]="ipageActive === pagesItems"
                src="assets/images/common/arrow-paginator.svg" alt="next" (click)="next()">
            </div>
            <button *ngIf="lastEvaluatedKey" class="fp-btn-white card-detail-custom-btn big"
                            (click)="loadMore()">
                            <h2 class="fp-body-one  detail-account-title-small">Ver más</h2>
                            <img src="assets/images/home/more.svg" alt="icono settings">
                        </button>
            
          </div>
        </form>
        <ng-container *ngIf="loading">
          <div class="container-detail-vehicles fake">
            <div class="list-vehicles-container-filters space-vehicles-header ">
              <div class="fake-report-title skeleton shadow header">
                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                </button>
              </div>
              <div class="fake-content skeleton shadow header">
                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                </button>
              </div>
              <div class="fake-format skeleton shadow header">
                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                </button>
              </div>
              <div class="fake-status skeleton shadow header"></div>
              <div class="fake-status skeleton shadow header"></div>
            </div>
            <div class="list-vehicles-container">
              <div class="fake-item-vehicle shadow" *ngFor="let item of [1,2,3]; let indexEven = even"
                [class.even]="indexEven">
                <div class="fake-report-title skeleton shadow"></div>
                <div class="fake-content skeleton shadow"></div>
                <div class="fake-format skeleton shadow"></div>
                <div class="fake-status skeleton shadow"></div>
                <div class="fake-button skeleton shadow"></div>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </section>
  </ng-template>
</fp-card-body>