import { IFormValidationMessages } from "@shared/models/form-validation-messages";

export interface IVehiclesCategoriesResponse {
  value: string;
  code: string;
  category: string;
  icon: string;
  shortDescription: string;
  description: string;
}

export interface IVehiclesCategories {
  data: IVehiclesCategoriesResponse[];
  error: any;
  loaded: boolean;
  loading: boolean;
  checked?: boolean;
}
export interface IVehicleCreationValidationMessages {
  licensePlate: IFormValidationMessages[];
  vehicleType: IFormValidationMessages[];
}

export interface IVehiclesCategoriesDataRequest {
  id?: number;
}

export interface IVehicleInformation {
  licensePlate: string;
  categoryValue: string | number;
  accountId?: string;
  tagEntityConfirmationId?: string;
}

export interface IVehicleCreation {
  vehicleToCreate: IVehicleToCreate;
}
export interface IVehicleToCreate {
  licensePlate: string;
  state: string;
  category: string;
  account?: string;
}

export interface IVehicleResponseData {
  data?: IVehiclesListResponse[];
  lastEvaluatedKey?: LastEvaluatedKey;
}

export interface IVehicleInformationData {
  vehicles: IVehicleDetail[];
  resultMessage: IResultMessageInformation;
  lastEvaluatedKey: string;
}

export interface IResultMessageInformation {
  code: string;
  message: string;
}

export interface IVehiclesListResponse {
  personId: string;
  account?: string;
  vehicle: IVehicleDetail;
  tagEntityConfirmation?: IVehicleTagDetail;
}

export interface IVehiclesListRequest {
  licensePlate?: string;
  personId?: string;
  pageSize: number;
  lastEvaluatedKey?: LastEvaluatedKey;
}

export interface IVehicleDetail {
  shortDescriptionCategory: string;
  stateDescription: string;
  licensePlate: string;
  state: number;
  category: string;
  personId: string;
  createdAt: string;
  updatedAt: string;
  currentStatus: string;
  personIdLicensePlate?: string;
  account?: string;
  additionalInformation?: string;
}

export interface IVehicleTagDetail {
  id: string;
  sequence: number;
  idExecution: string;
  idFacial: string;
  epc: string;
  hold: number;
  roll: number;
  courier: number;
  tagReference: number;
  batch?: number;
  creationDate: string;
  manufacturer: 9;
  provider: number;
  identificationType: string;
  idClient: string;
  plate: string;
  modality: string;
  idAccount: string;
  vehicle: string;
  categoryVehicle: number;
  assignationDate: string;
  status: number;
  activationDate: string;
  statusUpdateDate?: string;
  currentStatus: number;
  balance?: number;
  statusAccount?: string;
  currentDispatchDate?: string;
  balanceStatus?: number;
  typeSell?: string;
}

export interface IVehiclesList {
  data: IVehicleResponseData;
  error: any;
  loaded: boolean;
  loading: boolean;
  checked?: boolean;
}

export interface LastEvaluatedKey {
  personIdLicensePlate: string;
  currentStatus: string;
  personId: string;
  state: number;
}

export interface IDeleteVehicleRequest {
  tagId: string;
  plate: string;
  accountId: string;
  licensePlate: string;
  personId: string;
}

export interface IValidateVehicleRequest {
  personId: string;
  accountId: string;
  licensePlate: string;
  category: string;
  additionalInformation: string;
  idFacial?: string;
}

export interface IVehicles {
  data: IVehicle[];
}

export interface IVehicle {
  plate: string;
  category: string;
  additionalInfo: string;
  noTag?: string;
  personId?: string;
  accountId: string;
}

export enum VehicleValidationState {
  Idle = "IDLE",
  Validating = "VALIDATING",
  Verified = "VERIFIED",
  Error = "ERROR",
  Retry = "RETRY",
}

export enum VehicleTAGStatus {
  INACTIVO = 0,
  ACTIVO = 1,
}

export interface IVehiclesRequest {
  personId: string;
  accountId: string;
  licensePlate: string;
  category: string;
  additionalInformation?: string;
  idFacial?: string;
  city?: string;
  addresses?: any;
  services: Service[];
  date: Date;
}

export interface Service {
  id: string;
  status: string;
  versionTermsAndConditions: string;
}

export interface IVehicleUpdateRequest {
  account: string;
  accountId: string;
  category: string;
  licensePlate: string;
  personId: string;
  additionalInformation: string;
}
