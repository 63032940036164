import { createAction } from "@ngrx/store";
import { IDataHabeasDataResponse } from "@signup/models/habeas-data";

export const enum TypeActions {
  habeasDataLoadString = "[HABEASDATA] Loading data",
  habeasDataSaveString = "[HABEASDATA] Succesfull data loaded",
  habeasDataErrorString = "[HABEASDATA] Save error response",
  habeasDataToggleCheckOptionalString = "[HABEASDATA] Toggle check optional",
  habeasDataSetCheckOptionalString = "[HABEASDATA] Set check optional",
  setHabeasDataCheckRequiredString = "[HABEASDATA] Set check habeas required",
  resetHabeasDataString = "[HABEASDATA] reset data",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const habeasDataLoad = createAction(TypeActions.habeasDataLoadString);
/**
 * Action to detect event loaded terms and conditions data
 */
export const habeasDataSave = createAction(
  TypeActions.habeasDataSaveString,
  (data: IDataHabeasDataResponse[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const habeasDataError = createAction(
  TypeActions.habeasDataErrorString,
  (error: any) => ({ error }),
);
/**
 * Action to toggle check optional
 */
export const habeasDataToggleCheckOptional = createAction(
  TypeActions.habeasDataToggleCheckOptionalString,
  (key: number) => ({ key }),
);
/**
 * Action to set check optional
 */
export const habeasDataSetCheckOptional = createAction(
  TypeActions.habeasDataSetCheckOptionalString,
  (value: boolean) => ({ value }),
);
/**
 * Action to set check required
 */
export const setHabeasDataCheckRequired = createAction(
  TypeActions.setHabeasDataCheckRequiredString,
  (value: boolean) => ({ value }),
);

/**
 * Action to set check required
 */
export const resetHabeasData = createAction(TypeActions.resetHabeasDataString);
