import { ITemporalPassword } from "@app/models/temporal-password";
import { createAction } from "@ngrx/store";

export const enum TypeActionsCompleteSignUpTemporalPassword {
  completeSignUpTemporalPasswordString = "[COMPLETE SIGNUP Temporal Password] set temporal password",
}

export const CompleteSignUpTemporalPassword = createAction(
  TypeActionsCompleteSignUpTemporalPassword.completeSignUpTemporalPasswordString,
  (data: ITemporalPassword) => ({ data }),
);
