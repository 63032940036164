import { createAction, props } from "@ngrx/store";
import { IVehicle } from "../../models/vehicle";

export const enum TypeUpdateVehiclesActions {
  vehicleToUpdateSetString = "[VEHICLE TO UPDATE] SET vehicle",
}

/**
 * Action to detect event loaded vehicles categories data
 */
export const vehicleToUpdateSet = createAction(
  TypeUpdateVehiclesActions.vehicleToUpdateSetString,
  props<IVehicle>(),
);
