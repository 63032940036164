<div class="fp-alert-message" [ngStyle]="{'cursor': isRedirect ? 'pointer' : 'default' }" [ngClass]="{
    'fp-alert-message--info': type === alertsTypes.INFO,
    'fp-alert-message--warning': type === alertsTypes.WARNING,
    'fp-alert-message--background-white': backgroundWhite
  }">
  <div class="fp-alert-message-icon">
    <img [src]="icons[type]" alt="icon" class="fp-alert-message-icon__img">
  </div>

  <div *ngIf="custom; else defaultContent">
    <ng-content></ng-content>
  </div>

  <ng-template #defaultContent>
    <div class="fp-alert-message-content" [ngClass]="{
        'fp-alert-message-content--info': type === alertsTypes.INFO,
        'fp-alert-message-content--warning': type === alertsTypes.WARNING
      }">
      <p class="fp-alert-message-content__title" *ngIf="title">{{ title | translate }}</p>
      <p class="fp-alert-message-content__message">{{ message | translate }}</p>
      <p class="fp-alert-message-content__title" *ngIf="footerText" [ngStyle]="{'text-align': alignFooterText }">
        {{ footerText | translate }}
      </p>
    </div>
  </ng-template>
</div>