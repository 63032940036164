import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IUser } from "@app/models/user";
import { environment } from "@environment";
import { AESEncryptDecryptService } from "@shared/services/aes-encrypt-decrypt.service";
import { AuthenticationService } from "@shared/services/authentication.service";
import { from, Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {
  token: string = "";
  ngCaptcha: string = "";
  constructor(
    private aesEncryptDecryptService: AESEncryptDecryptService,
    private authenticationService: AuthenticationService,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(req, next));
  }
  private async handleAccess(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Promise<HttpEvent<any>> {
    let headers = req.headers;

    if (req.params instanceof CustomHttpParams && req.params.type) {
      const cognitoUser: IUser = await this.authenticationService.getCurrentAuthenticatedUser();
      if (cognitoUser) {
        this.token = cognitoUser.data.authenticationResult.idToken;
      }
      this.ngCaptcha = sessionStorage.getItem("captcha");
      headers = headers.append("ctype", "pj");
      headers = headers.append(
        "request-uuid",
        uuidv4() + "|" + new Date().getTime(),
      );
      switch (req.params.type) {
        case "api-pn-public-apiKey":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.publicPN.x_api_key,
            ),
          );
          break;
        case "api-pn-public-apiKey-token":
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.publicPN.x_api_key,
            ),
          );
          break;
        case "api-pn-public-apiKey-token-noctype":
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.publicPN.x_api_key,
            ),
          );
          headers = headers.delete("ctype");
          break;
        case "api-pn-public-token-noctype":
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.delete("ctype");
          break;
        case "api-pn-public-apiKey-captcha":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.publicPN.x_api_key,
            ),
          );
          headers = headers.append("authorizationToken", this.ngCaptcha);
          break;
        // case "api-pj-public-apiKey":
        //   headers = headers.append("request-date", new Date().toDateString());
        //   headers = headers.append(
        //     "x-api-key",
        //     this.aesEncryptDecryptService.decrypt(
        //       environment.api_key.publicPJ.x_api_key,
        //     ),
        //   );
        //   break;
        case "api-commons":
          headers = headers.append("Content-Type", "application/json");
          break;
        case "api-commons-apiKey-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.commons.x_api_key,
            ),
          );
          break;
        case "api-pn-security-apiKey":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.securityPN.x_api_key,
            ),
          );
          break;
        case "api-pn-security-apiKey-token":
        case "api-pj-public-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          break;
        case "api-pn-vehicles-apiKey-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.vehicles.x_api_key,
            ),
          );
          break;
        case "api-pn-vehicles-apiKey-token-no-ctype":
        case "api-pj-security-apiKey-token-noctype":
        case "api-pn-security-apiKey-token-new":
        case "api-pn-shared-apiKey-token":
        case "api-pj-security-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.delete("ctype");
          break;
        case "api-pn-security-apiKey-catpcha":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.securityPN.x_api_key,
            ),
          );
          headers = headers.append("authorizationToken", this.ngCaptcha);
          break;
        case "api-pn-vinculation-process-apiKey":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.vinculation_process.x_api_key,
            ),
          );
          break;
        case "api-pn-payments-apiKey-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.payments.x_api_key,
            ),
          );
          break;
        case "api-pn-payments-apiKey-token-noctype":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.payments.x_api_key,
            ),
          );
          headers = headers.delete("ctype");
          break;
        case "api-pn-private-apiKey-token":
          headers = headers.append("request-date", new Date().toDateString());
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.append(
            "x-api-key",
            this.aesEncryptDecryptService.decrypt(
              environment.api_key.private.x_api_key,
            ),
          );
          headers = headers.delete("ctype");
          break;
        case "api-token":
          headers = headers.append("Authorization", `Bearer ${this.token}`);
          headers = headers.delete("ctype");
          headers = headers.delete("request-uuid");
          break;
      }
    }
    const authReq = req.clone({
      headers,
    });
    return next.handle(authReq).toPromise();
  }
}
