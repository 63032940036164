import { IAccountLowBalance } from "@app/models/accounts";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { lowBalanceSelectedFeatureName } from "../state/app.state";

export const modulesRootSelector = createFeatureSelector<IAccountLowBalance>(
  lowBalanceSelectedFeatureName,
);

export const selectLowBalanceSelected = createSelector(
  modulesRootSelector,
  (state: IAccountLowBalance) => state,
);
