import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";
import { NavMenuComponent } from "./nav-menu.component";

@NgModule({
  declarations: [NavMenuComponent],
  exports: [NavMenuComponent],
  imports: [CommonModule, RouterModule, NgxPermissionsModule.forChild()],
})
export class NavMenuModule {}
