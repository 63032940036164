import { createAction } from "@ngrx/store";
import { IDataTagTypesResponse } from "@tag/models/tag-types";

export const enum TypeTagTypesActions {
  tagTypesLoadString = "[TAG TYPES] Loading data",
  tagTypesSaveString = "[TAG TYPES] Successful data loaded",
  tagTypesErrorString = "[TAG TYPES] Save error response",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const tagTypesLoad = createAction(
  TypeTagTypesActions.tagTypesLoadString,
);
/**
 * Action to detect event loaded terms and conditions data
 */
export const tagTypesSave = createAction(
  TypeTagTypesActions.tagTypesSaveString,
  (data: IDataTagTypesResponse[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const tagTypesError = createAction(
  TypeTagTypesActions.tagTypesErrorString,
  (error: any) => ({ error }),
);
