import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PaymentMethodComponent } from "./payment-method.component";

export const PaymentMethodRootRoute = "payment-method";
const routes: Routes = [
  {
    component: PaymentMethodComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentMethodRoutingModule {}
