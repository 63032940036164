import { IAccountsResponseDetails } from "@app/models/accounts";
import { createAction } from "@ngrx/store";

export const enum AccountsTypeActions {
  accountsLoadString = "[Accounts] Loading data",
  accountsSaveString = "[Accounts] Successful data loaded",
  accountsErrorString = "[Accounts] Save error response",
  accountsResetString = "[Accounts] Reset State",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const accountsLoad = createAction(
  AccountsTypeActions.accountsLoadString,
  (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const accountsSave = createAction(
  AccountsTypeActions.accountsSaveString,
  (data: IAccountsResponseDetails[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const accountsError = createAction(
  AccountsTypeActions.accountsErrorString,
  (error: any) => ({ error }),
);

export const accountsReset = createAction(
  AccountsTypeActions.accountsResetString,
);
