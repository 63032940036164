import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeLowBalancePagesActions {
  lowBalancePageString = "[LOW-BALANCE] set pages",
}

/**
 * Action to set data page
 */
export const LowBalanceSetPage = createAction(
  TypeLowBalancePagesActions.lowBalancePageString,
  (pages: IPage[]) => ({ pages }),
);
