import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { accountPagesConst } from "@core/constants/pages.constant";
import { AccountFacade } from "../store/facade/account.facade";

@Injectable({
  providedIn: "root",
})
export class AccountNavigationService {
  /**
   * Constructor
   * @param router
   * @param accountFacade
   */
  constructor(private router: Router, private accountFacade: AccountFacade) {}

  /**
   * Navigate to the next page
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = accountPagesConst[position];
    let savedPages: IPage[];
    savedPages = accountPagesConst.slice(0, position + 1);
    if (position > accountPagesConst.length - 1) {
      savedPages = [page];
    }
    this.accountFacade.setAccountPages(savedPages);
    if (page?.active) {
      this.router.navigate([`/account/${page.active}`]);
    }
  }

  clearAccountStore(): void {
    this.accountFacade.setAccountPages([accountPagesConst[0]]);
    this.accountFacade.resetsetAccountModality();
    this.accountFacade.resetsetAccountFinancialProduct();
  }
}
