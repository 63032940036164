import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { pageEvent, pageViewEvent } from "@app/helpers/utag.helpers";
import {
  IAccount,
  IAccountLowBalance,
  IAccountsResponseDetails,
  ILowBalance,
} from "@app/models/accounts";
import { IClientInfo, ICustomerInfo } from "@app/models/customer-info";
import { IAutomaticRecharge } from "@app/modules/account/models/automatic-recharge.interface";
import { AccountNavigationService } from "@app/modules/account/services/account-navigation-service.service";
import { AccountRequestsService } from "@app/modules/account/services/account-requests/account-requests.service";
import { AccountFacade } from "@app/modules/account/store/facade/account.facade";
import { AccountsService } from "@shared/services/accounts.service";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { NotifyService } from "@shared/services/notify.service";
import { ModalService } from "@theme/components/modal/service/modal.service";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "fp-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpAccounts = "fp-accounts";
  expand: boolean = true;
  loading: boolean = true;
  loadingCreate: boolean = false;
  accountId: string;
  emptyAccountList: boolean = false;

  accountSelect: IAccountsResponseDetails;
  lowBalanceSelected: IAccountLowBalance;
  formAccount: UntypedFormGroup;
  accounts: IAccountsResponseDetails[];
  statusAccounts: any;
  lowBalance: IAccountLowBalance[];
  @ViewChild("notifyError", { static: true }) notifyError: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  customerConsultation: IClientInfo;
  showPaymentsMethods: boolean = false;

  constructor(
    private appFacade: AppFacade,
    private accountFacade: AccountFacade,
    private commonsService: CommonsService,
    private accountsService: AccountsService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private notifyService: NotifyService,
    public permissionService: NgxPermissionsService,
    private accountNavigationService: AccountNavigationService,
    private accountRequestsService: AccountRequestsService,
  ) {}
  /**
   * Init state to component
   */
  ngOnInit(): void {
    this.initForm();
    this.getAccounts();
    this.getLowBalance();
    this.getCustomerInfo();
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * init form for accounts
   */
  initForm(): void {
    this.formAccount = new UntypedFormGroup({
      account: new UntypedFormControl("", [Validators.required]),
    });
  }
  /**
   * get customer info
   */
  getCustomerInfo(): any {
    this.selectCustomerInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ICustomerInfo) => {
        this.loading = action.loading;
        if (action && action.data !== null) {
          this.customerConsultation = action.data;
        }
      });
  }
  /**
   * Chose account selected
   */
  chooseAccount(data: {
    account: IAccountsResponseDetails;
    flag: boolean;
  }): void {
    this.formAccount.controls.account.setValue(data.account.accountId);
    this.accountSelect = { ...data.account };
    this.appFacade.setAccountSelected(data.account);
    this.chooseLowBalance(null, Number(data.account.accountId));
    if (data.flag) {
      // Tagging click event
      pageEvent("click-btn-account-config", "/home", "Botón configurar cuenta");
      this.commonsService.navigate("/account/administration");
    }
  }

  chooseLowBalance(
    data: IAccountLowBalance,
    accountId: number | null = null,
  ): void {
    if (this.lowBalance == undefined) return;
    const accountToSelect = accountId
      ? this.lowBalance.find((account) => account.accountId === accountId)
      : data;

    this.lowBalanceSelected = { ...accountToSelect };
    this.appFacade.setLowBalanceSelected(accountToSelect);
  }

  /**
   * New account
   */
  async createAccount(): Promise<any> {
    if (this.loadingCreate) {
      return;
    }
    this.loadingCreate = true;
    const userName = (await this.authenticationService.getUsername()) + "";
    try {
      const respCreateAccount = await this.accountsService
        .createAccount(userName.split("-")[0])
        .toPromise();
      await this.verifyAccountCreationProcess(respCreateAccount.execution_id);
    } catch (error) {
      this.notifyService.showNotify(this.notifyError);
      this.loadingCreate = false;
    }
  }

  async getAccountCreationProcess(executionId: string): Promise<any> {
    return this.accountsService.getAccountStatus(executionId).toPromise();
  }

  async verifyAccountCreationProcess(executionId: string): Promise<void> {
    try {
      await this.commonsService.getTokenNgCaptcha();
      const process = await this.getAccountCreationProcess(executionId);
      switch (process.status) {
        case "RUNNING":
          setTimeout(
            () => this.retryVerifyAccountCreationProcess(executionId),
            1000,
          );
          break;
        case "SUCCEEDED":
          this.accountId = process.data.accountId;
          this.modalService.open("fp-confirm-create-account");
          this.getAccounts(this.accountId);
          this.getLowBalance(this.accountId);
          this.loadingCreate = false;
          break;
        // Otros casos: ERROR, FAILED, etc...
      }
    } catch (error) {
      this.loadingCreate = false;
    }
  }

  retryVerifyAccountCreationProcess(executionId: string) {
    this.verifyAccountCreationProcess(executionId).catch((error) => {
      this.loadingCreate = false;
    });
  }

  /**
   * get accounts info
   */
  async getAccounts(accountId = null): Promise<any> {
    sessionStorage.removeItem("customToken");
    this.appFacade.resetAccountSelected();
    this.appFacade.getAccounts(await this.authenticationService.getUsername());
    this.selectAccounts$
      .pipe(
        takeUntil(this.destroy$),
        tap((action: IAccount) => {
          if (action.data) {
            const accountsIds = action?.data?.map(
              (account) => account.accountId,
            );
            const getStatusAccounts = async () => {
              const request = await this.accountRequestsService.getStatusAccounts(
                { accounts: accountsIds },
              );
              request?.pipe(takeUntil(this.destroy$)).subscribe({
                next: (response) => {
                  this.statusAccounts = response.data || {};
                  this.showPaymentsMethods = JSON.parse(
                    sessionStorage.getItem("publicKey"),
                  )?.config?.recurrent;
                },
              });
            };
            getStatusAccounts();
          }
        }),
      )
      .subscribe((action: IAccount) => {
        this.loading = action.loading;
        if (action && action.data !== null && action.data.length > 0) {
          this.emptyAccountList = false;
          this.accounts = action.data;
          if (accountId === null) {
            this.chooseAccount({ account: this.accounts[0], flag: false });
          } else {
            const accountFinded = this.accounts.find(
              (account: IAccountsResponseDetails) =>
                account.accountId === accountId,
            );
            accountFinded
              ? this.chooseAccount({ account: accountFinded, flag: false })
              : this.chooseAccount({ account: this.accounts[0], flag: false });
          }
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
        if (action && !action.data) {
          this.emptyAccountList = true;
        }
      });
  }

  async getLowBalance(accountId = null) {
    this.appFacade.getLowBalance(
      await this.authenticationService.getUsername(),
    );
    this.selectLowBalance$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ILowBalance) => {
        if (action && action.data !== null && action.data.length > 0) {
          this.lowBalance = [...action.data].sort(
            (a, b) => a.accountId - b.accountId,
          );

          if (accountId === null && this.lowBalance.length > 0) {
            this.chooseLowBalance(null, Number(this.lowBalance[0].accountId));
          } else {
            const accountFinded = this.lowBalance.find(
              (account: IAccountLowBalance) => account.accountId === accountId,
            );
            accountFinded
              ? this.chooseLowBalance(accountFinded)
              : this.chooseLowBalance(
                  null,
                  Number(this.lowBalance[0].accountId),
                );
          }
        }
      });
  }

  async navigateTo(route: string) {
    await this.setAutomaticRechargeData();
    this.commonsService.navigate(route);
  }
  /**
   * get accounts from Selector
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
  /**
   * get customer info from Selector
   */
  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }

  /**
   * get accounts from Selector
   */
  get selectLowBalance$(): Observable<ILowBalance> {
    return this.appFacade.selectLowBalance$;
  }

  /**
   * Go automatic recharge config page
   */
  async goAutomaticRechargeConfig() {
    await this.setAutomaticRechargeData();
    this.accountNavigationService.next(0);
  }

  /**
   * Set automatic recharge data
   */
  async setAutomaticRechargeData() {
    const automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    this.accountFacade.setAutomaticRecharge({
      ...automaticRechargeData,
      rechargeValue: automaticRechargeData?.rechargeValue ?? null,
      activeRechargeValue: automaticRechargeData?.activeRechargeValue ?? null,
      email: automaticRechargeData?.email ?? null,
      getAllCards: true,
      fromRecharge: false,
    });
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
