import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { IClientInfo, ICustomerInfo } from "@app/models/customer-info";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { ModalService } from "@theme/components/modal/service/modal.service";
import { CarouselComponent, OwlOptions } from "ngx-owl-carousel-o";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IInfoDocuemnt } from "../components/item-document/models/item-document";
import { stepsCarousel } from "./constants/stepsCarousel.constants";
@Component({
  selector: "fp-documents-carousel",
  templateUrl: "./documents-carousel.component.html",
  styleUrls: ["./documents-carousel.component.scss"],
})
export class DocumentsCarouselComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding("class") fpDocumentsCarousel = "fp-documents-carousel";
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild("infoDocumentCarousel", { static: false })
  infoDocumentCarousel: CarouselComponent;
  customerConsultation: IClientInfo;
  showCarousel: boolean;
  indexCarousel: number = 0;
  carouselPosition: string;
  refundActive: boolean = false;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    startPosition: 0,
    stagePadding: 30,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 4,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
    },
    nav: false,
    center: false,
  };
  infoDocumentValidation = stepsCarousel;
  infoDocumentCopy: IInfoDocuemnt[] = JSON.parse(
    JSON.stringify(this.infoDocumentValidation.filter((p) => p.show === true)),
  );
  infoDocumentStep: number;
  stepSuccessful: number;
  loading: boolean = true;
  constructor(
    private appFacade: AppFacade,
    private modalService: ModalService,
    private commonsService: CommonsService,
    private changeDetector: ChangeDetectorRef,
    public permissionService: NgxPermissionsService,
    public translate: TranslateService,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }
  /**
   * Init state to component
   */
  ngOnInit(): void {
    this.getCustomerInfo();
  }

  ngAfterViewInit(): void {
    this.updateIndexContinueProcces(this.carouselPosition);
  }

  /**
   * get customer info
   */
  getCustomerInfo(): any {
    this.selectCustomerInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ICustomerInfo) => {
        this.loading = action.loading;
        if (action && action.data !== null) {
          this.customerConsultation = action.data;
          this.getAccount();
        }
      });
  }

  evaluateCarouselIndex(value: number) {
    this.indexCarousel = this.indexCarousel + value;
    if (this.indexCarousel < 0) {
      this.indexCarousel = 0;
    } else if (this.indexCarousel > 2) {
      this.indexCarousel = 2;
    }
  }

  fillSkeleton() {
    for (let index = 0; index <= 1; index++) {
      this.infoDocumentCopy.push({
        state: "fake",
        assets: "",
        description: "fake",
        key: "fake",
        show: true,
        action: {
          clcik: "",
          elemen: "",
        },
      });
    }
  }
  /**
   * Validate state corousel
   */
  statusCarousel(data: IAccountsResponseDetails) {
    switch (this.customerConsultation.currentStatus) {
      // Already finished vinculation process, don´t show carousel again
      case "18012":
      case "18013":
      case "18014":
      case "18015":
        this.showCarousel = false;
        break;
      default:
        this.showCarousel = true;
        this.changeDetector.detectChanges();
        if (data.refund) {
          this.refundActive = true;
          this.infoDocumentCopy = this.infoDocumentValidation.filter(
            (p) => p.key === "modality" || p.key === "refund",
          );
          this.fillSkeleton();
          this.validateContinueProcess(data);
          this.indexCarousel = 0;
          this.infoDocumentStep = 2;
          this.stepSuccessful = 1;
        } else {
          this.refundActive = false;
          this.infoDocumentCopy = JSON.parse(
            JSON.stringify(
              this.infoDocumentValidation.filter((p) => p.show === true),
            ),
          );
          this.infoDocumentStep = this.infoDocumentCopy.length;
          if (typeof this.customerConsultation.treeStatus !== "string") {
            this.customerConsultation.treeStatus.forEach((p) => {
              const value = p.split("_")[0];
              switch (value) {
                case "18023":
                  this.updateItemCarousel("modality", "successful", false);
                  break;
                case "18024":
                  this.updateItemCarousel("create-users", "successful", false);
                  break;
                case "18011":
                  this.updateItemCarousel("recharge", "successful", false);
                  break;
                case "18008":
                  this.updateItemCarousel(
                    "create-vehicles",
                    "successful",
                    false,
                  );
                  break;
                case "18010":
                  this.updateItemCarousel(
                    "activation-vehicles",
                    "successful",
                    false,
                  );
                  break;
                default:
                  break;
              }
            });
          }
          switch (this.customerConsultation.currentStatus) {
            case "18007":
              this.updateItemCarousel("document", "successful", false);
              this.validateContinueProcess(data);
              break;
            case "18006":
              this.updateItemCarousel("document", "process");
              this.validateContinueProcess(data);
              break;
            case "18004":
              this.updateItemCarousel("document", "process");
              return;
            default:
              if (
                data.statusAccount === "Creada" ||
                data.statusAccount === "Activo"
              ) {
                this.infoDocumentCopy = this.infoDocumentCopy.filter(
                  (x) => x.key === "modality" || x.key === "document",
                );
                if (data.statusAccount === "Creada") {
                  this.updateItemCarousel("document", "process");
                  this.validateContinueProcess(data);
                }
                if (data.statusAccount === "Activo") {
                  this.updateItemCarousel("document", "successful");
                  this.updateItemCarousel("modality", "process", true);
                }
                this.stepSuccessful = this.infoDocumentCopy.filter(
                  (p) => p.state === "successful",
                ).length;
              }
              break;
          }
        }
        break;
    }
    this.orderCarouselElements();
  }
  /**
   * Order carousel elements:
   * Completed - Active - Inactive or Blocked
   */
  orderCarouselElements() {
    this.infoDocumentCopy.sort((a, b) => b.state.localeCompare(a.state));
  }
  /**
   * get accounts of user
   */
  getAccount(): any {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccountsResponseDetails) => {
        if (action) {
          this.statusCarousel(action);
        }
      });
  }
  /**
   * Validate account status
   */
  validateContinueProcess(data: IAccountsResponseDetails) {
    if (!data.statusAccount) {
      return;
    }
    if (data.refund) {
      this.updateItemCarousel("modality", "successful", false);
      this.updateItemCarousel("refund", "process");
    } else {
      if (data.statusAccount === "Creada") {
        this.updateItemCarousel("modality", "blocked", false);
        this.carouselPosition = "document";
      } else {
        if (!data.accountType) {
          if (data.statusAccount === "Activo") {
            this.updateItemCarousel("modality", "process");
          } else if (data.statusAccount.indexOf("Inactiva") > -1) {
            this.updateItemCarousel("modality", "blocked", false);
            this.carouselPosition = "document";
          }
        } else {
          if (
            data.statusAccount === "Activo" ||
            data.statusAccount.indexOf("Inactiva") > -1
          ) {
            this.updateItemCarousel("modality", "successful", false);
            if (this.customerConsultation.treeStatus === "NOT_FOUND") {
              this.updateItemCarousel("create-users", "process");
            } else {
              const state18024 = (this.customerConsultation
                .treeStatus as string[]).find(
                (p) => p.split("_")[0] === "18024",
              );
              if (!state18024) {
                this.updateItemCarousel("create-users", "process");
              } else {
                const state18011 = (this.customerConsultation
                  .treeStatus as string[]).find(
                  (p) => p.split("_")[0] === "18011",
                );
                if (!state18011) {
                  this.updateItemCarousel("recharge", "process");
                } else {
                  const state18008 = (this.customerConsultation
                    .treeStatus as string[]).find(
                    (p) => p.split("_")[0] === "18008",
                  );
                  if (!state18008) {
                    this.updateItemCarousel("create-vehicles", "process");
                  } else {
                    const state18010 = (this.customerConsultation
                      .treeStatus as string[]).find(
                      (p) => p.split("_")[0] === "18010",
                    );
                    if (!state18010) {
                      this.updateItemCarousel("activation-vehicles", "process");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.stepSuccessful = this.infoDocumentCopy.filter(
      (p) => p.state === "successful",
    ).length;
  }

  updateIndexContinueProcces(key: string) {
    if (!this.infoDocumentCarousel) {
      return;
    }
    this.infoDocumentCarousel.to("0");
    const keyIndex = this.infoDocumentCopy.findIndex((p) => p.key === key);
    this.indexCarousel = keyIndex;
    setTimeout(() => {
      try {
        this.infoDocumentCarousel.to(keyIndex.toString());
      } catch (error) {}
    }, 100);
  }
  /**
   * set state a carousel item
   */
  updateItemCarousel(
    key: string,
    value: string,
    updateIndex: boolean = true,
  ): void {
    this.infoDocumentCopy.forEach((item) => {
      if (item.key === key) {
        item.state = value;
      }
    });
    if (updateIndex) {
      this.updateIndexContinueProcces(key);
    }
  }

  /**
   * call action in the carousel
   */
  async callToAcctionCarousel(event: IInfoDocuemnt) {
    if (event.state !== "process") {
      return;
    }
    const permission = event.permission
      ? await this.permissionService.hasPermission(event.permission)
      : true;
    if (!permission) {
      this.modalService.open("fp-not-allowed-action");
      return;
    }
    switch (event.action.clcik) {
      case "redirect":
        this.commonsService.navigate(event.action.elemen);
        break;
      case "open-modal":
        this.modalService.open(event.action.elemen);
        break;
      default:
        break;
    }
  }
  /**
   * get customer info from Selector
   */
  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }
  /**
   * get account select from Selector
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
