import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "status",
})
export class StatusPipe implements PipeTransform {
  transform(value: number): string {
    let status;
    switch (value) {
      case 1:
        status = "Solicitado";
        break;

      case 2:
        status = "En Proceso";
        break;

      case 3:
        status = "Finalizado";
        break;

      default:
        status = "Fallido";
        break;
    }
    return status;
  }
}
