import { createAction } from "@ngrx/store";
import { IForgotPassword } from "@signin/models/forgot-password";

export const enum TypeActionsForgotPassword {
  forgotPasswordString = "[SIGN IN FORGOT PASSWORD] set information",
  forgotPasswordResettring = "[SIGN IN FORGOT PASSWORD] Reset data",
}
/**
 * Action to set vehicle information
 */
export const forgotPassword = createAction(
  TypeActionsForgotPassword.forgotPasswordString,
  (user: IForgotPassword) => ({ user }),
);
/**
 * Action to reset data
 */
export const forgotPasswordReset = createAction(
  TypeActionsForgotPassword.forgotPasswordResettring,
);
