import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { IRolesAdministrator } from "@app/models/roles-administrator";
import { ITemporalPassword } from "@app/models/temporal-password";
import { select, Store } from "@ngrx/store";
import { IForgotPassword, ITimestamp } from "@signin/models/forgot-password";
import * as signInActions from "@signin/store/actions";
import { ITimerCode } from "@signup/modules/otpcode/models/otpcode";
import { Observable } from "rxjs";
import { selectForgotPassword } from "../selectors/forgot-password.selector";
import { selectSignInotpCodeTimer } from "../selectors/signin-otp-timer.selector";
import { selectSignInpages } from "../selectors/signin-pages.selector";
import { selectSignInTemporalPassword } from "../selectors/signin-temporal-password.selector";
import { selectSignInTimestamp } from "../selectors/signin-timestamp.selector";

import { SignInModuleState } from "../state/signin.state";
@Injectable({
  providedIn: "root",
})
export class SignInFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectForgotPassword$: Observable<IForgotPassword> = this.store.pipe(
    select(selectForgotPassword),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSignInPages$: Observable<IPages> = this.store.pipe(
    select(selectSignInpages),
  );
  public selectSignInotpCodeTimer$: Observable<ITimerCode> = this.store.pipe(
    select(selectSignInotpCodeTimer),
  );

  public selectTemporalPassword$: Observable<
    ITemporalPassword
  > = this.store.pipe(select(selectSignInTemporalPassword));

  public selectTimestamp$: Observable<ITimestamp> = this.store.pipe(
    select(selectSignInTimestamp),
  );

  constructor(private store: Store<SignInModuleState>) {}

  setSignInPages(pages: IPage[]) {
    this.store.dispatch(signInActions.signInSetPage(pages));
  }

  setForgotPassword(forgotPasswordInformation: IForgotPassword) {
    this.store.dispatch(
      signInActions.forgotPassword(forgotPasswordInformation),
    );
  }

  resetsetForgotPassword(): void {
    this.store.dispatch(signInActions.forgotPasswordReset());
  }

  public setOtpCodeTimer(timer: ITimerCode): void {
    this.store.dispatch(signInActions.SignInOtpTimer(timer));
  }

  public setTemporalPassword(data: ITemporalPassword): void {
    this.store.dispatch(signInActions.SignInTemporalPassword(data));
  }
}
