<div class="datepicker">
  <label class="datepicker-label" for="daterange">{{ 'Rango de fechas' }}</label>
  <input type="text"
  class="datepicker-input"
  [ngClass]="{'valid': validInput}"
    ngxDaterangepickerMd
    [showWeekNumbers]="false"
    [showISOWeekNumbers]="false"
    [autoApply]="true"
    [locale]="locale"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [linkedCalendars]="true"
    placeholder="Seleccione un rango de fechas"
    (datesUpdated)="choosedDate($event)"
    id="daterange"
    name="daterange"/>
</div>
