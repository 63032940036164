import { createAction } from "@ngrx/store";
import {
  IRechargeAccountMode,
  IServiceRecharge,
} from "@postpaid/models/recharge-account";

export const enum TypeActionsRechargeAccount {
  rechargeAccountInformationString = "[POSTPAID Recharge Account] set information",
  rechargeAccountInformationStringReset = "[POSTPAID Recharge Account] reset information",
  rechargeAccountServiceSave = "[POSTPAID Recharge Account] save service recharge account",
  rechargeAccountServiceError = "[POSTPAID Recharge Account] error service recharge account",
  rechargeAccountServiceLoad = "[POSTPAID Recharge Account] load service recharge account",
}
/**
 * Action to set vehicle information
 */
export const postpaidRechargeAccount = createAction(
  TypeActionsRechargeAccount.rechargeAccountInformationString,
  (recharge: IRechargeAccountMode) => ({ recharge }),
);

export const postpaidRechargeAccountReset = createAction(
  TypeActionsRechargeAccount.rechargeAccountInformationStringReset,
);

export const postpaidRechargeAccountServiceSave = createAction(
  TypeActionsRechargeAccount.rechargeAccountServiceSave,
  (data: any) => ({ data }),
);

export const postpaidRechargeAccountServiceLoad = createAction(
  TypeActionsRechargeAccount.rechargeAccountServiceLoad,
  (dataServiceRecharge: IServiceRecharge) => ({ dataServiceRecharge }),
);

export const postpaidRechargeAccountServiceError = createAction(
  TypeActionsRechargeAccount.rechargeAccountServiceError,
  (error: any) => ({ error }),
);
