import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const TermsandConditionsRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectTermsandConditions = createSelector(
  TermsandConditionsRootSelector,
  (state: SignUpModuleState) => state.termsandConditionsReducer,
);
