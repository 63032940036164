import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@core/core.module";
import { ItemListMovementComponent } from "./item-list-movement.component";

@NgModule({
  declarations: [ItemListMovementComponent],
  imports: [CommonModule, CoreModule],
  providers: [DatePipe],
  exports: [ItemListMovementComponent],
})
export class ItemListMovementModule {}
