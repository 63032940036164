import {
  IFinancialProducts,
  IMinimumAccount,
} from "../../models/financial-data";

export const financialDataFeatureName = "financialData";

export type FinancialDataModuleState = Readonly<{
  financialProductsReducer: IFinancialProducts;
  minimumAmountPrepaidReducer: IMinimumAccount;
  minimumAmountPospaidReducer: IMinimumAccount;
}>;
export const InitialStateProductsReducer: IFinancialProducts = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const InitialStateMinimumAmount: IMinimumAccount = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
