import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  constructor(private http: HttpClient) {}
  getRoles(personId: string): Observable<any> {
    return this.http.get(
      environment.api_key.securityPJ.back_url +
        environment.endpoints.roles.init +
        "?person_id=" +
        personId +
        "&filter=true",
      {
        params: new CustomHttpParams("api-pj-security-token"),
      },
    );
  }
}
