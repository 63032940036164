import { createAction } from "@ngrx/store";
import {
  ITagReservationRequest,
  ITagReservationResponse,
} from "@tag/models/tag-reservation";

export const enum TypeTagReservationActions {
  tagReservationLoadString = "[TAG RESERVATION] Loading data",
  tagReservationSaveString = "[TAG RESERVATION] Successful data loaded",
  tagReservationErrorString = "[TAG RESERVATION] Save error response",
  tagReservationResetString = "[TAG RESERVATION] Reset data",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const tagReservationLoad = createAction(
  TypeTagReservationActions.tagReservationLoadString,
  (data: ITagReservationRequest) => ({ data }),
);
/**
 * Action to detect event loaded terms and conditions data
 */
export const tagReservationSave = createAction(
  TypeTagReservationActions.tagReservationSaveString,
  (data: ITagReservationResponse) => ({ data }),
);
/**
 * Action to set data error
 */
export const tagReservationError = createAction(
  TypeTagReservationActions.tagReservationErrorString,
  (error: any) => ({ error }),
);
/**
 * Action to reset data
 */
export const tagReservationReset = createAction(
  TypeTagReservationActions.tagReservationResetString,
);
