import { createAction } from "@ngrx/store";
import { IUserInformation } from "../../models/complete-signup";

export const enum TypeActionsUserInformation {
  userInformationString = "[COMPLETE SIGNUP USER] set information",
  userInformationResetString = "[COMPLETE SIGNUP USER] Reset data",
}
/**
 * Action to set user information
 */
export const userInformation = createAction(
  TypeActionsUserInformation.userInformationString,
  (user: IUserInformation) => ({ user }),
);
/**
 * Action to reset data
 */
export const userInformationReset = createAction(
  TypeActionsUserInformation.userInformationResetString,
);
