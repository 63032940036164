import { IAccountsResponseDetails } from "@app/models/accounts";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { accountSelectedFeatureName } from "../state/app.state";

export const modulesRootSelector = createFeatureSelector<
  IAccountsResponseDetails
>(accountSelectedFeatureName);

export const selectAccountSelected = createSelector(
  modulesRootSelector,
  (state: IAccountsResponseDetails) => state,
);
