import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  financialDataFeatureName,
  FinancialDataModuleState,
} from "../state/financial-data.state";

export const MinimumAmountPospaidRootSelector = createFeatureSelector<
  FinancialDataModuleState
>(financialDataFeatureName);

export const selectMinimumAmountPospaid = createSelector(
  MinimumAmountPospaidRootSelector,
  (state: FinancialDataModuleState) => state.minimumAmountPospaidReducer,
);
