import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonsService } from "../shared/services/commons.service";

@Component({
  selector: "fp-overdraft",
  templateUrl: "./overdraft-account.component.html",
})
export class OverdraftAccountComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  account: IAccountsResponseDetails;
  message: string;
  constructor(
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private currencyPipe: CurrencyPipe,
  ) {}

  ngOnInit(): void {
    this.getAccountSelected();
  }

  navigateToRecharge() {
    this.commonsService.navigate("transactions/recharge");
  }

  getAccountSelected(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccountsResponseDetails) => {
        this.account = JSON.parse(JSON.stringify(action));
        this.account.balance *= -1;
        this.account.approximateValue =
          Math.ceil(this.account.balance / 10000) * 10000;
        this.message = `Debe asignar mínimo ${this.currencyPipe.transform(
          this.account.approximateValue,
          "COP",
          "symbol",
          "1.0-0",
        )} para poder cambiar de modalidad`;
      });
  }

  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }
}
