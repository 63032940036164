import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GenericErrorComponent } from "./generic-error.component";

export const genericErrorRootRoute = "error";
const routes: Routes = [
  {
    component: GenericErrorComponent,
    path: "",
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class GenericErrorRoutingModule {}
