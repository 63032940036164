import { AbstractControl, ValidatorFn } from "@angular/forms";

export function onlyDigitsAndDots(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? control.value.toString().match(/^[0-9.]*$/)
        ? null
        : {
            onlyDigitsAndDots: Number(
              control.value.toString().replace(/\./g, ""),
            ),
          }
      : null;
}

export function maxValue(value: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? Number(control.value.toString().replace(/\./g, "")) <= value
        ? null
        : { maxValue: Number(control.value.toString().replace(/\./g, "")) }
      : null;
}

export function minValue(value: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? Number(control.value.toString().replace(/\./g, "")) >= value
        ? null
        : { minValue: Number(control.value.toString().replace(/\./g, "")) }
      : null;
}
