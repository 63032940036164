import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignInGuard } from "./guards/signin.guard";

export const signInRootRoute = "signin";
const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./modules/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  {
    path: "new-password",
    loadChildren: () =>
      import("./modules/new-password/new-password.module").then(
        (m) => m.NewPasswordModule,
      ),
    canActivate: [SignInGuard],
  },
  {
    path: "identity-verification",
    loadChildren: () =>
      import(
        // eslint-disable-next-line comma-dangle
        "./modules/identity-verification/identity-verification.module"
      ).then((m) => m.IdentityVerificationModule),
    canActivate: [SignInGuard],
  },
  {
    path: "successful",
    loadChildren: () =>
      import("./modules/successful/successful.module").then(
        (m) => m.SuccessfulModule,
      ),
    canActivate: [SignInGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignInRoutingModule {}
