import { createAction, props } from "@ngrx/store";
import { IDeliveryInfo } from "@tag/models/delivery";

export const enum TypeDeliveryInformationActions {
  tagSetDeliveryString = "[shipping] set delivery information",
}

export const setDeliveryInformation = createAction(
  TypeDeliveryInformationActions.tagSetDeliveryString,
  props<IDeliveryInfo>(),
);
