import { createAction } from "@ngrx/store";
import { IMinimumAmountRequest } from "../../models/financial-data";

export const enum TypeActionsMinimumAmountPospaid {
  minimumAmountPospaidResetString = "[POSTPAID Minimum amount] reset information",
  minimumAmountPospaidSaveString = "[POSTPAID Minimum amount] save service minimum amount",
  minimumAmountPospaidErrorString = "[POSTPAID Minimum amount] error service minimum amount",
  minimumAmountPospaidLoadString = "[POSTPAID Minimum amount] load service minimum amount",
}
/**
 * Action to set vehicle information
 */

export const minimumAmountPospaidReset = createAction(
  TypeActionsMinimumAmountPospaid.minimumAmountPospaidResetString,
);

export const minimumAmountPospaidSave = createAction(
  TypeActionsMinimumAmountPospaid.minimumAmountPospaidSaveString,
  (data: any) => ({ data }),
);

export const minimumAmountPospaidLoad = createAction(
  TypeActionsMinimumAmountPospaid.minimumAmountPospaidLoadString,
  (dataServiceMinimumAmount: IMinimumAmountRequest) => ({
    dataServiceMinimumAmount,
  }),
);

export const minimumAmountPospaidError = createAction(
  TypeActionsMinimumAmountPospaid.minimumAmountPospaidErrorString,
  (error: any) => ({ error }),
);
