import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "fp-page-message",
  templateUrl: "./page-message.component.html",
  styleUrls: ["./page-message.component.scss"],
})
export class PageMessageComponent implements OnDestroy, AfterViewInit {
  @HostBinding("class") fpPageMessage = "fp-page-message";
  @Input()
  icon: string;
  @Input()
  title: string;
  @Input()
  title2: string;
  @Input()
  title3: string;
  @Input()
  title4: string;
  @Input()
  messageSanitizer: SafeHtml;
  @Input()
  labelButton: string;
  @Input()
  labelBack: string;
  @Input()
  labelButtonSecond: string;
  @Input()
  twoAction: boolean = false;
  @Input()
  big: boolean = false;
  titleSecurity: SafeHtml;
  @Input() set message(text: string) {
    this.messageSanitizer = this.sanitizer.bypassSecurityTrustHtml(text);
  }

  @Output() goNext: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @ViewChild("btnContinue") btnContinue: ElementRef;

  constructor(private sanitizer: DomSanitizer) {}

  /**
   * After view
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.blockScroll();
    }, 500);
  }

  /**
   * Destroy component
   */
  ngOnDestroy() {
    document.body.className = "";
  }

  /**
   * block scroll in screens that dont hidde continue button
   */
  public blockScroll() {
    const bounding = this.btnContinue.nativeElement.getBoundingClientRect();
    if (
      bounding.bottom <
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      document.body.className = "no-overflow";
    }
  }

  next(param?: string) {
    this.goNext.emit(param);
  }

  back(): void {
    this.goBack.emit();
  }
}
