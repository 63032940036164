import { INavegate } from "@app/models/pages";
import { IAccountInfo } from "@postpaid/models/account-info";
import { IAvailableAccounts } from "@postpaid/models/available-accounts";
import {
  // IEntities,
  IEntitiesAll,
  IProductAssociation,
} from "@postpaid/models/entity";
import {
  IMoneyBack,
  IMoneyPartialBack,
  IServiceMoneyBack,
} from "@postpaid/models/money-back";
import {
  IRechargeAccountMode,
  // IServices,
} from "@postpaid/models/recharge-account";
import { ISelectMode } from "@postpaid/models/select-mode";
import { IServiceAccount } from "@postpaid/models/service-account";

export const PostpaidFeatureName = "PostpaidState";

export type PostpaidModuleState = Readonly<{
  selectModeReducer: ISelectMode;
  accountInfoReducer: IAccountInfo;
  availableAccountsReducer: IAvailableAccounts;
  // financialProductsReducer: IEntities;
  financialProductsAllReducer: IEntitiesAll;
  rechargeAccountReducer: IRechargeAccountMode;
  associateProductsReducer: IProductAssociation;
  // minimumAmountReducer: IServices;
  moneyBackReducer: IMoneyBack;
  moneyPartialBackReducer: IMoneyPartialBack;
  postPaidPagesReducer: INavegate;
  serviceAccountReducer: IServiceAccount;
  serviceCreateMoneyBackReducer: IServiceMoneyBack;
  serviceCreateMoneyPartialBackReducer: IServiceMoneyBack;
  serviceUpDateMoneyPartialBackReducer: IServiceMoneyBack;
}>;
