import { IDocumentType } from "@app/models/form-validation-messages";
import { createAction } from "@ngrx/store";

export const enum TypeActions {
  identificationTypesLoadString = "[identificationTypes] Loading data",
  identificationTypesSaveString = "[identificationTypes] Successful data loaded",
  identificationTypesErrorString = "[identificationTypes] Save error response",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const identificationTypesLoad = createAction(
  TypeActions.identificationTypesLoadString,
  // (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const identificationTypesSave = createAction(
  TypeActions.identificationTypesSaveString,
  (data: IDocumentType[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const identificationTypesError = createAction(
  TypeActions.identificationTypesErrorString,
  (error: any) => ({ error }),
);
