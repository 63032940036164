<fp-modal class="fp-payment-method" typeModal="custom"
    [hideClose]="'false'" [id]="'fp-new-vehicle'" [btnAccept]="'modal-new.btn' | translate"
    [showConfirm]="true">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-payment-method-icon"
                src="assets/images/vehicle/new-vehicle.svg">
        </div>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">{{'modal-new.title' | translate}}</h1>
            <div>
                <p class="fp-payment-method-message">
                    {{'modal-new.message' | translate}}
                    <a class="download-link" href="mailto:registro@facilpass.com">{{'modal-new.message_email' | translate}}</a>
                </p>
                <p class="fp-payment-method-message">
                    <a class="download-link" (click)="downloadTemplateGuide()">{{'modal-new.download_link' | translate}}</a>
                </p>
            </div>
        </div>
    </ng-template>
</fp-modal>