import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeReportsPagesActions {
  reportsPageString = "[REPORTS] set pages",
}

/**
 * Action to set data page
 */
export const ReportsSetPage = createAction(
  TypeReportsPagesActions.reportsPageString,
  (pages: IPage[]) => ({ pages }),
);
