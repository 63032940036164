import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { CoreModule } from "@core/core.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { InputComponent } from "./input.component";
import { TranslateService } from "./service/translate.service";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateService,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    CoreModule,
  ],
  exports: [InputComponent],
  declarations: [InputComponent],
})
export class InputModule {}
