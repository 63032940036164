import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { IPt, IServiceBody } from "../../models/home";

@Pipe({
  name: "filterService",
})
export class FilterServicePipe implements PipeTransform {
  dateFilter = new Date();
  transform(
    value: [IServiceBody[]],
    textFilterDay: string,
    service: string,
    multiple: boolean = false,
    typeStationGroup: "length" | "report" | "group" = "group",
  ) {
    let allStations = [];
    const filterDay: number =
      textFilterDay.substring(0, 2) === "Ho"
        ? 0
        : Number(textFilterDay.substring(0, 2));

    const dateLimit = moment(this.dateFilter)
      .subtract(filterDay, "d")
      .format("YYYY-MM-DD");
    const valueTransform = JSON.parse(JSON.stringify(value));
    allStations = valueTransform.filter(
      (p) =>
        p[0].code === service &&
        moment(p[0].date).isSameOrAfter(dateLimit, "day"),
    );

    if (!multiple) {
      let suma: number = 0;
      allStations.forEach((element) => {
        element[0].pt.forEach((station) => {
          suma += station.total;
        });
      });
      return suma;
    } else {
      try {
        let stations = [];
        allStations.forEach((element) => {
          stations = [...stations.concat(element[0].pt)];
        });

        const keyValues = [];
        stations.forEach((element) => {
          keyValues.push(element.name);
        });
        let stationGroup = [];
        const uniqueValues = keyValues.filter(
          (val, index, array) => array.indexOf(val) === index,
        );

        let valueSameKey: IPt[];
        let toll: IPt;
        let total: number;

        uniqueValues.forEach((element) => {
          total = 0;
          valueSameKey = stations.filter((p) => p.name === element);
          for (const iterator of valueSameKey) {
            total = total + iterator.total;
          }
          toll = valueSameKey[0];
          if (typeStationGroup === "group") {
            toll.total = total;
          }
          stationGroup.push(toll);
        });

        stationGroup.sort((a, b) => (a.total > b.total ? -1 : 1));
        if (typeStationGroup === "group") {
          stationGroup = stationGroup.slice(0, 5);
          return stationGroup;
        } else if (typeStationGroup === "report") {
          return allStations.reduce((acc, val) => acc.concat(val), []);
        } else if (typeStationGroup === "length") {
          return stationGroup.length;
        }
      } catch (error) {
        return allStations;
      }
    }
  }
}
