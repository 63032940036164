import { createAction } from "@ngrx/store";
import { IDataCities } from "@shared/models/cities";

export const enum TypeActions {
  citiesLoadString = "[CITIES] Loading data",
  citiesSaveString = "[CITIES] Successful data loaded",
  citiesErrorString = "[CITIES] Save error response",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const citiesLoad = createAction(TypeActions.citiesLoadString);
/**
 * Action to detect event loaded vehicles categories data
 */
export const citiesSave = createAction(
  TypeActions.citiesSaveString,
  (data: IDataCities[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const citiesError = createAction(
  TypeActions.citiesErrorString,
  (error: any) => ({ error }),
);
