import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeSingUpPagesActions {
  signUpPageString = "[SIGNUP] set pages",
  signUpResetPageString = "[SIGNUP] reset pages",
}

/**
 * Action to set data page
 */
export const signupSetPage = createAction(
  TypeSingUpPagesActions.signUpPageString,
  (pages: IPage[]) => ({ pages }),
);
