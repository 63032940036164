import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const SignUpCompanyRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectSignUpCompany = createSelector(
  SignUpCompanyRootSelector,
  (state: SignUpModuleState) => state.signUpCompanyReducer,
);
