<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" urlBack="/home" [template]="'fourTemplate'"
    exit="true" headerType="secondary" showfooterBallsRight="false" [actionType]="'logout'">
    <ng-template #tplRef>
        <div class="fp-purchase-detail-container">
            <div class="form-container">
                <form novalidate [formGroup]="formTopUp">
                    <div class="title_box">
                        <h1 class="fp-h1 top-up title">{{ "title" | translate }}</h1>
                        <h2 class="fp-body-one subtitle"> {{"subtitleRechargePage" | translate }}</h2>
                    </div>
                    <div class="fp-card-detail">
                        <div class="select-content">
                            <label class="form-label" for="accountSelect">
                                {{ 'account' | translate }}</label>
                            <mat-form-field appearance="fill">
                                <mat-select #accountSelect formControlName="account"
                                    (selectionChange)="changeSelectedAccount($event.value, $event)"
                                    panelClass="fp-address-select">
                                    <ng-container *ngFor="let account of accounts">

                                        <mat-option *ngIf="account.statusAccount" class="option-select"
                                            [value]="account.accountId">
                                            {{ "accountWith" | translate }}
                                            {{ (account.accountType === "PRE" ? "prepaidRecharge" : "automaticRecharge") | translate }}
                                            {{ account.accountId }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                        <fp-input #inputAmount class="form-group input-vehicle" labelClass="form-label"
                            [label]="'min_amount' | translate:{minAmount:minAmount | currency:'COP':'': '1.0-0'}"
                            [autoFocus]="false" viewSpaceInput="true" [viewErrorsInput]="true"
                            [viewIconErrorInput]="true" inputClass="input-large" typeInput="text" nameInput="amount"
                            idInput="amount" [formControl]="formTopUp.controls['amount']"
                            [inputValidationMessages]="TopUpErrorMessages.error" [currencyMode]="true"
                            (onBlur)="validateAmount($event.target.value)"
                            (onInput)="validateAmountTag($event.target.value)"
                            [valueTranslate]="minAmount | currency:'COP':'': '1.0-0'" regexp="^[0-9\.\$ ]+$"
                            matchRegexp="([0-9\.\$ ]*)">
                        </fp-input>
                    </div>
                    <ng-container *ngIf="showBannerAutomaticRecharge && allAccountsPRE">
                      <ng-container *ngxPermissionsOnly="['CUESEL', 'CUEMOD']">
                        <ng-container *ngIf="selectedAccountType === 'PRE'">
                          <fp-alert-message
                            type="info"
                            [title]="'saveTimeByRechargingWithAutomaticReloading' | translate"
                            [footerText]="'switchToAutoRecharge' | translate"
                            alignFooterText="right"
                            [message]="'changeYourPaymentMethodRechargeAutomaticallyAndNeverRunOutOfBalance' | translate"
                            [backgroundWhite]="true"
                            [isRedirect]="true"
                            (click)="goAutomaticRechargeConfig()"
                          ></fp-alert-message>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <div #btnContinue class=" form-group-submit">
                        <div class="fp-container-btn submit-btn">
                            <button class="fp-btn-primary" (click)="createTransaction()"
                                [disabled]="formTopUp.invalid || viewMessageError || creatingTransaction">
                                <span>{{ "continue" | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</fp-card-body>

<ng-template #reminder>
    <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
        [description]="'info_message.description' | translate:{time:time}" [type]="typeMessage">
    </fp-snack-message>
</ng-template>

<ng-template #notify>
    <fp-snack-message [title]="'alert.title' | translate" [description]="errorCode
    ? ('alert.description_error' | translate:{'error': errorCode})
    : (pendingTransactionMessage ? pendingTransactionMessage : ('alert.description' | translate))" type="error">
    </fp-snack-message>
</ng-template>

<ng-template #changeModality>
  <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
      [description]="'yourAccountSuccessfullySwitchedToPrepaidRecharge' | translate" type="success">
  </fp-snack-message>
</ng-template>
