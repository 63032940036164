import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { AlertsTypes } from "@app/modules/shared/models/alert-message.enum";
import { ICities, IDataCities } from "@app/modules/shared/models/cities";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { InputComponent } from "@app/modules/theme/components/input/input.component";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ICreateTransaction } from "../../models/transaction.interface";
import { TransactionsService } from "../../services/transactions.service";

@Component({
  selector: "fp-unique-recharge",
  templateUrl: "./unique-recharge.component.html",
  styleUrls: ["./unique-recharge.component.scss"],
})
export class UniqueRechargeComponent implements OnInit, OnDestroy {
  @ViewChild("inputEmail") inputEmail: InputComponent;
  @ViewChild("idnumber") inputIdNumber: InputComponent;
  /** Host binding */
  @HostBinding("class") fpRechargeTransaction = "fp-recharge-transaction";
  /** Snackbar message */
  @ViewChild("snackbarMsg", { static: true }) snackbarMsg: TemplateRef<any>;
  /** Transaction data */
  transactionData: ICreateTransaction;
  /** Form group */
  transactionRechargeForm: UntypedFormGroup;
  /** Loading flag */
  loading: boolean = false;
  /** Loading flag */
  sending: boolean = false;
  /** Show details transaction */
  showDetailsTransaction: boolean = false;
  /** Account type */
  accountType: string = "PRE";
  /** Payment method selected */
  paymentMethodSelected: string = "";
  /** Document types */
  documentTypes = [
    { label: "NIT", value: "NIT" },
    { label: "C.C", value: "CC" },
    { label: "C.E", value: "CE" },
  ];

  documentTypeSelected: string;
  /** Cities */
  cities: IDataCities[];
  /** Destroy subject */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Get url tokenization flag */
  getUrlTokenization = false;
  /** Message Sanckbar */
  messageSnackbar: string = "";
  /** Message type */
  messageType: string = AlertsTypes.WARNING;

  @ViewChild("inputAutocomplete") inputAutocomplete: InputComponent;

  inputClass: string = "input-large autocomplete-open";

  labelName: string = "";
  labelPhone: string = "";

  RechargeErrorMessages = {
    documentNumber: [
      {
        type: "required",
        message: "form_errors.identificationNit.required",
      },
      {
        type: "pattern",
        message: "form_errors.identificationNit.pattern",
      },
    ],
    name: [
      {
        type: "required",
        message: "form_errors.businessName.required",
      },
      {
        type: "pattern",
        message: "form_errors.businessName.pattern",
      },
      {
        type: "minlength",
        message: "form_errors.businessName.minlength",
      },
      {
        type: "maxlength",
        message: "form_errors.businessName.maxlength",
      },
    ],
    cities: [
      { type: "required", message: "form_errors.cities.required" },
      { type: "pattern", message: "form_errors.cities.pattern" },
      {
        type: "option_invalid",
        message: "form_errors.cities.invalid",
      },
    ],
    email: [
      {
        type: "required",
        message: "form_errors.email.register_error_required",
      },
      {
        type: "pattern",
        message: "form_errors.email.register_error_email",
      },
    ],
    confirmEmail: [
      {
        type: "required",
        message: "form_errors.confirmEmail.register_error_required",
      },
      {
        type: "pattern",
        message: "form_errors.confirmEmail.register_error_email",
      },
      {
        type: "emailMismatch",
        message: "form_errors.confirmEmail.email_mismatch",
      },
    ],
    phone: [
      {
        type: "required",
        message: "form_errors.phone.required",
      },
      {
        type: "pattern",
        message: "form_errors.phone.pattern",
      },
      {
        type: "minlength",
        message: "form_errors.phone.minlength",
      },
    ],
    address: [
      { type: "required", message: "form_errors.cities.required" },
      { type: "pattern", message: "form_errors.address_fields.pattern" },
    ],
    businessName: [
      {
        type: "required",
        message: "form_errors.businessName.required",
      },
      {
        type: "pattern",
        message: "form_errors.businessName.pattern",
      },
      {
        type: "minlength",
        message: "form_errors.businessName.minlength",
      },
      {
        type: "maxlength",
        message: "form_errors.businessName.maxlength",
      },
    ],
  };

  viewMessageError: boolean;

  /**
   * Constructor
   * @param transactionsService Transactions service
   */
  constructor(
    private transactionsService: TransactionsService,
    private commonsService: CommonsService,
    private formBuilder: FormBuilder,
    private appFacade: AppFacade,
    private modalService: ModalService,
    private notifyService: NotifyService,
  ) {}

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    this.loading = true;

    this.transactionData = JSON.parse(
      sessionStorage.getItem("transactionDetail"),
    );
    this.initForm();
    this.getCities();

    this.transactionRechargeForm
      .get("amount")
      ?.setValue(this.transactionData.payment.totalAmount);
    this.transactionRechargeForm
      .get("documentType")
      .setValue(this.transactionData.payment.clientDocumentType);
    this.transactionRechargeForm
      .get("documentNumber")
      .setValue(this.transactionData.payment.clientDocument);
    this.transactionRechargeForm
      .get("name")
      .setValue(this.transactionData.payment.clientFullName);
    this.transactionRechargeForm
      .get("phone")
      .setValue(this.transactionData.payment.clientMobilePhone);
    this.transactionRechargeForm
      .get("email")
      .setValue(this.transactionData.payment.clientEmail);
    this.transactionRechargeForm
      .get("confirmEmail")
      .setValue(this.transactionData.payment.clientEmail);
    this.transactionRechargeForm
      .get("address")
      .setValue(this.transactionData.payment.billingAddress);

    sessionStorage.setItem("customToken", this.transactionData.token);
    this.selectDocumentType(this.transactionData.payment.clientDocumentType);

    this.transactionRechargeForm
      .get("documentType")
      .valueChanges.subscribe((documentTypeValue) => {
        this.updateNameValidators(documentTypeValue);
        this.documentTypeSelected = documentTypeValue;
      });

    const initialDocumentType = this.transactionRechargeForm.get("documentType")
      .value;
    this.updateNameValidators(initialDocumentType);
    this.documentTypeSelected = initialDocumentType;
    this.loading = false;
  }

  updateNameValidators(documentTypeValue: string) {
    const nameControl = this.transactionRechargeForm.get("name");

    if (documentTypeValue === "NIT") {
      nameControl.setValidators([
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ\\&0-9' ]+$"),
        Validators.minLength(3),
        Validators.maxLength(60),
      ]);
    } else {
      nameControl.setValidators([
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        Validators.minLength(3),
        Validators.maxLength(60),
      ]);
    }

    nameControl.updateValueAndValidity();
  }

  /**
   * Get info cities
   */
  async getCities(): Promise<any> {
    const promiseCities = await this.selectCities$.pipe(take(1)).toPromise();
    if (promiseCities.data === null) {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      this.appFacade.getCities();
      this.selectCities2$.pipe(takeUntil(this.destroy$)).subscribe((cities) => {
        if (cities.data) {
          this.cities = cities.data;
        }
        this.appFacade.setLoaderHide();
      });
    }
    if (promiseCities.data !== null) {
      this.cities = promiseCities.data;
    }
  }

  /**
   * Init form
   */
  initForm() {
    this.transactionRechargeForm = this.formBuilder.group(
      {
        paymentMethod: ["", Validators.required],
        amount: [0, Validators.required],
        documentType: ["", Validators.required],
        documentNumber: [
          "",
          [
            Validators.required,
            Validators.pattern("^[0-9]{5,10}$"), // Correcto, ahora está en el array de validadores síncronos.
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^([a-zA-Z0-9-+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
            ),
          ],
        ],
        confirmEmail: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^([a-zA-Z0-9-+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
            ),
          ],
        ],
        phone: [
          "",
          [Validators.required, Validators.pattern("^[3]{1}[0-9]{9}$")],
        ],
        address: ["", [Validators.required]],
        city: ["", Validators.required],
        name: [""],
        acceptTerms: [false, Validators.requiredTrue],
      },
      { validators: this.emailMatchValidator() }, // Añadir el validador personalizado aquí
    );
  }

  private emailMatchValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const emailBilling = group.get("email");
      const confirmEmail = group.get("confirmEmail");

      if (
        emailBilling &&
        confirmEmail &&
        emailBilling.value !== confirmEmail.value
      ) {
        confirmEmail.setErrors({ emailMismatch: true });
        return { emailMismatch: true };
      } else {
        if (confirmEmail.hasError("emailMismatch")) {
          confirmEmail.setErrors(null);
        }
        return null;
      }
    };
  }

  /**
   * Select payment method
   * @param methodId string
   */
  selectPaymentMethod(methodId: string) {
    this.transactionRechargeForm.get("paymentMethod")?.setValue(methodId);
    this.showDetailsTransaction = true;
    this.paymentMethodSelected = this.transactionData?.paymentMethods.find(
      (method) => method.id === methodId,
    )?.name;

    setTimeout(() => {
      this.setDefaultCity(
        this.transactionData.payment.billingCity,
        this.transactionData.payment.billingProvince,
      );
    }, 1000);
  }

  /**
   * Select document type
   * @param value string
   */
  selectDocumentType(value: string) {
    this.documentTypeSelected = value;
    this.transactionRechargeForm.get("documentType")?.setValue(value);
    if (value == "NIT") {
      this.labelName = "label_company_name";
      this.labelPhone = "label_company_phone";
      return;
    }
    this.labelName = "label_name";
    this.labelPhone = "label_phone";
  }

  /**
   * Open modal confirm payment method
   */
  openModalPaymentMethod(): void {
    if (this.transactionRechargeForm.valid) {
      this.modalService?.open("fp-modal-confirm-exit-facilpass");
    }
  }

  /**
   * Close modal
   */
  closeModal(): void {
    if (!this.getUrlTokenization) {
      this.modalService?.close("fp-modal-confirm-exit-facilpass", false);
    }
  }

  /**
   * Get url redirect add payment method
   */
  getUrlRedirectPaymentMethod() {
    if (!this.getUrlTokenization) {
      this.getUrlTokenization = true;
      this.updateTransaction();
    }
  }

  onPaste(event: Event): void {
    event.preventDefault();
  }

  /**
   * Update transaction
   */
  async updateTransaction() {
    this.sending = true;
    const values = this.transactionRechargeForm.value;
    const [city, department] = values.city.split(" - ");
    const payload = {
      clientFullName: values.name,
      clientDocumentType: values.documentType,
      clientDocument: values.documentNumber,
      clientMobilePhone: values.phone,
      clientEmail: values.email,
      billingCity: city,
      billingProvince: department,
      billingCityCode: this.inputAutocomplete.optionSelected.value,
      billingCountry: "CO",
      billingAddress: values.address,
      paymentMethod: values.paymentMethod,
    };
    const request = await this.transactionsService.updateTransaction(
      this.transactionData.payment.id,
      payload,
    );
    request.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        this.getUrlTokenization = false;
        this.closeModal();
        if (response.data.message) {
          this.messageType = AlertsTypes.WARNING;
          this.messageSnackbar = response.data.message;
          this.notifyService.showNotify(this.snackbarMsg, "", null);
        } else {
          location.href = response.data.link;
        }
      },
      error: (error) => {
        this.sending = false;
        this.getUrlTokenization = false;
        this.messageType = AlertsTypes.ERROR;
        this.messageSnackbar = error.response.data.message;
        this.notifyService.showNotify(this.snackbarMsg, "", null);

        if (
          error.response.data.message &&
          error.response.data.message.includes(
            "ya fue enviado al proveedor de pagos",
          )
        ) {
          setTimeout(() => {
            this.commonsService.navigate("/transactions/recharge");
          }, 5000);
        }
      },
    });
  }

  inputEvent($event, inputModal: any): void {
    if (
      $event.inputType === "insertFromPaste" ||
      $event.inputType === "insertText"
    ) {
      if (
        inputModal.formControl.value.length > 0 &&
        inputModal.formControl.value[0] !== "3"
      ) {
        this.viewMessageError = true;
        this.setErrorInputGeneric();
      } else {
        this.viewMessageError = false;
      }
    }
    return;
  }

  /**
   * Set errot to Input in form
   *
   * @param control
   * @param error
   * @param input
   */
  setErrorInputGeneric(
    control: string = "phone",
    error: object = { pattern: true },
    input: string = "inputRegister",
  ): void {
    this.transactionRechargeForm.get(control).setErrors(error);
    this.transactionRechargeForm.get(control).markAsTouched();
    switch (input) {
      case "inputRegister":
      case "inputEmail":
        this.inputEmail.updateInput();
        break;
      case "inputIdNumber":
        this.inputIdNumber.updateInput();
        break;
    }
  }

  /**
   * Input blur
   */
  inputOnBlur(): void {
    if (this.inputAutocomplete.optionSelected) {
      this.setErrorInput(false);
    } else {
      this.setErrorInput(true);
    }
  }

  /**
   * Set errot to Input in form
   *
   * @param setErrot boolean error
   */
  setErrorInput(setErrot: boolean): void {
    if (setErrot) {
      this.transactionRechargeForm
        .get("city")
        .setErrors({ option_invalid: true });
      this.transactionRechargeForm.get("city").markAsTouched();
    } else {
      this.transactionRechargeForm.get("city").updateValueAndValidity();
    }
    this.inputAutocomplete.updateInput();
  }

  /**
   * onRestInput
   */
  onRestInput(): void {
    this.transactionRechargeForm.controls.city.setValue(null);
  }

  setDefaultCity(billingCity: string, billingProvince: string) {
    const currentCity = `${billingCity.trim()} - ${billingProvince.trim()}`;

    const dataCity = this.cities.find(
      (city) => city.description.toLowerCase() == currentCity.toLowerCase(),
    );
    this.transactionRechargeForm.controls.city.setValue(dataCity.description);
    this.inputAutocomplete.optionSelected = dataCity;
    this.inputAutocomplete.updateInput();
  }

  /**
   * Get selector Cities
   */
  get selectCities$(): Observable<ICities> {
    return this.appFacade.selectCities$;
  }

  /**
   * Get selector Cities
   */
  get selectCities2$(): Observable<ICities> {
    return this.appFacade.selectCities2$;
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // https://de6uaogg2cv7r.cloudfront.net/#/account/unique-recharge/validate?id=79427e4b-6cb7-4e65-bc0a-4038e9bcffe3
  // este id se envia en la cuarta petición
}
