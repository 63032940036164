<fp-card-body
  [template]="'secondTemplate'"
  [urlBack]="automaticRechargeData?.getAllCards ? '/home' : !updating ? '/account/automatic-recharge-config' : '/account/administration'"
  backOption="true"
  headerType="secondary"
>
  <ng-template #tplRef>
      <div class="container">
          <div class="box">
              <h1 class="fp-h1">{{ 'chooseAPaymentMethod' | translate }}</h1>
              <ng-container *ngIf="!automaticRechargeData?.getAllCards">
                <h2 class="fp-body-one subtitle"> {{ 'subtitlePaymentMethod' | translate}}</h2>
              </ng-container>
              <form novalidate [formGroup]="formPaymentMethod">
                  <div class="container-cards">
                    <div class="card-config" *ngIf="!automaticRechargeData?.getAllCards">
                      <fp-account-info [accountDataFromParent]="accountData"></fp-account-info>
                    </div>
                    <div class="card-config">
                      <div class="payment-methods">
                        <div class="payment-methods-empty" *ngIf="cards?.length <= 0 && !loading">
                          <img class="payment-methods-empty__icon" src="assets/images/common/empty-payments-icon.svg" alt="Icon payments methods empty">
                          <p class="payment-methods-empty__text">{{ 'youDoNotHaveAnActivePaymentMethodYet' | translate }}</p>
                        </div>
                        <div class="cards-list" *ngIf="cards?.length > 0 || loading">
                          <ng-container *ngIf="!automaticRechargeData?.getAllCards">
                            <div class="cards-list__message">{{ 'whichCardAreYouGoingToRechargeWith' | translate }}</div>
                          </ng-container>
                          <ng-container *ngIf="!loading">
                            <div class="card" *ngFor="let card of cards" [ngClass]="{'card--blocked': card.status === statusCardValidatedEnum.BLOCKED}">
                              <div class="container-info-card">
                                <span class="container-info-card__blocked-message" *ngIf="card.status === statusCardValidatedEnum.BLOCKED">
                                  {{ 'blockedPaymentMethod' | translate }}
                                </span>
                                <div class="info-card">
                                  <img [src]="cardsBrands[card.brand]" [alt]="card.nameMask" class="info-card__logo">
                                  <span class="info-card__mask">{{ card.cardMask }}</span>
                                </div>
                                <span class="blocked-description" *ngIf="card.status === statusCardValidatedEnum.BLOCKED">
                                  <img alt="icon info" class="blocked-description__icon" src="assets/images/common/icon-info-outline.svg" />
                                  {{ card.statusMessage }}. {{ 'pleaseRemoveItFromYourPaymentMethods' | translate }}
                                </span>
                              </div>
                              <fp-radiobutton formControlName="paymentMethod" name="paymentMethod"
                                  [value]="card.id"
                                  inputId="paymentMethod_{{ card.id }}"
                                  (onClick)="tagSelectCard(card.id)"
                                  *ngIf="!automaticRechargeData?.getAllCards"
                                >
                              </fp-radiobutton>
                              <img
                                src="assets/images/common/trash.svg"
                                alt="Trash icon" class="card__trash-icon"
                                *ngIf="automaticRechargeData?.getAllCards && cardSelectedToDelete !== card.id"
                                (click)="deletePaymentMethod(card.id)"
                                [ngClass]="{'card__trash-icon--disabled': sendingData && cardSelectedToDelete !== ''}"
                              />
                              <span
                                class="loader"
                                *ngIf="automaticRechargeData?.getAllCards && sendingData && cardSelectedToDelete === card.id">
                              </span>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="loading">
                            <div class="card-skeleton skeleton" *ngFor="let card of ['', '']" ></div>
                          </ng-container>
                        </div>
                        <button class="add-payment-method" (click)="openModal()">
                          <img class="add-payment-method__icon" src="assets/images/common/add-payment-method-icon.svg" alt="Add payment method icon">
                          <span class="add-payment-method__text">{{ 'addNewPaymentMethod' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group-submit" *ngIf="formPaymentMethod.valid && accountData && !automaticRechargeData?.getAllCards">
                    <div class="fp-container-btn">
                      <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                        [disabled]="!formPaymentMethod.valid || sendingData || !accountData || currentCard === formPaymentMethod.get('paymentMethod').value || selectedCardBlocked"
                        (click)="updating ? openModalPaymentMethod() : sendDataForm()">
                        <span class="btn-content-text" *ngIf="!sendingData">
                            {{ (updating ? 'btn_save' : 'activateAutomaticRecharge') | translate }}
                        </span>
                        <span
                          class="loader"
                          *ngIf="sendingData">
                        </span>
                      </button>
                    </div>
                  </div>
              </form>
          </div>
      </div>
  </ng-template>
</fp-card-body>

<fp-confirmation-modal
  (itemClickActive)="$event?.typeAction === 'continue' ? getUrlRedirectAddPaymentMethod() : closeModal();"
  [title]="'addNewPaymentMethod'">
</fp-confirmation-modal>

<fp-confirmation-modal
  (itemClickActive)="$event?.typeAction === 'continue' ? sendDataForm() : closeModalPaymentMethod();"
  [accountId]="accountData?.accountId">
    <span class="card-selected-info">
      <img [src]="cardsBrands[creditCardSelected?.brand]" alt="Card brand" class="card-selected-info__img">
      {{ creditCardSelected?.cardMask }}
    </span>
</fp-confirmation-modal>

<fp-confirmation-modal
  (itemClickActive)="$event?.typeAction === 'continue' ? gotToSpecificPage() : closeModalDeleteCard();"
  [accountId]="linkedAccounts?.length > 1 ? linkedAccounts : linkedAccounts[0]">
</fp-confirmation-modal>

<fp-loader
  *ngIf="getUrlTokenization"
  [title]="'waitAMoment' | translate"
  [description]="'youWillBeRedirectedToThePaymentGateway' | translate">
</fp-loader>

<ng-template #snackbarMsg>
  <fp-snack-message
    class="fp-snack-message-login"
    (snackMessageEvent)="closeMessage()"
    [description]="messageSuccess | translate"
    [type]="alertsTypes.SUCCESS">
  </fp-snack-message>
</ng-template>

<ng-template #snackbarMsgError>
  <fp-snack-message
    class="fp-snack-message-login"
    (snackMessageEvent)="closeMessage()"
    [description]="messageError | translate"
    [type]="alertsTypes.ERROR">
  </fp-snack-message>
</ng-template>
