import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StatusComponent } from "./status.component";

export const createStatusTransactionRootRoute = "status/:id";
export const createStatusTransactionRootRouteFreeTag = "status";

const routes: Routes = [
  {
    component: StatusComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StatusRoutingModule {}
