import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@core/core.module";
import { InputModule } from "../input/input.module";
import { OtpPasswordComponent } from "./otp-password.component";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputModule, CoreModule],
  exports: [OtpPasswordComponent],
  declarations: [OtpPasswordComponent],
  providers: [],
})
export class OtpPasswordModule {}
