export const pageWhitMenu = [
  {
    route: "/",
  },
  {
    route: "/home",
  },
  {
    route: "/movements",
  },
  {
    route: "/transactions/recharge",
  },
  {
    route: "/options",
  },
  {
    route: "/vehicle/list",
  },
];
