import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  TransactionsFeatureName,
  TransactionsModuleState,
} from "../state/statusTransaction.state";

export const PospaidRechargeAccountRootSelector = createFeatureSelector<
  TransactionsModuleState
>(TransactionsFeatureName);

export const selectPospaidRechargeAccount = createSelector(
  PospaidRechargeAccountRootSelector,
  (state: TransactionsModuleState) => state.postpaidRechargeAccountReducer,
);
