import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { ModalService } from "@theme/components/modal/service/modal.service";
import { Subject } from "rxjs";

@Component({
  selector: "fp-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent implements OnDestroy, OnChanges {
  accountSelected: IAccountsResponseDetails;
  @Input() accountId: string | string[] = "";
  @Input() accountType: string = "";
  @Input() title: string = "";
  @Input() infoBubble: string = "";
  clientId: string = "";
  userId: string = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild("message", { static: true }) tMessage: TemplateRef<any>;
  @Output() itemClickActive = new EventEmitter<{
    payload: string;
    typeAction: string;
  }>();
  accountIdMasked: string | string[] = "";
  accountIdMaskedArray: string[] = [];
  accountIdIsArray = false;

  /**
   * Constructor
   * @param modalService
   */
  constructor(private modalService: ModalService) {}

  /**
   * OnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.accountId = changes.accountId?.currentValue;
    this.accountIdIsArray = Array.isArray(this.accountId);
    if (!this.accountIdIsArray) {
      this.accountIdMasked = this.accountId?.toString().substring(1);
    } else {
      this.accountIdMaskedArray = [];
      Array.from(this.accountId).forEach((id) => {
        this.accountIdMaskedArray.push(id.substring(1));
      });
    }
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * get type action of modal
   */
  modalAction(action: { typeAction: string; payload: string }) {
    switch (action.typeAction) {
      case "continue":
        this.itemClickActive.emit(action);
        break;
      case "exit":
        this.closeModal(action.payload);
        break;
      case "custom":
        window.open("assets/documents/dudas-sobre-GOU.pdf", "_blank");
        break;
      default:
        break;
    }
  }

  /**
   * Close modal
   * @param id string id elemnt Html
   */
  closeModal(id: string) {
    this.modalService.close(id, false);
  }
}
