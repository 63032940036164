import { Component, Input } from "@angular/core";

@Component({
  selector: "fp-transaction-status",
  templateUrl: "./transaction-status.component.html",
  styleUrls: ["./transaction-status.component.scss"],
})
export class TransactionStatusComponent {
  @Input() status: string;
}
