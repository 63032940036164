<fp-card-body [template]="'secondTemplate'" headerType="secondary">
  <ng-template #tplRef>
      <div class="container">
          <div class="box">
            <div
              class="icon-message">
              <img
                src="assets/images/common/toast-success.svg"
                alt="Icon message"
                class="icon-message__icon"
              >
            </div>
            <div class="title-message">
              <span class="title-message__text">
                {{ 'theAutomaticRechargeForYourAccount' | translate }}
                {{ accountData?.accountId }}
                {{ ( updating ? 'wasEditedSuccessfully' : 'isReady') | translate }}
              </span>
            </div>
            <div class="subtitle-message">
              <span class="subtitle-message__text">
                {{ 'belowWePresentTheInformatioYouHaveConfigured' | translate }}
              </span>
            </div>
            <div  class="date-message">
              <span class="date-message__text">
                {{ today | date: 'dd MMMM yyyy / hh:mm a' }}
              </span>
            </div>
            <div class="content-message">
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'automaticRechargeValue' | translate }}</div>
                <div class="content-message-row-item content-message-row-item--outstanding content-message-row-item--outstanding-green">${{ automaticRechargeData?.rechargeValue | formatNumber }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'balanceThatActivatesYourRecharge' | translate }}</div>
                <div class="content-message-row-item content-message-row-item--outstanding">${{ automaticRechargeData?.activeRechargeValue | formatNumber }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'weWillNotifyTheEmail' | translate }}</div>
                <div class="content-message-row-item">{{ automaticRechargeData?.email }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'reference' | translate }}</div>
                <div class="content-message-row-item">{{ automaticRechargeData?.referenceCard }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'paymentMethod' | translate }}</div>
                <div class="content-message-row-item">
                  <img
                    [src]="cardsBrands[automaticRechargeData?.brandCard]"
                    alt="Brand card"
                    class="content-message-row-item__brand"
                  >
                  <span class="content-message-row-item__text">{{ automaticRechargeData?.cardMask }}</span>
                </div>
              </div>
            </div>
            <button class="button-message" (click)="goHomePage()">
              <span class="button-message__text">{{ 'finish' | translate}}</span>
            </button>
          </div>
      </div>
  </ng-template>
</fp-card-body>
