import { Component } from "@angular/core";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { ReportsNavigationService } from "../../services/reports-navigation.service";

@Component({
  selector: "fp-successful",
  templateUrl: "./successful.component.html",
})
export class SuccessfulComponent {
  constructor(
    private commonsService: CommonsService,
    private reportsNavigationService: ReportsNavigationService,
  ) {}

  navigateToReportList() {
    this.reportsNavigationService.clearReportsPagesStore();
    this.commonsService.navigate("/reports/list");
  }
}
