import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountFeatureName, AccountModuleState } from "../state/account.state";

export const AccountPagesRootSelector = createFeatureSelector<
  AccountModuleState
>(AccountFeatureName);

export const selectAccountPages = createSelector(
  AccountPagesRootSelector,
  (state: AccountModuleState) => state.accountPagesReducer,
);
