import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNumber",
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: string): string {
    try {
      const number = value
        ?.toString()
        .replace(/^0+/, "")
        .replace(/\./g, "")
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
      return number ?? "";
    } catch (error) {
      return "";
    }
  }
}
