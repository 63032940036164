import { createAction } from "@ngrx/store";
import { ITimerCode } from "@otpcode/models/otpcode";

export const enum TypeActionsOtpTimer {
  completeSignUpOtpTimerString = "[COMPLETE SIGNUP Otp Timer] timer to loaded otp",
}

export const CompleteSignupOtpTimer = createAction(
  TypeActionsOtpTimer.completeSignUpOtpTimerString,
  (timer: ITimerCode) => ({ timer }),
);
