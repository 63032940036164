import { createAction } from "@ngrx/store";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";

export const enum TypeActionsAutomaticRechargeConfig {
  automaticRechargeData = "[AUTOMATIC RECHARGE] set information",
  automaticRechargeResetData = "[AUTOMATIC RECHARGE] Reset data",
}
/**
 * Action to set accountModality information
 */
export const automaticRecharge = createAction(
  TypeActionsAutomaticRechargeConfig.automaticRechargeData,
  (automaticRechargeConfig: IAutomaticRecharge) => ({
    automaticRechargeConfig,
  }),
);
/**
 * Action to reset data
 */
export const automaticRechargeReset = createAction(
  TypeActionsAutomaticRechargeConfig.automaticRechargeResetData,
);
