import { IRolesAdministrator } from "@app/models/roles-administrator";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { rolesAdministratorFeatureName } from "../state/app.state";

export const rolesAdministratorRootSelector = createFeatureSelector<
  IRolesAdministrator
>(rolesAdministratorFeatureName);

export const selectRolesAdministrator = createSelector(
  rolesAdministratorRootSelector,
  (state: IRolesAdministrator) => state,
);
