import { IInfoDocuemnt } from "../../components/item-document/models/item-document";

export const stepsCarousel: IInfoDocuemnt[] = [
  {
    state: "blocked",
    assets: "assets/images/home/carousel/document.svg",
    description: "Enviar documentos",
    key: "document",
    show: true,
    action: {
      clcik: "open-modal",
      elemen: "fp-upload-documents",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/notification.svg",
    description: "Correos de notificación",
    key: "emails",
    show: false,
    action: {
      clcik: "",
      elemen: "",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/modality.svg",
    description: " Modalidad de pago",
    key: "modality",
    show: true,
    permission: "CUESEL",
    action: {
      clcik: "redirect",
      elemen: "/account/select-modality",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/user.svg",
    description: "Creación de  usuarios",
    key: "create-users",
    show: true,
    permission: "USUCRE",
    action: {
      clcik: "redirect",
      elemen: "/user/create",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/recharge.svg",
    description: "Recargar cuenta",
    key: "recharge",
    show: true,
    permission: "CUEASI",
    action: {
      clcik: "redirect",
      elemen: "/transactions/recharge",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/create-vehicle.svg",
    description: "Creación de Vehículos",
    key: "create-vehicles",
    show: true,
    action: {
      clcik: "redirect",
      elemen: "/vehicles/list",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/tag.svg",
    description: "Activación de Vehículos",
    key: "activation-vehicles",
    show: true,
    permission: "VEHCON",
    action: {
      clcik: "redirect",
      elemen: "/vehicles/list",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/changge-modality.svg",
    description: "Cambio modalidad",
    key: "change-modality",
    show: false,
    action: {
      clcik: "redirect",
      elemen: "/postpaid/paymentmode/paymentmethod",
    },
  },
  {
    state: "blocked",
    assets: "assets/images/home/carousel/refund.svg",
    description: "Devolución dinero",
    key: "refund",
    show: false,
    action: {
      clcik: "redirect",
      elemen: "/postpaid/cancelAccount/money-partial-back",
    },
  },
];
