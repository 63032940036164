import { Injectable } from "@angular/core";
import { IPseBanks } from "@app/models/pse";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { pseBanksLoad } from "../actions/pse-banks.actions";
import { selectPseBanks } from "../selector/pse-banks.selector";
import { PaymentModuleState } from "../state/payment.state";

@Injectable()
export class PaymentFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectPseBanks$: Observable<IPseBanks> = this.store.pipe(
    select(selectPseBanks),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectPseBanks2$: Observable<IPseBanks> = this.store.pipe(
    select(selectPseBanks),
  );
  constructor(private store: Store<PaymentModuleState>) {}
  /**
   * get pse banks data
   */
  public getPseBanks(): void {
    this.store.dispatch(pseBanksLoad());
  }
}
