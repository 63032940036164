import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const TagRechargeRootSelector = createFeatureSelector<TagModuleState>(
  TagFeatureName,
);

export const selectTagRecharge = createSelector(
  TagRechargeRootSelector,
  (state: TagModuleState) => state.tagRechargeReducer,
);
