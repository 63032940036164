import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const SignUpRepresentativeRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectSignUpLegalRepresentative = createSelector(
  SignUpRepresentativeRootSelector,
  (state: SignUpModuleState) => state.signUpLegalRepresentativeReducer,
);
