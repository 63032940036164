import { IAccountLowBalance } from "@app/models/accounts";
import { createAction } from "@ngrx/store";

export const enum TypeActionsLowBalanceSelected {
  lowBalanceSelectedString = "[LOW BALANCE SELECTED] set information",
  lowblanceSelectedResetString = "[ SELECTED] Reset data",
}
/**
 * Action to set  as selected
 */
export const lowBalanceSelected = createAction(
  TypeActionsLowBalanceSelected.lowBalanceSelectedString,
  (account: IAccountLowBalance) => ({ account }),
);
/**
 * Action to reset data
 */
export const lowBalanceSelectedReset = createAction(
  TypeActionsLowBalanceSelected.lowblanceSelectedResetString,
);
