import { createAction } from "@ngrx/store";
import { IRegister } from "@signup/models/legal-representative";

export const enum TypeActionsLegalRepresentative {
  legalRepresentativeString = "[SIGN UP Legal Representative] set information",
  legalRepresentativeReset = "[SIGN UP Legal Representative] reset information",
}
/**
 * Action to set vehicle information
 */
export const signUpLegalRepresentative = createAction(
  TypeActionsLegalRepresentative.legalRepresentativeString,
  (user: IRegister) => ({ user }),
);

export const signUpLegalRepresentativeReset = createAction(
  TypeActionsLegalRepresentative.legalRepresentativeReset,
);
