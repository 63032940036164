import { EVENTS } from "@core/constants/tags.constant";
declare const dataLayer;
declare const utag;
function dataLayerPush(event: any): void {
  try {
    dataLayer.push(event);
  } catch (err) {}
}
function uTagLayerPush(event: any): void {
  try {
    utag.track(EVENTS.tealium.VIEW, event);
  } catch (err) {}
}
export function pageViewEvent(path: string): void {
  try {
    (window as any).utag_cfg_ovrd = { noview: true };
    (window as any).utag_data = {};
    dataLayerPush({
      event: EVENTS.google.PAGE_VIEW,
      pagePath: path,
    });
    uTagLayerPush({
      event: EVENTS.tealium.VIEW,
      pagePath: path,
    });
  } catch (err) {}
}

export function pageEvent(
  event: string,
  path: string,
  label: string = "contrasena-incorrecta",
) {
  dataLayerPush({
    event,
    eventCategory: "FacilPass",
    eventAction: path,
    eventLabel: label,
  });
  uTagLayerPush({
    event,
    eventCategory: "FacilPass",
    eventAction: path,
    eventLabel: label,
  });
}
