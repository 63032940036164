import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as financialDataActions from "@shared-financial-data/store/actions/index";
import { Observable } from "rxjs";
import {
  IFinancialProducts,
  IMinimumAccount,
  IMinimumAmountRequest,
} from "../../models/financial-data";
import { selectFinancialProducts } from "../selectors/financial-products.selector";
import { selectMinimumAmountPospaid } from "../selectors/minimum-amount-pospaid.selector";
import { selectMinimumAmountPrepaid } from "../selectors/minimum-amount-prepaid.selector";

import { FinancialDataModuleState } from "../state/financial-data.state";

@Injectable({
  providedIn: "root",
})
export class SharedFinancialDataFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering

  public selectFinancialProducts$: Observable<
    IFinancialProducts
  > = this.store.pipe(select(selectFinancialProducts));

  public selectFinancialProducts2$: Observable<
    IFinancialProducts
  > = this.store.pipe(select(selectFinancialProducts));

  public selectMinimumAmountPospaid$: Observable<
    IMinimumAccount
  > = this.store.pipe(select(selectMinimumAmountPospaid));

  public selectMinimumAmountPospaid2$: Observable<
    IMinimumAccount
  > = this.store.pipe(select(selectMinimumAmountPospaid));

  public selectMinimumAmountPrepaid$: Observable<
    IMinimumAccount
  > = this.store.pipe(select(selectMinimumAmountPrepaid));

  public selectMinimumAmountPrepaid2$: Observable<
    IMinimumAccount
  > = this.store.pipe(select(selectMinimumAmountPrepaid));

  constructor(private store: Store<FinancialDataModuleState>) {}

  public getFinancialProducts(): void {
    this.store.dispatch(financialDataActions.financialProductsLoad());
  }

  public getMinimumAmountPospaid(
    postpaidMinimumAmountLoad: IMinimumAmountRequest,
  ): void {
    this.store.dispatch(
      financialDataActions.minimumAmountPospaidLoad(postpaidMinimumAmountLoad),
    );
  }

  public getMinimumAmountPrepaid(
    prepaidMinimumAmountLoad: IMinimumAmountRequest,
  ): void {
    this.store.dispatch(
      financialDataActions.minimumAmountPrepaidLoad(prepaidMinimumAmountLoad),
    );
  }
}
