<fp-loader *ngIf="getUrlTokenization" [title]="'Espere un momento' | translate"
  [description]="'Será redirigido a la pasarela de pagos' | translate">
</fp-loader>

<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'fourTemplate'"
  headerType="secondary" showfooterBallsRight="false" urlBack="/transactions/recharge">
  <ng-template #tplRef>
    <div class="fp-purchase-detail-container">
      <form novalidate [formGroup]="transactionRechargeForm" *ngIf="transactionRechargeForm"
        (ngSubmit)="openModalPaymentMethod()">
        <div class="title_box">
          <h1 class="fp-h1 top-up title">{{ "Confirme los datos de su pago" | translate }}</h1>
        </div>
        <div class="form-container">
          <div class="fp-card-detail">
            <h2 class="fp-card-detail__title">{{ 'Seleccione un método de pago' | translate }}</h2>
            <div class="payment-methods">
              <ng-container *ngIf="loading">
                <div class="card-skeleton skeleton" *ngFor="let item of ['', '']"></div>
              </ng-container>
              <ng-container *ngIf="transactionData?.paymentMethods && !loading">
                <div class="payment-method" *ngFor="let method of transactionData.paymentMethods">
                  <div class="payment-method-name">
                    <img [src]="'assets/images/common/' + method.logo" [alt]="method.name"
                      class="payment-method-name__icon" *ngIf="method.id === 'GOU|PSE'">
                    {{ method.name }}
                  </div>
                  <fp-radiobutton formControlName="paymentMethod" name="paymentMethod" [value]="method.id"
                    inputId="paymentMethod_{{ method.id }}" (onClick)="selectPaymentMethod(method.id)"></fp-radiobutton>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="fp-card-detail" *ngIf="showDetailsTransaction">
            <h2 class="fp-card-detail__title">{{ 'Información de la recarga' | translate }}</h2>
            <div class="transaction-details">
              <div class="transaction-detail">
                <span class="transaction-detail__text">
                  {{ "Cuenta con" | translate }}
                  {{ (accountType === "PRE" ? "recarga prepago" : "recarga automática") | translate }}
                </span>
                <span class="transaction-detail__text">{{ transactionData?.payment?.transactionTypeData?.account
                  }}</span>
              </div>
              <div class="transaction-detail">
                <span class="transaction-detail__text">{{ "Medio de pago" | translate }}</span>
                <span class="transaction-detail__text">{{ paymentMethodSelected }}</span>
              </div>
              <div class="transaction-detail">
                <span class="transaction-detail__text">{{ "Valor recarga" | translate }}</span>
                <span class="transaction-detail__text transaction-detail__text--number">${{
                  transactionData?.payment?.totalAmount | number }}</span>
              </div>
            </div>
          </div>
          <div class="fp-card-detail" *ngIf="showDetailsTransaction">
            <h2 class="fp-card-detail__title">{{ 'Datos del titular de la tarjeta' | translate }}</h2>
            <div class="cardholder-details">
              <div class="form-group">
                <label class="form-group__label">{{ 'Seleccione el tipo de documento' | translate }}</label>
                <div class="document-types">
                  <div class="document-type" *ngFor="let document of documentTypes">
                    <div class="document-type__name">{{ document.label }}</div>
                    <fp-radiobutton formControlName="documentType" name="documentType" [value]="document.value"
                      inputId="document-type_{{ document.value }}"
                      (onClick)="selectDocumentType(document.value)"></fp-radiobutton>
                  </div>
                </div>
              </div>

            </div>
            <fp-input #idnumber class="form-group" labelClass="form-label" nameLabelTranslate="payment_documentId"
              viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true" [inputClass]="'input-large'"
              typeInput="tel" nameInput="documentNumber" idInput="documentNumber" maxlength="10" min="4"
              formControlName="documentNumber" [inputValidationMessages]="RechargeErrorMessages.name"
              [formControl]="transactionRechargeForm.controls['documentNumber']" regexp="^[0-9]{0,10}$"
              matchRegexp="(\d+)">
            </fp-input>

            <fp-input class="form-group" labelClass="form-label" styleClass="form-group"
              [nameLabelTranslate]="labelName" viewSpaceInput="true" [viewErrorsInput]="true"
              [viewIconErrorInput]="true" typeInput="text" [inputClass]="'input-large'" nameInput="names"
              idInput="names" formControlName="name" [inputValidationMessages]="RechargeErrorMessages.name"
              maxlength="100" minlength="3" [formControl]="transactionRechargeForm.controls['name']"
              regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\\&0-9' ]+$" matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\\&0-9' ])"></fp-input>

            <fp-input #inputRegister class="form-group" styleClass="form-group" labelClass="form-label"
              [nameLabelTranslate]="labelPhone" viewSpaceInput="true" [viewErrorsInput]="true"
              [viewIconErrorInput]="true"
              [inputClass]="transactionRechargeForm.controls['phone'].valid ? 'black-border': 'input-large'"
              typeInput="tel" nameInput="phone" idInput="phone" maxlength="10" min="10" formControlName="phone"
              [inputValidationMessages]="RechargeErrorMessages.phone"
              [formControl]="transactionRechargeForm.controls['phone']" regexp="[0123456789]" matchRegexp="(\d+)"
              [viewMessageError]="viewMessageError" (onInput)="inputEvent($event, inputRegister)"></fp-input>

            <fp-input #inputEmail class="form-group" labelClass="form-label" nameLabelTranslate="payment_email"
              viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
              [inputClass]="transactionRechargeForm.controls['email'].valid ? 'black-border': 'input-large'"
              typeInput="email" nameInput="email" idInput="email" formControlName="email" maxlength="78"
              [inputValidationMessages]="RechargeErrorMessages.email"
              [formControl]="transactionRechargeForm.controls['email']" regexp="^[A-Za-z0-9-+@_\-\.]+$"
              matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
            </fp-input>

            <fp-input #inputEmail class="form-group" labelClass="form-label" nameLabelTranslate="payment_confirm_email"
              viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true" maxlength="78"
              [inputClass]="transactionRechargeForm.controls['confirmEmail'].valid ? 'black-border': 'input-large'"
              typeInput="confirmEmail" nameInput="confirmEmail" idInput="confirmEmail" formControlName="confirmEmail"
              [inputValidationMessages]="RechargeErrorMessages.confirmEmail" (paste)="onPaste($event)"
              [formControl]="transactionRechargeForm.controls['confirmEmail']" regexp="^[A-Za-z0-9-+@_\-\.]+$"
              matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
            </fp-input>

            <fp-input #inputAutocomplete class="form-group" styleClass="form-group" labelClass="form-label"
              [nameLabelTranslate]="'Ciudad' | translate" [placeholder]="'Seleccione una ciudad' | translate"
              viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true" [inputClass]="inputClass"
              [typeInput]="'text'" nameInput="inputAutocomplete" idInput="inputAutocomplete" formControlName="city"
              [inputValidationMessages]="RechargeErrorMessages.cities" matAutocomplete="true"
              [formControl]="transactionRechargeForm.controls['city']" regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-,-. ]+$"
              matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-,-. ])" [matchOnInput]="false" [list]="cities" (onBlur)="inputOnBlur()"
              (onRestInput)="onRestInput()">
            </fp-input>

            <fp-input class="form-group" styleClass="form-group" labelClass="form-label"
              [nameLabelTranslate]="'payment_address' | translate" viewSpaceInput="true" [viewErrorsInput]="true"
              [viewIconErrorInput]="true" inputClass="input-large" [typeInput]="'text'" nameInput="inputOtherAdrress"
              idInput="inputOtherAdrress" formControlName="address" maxlength="255"
              [inputValidationMessages]="RechargeErrorMessages.address"
              [formControl]="transactionRechargeForm.controls['address']"
              regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ]+$" [minlength]="3"
              matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ])" [matchOnInput]="false">
            </fp-input>

            <div class="row-terms">
              <fp-checkbox chkboxId="acceptTerms" name="acceptTerms" inputId="acceptTerms"
                [formControl]="transactionRechargeForm.controls['acceptTerms']" binary="true">
              </fp-checkbox>
              <a [href]="'policy_accept.link' | translate" class="checkbox-obligatori padding">{{
                "policy_accept.message1" | translate }} <u class="fp-btn-link" id="link-termins-conditions">{{
                  "policy_accept.policy_authorize" | translate
                  }}</u> {{ "policy_accept.message2" | translate }}</a>
            </div>
          </div>
          <div #btnContinue class="form-group-submit">
            <div class="fp-container-btn submit-btn">
              <button class="fp-btn-primary" type="submit" [disabled]="transactionRechargeForm.invalid || sending">
                <span>{{ "Continuar" | translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</fp-card-body>

<fp-confirmation-modal
  (itemClickActive)="$event?.typeAction === 'continue' ? getUrlRedirectPaymentMethod() : closeModal();">
</fp-confirmation-modal>

<ng-template #snackbarMsg>
  <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
    [description]=" messageSnackbar | translate" [type]="messageType">
  </fp-snack-message>
</ng-template>