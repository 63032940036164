<fp-card-body [template]="'secondTemplate'" headerType="secondary" close="true" (actionClose)="statusCardValidated ? goPaymentMethods() : null" actionType="custom">
  <ng-template #tplRef>
      <div class="container">
          <div class="box">
            <div
              [class]="'icon-message icon-message--' + (statusCardValidated ? statusCardValidated.status : 'skeleton')">
              <img
                [src]="statusIcons[statusCardValidated?.status]"
                alt="Icon message"
                class="icon-message__icon"
                *ngIf="statusCardValidated"
              >
            </div>
            <div class="title-message" [ngClass]="{'title-message--skeleton': !statusCardValidated}">
              <span class="title-message__text" *ngIf="statusCardValidated">
                {{ titles[statusCardValidated?.status] | translate }}
              </span>
            </div>
            <div  class="date-message" [ngClass]="{'date-message--skeleton': !statusCardValidated}">
              <span class="date-message__text" *ngIf="statusCardValidated">
                {{ statusCardValidated?.statusDate | date: 'dd MMMM yyyy / hh:mm a' }}
              </span>
            </div>
            <div class="toast-message">
              <fp-alert-message
                [type]="alertsTypes.INFO"
                [title]=""
                *ngIf="statusCardValidated?.status === statusCardValidatedEnum.DUPLICATED"
                [message]="'theCardYouAreTryingToAddIsAlreadyInYourPaymentMethods'"
              ></fp-alert-message>
            </div>
            <div
              class="content-message"
              [ngClass]="{ 'content-message--skeleton': !statusCardValidated }"
            >
            <ng-container *ngIf="statusCardValidated">
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'state' | translate }}</div>
                <div [class]="'content-message-row-item' + (statusCardValidated ? ' content-message-row-item--' + statusCardValidated.status : '')">
                  {{ statusCardValidated?.status | translate | titlecase }}
                </div>
              </div>
              <div class="content-message-row" *ngIf="statusCardValidated?.status === statusCardValidatedEnum.REJECTED">
                <div class="content-message-row-item">{{ 'description' | translate }}</div>
                <div class="content-message-row-item">{{ statusCardValidated?.description }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'reference' | translate }}</div>
                <div class="content-message-row-item">{{ statusCardValidated?.id }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'facilPassAccount' | translate }}</div>
                <div class="content-message-row-item">{{ accountId }}</div>
              </div>
              <div class="content-message-row">
                <div class="content-message-row-item">{{ 'paymentMethod' | translate }}</div>
                <div class="content-message-row-item">
                  <img
                    [src]="statusCardValidated?.brand && statusCardValidated?.brand !== 'EMPTY'
                      ? cardsBrands[statusCardValidated?.brand]
                      : 'assets/images/common/credit-card-icon.svg'"
                    alt="Brand card"
                    class="content-message-row-item__brand"
                  >
                </div>
              </div>
            </ng-container>
              <ng-container *ngIf="!statusCardValidated">
                <div class="content-message-row">
                  <div class="content-message-row-item-skeleton"></div>
                  <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
                </div>
                <div class="content-message-row">
                  <div class="content-message-row-item-skeleton"></div>
                  <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
                </div>
                <div class="content-message-row">
                  <div class="content-message-row-item-skeleton"></div>
                  <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
                </div>
                <div class="content-message-row">
                  <div class="content-message-row-item-skeleton"></div>
                  <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
                </div>
              </ng-container>
            </div>
            <button
              class="button-message"
              [ngClass]="{ 'button-message--skeleton': !statusCardValidated }"
              (click)="goPaymentMethods()"
            >
              <span class="button-message__text" *ngIf="statusCardValidated">
                {{ (statusCardValidated?.status !== statusCardValidatedEnum.DUPLICATED
                    ? 'continue'
                    : 'goToPaymentMethods') | translate
                }}
              </span>
            </button>
          </div>
      </div>
  </ng-template>
</fp-card-body>

<ng-template #snackbarMsg>
  <fp-snack-message
    class="fp-snack-message-login"
    (snackMessageEvent)="closeMessage()"
    [description]="'pleaseWaitAFewMinutesToBeAbleToUseThePaymentMethodOnOurPlatformWeAreValidatingIt' | translate"
    [type]="alertsTypes.WARNING">
  </fp-snack-message>
</ng-template>
