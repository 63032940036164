import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ParameterValidatorAlertComponent } from "./parameter-validator-alert.component";

@NgModule({
  declarations: [ParameterValidatorAlertComponent],
  imports: [CommonModule],
  exports: [ParameterValidatorAlertComponent],
})
export class ParameterValidatorAlertModule {}
