import { createAction } from "@ngrx/store";
import {
  IVinculationRequest,
  IVinculationResponse,
} from "@shared-sign/generic-otp/models/otpcode";
import { ITimerCode } from "../../modules/otpcode/models/otpcode";

export const enum TypeActions {
  otpLoad = "[OTP Timer] timer to loaded otp",
  otpReset = "[OTP Timer] reset timer otp",
  otpCodeVinculationLoadString = "[OTP Vinculation]  Loading data",
  otpCodeVinculationSaveString = "[OTP Vinculation] Successful data loaded",
  otpCodeVinculationErrorString = "[OTP Vinculation] Save error response",
  otpCodeVinculationResetDataString = "[OTP Vinculation] Reset data",
}

export const otpTimer = createAction(
  TypeActions.otpLoad,
  (timer: ITimerCode) => ({ timer }),
);

/**
 * Action to detect event loading vinculation data
 */
export const otpCodeVinculationLoad = createAction(
  TypeActions.otpCodeVinculationLoadString,
  (data: IVinculationRequest) => ({ data }),
);
/**
 * Action to detect event loaded vinculation data
 */
export const otpCodeVinculationSave = createAction(
  TypeActions.otpCodeVinculationSaveString,
  (data: IVinculationResponse) => ({ data }),
);
/**
 * Action to set data error
 */
export const otpCodeVinculationError = createAction(
  TypeActions.otpCodeVinculationErrorString,
  (error: any) => ({ error }),
);

export const resetOtpCodeVinculationData = createAction(
  TypeActions.otpCodeVinculationResetDataString,
);

export const otpTimerReset = createAction(TypeActions.otpReset);
