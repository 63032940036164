<fp-card-body
  [template]="'secondTemplate'"
  [urlBack]="automaticRechargeData?.fromRecharge ? '/transactions/recharge' : automaticRechargeData?.getAllCards ? '/home' : accountSelectedType && accountSelectedType !== '' ? '/account/administration' : '/home'"
  backOption="true"
  headerType="secondary"
>
  <ng-template #tplRef>
      <div class="cont">
          <div class="box">
              <h1 class="fp-h1">{{ title | translate }}</h1>
              <h2 class="fp-body-one subtitle"> {{ subtitle | translate}} </h2>
              <form novalidate [formGroup]="formSelectModality" (ngSubmit)="selectModality()">
                  <div class="cards-payment">
                      <div class="cards-payment-cont" id="paymentType">
                          <ng-container>
                              <div class="card-payment" [ngClass]="{'center': accountSelected?.accountType}">
                                <ng-container *ngFor="let modality of modalities; let itype= index">
                                  <button
                                    class="input-radio-option"
                                    [class.selected-option]="this.formSelectModality.controls.paymentType.value === modality.value"
                                    type="button" (click)="chooseModality(modality.value)"
                                    id="type{{modality.value}}"
                                    *ngIf="modality.enabled"
                                  >
                                    <div class="card-header">
                                        <span class="radio-btn-container">
                                            <fp-radiobutton formControlName="paymentType" name="paymentType"
                                                [value]="modality.value" inputId="paymentType{{modality.value}}">
                                            </fp-radiobutton>
                                        </span>
                                        <div
                                            [class]="modality.recommended ? 'card-recommended' : 'card-recommended hidden'">
                                            {{ modality.description | translate }}
                                        </div>
                                        <div
                                            [class]="modality.recommended ? 'card-title recommended' : 'card-title'">
                                            {{ modality.name }}
                                        </div>
                                    </div>
                                    <div class="card-info">
                                        <div *ngIf="modality.message">
                                            <div *ngIf="modality.message.alert">
                                                <div class="card-message">
                                                    <div [class]="modality.message.icon"></div>
                                                    <label class="message-text">{{ modality.message.text |
                                                        translate}}
                                                        <a class="download-link" [href]="modality.message.link.to"
                                                            target="_blank">{{
                                                            modality.message.link.label | translate }}</a>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-item" *ngFor="let item of modality.info">
                                          <div class="card-icon"></div>
                                          <div class="card-text">
                                            <label>
                                              {{item.text | translate}}
                                            </label>
                                          </div>
                                        </div>
                                    </div>
                                  </button>
                                </ng-container>
                              </div>
                          </ng-container>
                      </div>
                      <div class="space-inputs-small"></div>
                  </div>
                  <div class="form-group-submit">
                      <div class="fp-container-btn">
                          <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                              [disabled]="!formSelectModality.valid || changingModality || accountSelectedType === modalitySelected">
                              <span class="btn-content-text" *ngIf="!changingModality">
                                  {{ 'btn_continue' | translate }}
                              </span>
                              <span
                                class="loader"
                                *ngIf="changingModality">
                              </span>
                          </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </ng-template>
</fp-card-body>

<ng-template #notify>
    <fp-snack-message (snackMessageEvent)="closeNotify()" [title]="'alert.title' | translate"
        [description]="'alert.description' | translate" type="error">
    </fp-snack-message>
</ng-template>

<fp-confirmation-modal
  (itemClickActive)="$event?.typeAction === 'continue' ? callService() : closeModal();"
  [accountId]="accountSelected?.accountId"
  [accountType]="accountSelected?.accountType"
  [title]="'Selecciono recarga prepago'"
  [infoBubble]="modalInfoBubble" >
</fp-confirmation-modal>
