import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import {
  IVehicle,
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
  IVehicleUpdateRequest,
} from "../../models/vehicle";
import { VehicleNavigationService } from "../../services/vehicle-navigation.service";
import { VehiclesService } from "../../services/vehicle.service";
import { VehiclesFacade } from "../../store/facade/vehicles.facade";

@Component({
  selector: "fp-edit-vehicle",
  templateUrl: "./edit-vehicle.component.html",
  styleUrls: ["./edit-vehicle.component.scss"],
})
export class EditVehicleComponent implements OnInit {
  formEditVehicle: FormGroup;

  categories: IVehiclesCategoriesResponse[] = [];

  vehicle: IVehicle;

  loadingData: boolean = true;

  constructor(
    private vhNavigation: VehicleNavigationService,
    private vehiclesFacade: VehiclesFacade,
    private modalService: ModalService,
    private vehicleServices: VehiclesService,
    private appFacade: AppFacade,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getVehicleCategories();
    await this.getVehicle();

    this.formEditVehicle = new FormGroup({
      category: new FormControl(this.vehicle.category, Validators.required),
      additionalInfo: new FormControl(this.vehicle.additionalInfo),
    });

    this.loadingData = false;
  }

  async getVehicleCategories(): Promise<any> {
    const promisevehiclesCategories = await this.selectVehiclesCategories$
      .pipe(take(1))
      .toPromise();

    this.categories = promisevehiclesCategories.data;
  }

  async getVehicle(): Promise<any> {
    const promiseVehicle = await this.selectVehicleToUpdate$
      .pipe(take(1))
      .toPromise();

    this.vehicle = promiseVehicle;
  }

  navigateBack() {
    this.vhNavigation.next(0);
  }

  editVehicle() {
    this.appFacade.setLoaderShow({
      type: "GENERAL",
    });

    const request: IVehicleUpdateRequest = {
      account: this.vehicle.accountId,
      accountId: this.vehicle.accountId,
      category: this.formEditVehicle.controls["category"].value,
      licensePlate: this.vehicle.plate,
      personId: this.vehicle.personId,
      additionalInformation: this.formEditVehicle.controls["additionalInfo"]
        .value,
    };

    this.vehicleServices.update(request).subscribe({
      next: (res) => {
        this.appFacade.setLoaderHide();
        this.vhNavigation.next(4);
      },
      error: (error) => {
        this.appFacade.setLoaderHide();
        this.vhNavigation.next(5);
      },
    });
  }

  /**
   * Open modal
   * @param id string id element Html
   */
  openModal(id: string): void {
    this.modalService.open(id);
  }

  /**
   * get vehicle categories from Selector
   */
  get selectVehiclesCategories$(): Observable<IVehiclesCategories> {
    return this.vehiclesFacade.selectVehiclesCategories$;
  }

  /**
   * get vehicle categories from Selector
   */
  get selectVehicleToUpdate$(): Observable<IVehicle> {
    return this.vehiclesFacade.selectVehicleToUpdate$;
  }
}
