import { createAction, props } from "@ngrx/store";

export const enum TypeActionsProspectCompanyInvalid {
  ProspectStringLoad = "[PROSPECT Invalid] Company PROSPECT loaded",
  ProspectStringSave = "[PROSPECT Invalid] Prospect validate save",
}

export const prospectCompanyInvalidSave = createAction(
  "[PROSPECT Invalid] prospect validate save",
  props<{
    identificationNumber: string;
    identificationNumberType: string;
    invalid: boolean;
  }>(),
);

export const prospectCompanyInvalidError = createAction(
  "[PROSPECT Invalid] iprospect validate error",
  props<{ error }>(),
);
export const prospectCompanyInvalidReset = createAction(
  "[PROSPECT Invalid] reset iprospect valid",
);
