import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  CompleteSignUpFeatureName,
  CompleteSignUpModuleState,
} from "../state/complete-signup.state";

export const CompleteSignUpOtpCodeTimerRootSelector = createFeatureSelector<
  CompleteSignUpModuleState
>(CompleteSignUpFeatureName);

export const selectCompleteSignUpOtpCodeTimer = createSelector(
  CompleteSignUpOtpCodeTimerRootSelector,
  (state: CompleteSignUpModuleState) => state.completeSignUpOtpCodeTimerReducer,
);
