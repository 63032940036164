import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { DashboardService } from "@shared/services/dashboard.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as lowBlanceActions from "../actions/low-balance.actions";

@Injectable()
/**
 * Accounts Effect
 */
export class LowBalanceEffect {
  accounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(lowBlanceActions.lowBalanceLoad),
      switchMap((action) =>
        this.dashboardService.getLowBalance(action.data).pipe(
          take(1),
          map((resp: any) => lowBlanceActions.lowBalanceSave(resp.accounts)),
          catchError((error) =>
            of(lowBlanceActions.lowBalanceError({ error })),
          ),
        ),
      ),
    ),
  );

  /**
   * Constructor accounts Service Effect
   * @param actions$ Action provider to set data en store
   * @param dashboardService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
