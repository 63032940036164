<fp-card-body [template]="'secondTemplate'" headerType="secondary" close="true" *ngIf="account">
  <ng-template #tplRef>
      <div class="container-successful">
          <fp-page-message icon="assets/images/register/icon-check.svg" [big]="'true'" [title]="'title.success' | translate"
              [message]=" 'info.paymentMethod'| translate:{ 'accountId': accountIdMasked }"
              [labelButton]="'finish' | translate" (goNext)="redirectToPage()">
          </fp-page-message>
      </div>
  </ng-template>
</fp-card-body>
