import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { DashboardService } from "@shared/services/dashboard.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as accountsActions from "../actions/accounts.actions";

@Injectable()
/**
 * Accounts Effect
 */
export class AccountsEffect {
  accounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.accountsLoad),
      switchMap((action) =>
        this.dashboardService.getAccounts(action.data).pipe(
          take(1),
          map((resp: any) => accountsActions.accountsSave(resp)),
          catchError((error) => of(accountsActions.accountsError({ error }))),
        ),
      ),
    ),
  );

  /**
   * Constructor accounts Service Effect
   * @param actions$ Action provider to set data en store
   * @param dashboardService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
