import { ITemporalPassword } from "@app/models/temporal-password";
import { createAction } from "@ngrx/store";

export const enum TypeActionsSignInTemporalPassword {
  signIntemporalPasswordString = "[SIGNIN Temporal Password] set temporal password",
}

export const SignInTemporalPassword = createAction(
  TypeActionsSignInTemporalPassword.signIntemporalPasswordString,
  (data: ITemporalPassword) => ({ data }),
);
