<div class="fp-container-select" [class.disabled]="disabled">
    <div class="fp-select-content" (click)="openSelect()" [class.disabled]="disabled" [id]="id">
        <div class="fp-body-select" [class.disabled]="disabled">
            <img class="icon-rotate" src="assets/images/common/arrow-paginator.svg" alt="mostrar opciones">
            <span *ngIf="label">
                {{items}}
            </span>
        </div>
    </div>
    <div class="fp-select-options" *ngIf="showOverlayMask" [style.width]="width">
        <span *ngFor="let option of options" [class.selected]="items === option" class="selected"
            (click)="closeSelect(option)">{{option}}</span>
    </div>
</div>
<div class="fp-overlay-mask" (click)="closeSelect()" *ngIf="showOverlayMask">
</div>