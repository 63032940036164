import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { signInPagesConst } from "@core/constants/pages.constant";
import { SignInFacade } from "@signin/store/facade/signin.facade";
import {
  initialStateOtpCodeTimer,
  initialStateTemporalPassword,
} from "../store/state/signin.state";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class SignInNavigationService {
  constructor(private router: Router, private signInFacade: SignInFacade) {}
  /**
   *
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = signInPagesConst[position];
    let savedPages: IPage[];
    savedPages = signInPagesConst.slice(0, position + 1);
    if (position >= 1) {
      savedPages = [page];
    }
    savedPages = [page];
    this.signInFacade.setSignInPages(savedPages);
    this.router.navigate([`/signin/${page.active}`]);
  }
  /**
   * Navigate to error
   */
  navigatetoError(): void {
    this.router.navigate(["/error"]);
  }
  /**
   * Clear Vehicle Information
   */
  clearSignInStore(flag: boolean = true): void {
    this.signInFacade.resetsetForgotPassword();
    if (flag) {
      this.signInFacade.setSignInPages([signInPagesConst[0]]);
    }
    this.signInFacade.setTemporalPassword(initialStateTemporalPassword);
    this.signInFacade.setOtpCodeTimer(initialStateOtpCodeTimer);
  }
}
