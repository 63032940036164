import { IRoles } from "@app/models/roles";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { rolesInfoFeatureName } from "../state/roles.state";

export const RolesRootSelector = createFeatureSelector<IRoles>(
  rolesInfoFeatureName,
);

export const selectRoles = createSelector(
  RolesRootSelector,
  (state: IRoles) => state,
);
