import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { VehicleGuard } from "./guards/vehicle.guard";
import { editRoute } from "./modules/edit-vehicle/edit-vehicle-routing.module";
import { errorRootRoute } from "./modules/error-edit/error-vehicle-edit-routing.module";
import { listVehicleRoot } from "./modules/list/list-vehicle-routing.module";
import { requestTagsRoot } from "./modules/request-tags/request-tags-routing.module";
import { successfulRoute } from "./modules/successful/successful-vehicle-add-routing.module";
import { successfulEditRoute } from "./modules/successful-edit/successful-vehicle-edit-routing.module";

export const vehiclesRootRoute = "vehicles";
const routes: Routes = [
  {
    path: "",
    redirectTo: listVehicleRoot,
    pathMatch: "full",
  },
  {
    path: listVehicleRoot,
    loadChildren: () =>
      import("./modules/list/list-vehicle.module").then(
        (m) => m.ListVehicleModule,
      ),
    canActivateChild: [NgxPermissionsGuard],
    canActivate: [VehicleGuard],
    data: {
      permissions: {
        only: "VEHCON",
      },
    },
  },
  {
    path: "shipping-address",
    loadChildren: () =>
      import("./modules/shipping-address/shipping-address.module").then(
        (m) => m.ShippingAddressModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: successfulRoute,
    loadChildren: () =>
      import("./modules/successful/successful-vehicle-add.module").then(
        (m) => m.SuccessfulVehicleAddModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: editRoute,
    loadChildren: () =>
      import("./modules/edit-vehicle/edit-vehicle.module").then(
        (m) => m.EditVehicleModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: successfulEditRoute,
    loadChildren: () =>
      import("./modules/successful-edit/successful-vehicle-edit.module").then(
        (m) => m.SuccessfulVehicleEditModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: requestTagsRoot,
    loadChildren: () =>
      import("./modules/request-tags/request-tags.module").then(
        (m) => m.RequestTagsModule,
      ),
  },
  {
    path: errorRootRoute,
    loadChildren: () =>
      import("./modules/error-edit/error-vehicle-edit.module").then(
        (m) => m.ErrorVehicleEditModule,
      ),
    canActivate: [VehicleGuard],
  },
  {
    path: "**",
    redirectTo: listVehicleRoot,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiclesRoutingModule {}
