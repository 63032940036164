import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
} from "@angular/core";
import { TooltipComponent } from "./tooltip.component";

@Directive({
  selector: "[fpTooltip]",
})
export class TooltipDirective implements OnDestroy {
  /** Tooltip message */
  @Input() tooltip = "";
  /** Tooltip message */
  @Input() tooltipPosition = "left";
  /** Component Ref */
  private componentRef: ComponentRef<any> = null;

  /**
   * Constructor
   * @param elementRef
   * @param appRef
   * @param componentFactoryResolver
   * @param injector
   */
  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  /**
   * Mouse enter event
   */
  @HostListener("mouseenter")
  onMouseEnter(): void {
    if (this.componentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        TooltipComponent,
      );
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  /**
   * Mouse leave event
   */
  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.destroy();
  }

  /**
   * Destroy event
   */
  ngOnDestroy(): void {
    this.destroy();
  }

  /**
   * Destroy component
   */
  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  /**
   * Set tooltip component properties
   */
  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      const {
        left,
        right,
        bottom,
        width,
      } = this.elementRef.nativeElement.getBoundingClientRect();
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = bottom;
      this.componentRef.instance.tooltipPosition = this.tooltipPosition;
    }
  }
}
