import { IPage } from "@app/models/pages";

export const postpaidPagesConst: IPage[] = [
  {
    active: "company",
    previous: "company",
    next: "legal-representative",
    list: [
      {
        name: "terms-and-conditions",
        params: {},
      },
    ],
  },
];

export const singUpPagesConst: IPage[] = [
  {
    active: "company",
    previous: "company",
    next: "legal-representative",
    list: [],
  },
  {
    active: "legal-representative",
    previous: "company",
    next: "administrator",
    list: [
      {
        name: "terms-and-conditions",
        params: {},
      },
      {
        name: "habeas-data",
        params: {},
      },
    ],
  },
  {
    active: "administrator",
    previous: "legal-representative",
    next: "authorizations",
  },
  {
    active: "authorizations",
    previous: "administrator",
    next: "create-password",
  },
  {
    active: "create-password",
    previous: "administrator",
    next: "create-password",
  },
  {
    active: "otpcode",
    previous: "otpcode",
    next: "successful",
  },
  {
    active: "successful",
    previous: "otpcode",
    next: "successful",
  },
];
export const vehiclesPagesConst: IPage[] = [
  {
    active: "list",
    previous: "list",
    next: "successful",
  },
  {
    active: "shipping-address",
    previous: "list",
    next: "successful",
  },
  {
    active: "successful",
    previous: "list",
    next: "",
  },
  {
    active: "edit",
    previous: "list",
    next: "successful-edit",
  },
  {
    active: "successful-edit",
    previous: "list",
    next: "",
  },
  {
    active: "error-edit",
    previous: "list",
    next: "",
  },
];

export const tagPagesConst: IPage[] = [
  {
    active: "shipping-address",
    previous: "shipping-address",
    next: "purchase-detail",
  },
  {
    active: "purchase-detail",
    previous: "shipping-address",
    next: "purchase-detail",
  },
  {
    active: "transaction",
    previous: "",
    next: "",
  },
];

export const signInPagesConst: IPage[] = [
  {
    active: "forgot-password",
    previous: "forgot-password",
    next: "new-password",
  },
  {
    active: "new-password",
    previous: "forgot-password",
    next: "identity-verification",
  },
  {
    active: "identity-verification",
    previous: "forgot-password",
    next: "successful",
  },
  {
    active: "successful",
    previous: "identity-verification",
    next: "",
  },
];

export const completeSignUpPagesConst: IPage[] = [
  {
    active: "user",
    previous: "user",
    next: "new-password",
  },
  {
    active: "new-password",
    previous: "forgot-password",
    next: "identity-verification",
  },
  {
    active: "identity-verification",
    previous: "forgot-password",
    next: "successful",
  },
  {
    active: "successful",
    previous: "identity-verification",
    next: "",
  },
];

export const tagActivationPagesConst: IPage[] = [
  {
    active: "activation",
    previous: "activation",
    next: "successful-activation",
  },
  {
    active: "successful-activation",
    previous: "activation",
    next: "successful-activation",
  },
];

export const accountPagesConst: IPage[] = [
  {
    active: "select-modality",
    previous: "select-modality",
    next: "financial-product",
  },
  {
    active: "financial-product",
    previous: "select-modality",
    next: "successful-association",
  },
  {
    active: "successful-association",
    previous: "financial-product",
    next: "successful-association",
  },
  {
    active: "automatic-recharge-config",
    previous: "select-modality",
    next: "payment-method",
    tagScreenView: true,
  },
  {
    active: "payment-method",
    previous: "automatic-recharge-config",
    next: "",
    tagScreenView: true,
  },
  {
    active: "success-change-modality",
    previous: "payment-method",
    next: "",
    tagScreenView: true,
  },
  {
    active: "successful-process",
    previous: "payment-method",
    next: "",
    tagScreenView: true,
  },
];

export const reportsPagesConst: IPage[] = [
  {
    active: "request",
    previous: "request",
    next: "sucessful",
  },
  {
    active: "sucessful",
    previous: "request",
    next: "sucessful",
  },
];

export const lowBalancePagesConst: IPage[] = [
  {
    active: "low-balance",
    previous: "low-balance",
    next: "successful-configuration",
  },
  {
    active: "successful-configuration",
    previous: "low-balance",
    next: "successful-configuration",
  },
];
