import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { AlertsTypes } from "../../../shared/models/alert-message.enum";

@Component({
  selector: "fp-alert-message",
  templateUrl: "./alert-message.component.html",
  styleUrls: ["./alert-message.component.scss"],
})
export class AlertMessageComponent {
  @ContentChild("tplRef") tplRef: TemplateRef<any>;
  /** Type alert */
  @Input() type: AlertsTypes;
  /** Title alert */
  @Input() title: string;
  /** Message alert */
  @Input() message: string;
  /** Alerts types enum */
  @Input() backgroundWhite = false;
  /** Footer text */
  @Input() footerText: string;
  /** Align footer text */
  @Input() alignFooterText = "left";
  /** Is redirect alert */
  @Input() isRedirect = false;
  /** Alerts types enum */
  @Input() custom: boolean = false;
  /** Alerts types enum */
  alertsTypes = AlertsTypes;
  /** Icons for alerts */
  icons = {
    info: "assets/images/common/hexagonal-info-icon.svg",
    warning: "assets/images/common/filled-yellow-warning.svg",
  };
}
