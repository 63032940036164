<div class="fp-item-row j-center" [class.card-second]="typeTemplate !== 'firstTemplate'" [class.bg-tertiary]="indexEven"
    [class.border]="!indexEven">
    <fp-checkbox *ngIf="false" [chkboxId]="chkboxId" [name]="chkboxName" [inputId]="chkboxId" class="check"
        binary="true" [chkboxBig]="true" (onChange)="changeCheckbox($event)"
        [formControl]="formAccount.controls[chkboxId]">
    </fp-checkbox>

    <div class="fp-item-colum texts">
        <div class="fp-item-title plate">
            {{vehicle?.vehicle.licensePlate}}
        </div>
        <span class="fp-item-description">
            {{vehicle?.vehicle.categoryValue | descriptionCategory}}
        </span>
    </div>
    <span class="fp-item-number-tag">
        {{vehicle?.tagEntityConfirmation? vehicle.tagEntityConfirmation?.idFacial : ""}}
    </span>
    <span class="fp-item-info-aditional">
        {{vehicle?.vehicle? vehicle.vehicle?.additionalInformation : ""}}
    </span>
    <button *ngIf="typeTemplate === 'firstTemplate'" class="fp-btn-fap fp-only-mobile" (click)="openModalMoreOptions()">
        <img src="assets/images/vehicle/icon-plus.svg" alt="icono mas opciones">
    </button>
    <ng-container *ngIf="typeTemplate === 'firstTemplate'">
        <div class="item-list-estate fp-only-desktop" [ngSwitch]="vehicle?.tagEntityConfirmation?.currentStatus">
            <span *ngSwitchCase="0">
                Inactivo
            </span>
            <span *ngSwitchCase="1">
                Activo
            </span>
            <span *ngSwitchCase="2">
                Inactivo
            </span>
            <span *ngSwitchDefault>
                Sin TAG
            </span>
        </div>
    </ng-container>
    <div class="fp-desktop-direcction-row" [class.secondTemplate]="typeTemplate === 'secondTemplate'">
        <div class="fp-content-btn" *ngIf="!vehicle?.tagEntityConfirmation"
            [class.no-permision]="!permissionService.getPermission('TAGAUT')">
            <button class="btn-item-toogle fp-btn-green" type="button"
                [class.secondTemplate]="typeTemplate === 'secondTemplate'"
                id="btn-item-associated-tag-{{vehicle?.vehicle.licensePlate}}" (click)="associatedTag()"
                [ngClass]="{'disabled': !permissionService.getPermission('TAGAUT')}">
                <span class="btn-content-text" id="text-transactions-create-buy">
                    Asociar TAG
                </span>
            </button>
        </div>
        <div class="fp-content-btn" *ngIf="vehicle?.tagEntityConfirmation"
            [ngClass]="{'no-permision': (vehicle?.tagEntityConfirmation.currentStatus !== 1 && !permissionService.getPermission('VEHACTSTATUS') )|| 
                    (vehicle?.tagEntityConfirmation.currentStatus === 1 && !permissionService.getPermission('VEHINA')) }">
            <button [ngClass]="{'fp-btn-tertiary': vehicle?.tagEntityConfirmation.currentStatus !== 1,
                                'fp-btn-secondary': vehicle?.tagEntityConfirmation.currentStatus === 1,
                                'disabled': (vehicle?.tagEntityConfirmation.currentStatus !== 1 && !permissionService.getPermission('VEHACTSTATUS') )|| 
                    (vehicle?.tagEntityConfirmation.currentStatus === 1 && !permissionService.getPermission('VEHINA'))}"
                    class="btn-item-toogle" [class.secondTemplate]="typeTemplate === 'secondTemplate'" type="button"
                    id="btn-item-{{vehicle?.tagEntityConfirmation.id}}" (click)="changeStatusTag()">
                <span class="btn-content-text" id="text-transactions-create" *ngIf="!loading">
                    {{ vehicle?.tagEntityConfirmation.currentStatus === 1 ?'Desactivar' : 'Activar'}}
                </span>
                <span class="loader" *ngIf="loading">
                </span>
            </button>
        </div>
        <div *ngIf="typeTemplate === 'firstTemplate'" style="height: 100%"></div>
        <div *ngIf="typeTemplate === 'firstTemplate'" class="fp-item-more-options"
            [ngClass]="{'bg-tertiary': indexEven,'bg-white': !indexEven}" [class.even]="indexEven"
            [class.show-options]="moreOptions">
            <button class="fp-btn-fap" type="button" [class.less]="moreOptions" (click)="toggleMoreOptions()">
                <img *ngIf="!moreOptions" src="assets/images/vehicle/icon-plus.svg" alt="icono mas opciones">
                <img *ngIf="moreOptions" src="assets/images/vehicle/icon-less.svg" alt="icono menos opciones">
            </button>
            <div class="fp-direcction-row toogle-animate" [class.show]="moreOptions">
                <div class="fp-content-btn" *ngIf="vehicle?.tagEntityConfirmation"
                    [class.no-permision]="!permissionService.getPermission('TAGAUT')">
                    <button class="btn-item-toogle fp-btn-secondary mr" type="button"
                        id="btn-item-change-tag-{{vehicle?.vehicle.licensePlate}}"
                        [ngClass]="{'disabled': !permissionService.getPermission('TAGAUT')}" (click)="changeTag()">
                        <span class="btn-content-text" id="text-change-create">
                            Cambiar Tag
                        </span>
                    </button>
                </div>
                <div class="fp-content-btn" *ngIf="!vehicle?.tagEntityConfirmation"
                    [class.no-permision]="!permissionService.getPermission('TAGCOM')">
                    <button class="btn-item-toogle fp-btn-green" type="button"
                        id="btn-item-buy-{{vehicle?.vehicle.licensePlate}}" (click)="buyTag()"
                        [ngClass]="{'disabled': !permissionService.getPermission('TAGCOM')}">
                        <span class="btn-content-text" id="text-associated-create">
                            Comprar TAG
                        </span>
                    </button>
                </div>
                <div class="fp-content-btn-delete" [class.no-permision]="!permissionService.getPermission('VEHACT')">
                    <button class="fp-btn-white fp-btn-trash" type="button" (click)="editVehicle()"
                        [disabled]="!(permissionService.getPermission('VEHACT') && vehicle?.tagEntityConfirmation)">
                        <img src="assets/images/vehicle/pen-edit.svg" alt="icono editar">
                    </button>
                </div>
                <div class="fp-content-btn-delete" [class.no-permision]="!permissionService.getPermission('VEHELI')">
                    <button class="fp-btn-white fp-btn-trash" type="button" (click)="deleteVehicle()"
                        [disabled]="!permissionService.getPermission('VEHELI')">
                        <img src="assets/images/vehicle/icon-trash.svg" alt="icono eliminar">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>