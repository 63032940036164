import { createAction } from "@ngrx/store";
import { IAccountModality } from "../../models/account-modality";

export const enum TypeActionsAccountModality {
  accountModalityString = "[ACCOUNT MODALITY] set information",
  accountModalityResetString = "[ACCOUNT MODALITY] Reset data",
}
/**
 * Action to set accountModality information
 */
export const accountModality = createAction(
  TypeActionsAccountModality.accountModalityString,
  (accountModalitySelected: IAccountModality) => ({ accountModalitySelected }),
);
/**
 * Action to reset data
 */
export const accountModalityReset = createAction(
  TypeActionsAccountModality.accountModalityResetString,
);
