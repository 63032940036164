import { Component } from "@angular/core";

@Component({
  selector: "fp-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent {
  /** Tooltip */
  tooltip: string = "";
  /** Left position */
  left: number = 0;
  /** Top position */
  top: number = 0;
  /** Tooltip position */
  tooltipPosition = "left";
}
