<fp-card-body [headerType]="'secondary'" [template]="'secondTemplate'" urlBack="/home"
    backOption="true" close="true">
    <ng-template #tplRef>
        <div class="financial-body">
            <h1 class="fp-h1 financial-title">{{ 'title' | translate }}</h1>
            <h2 class="fp-body-one financial-subtitle">{{ 'subtitle' | translate }}</h2>
            <div class="form-group">
                <div class="financial-card">
                    <form class="formFinancialProduct" novalidate [formGroup]="formLowBalance">
                        <div class="select-content">
                            <label class="form-label" for="accountSelect">
                                {{ 'account' | translate }}</label>
                            <mat-form-field appearance="fill">
                                <mat-select #accountSelect formControlName="account"
                                    (selectionChange)="changeSelectedAccount($event.value, $event)"
                                    panelClass="fp-address-select">
                                    <ng-container *ngFor="let account of accounts">
                                        
                                        <mat-option *ngIf="account.statusAccount" class="option-select"
                                            [value]="account.accountId">
                                            {{account.accountId}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                        <div class="select-content">
                             <fp-input #inputAmount class="form-group input-vehicle" labelClass="form-label"
                                [label]="'lowbalance_amount' | translate"
                                [autoFocus]="false" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true" inputClass="input-large" typeInput="text" nameInput="amount"
                                idInput="amount" [formControl]="formLowBalance.controls['amount']"
                                [inputValidationMessages]="lowBalanceValidationMessage.amount" [currencyMode]="true"
                                (keydown)="onKeydown($event)" regexp="^[0-9\.\$ ]+$"
                                matchRegexp="([0-9\.\$ ]*)">
                            </fp-input>

                           <fp-input #email class="form-group" labelClass="form-label" nameLabelTranslate="email"
                                viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true" [inputClass]="'input-large'"
                                typeInput="tel" nameInput="email" idInput="email" [inputValidationMessages]="lowBalanceValidationMessage.email"
                                formControlName="email" regexp="^[A-Za-z0-9-+@_\-\.]+$"
                                [formControl]="formLowBalance.controls['email']" matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
                            </fp-input> 
                        </div>
                    </form>
                </div>
                <div *ngxPermissionsOnly="['CUEPROSALB']" class="form-group-submit-low">
                    <div class="fp-container-btn-low">
                        <button class="fp-btn-primary submit-btn" id="buttonContinue"
                            [disabled]="!formValidator()" (click)="saveLowBalance()">
                            <span class="btn-content-text">
                                {{ 'low_balance_btn' | translate }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</fp-card-body>
<ng-template #tempMessageError>
    <fp-snack-message (snackMessageEvent)="closeMessage()" [title]="'Error' "
        [description]="errorMessage " type="error">
    </fp-snack-message>
</ng-template>