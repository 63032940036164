import { IPages } from "@app/models/pages";
import { reportsPagesConst } from "@core/constants/pages.constant";

/**
 * Set a Name to read chart redux
 */
export const ReportsFeatureName = "ReportsState";

export type ReportsModuleState = Readonly<{
  reportsPagesReducer: IPages;
}>;

export const initialStateReportsPages: IPages = {
  pages: [reportsPagesConst[0]],
};
