import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import { IServiceRecharge } from "@postpaid/models/recharge-account";
import { ITagFreeRequest } from "@status-transactions/models/transactionsStatus.interface";
import { Observable } from "rxjs";
import * as transactionActions from "../actions/index";
import {
  postpaidRechargeAccountReset,
  postpaidRechargeAccountServiceLoad,
} from "../actions/recharge-account.actions";
import {
  statusTransactionsLoad,
  statusTransactionsReset,
} from "../actions/statusTransaction.actions";
import { selectPospaidRechargeAccount } from "../selectors/pospaid-rechage-account.selector";
import { selectStatusTransaction } from "../selectors/statusTransaction.selector";
import {
  IPospaidRechargeAccount,
  IStatusTransactionState,
  TransactionsModuleState,
} from "../state/statusTransaction.state";

@Injectable()
export class TransactionFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectStatusTransaction$: Observable<
    IStatusTransactionState
  > = this.store.pipe(select(selectStatusTransaction));

  public selectPospaidRechargeAccount$: Observable<
    IPospaidRechargeAccount
  > = this.store.pipe(select(selectPospaidRechargeAccount));

  constructor(private store: Store<TransactionsModuleState>) {}
  /**
   * get pse status transaction data
   */
  public getStatusTransaction(data: {
    url?: string;
    body?: ITagFreeRequest;
  }): void {
    this.store.dispatch(statusTransactionsLoad(data));
  }

  public getRechargeAccount(data: IServiceRecharge): void {
    this.store.dispatch(postpaidRechargeAccountServiceLoad(data));
  }

  public resetRechargeAccount(): void {
    this.store.dispatch(postpaidRechargeAccountReset());
  }
  public resetStatusTransactionData(): void {
    this.store.dispatch(statusTransactionsReset());
  }
}
