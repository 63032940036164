import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OverdraftAccountComponent } from "./overdraft-account.component";
export const OverdraftRootRoute = "overdraft-account";
const routes: Routes = [
  {
    path: "",
    component: OverdraftAccountComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OverdraftAccountRoutingModule {}
