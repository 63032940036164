import { createReducer, on } from "@ngrx/store";
import * as rolesActions from "../actions/roles.actions";
import { initRolesDataState } from "../state/roles.state";

export const rolesReducer = createReducer(
  initRolesDataState,
  on(rolesActions.rolesLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: null,
    loading: true,
  })),
  on(rolesActions.rolesSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(rolesActions.rolesError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(rolesActions.rolesReset, (state) => ({
    ...state,
    ...initRolesDataState,
  })),
);
