import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AlertMessageComponent } from "./alert-message.component";

@NgModule({
  declarations: [AlertMessageComponent],
  exports: [AlertMessageComponent],
  imports: [CommonModule, TranslateModule],
})
export class AlertMessageModule {}
