import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SuccessfulAssociationComponent } from "./successful-association.component";
export const SuccessfulAssociationRootRoute = "successful-association";
const routes: Routes = [
  {
    path: "",
    component: SuccessfulAssociationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuccessfulAssociationRoutingModule {}
