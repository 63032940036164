import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RequestTagsComponent } from "./request-tags.component";

export const requestTagsRoot = "request-tags";
const routes: Routes = [
  {
    component: RequestTagsComponent,
    path: "",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RequestTagsRoutingModule {}
