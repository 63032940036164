import { createAction } from "@ngrx/store";
import { ITimestamp } from "@signin/models/forgot-password";

export const enum TypeActionsSignInTimestamp {
  signInTimestamp = "[SIGNIN Timestamp] set timestamp",
}

export const SignInTimestamp = createAction(
  TypeActionsSignInTimestamp.signInTimestamp,
  (data: ITimestamp) => ({ data }),
);
