<fp-card-body [headerType]="'secondary'" [template]="'secondTemplate'" close="true" (emitNavigateBack)="navigateBack()"
    navigateBackOtion="true">
    <ng-template #tplRef>
        <div class="edit-body">

            <div class="form-group">
                <div *ngIf="loadingData; else formTemplate">
                    <div class="edit-card fake ">
                        <div>
                            <div class="skeleton shadow" style="height: 16px; width: 40%;"></div>
                            <div class="skeleton shadow" style="height: 48px; width: 100%; margin-top: 4px"></div>
                        </div>
                        <div style="margin-top: 24px">
                            <div class="skeleton shadow" style="height: 16px; width: 40%;"></div>
                            <div class="skeleton shadow" style="height: 48px; width: 100%; margin-top: 4px"></div>
                        </div>
                    </div>
                </div>
                <ng-template #formTemplate>
                    <h1 class="fp-h1 edit-title">{{ 'title' | translate:{plate:vehicle.plate, noTag:vehicle.noTag} }}
                    </h1>
                    <div class="edit-card">
                        <form class="formEditVehicle" novalidate [formGroup]="formEditVehicle">
                            <div class="select-content">
                                <label class="form-label" for="category">
                                    {{ 'form.label_category' | translate }}
                                    <img src="assets/images/common/icon-question.svg" class="clickable"
                                        alt="icono informativo" (click)="openModal('fp-category-modal')">
                                </label>
                                <mat-form-field appearance="fill">
                                    <mat-select #categorySelect formControlName="category"
                                        panelClass="fp-category-select">
                                        <ng-container *ngFor="let category of categories">

                                            <mat-option class="option-select" [value]="category.value">
                                                {{category.shortDescription}} - {{category.description}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                                </mat-form-field>
                            </div>
                            <div class="form-input">
                                <fp-input #additionalInformation labelClass="form-label"
                                    icon="assets/images/common/icon-question.svg" [maxlength]="100"
                                    (onIcon)="openModal('fp-information-modal')" nameLabelTranslate="form.label_addInfo"
                                    viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                    [inputClass]="'input-large'" typeInput="text" nameInput="additionalInfo"
                                    idInput="additionalInfo" formControlName="additionalInfo"
                                    [formControl]="formEditVehicle.controls['additionalInfo']"
                                    [matchOnInput]="false"
                                    regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ]+$"
                                    matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ])">
                                </fp-input>

                            </div>
                        </form>
                    </div>
                    <div class="form-group-submit-low">
                        <div class="fp-container-btn-low">
                            <button class="fp-btn-primary submit-btn" id="buttonContinue" (click)="editVehicle()"
                                [disabled]="!formEditVehicle.valid">
                                <span class="btn-content-text">
                                    {{ 'form.btn' | translate }}
                                </span>
                            </button>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </ng-template>
</fp-card-body>

<fp-information-modal [vehicleTypes]="categories"></fp-information-modal>