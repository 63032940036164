import { createReducer, on } from "@ngrx/store";
import * as lowBalanceAcctions from "../actions/low-balance-selected.actions";
import { initialLowBalanceSelectedState } from "../state/app.state";

export const lowBalanceSelectedReducer = createReducer(
  initialLowBalanceSelectedState,
  on(lowBalanceAcctions.lowBalanceSelected, (state, { account }) => ({
    ...state,
    ...account,
  })),
  on(lowBalanceAcctions.lowBalanceSelectedReset, (state) => ({
    ...state,
    ...initialLowBalanceSelectedState,
  })),
);
