import { createReducer, on } from "@ngrx/store";
import * as modulesActions from "../actions/modules.actions";
import { initialModulesState } from "../state/modules.state";

export const modulesReducer = createReducer(
  initialModulesState,
  on(modulesActions.setModules, (state, { modules }) => ({
    tag: modules.tag,
    vehicle: modules.vehicle,
    signin: modules.signin,
    signup: modules.signup,
    transaction: modules.transaction,
    completeSignup: modules.completeSignup,
    account: modules.account,
  })),
);
