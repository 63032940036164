import { createAction } from "@ngrx/store";
export const enum UserInfoTypeActions {
  userInfoLoadString = "[User Info] Loading data",
  userInfoSaveString = "[User Info] Successful data loaded",
  userInfoErrorString = "[User Info] Save error response",
  userInfoResetString = "[User Info] Reset State",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const userInfoLoad = createAction(
  UserInfoTypeActions.userInfoLoadString,
  (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const userInfoSave = createAction(
  UserInfoTypeActions.userInfoSaveString,
  (data: any) => ({ data }),
);
/**
 * Action to set data error
 */
export const userInfoError = createAction(
  UserInfoTypeActions.userInfoErrorString,
  (error: any) => ({ error }),
);

export const userInfoReset = createAction(
  UserInfoTypeActions.userInfoResetString,
);
