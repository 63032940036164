<ng-container *ngIf="!listMovements.loading && !vehiclesState.loading">
    <section>
        <h1 class="fp-h1 detail-title">Cuenta FacilPass No. {{accountSelectd?.accountId}}</h1>
        <div class="row space fp-fadeIn">
          <div *ngIf="accountSelectd?.accountType === 'PRE-REC' || accountSelectd?.accountType === 'PRE'  " class="row subtitle-accounts-small" (click)="navigateParam('/transactions/card-transactions', accountSelectd.accountId)">
            Transacciones con tarjeta <img class="rotar" src="assets/images/home/accounts/arrow.svg" alt="icono currency">
          </div>
        </div>
        <div class="info-refund" *ngIf="accountSelectd?.refund">
            <img src="assets/images/common/icon-info.svg" alt="devolución pendientte">
            Saldo en devolución ${{ accountSelectd?.balanceRefund | currency : 'COP' : '' : '1.0-0' }}
        </div>
        <div *ngxPermissionsOnly="['REPGEN']" class="detail-account-card">
            <div class="detail-account-card-header">
                <h2 class="fp-body-one detail-account-title">Consumo general</h2>
                <div class="row-header">
                    <h2 class="fp-body-one  detail-account-title-small">{{ textFilterGeneral }}</h2>
                    <fp-select [options]="options" [items]="options[2]" [width]="'max-content'"
                        (itemPageActive)="changeFilterGeneralLimit($event)" [label]="false"
                        [id]="'select-detail-general'">
                    </fp-select>
                </div>
            </div>
            <div class="status-line">
                <hr>
            </div>
            <div class="detail-account-card-body">
                <div class="detail-account-detail" *ngIf="listMovements.data && listMovements.data.length !== 0">
                    <span class="title">Peajes</span>
                    <span class="description">-{{ listMovements.data |filterService:textFilterGeneral:'peaje' |
                        currency : 'COP' : 'symbol' : '1.0-0'}}</span>
                </div>
                <fp-item-list-empty *ngIf="listMovements.data && listMovements.data.length === 0"
                    iconEmpety="assets/images/common/icon-empty.svg" [descriptionEmpety]="'Aun no tiene consumo'">
                </fp-item-list-empty>
                <fp-item-list-empty *ngIf="listMovements.error" iconEmpety="assets/images/home/icon-war.svg"
                    [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar tu información'"
                    [notifyError]="'true'">
                </fp-item-list-empty>
                <div *ngIf="listMovements.error" class="detail-container-btn">
                    <button type="button" (click)="getMovementsDashboard()" class="fp-btn-green">Volver a
                        intentar</button>
                </div>
            </div>
        </div>
        <div *ngxPermissionsOnly="['REPESP']" class="detail-account-card">
            <div class="detail-account-card-header">
                <h2 class="fp-body-one detail-account-title">Consumo especifico</h2>
                <div class="row-header">
                    <h2 class="fp-body-one  detail-account-title-small">{{ textFilterSpecific }}</h2>
                    <fp-select [options]="options" [items]="options[2]" [width]="'max-content'"
                        (itemPageActive)="changeFilterSpecificLimit($event)" [label]="false"
                        [id]="'select-detail-specific'">
                    </fp-select>
                </div>
            </div>
            <div class="status-line">
                <hr>
            </div>
            <div class="detail-account-card-body">
                <div class="detail-account-detail">
                    <span class="title--bold">Estación </span>
                    <span class="title--bold">Valor </span>
                </div>
                <div *ngIf="listMovements.data && listMovements.data.length !== 0">
                    <div class="detail-account-detail"
                        *ngFor="let item of listMovements.data |filterService:textFilterSpecific:'peaje':true:'group'">
                        <span class="title">{{item.name | titlecase}} </span>
                        <span class="description">-{{item.total | currency : 'COP' : 'symbol' : '1.0-0'}}
                        </span>
                    </div>

                </div>
                <fp-item-list-empty
                    *ngIf="listMovements.data &&  (listMovements.data |filterService:textFilterSpecific:'peaje':true:'length') === 0"
                    iconEmpety="assets/images/common/icon-empty.svg" [descriptionEmpety]="'Aun no tiene consumo'">
                </fp-item-list-empty>
                <fp-item-list-empty *ngIf="listMovements.error" iconEmpety="assets/images/home/icon-war.svg"
                    [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar tu información'"
                    [notifyError]="'true'">
                </fp-item-list-empty>
                <div *ngIf="listMovements.error" class="detail-container-btn">
                    <button type="button" (click)="getMovementsDashboard()" class="fp-btn-green">Volver a
                        intentar</button>
                </div>
                <div *ngIf="listMovements.data && listMovements.data.length !== 0" class="status-line">
                    <hr>
                </div>
            </div>
            <div *ngIf="listMovements.data && listMovements.data.length !== 0" class="detail-account-detail">
                <span class="description">Estaciones utilizadas {{
                    listMovements.data |filterService:textFilterSpecific:'peaje':true:'length'}}</span>
                <a class="fp-btn-link--secondary" id="download-specific-excel-report"
                    (click)="downloadStationsReport()">
                    <u>Descargar</u>
                </a>
            </div>
        </div>
        <div *ngxPermissionsOnly="['VEHRES']" class="detail-account-card">
            <div class="detail-account-card-header">
                <h2 class="fp-body-one detail-account-title">Vehículos</h2>
                <div class="row-header">
                    <button class="fp-btn-white card-detail-custom-btn big" [disabled]="!accountSelectd?.accountType"
                    (click)="accountSelectd?.accountType? navigateTo('/vehicles/list'): null">
                        <h2 class="fp-body-one  detail-account-title-small">Ver más</h2>
                        <img src="assets/images/home/more.svg" alt="icono settings">
                    </button>
                </div>
            </div>
            <div class="status-line">
                <hr>
            </div>
            <ng-container *ngIf="vehiclesState && !vehiclesState.loading">
                <div class="detail-account-card-body" *ngIf="vehiclesState.data && vehicles.length === 0">
                    <fp-item-list-empty *ngIf="vehicles.length === 0" iconEmpety="assets/images/common/vehicle-empty.svg"
                        [descriptionEmpety]="'Cree su primer vehículo'"></fp-item-list-empty>
                    <button class="fp-btn-primary btn-navigate-vehicles" type="button" id="btn-navigate-vehicles"
                    (click)="openModal('fp-new-vehicle')" *ngxPermissionsOnly="['VEHCRE']">
                        Crear vehículo
                    </button>
                </div>
                <div class="detail-account-card-body" *ngIf="vehiclesState.error">
                    <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                        [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'"
                        [notifyError]="'true'">
                    </fp-item-list-empty>
                    <button class="fp-btn-primary btn-navigate-vehicles" type="button" id="btn-reload-vehicles-dashboard">
                        Intentar de nuevo
                    </button>
                </div>
                <div class="detail-account-card-body" *ngIf="vehiclesState.data && vehicles.length > 0">
                    <div class="vehicles-overview-line"><span>Activos</span><span>{{vehiclesStatusNum.activos}}</span></div>
                    <div class="vehicles-overview-line"><span>Inactivos</span><span>{{vehiclesStatusNum.inactivos}}</span></div>
                    <div class="vehicles-overview-line"><span>Sin TAG</span><span>{{vehiclesStatusNum.sinTag}}</span></div>
                </div>
            </ng-container>
        </div>
    </section>
</ng-container>
<ng-container *ngIf="listMovements.loading && vehiclesState.loading">
    <h1 class="fp-h1 detail-title skeleton"></h1>
    <div class="detail-account-card fake">
        <div class="detail-account-card-header ">
            <h2 class="fp-body-one detail-account-title skeleton"></h2>
            <div class="row-header fake">
                <h2 class="fp-body-one  detail-account-title-small skeleton"></h2>
                <button class="fp-btn-white card-detail-custom-btn skeleton">
                </button>
            </div>
        </div>
        <div class="status-line">
            <hr>
        </div>
        <div class="detail-account-card-body">
            <div class="circle-fake skeleton"> </div>
            <p class="text-fake"></p>
        </div>
    </div>
    <div class="detail-account-card fake">
        <div class="detail-account-card-header ">
            <h2 class="fp-body-one detail-account-title skeleton"></h2>
            <div class="row-header fake">
                <h2 class="fp-body-one  detail-account-title-small skeleton"></h2>
                <button class="fp-btn-white card-detail-custom-btn skeleton">
                </button>
            </div>
        </div>
        <div class="status-line">
            <hr>
        </div>
        <div class="detail-account-card-body">
            <div class="circle-fake skeleton"> </div>
            <p class="text-fake"></p>
        </div>
    </div>
</ng-container>
<fp-new-vehicle></fp-new-vehicle>
