import { IPages } from "@app/models/pages";
import { ITemporalPassword } from "@app/models/temporal-password";
import { singUpPagesConst } from "@core/constants/pages.constant";
import { ITimerCode } from "@otpcode/models/otpcode";
import { IOtpVinculation } from "@shared-sign/generic-otp/models/otpcode";
import { IAdministrator } from "@signup/models/administrator";
import { ICompany, IProspectCompanyInvalid } from "@signup/models/company";
import { IHabeasData } from "@signup/models/habeas-data";
import { IRegister } from "@signup/models/legal-representative";
import { ITermsandConditions } from "@signup/models/termsand-conditions";

/**
 * Set a Name to read chart redux
 */
export const SignUpFeatureName = "SignUpState";

/**
 * Grouping to SignUpState
 */
export type SignUpModuleState = Readonly<{
  signUpPagesReducer: IPages;
  signUpCompanyReducer: ICompany;
  signUpLegalRepresentativeReducer: IRegister;
  signUpAdministratorReducer: IAdministrator;
  termsandConditionsReducer: ITermsandConditions;
  habeasDataReducer: IHabeasData;
  prospectCompanyInvalid: IProspectCompanyInvalid;
  signUpOtpCodeReducer: ITimerCode;
  signUpOtpCodeVinculationReducer: IOtpVinculation;
  signUptemporalPasswordReducer: ITemporalPassword;
}>;
export const initalOtpcodeTimer: ITimerCode = {
  time: null,
};
export const initialStateSignUpCompany: ICompany = {
  type: "",
  nit: "",
  dv: "",
  businessName: "",
  city: "",
  location: "",
  address1: "",
  address2: "",
  address3: "",
  otherAddress: "",
  neighborhood: "",
  address: "",
  phone: "",
  phone_extension: "",
  ciiu: "",
  additionalInfo: "",
  cityCodeDane: "",
  emailBilling: "",
  confirmEmail: "",
};

export const initialStateProspectCompanyInvalid: IProspectCompanyInvalid = {
  identificationNumber: null,
  identificationNumberType: null,
  error: null,
  invalid: null,
  loaded: null,
  loading: null,
};

export const initialStateSignUpPages: IPages = {
  pages: [singUpPagesConst[0]],
};

/**
 * Initialize state to Terms and Conditions
 */
export const initialStateITermsandConditions: ITermsandConditions = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to HabeasData
 */
export const initialStateIHabeasData: IHabeasData = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialStateSignUpAdministrator: IAdministrator = {
  identificationType: null,
  identificationNumber: null,
  name: null,
  lastname: null,
  phone: null,
  email: null,
};

export const initialStateTemporalPassword: ITemporalPassword = {
  temporalPassword: null,
};
