import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AutomaticRechargeConfigComponent } from "./automatic-recharge-config.component";

export const AutomaticRechargeConfigRootRoute = "automatic-recharge-config";
const routes: Routes = [
  {
    component: AutomaticRechargeConfigComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AutomaticRechargeConfigRoutingModule {}
