import { ILowBalance } from "@app/models/accounts";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { lowBalanceFeatureName } from "../state/app.state";

export const LowbalanceRootSelector = createFeatureSelector<ILowBalance>(
  lowBalanceFeatureName,
);

export const selectLowBalance = createSelector(
  LowbalanceRootSelector,
  (state: ILowBalance) => state,
);
