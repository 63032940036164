import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class IdentificationTypesService {
  constructor(private http: HttpClient) {}
  /**
   * get Documents Type
   * @param enrollmentType enrollmentType
   */
  getDocumentTypes(enrollmentType: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.prospect.init +
        environment.endpoints.prospect.specific.identificationTypes +
        `?enrollment_type=${enrollmentType}`,
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
  /**
   * get Vinculation Status
   * @param executionId executionId
   */
  // getVinculateStatus(executionId: string): Observable<any> {
  //   return this.http.get(
  //     environment.api_key.publicPJ.back_url +
  //       environment.endpoints.vinculate.init +
  //       "?execution_id=" +
  //       executionId,
  //     {
  //       params: new CustomHttpParams("api-pj-public-apiKey"),
  //     },
  //   );
  // }
}
