import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgScrollbarModule } from "ngx-scrollbar";
import { CheckboxModule } from "../checkbox/checkbox.module";
import { DropdownMultiSelectComponent } from "./dropdown-multi-select.component";

@NgModule({
  declarations: [DropdownMultiSelectComponent],
  imports: [CommonModule, NgScrollbarModule, CheckboxModule, FormsModule],
  exports: [DropdownMultiSelectComponent],
})
export class DropdownMultiSelectModule {}
