import * as rolesAdministratorActions from "@app/store/actions/roles-administrator.action";
import { createReducer, on } from "@ngrx/store";
import { initialStateRolesAdministrator } from "../state/app.state";

export const rolesAdministratorReducer = createReducer(
  initialStateRolesAdministrator,
  on(rolesAdministratorActions.rolesAdministrator, (state, { data }) => ({
    ...state,
    ...data,
  })),
);
