import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@core/core.module";
import { PageMessageComponent } from "./page-message.component";
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreModule],
  exports: [PageMessageComponent],
  declarations: [PageMessageComponent],
})
export class PageMessageModule {}
