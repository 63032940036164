import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "fp-search-value",
  templateUrl: "./search-value.component.html",
  styleUrls: ["./search-value.component.scss"],
})
export class SearchValueComponent {
  @Input() text: string;
  @Input() index: number;
  @Input() active: boolean = false;
  @Output() click$ = new EventEmitter<number>();
  constructor() {}

  clickItem() {
    this.click$.emit(this.index);
  }
}
