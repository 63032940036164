<nav class="fp-nav-primary">
    <ul class="fp-list-menu">
        <img class="fp-logo-blanck" src="assets/images/logo/new-logo.svg" alt="Icono">
        <ng-container *ngFor="let itemMenu of itemsMenu">
            <li
              (click)="handleOption(itemMenu)"
              class=" fp-list-item"
              *ngxPermissionsOnly="[itemMenu.permission]"
              [routerLink]="[itemMenu.router]"
              routerLinkActive="active"
              [id]="'nav-menu-'+itemMenu.id">
                <img class="fp-icon-menu" [src]="itemMenu.icon" alt="Icono">
                <a>{{itemMenu.title}}
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
