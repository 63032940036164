import { createReducer, on } from "@ngrx/store";
import * as customerInfoActions from "../actions/customer-info.actions";
import { initialCustomerInfoState } from "../state/customer-info.state";

export const customerInfoReducer = createReducer(
  initialCustomerInfoState,
  on(customerInfoActions.customerInfoLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(customerInfoActions.customerInfoSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(customerInfoActions.customerInfoError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(customerInfoActions.customerInfoReset, () => ({
    ...initialCustomerInfoState,
  })),
);
