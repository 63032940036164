import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInFeatureName, SignInModuleState } from "../state/signin.state";

export const SignInTemporalPasswordRootSelector = createFeatureSelector<
  SignInModuleState
>(SignInFeatureName);

export const selectSignInTemporalPassword = createSelector(
  SignInTemporalPasswordRootSelector,
  (state: SignInModuleState) => state.signInTemporalPasswordReducer,
);
