import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ItemListEmptyComponent } from "./item-list-empty.component";

@NgModule({
  declarations: [ItemListEmptyComponent],
  imports: [CommonModule],
  exports: [ItemListEmptyComponent],
})
export class ItemListEmptyModule {}
