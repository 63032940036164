import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { DatepickerComponent } from "./datepicker.component";

@NgModule({
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    MatInputModule,
  ],
  providers: [],
})
export class DatepickerModule {}
