import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { cardTransactionsRoute } from "./modules/card-transactions/card-transactions-routing.module";
import { createRechargeRootRoute } from "./modules/recharge/recharge-routing.module";
import {
  createStatusTransactionRootRoute,
  createStatusTransactionRootRouteFreeTag,
} from "./modules/status/status-routing.module";
import { uniqueRechargeRootRoute } from "./modules/unique-recharge/unique-recharge-routing.module";

export const transactionsRootRoute = "transactions";

const routes: Routes = [
  {
    path: "",
    redirectTo: createRechargeRootRoute,
    pathMatch: "full",
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: createRechargeRootRoute,
    loadChildren: () =>
      import("./modules/recharge/recharge.module").then(
        (m) => m.RechargeModule,
      ),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: uniqueRechargeRootRoute,
    loadChildren: () =>
      import("./modules/unique-recharge/unique-recharge.module").then(
        (m) => m.UniqueRechargeModule,
      ),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: "unique-recharge/validate",
    loadChildren: () =>
      import(
        "./modules/unique-recharge-validate/unique-recharge-validate.module"
      ).then((m) => m.UniqueRechargeValidateModule),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: createStatusTransactionRootRoute,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: createStatusTransactionRootRouteFreeTag,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: cardTransactionsRoute,
    loadChildren: () =>
      import("./modules/card-transactions/card-transactions.module").then(
        (m) => m.CardTransactionsModule,
      ),
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
  {
    path: "**",
    redirectTo: createRechargeRootRoute,
    pathMatch: "full",
    data: {
      permissions: {
        only: "CUEASI",
      },
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
