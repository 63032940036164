import { Location } from "@angular/common";
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { pageViewEvent } from "@app/helpers/utag.helpers";
import { CommonsService } from "@shared/services/commons.service";
import { IInfosStepper } from "@signup/models/infoStepper";
import { ModalService } from "../../modal/service/modal.service";

@Component({
  selector: "fp-card-body",
  styleUrls: ["./card-body.component.scss"],
  templateUrl: "./card-body.component.html",
})
export class FpCardBodyComponent implements OnInit {
  @ContentChild("tplRef") tplRef: TemplateRef<any>;
  @ContentChild("tplRefTwo") tplRefTwo: TemplateRef<any>;
  @Input() title: string;
  subTitleSanitizer: SafeHtml;
  @Input() set subTitle(text: string) {
    if (text) {
      this.subTitleSanitizer = this.sanitizer.bypassSecurityTrustHtml(text);
    }
  }
  @Input() showLogo: boolean = false;
  @Input() backOption: boolean = false;
  @Input() navigateBackOtion: boolean = false;
  @Input() showBackButton: string = "visible";
  @Input() template: string;
  @Input() urlBack: string;
  @Input() close: boolean = false;
  @Input() exit: boolean = false;
  @Input() actionType: string = "redirect";
  @Input() typeDashboard: boolean = false;
  @Output() actionClose = new EventEmitter<boolean>();
  @Input() headerType: string;
  @Input() infoStepper: IInfosStepper;
  @Input() showPopUp: boolean = false;
  @Output() emitActionPopUp = new EventEmitter<boolean>();
  @Output() emitNavigateBack = new EventEmitter<boolean>();
  @Input() showfooterBallsRight: boolean = false;
  @Input() oneColumn: boolean = false;
  @Input() subtitleModalSecureExit: string =
    "Complete el proceso para utilizar el servicio <br> de peajes electrónicos";

  constructor(
    public location: Location,
    private router: Router,
    private modalService: ModalService,
    private commonService: CommonsService,
    private sanitizer: DomSanitizer,
  ) {}
  /**
   * Initial state
   */
  ngOnInit(): void {
    document.body.style.backgroundColor = "#f9f9f9";
  }
  /**
   * return Redirect
   */
  back(): void {
    if (!this.urlBack) {
      this.location.back();
      return;
    }
    this.router.navigate([this.urlBack]);
  }

  emitActionClose(): void {
    this.actionClose.emit(true);
  }
  /**
   * action to right button
   */
  rigthAction(): void {
    switch (this.actionType) {
      case "logout":
        this.commonService.logOut();
        break;
      case "redirect":
        this.router.navigate(["/home"]);
        break;
      case "custom":
        this.emitActionClose();
        break;
    }
  }

  /**
   * Open modal
   * @param id string id element Html
   */
  openModal(id: string): void {
    this.modalService.open(id);
    pageViewEvent(`card-body/${id}`);
  }

  actionModal($event: any): void {
    if ($event.typeAction === "exit") {
      this.emitActionPopUp.emit(true);
      this.modalService.close("fp-exit-progress", false);
    } else if ($event.typeAction === "accept") {
      this.modalService.close("fp-exit-progress", false);
    }
  }

  navegateBack() {
    this.emitNavigateBack.emit(true);
  }
}
