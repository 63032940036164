import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  financialDataFeatureName,
  FinancialDataModuleState,
} from "../state/financial-data.state";

export const MinimumAmountPrepaidRootSelector = createFeatureSelector<
  FinancialDataModuleState
>(financialDataFeatureName);

export const selectMinimumAmountPrepaid = createSelector(
  MinimumAmountPrepaidRootSelector,
  (state: FinancialDataModuleState) => state.minimumAmountPrepaidReducer,
);
