import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "hours",
})
export class FpDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, arg?: string): string {
    if (value) {
      if (arg) {
        const valor = this.datePipe.transform(value, "d/MMM/yyyy");
        return valor.replace(".", "");
      } else {
        const valor = this.datePipe.transform(value, "shortTime");
        return valor.replace(". ", ".");
      }
    }
  }
}
