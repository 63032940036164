import { createReducer, on } from "@ngrx/store";
import * as actionsLoader from "../actions/loader.actions";
import { initialLoader } from "../state/loader.state";

export const loaderReducer = createReducer(
  initialLoader,
  on(actionsLoader.loaderShow, (state, { loader }) => ({
    ...state,
    loading: true,
    type: loader.type,
  })),
  on(actionsLoader.loaderHide, (state) => ({
    ...state,
    loading: false,
  })),
);
