import { Injectable, TemplateRef } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Show notification
   */
  showNotify(
    template: TemplateRef<any>,
    panelClass: string | string[] = "",
    duration: number = 4000,
    horizontalPosition: MatSnackBarHorizontalPosition = "center",
    verticalPosition: MatSnackBarVerticalPosition = "top",
  ): void {
    this.snackBar.openFromTemplate(template, {
      horizontalPosition,
      panelClass,
      verticalPosition,
      duration,
    });
  }

  /**
   * Close notification
   */
  closeNotify(): void {
    this.snackBar.dismiss();
  }
}
