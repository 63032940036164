import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const SignUpPagesRootSelector = createFeatureSelector<SignUpModuleState>(
  SignUpFeatureName,
);

export const selectSignUppages = createSelector(
  SignUpPagesRootSelector,
  (state: SignUpModuleState) => state.signUpPagesReducer,
);
