import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import {
  IVehicle,
  IVehicleInformation,
  IVehicles,
  IVehiclesCategories,
  IVehiclesList,
  LastEvaluatedKey,
} from "@vehicles/models/vehicle";
import * as vehicleActions from "@vehicles/store/actions";
import { Observable } from "rxjs";
import {
  selectVehiclesCategories,
  selectVehiclesInformation,
} from "../selectors/vehicles-categories.selector";
import { selectVehiclesToCreate } from "../selectors/vehicles-create.selector";
import { selectVehiclesList } from "../selectors/vehicles-list.selector";
import { selectVehiclepages } from "../selectors/vehicles-pages.selector";
import { selectVehiclesToUpdate } from "../selectors/vehicles-update.selector";
import { VehiclesModuleState } from "../state/vehicles.state";
@Injectable({
  providedIn: "root",
})
export class VehiclesFacade {
  constructor(private store: Store<VehiclesModuleState>) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclesCategories$: Observable<
    IVehiclesCategories
  > = this.store.pipe(select(selectVehiclesCategories));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclesCategories2$: Observable<
    IVehiclesCategories
  > = this.store.pipe(select(selectVehiclesCategories));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclesList$: Observable<IVehiclesList> = this.store.pipe(
    select(selectVehiclesList),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclesList2$: Observable<IVehiclesList> = this.store.pipe(
    select(selectVehiclesList),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclesInformation$: Observable<
    IVehicleInformation
  > = this.store.pipe(select(selectVehiclesInformation));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehiclePages$: Observable<IPages> = this.store.pipe(
    select(selectVehiclepages),
  );

  public selectVehiclesToCreate$: Observable<IVehicles> = this.store.pipe(
    select(selectVehiclesToCreate),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectVehicleToUpdate$: Observable<IVehicle> = this.store.pipe(
    select(selectVehiclesToUpdate),
  );

  setvehiclesToCreate(vehicles: IVehicle[]) {
    this.store.dispatch(vehicleActions.vehiclesToCreateSet(vehicles));
  }

  setVehicleToUpdate(vehicle: IVehicle) {
    this.store.dispatch(vehicleActions.vehicleToUpdateSet(vehicle));
  }

  setvehiclePages(pages: IPage[]) {
    this.store.dispatch(vehicleActions.vehicleSetPage(pages));
  }

  getVehiclesCategories() {
    this.store.dispatch(vehicleActions.vehiclesCategoriesLoad());
  }
  getVehiclesList(personId: string, lastEvaluatedKey?: LastEvaluatedKey) {
    this.store.dispatch(
      vehicleActions.vehiclesListLoad({
        personId,
        lastEvaluatedKey,
        pageSize: 500,
      }),
    );
  }

  setVehicleInformation(vehicleInformation: IVehicleInformation) {
    this.store.dispatch(
      vehicleActions.setVehicleInformation(vehicleInformation),
    );
  }
  /**
   * Set event to call service to reserve tag
   */
  public vehicleListReset() {
    this.store.dispatch(vehicleActions.vehiclesListReset());
  }
}
