import { IPages } from "@app/models/pages";
import { vehiclesPagesConst } from "@core/constants/pages.constant";
import {
  IVehicle,
  IVehicleInformation,
  IVehicles,
  IVehiclesCategories,
  IVehiclesList,
} from "@vehicles/models/vehicle";

/**
 * Set a Name to read chart redux
 */
export const VehiclesFeatureName = "VehicleState";

/**
 * Grouping to VehiclesState
 */
export type VehiclesModuleState = Readonly<{
  vehiclesCategoriesReducer: IVehiclesCategories;
  vehicleInformationReducer: IVehicleInformation;
  vehiclePagesReducer: IPages;
  vehicleListReducer: IVehiclesList;
  vehiclesToCreateReducer: IVehicles;
  vehiclesToUpdateReducer: IVehicle;
}>;

export const initialStateVehiclesListReducer: IVehiclesList = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to Vehicle types
 */
export const initialStateVehiclesCategoriesReducer: IVehiclesCategories = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to Vehicle plate
 */
export const initialStateVehicleInformationReducer: IVehicleInformation = {
  licensePlate: null,
  categoryValue: null,
  accountId: null,
};

export const initialStateVehiclePages: IPages = {
  pages: [vehiclesPagesConst[0]],
};

export const initialStateVehiclesToCreate: IVehicles = {
  data: null,
};

export const initialStateVehiclesToUpdate: IVehicle = {
  plate: null,
  category: null,
  additionalInfo: null,
  noTag: null,
  personId: null,
  accountId: null,
};
