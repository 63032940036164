import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";
import { DescriptionCategoryPipe } from "../../pipes/description-category.pipe";
import { CheckboxModule } from "../checkbox/checkbox.module";
import { ItemListVehicleComponent } from "./item-list-vehicle.component";

@NgModule({
  declarations: [ItemListVehicleComponent, DescriptionCategoryPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [ItemListVehicleComponent],
})
export class ItemListVehicleModule {}
