<div class="fp-modal-background">
</div>

<div class="loder-content">
    <igx-circular-bar [indeterminate]="true" class="custom-size">
        <ng-template igxProgressBarGradient let-id>
            <svg:linearGradient [id]="id" gradientTransform="rotate(0)">
                <stop offset="0%" [attr.stop-color]="'#aacc00'" />
                <stop offset="100%" [attr.stop-color]="'#55a630'" />
            </svg:linearGradient>
        </ng-template>
    </igx-circular-bar>
    <h1 class="fp-h1"> {{ title }} </h1>
    <h2> {{ description }} </h2>
</div>