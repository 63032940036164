import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { Languages } from "@app/store/actions/app.actions";
import { initialModulesResetState } from "@app/store/state/modules.state";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { IMinimumAccount } from "@shared-financial-data/models/financial-data";
import { SharedFinancialDataFacade } from "@shared-financial-data/store/facade/shared-financial-data.facade";
import { TransactionsService } from "@transactions/services/transactions.service";
import { TransactionFacade } from "@transactions/store/facade/transaction.facade";
import {
  IPospaidRechargeAccount,
  IStatusTransactionState,
} from "@transactions/store/state/statusTransaction.state";
import { saveAs } from "file-saver";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import {
  ITransactionsResponse,
  TransactionsStatus,
} from "./models/transactionsStatus.interface";
@Component({
  selector: "fp-transaction-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpTransactionStatus = "fp-transaction-status";
  destroy$: Subject<boolean> = new Subject<boolean>();
  collapsed: boolean = true;
  loading: boolean = true;
  loadingDownloadPdf: boolean = false;
  transactionsStatus: ITransactionsResponse;
  status: TransactionsStatus;
  message: string;
  urlClean: string;
  exit: boolean;
  temporalTransactionObject: any;
  messageTime: string = "Podrá hacer uso de sus recursos después de ";
  @ViewChild("notifi", { static: true }) notifi: TemplateRef<any>;
  entityError: string = "";
  constructor(
    private transactionFacade: TransactionFacade,
    private commonsService: CommonsService,
    private transactionsService: TransactionsService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private appFacade: AppFacade,
    private route: ActivatedRoute,
    private sharedFinancialDataFacade: SharedFinancialDataFacade,
  ) {}
  /**
   * ngOnInit()
   */
  async ngOnInit(): Promise<any> {
    this.appFacade.setModules(initialModulesResetState);
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
    const promisePospaidRechargeData = await this.selectPospaidRechargeAccount$
      .pipe(take(1))
      .toPromise();
    if (
      promisePospaidRechargeData.data === null &&
      promisePospaidRechargeData.loading === false &&
      promisePospaidRechargeData.error === null
    ) {
      await this.getMinimumAmountData("PRE");
      this.urlClean = this.route.snapshot.paramMap.get("id")
        ? window.location.href.replace("#", "%23").split("?token")[0]
        : "";
      this.selectStatusTransaction$
        .pipe(takeUntil(this.destroy$))
        .subscribe((statusTransaction: IStatusTransactionState) => {
          if (statusTransaction.loaded) {
            this.verifyTransactionState(statusTransaction);
          } else if (
            this.urlClean &&
            !statusTransaction.loaded &&
            !statusTransaction.status?.data &&
            !statusTransaction.loading &&
            !statusTransaction.error &&
            !this.exit
          ) {
            this.transactionFacade.getStatusTransaction({
              url: this.urlClean,
            });
          }
        });
    }
    if (promisePospaidRechargeData.data !== null) {
      await this.getMinimumAmountData("POS");
      this.status = "approved";
      this.loading = false;
      this.temporalTransactionObject = JSON.parse(
        localStorage.getItem("temporalTransactionObject"),
      );
    }
    if (promisePospaidRechargeData.error !== null) {
      this.entityError = promisePospaidRechargeData.error.error.message;
    }
  }
  async getMinimumAmountData(methodType: "PRE" | "POS"): Promise<any> {
    let serviceInfo: any;
    if (methodType === "POS") {
      const promiseData = await this.selectMinimumAmountPospaid$
        .pipe(take(1))
        .toPromise();
      serviceInfo =
        promiseData.data === null
          ? { tiempoActualizacionPeajes: 1 }
          : promiseData.data[0];
    } else {
      const promiseMinPrepaid = await this.selectMinimumAmountPrepaid$
        .pipe(take(1))
        .toPromise();
      serviceInfo =
        promiseMinPrepaid.data === null
          ? { tiempoActualizacionPeajes: 1 }
          : promiseMinPrepaid.data?.find(
              (accountType: any) => accountType.convenio === "4023",
            );
    }
    this.messageTime +=
      serviceInfo?.tiempoActualizacionPeajes > 1
        ? `${serviceInfo?.tiempoActualizacionPeajes} horas`
        : `${serviceInfo?.tiempoActualizacionPeajes} hora`;
  }
  /**
   * downloadPdfReceipt
   */
  async downloadPdfReceipt(): Promise<any> {
    this.loadingDownloadPdf = true;
    const promiseStatusTransaction = await this.selectStatusTransaction$
      .pipe(take(1))
      .toPromise();
    try {
      const transactionId =
        promiseStatusTransaction.status.data.transactionInfo.transactionNumber;
      const receiptInfo = await this.transactionsService
        .getTransactionReceipt(transactionId)
        .toPromise();
      const pruebaPDF =
        receiptInfo.data.transactionReceiptInfo.pdfTransactionReceipt;
      const prueba = this.generateBlob(pruebaPDF);
      const fileName = `${promiseStatusTransaction.status.data.transactionInfo.transactionNumber}.pdf`;
      saveAs(prueba, fileName);
    } catch (error) {
      this.snackBar.openFromTemplate(this.notifi, {
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: "fp-snack-modify",
        duration: 6000,
      });
    } finally {
      this.loadingDownloadPdf = false;
    }
  }

  /**
   * Convert string base64 to data Bold
   * @param data string of base 64
   * @param sliceSize number of base 64
   */
  generateBlob(data: string) {
    const decodeData = atob(data);
    const dataBold = [];
    const sliceSize = 512;
    for (let index = 0; index < decodeData.length; index += sliceSize) {
      const slice = decodeData.slice(index, index + sliceSize);
      const generateInfo = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        generateInfo[i] = slice.charCodeAt(i);
      }
      const generateArrayBold = new Uint8Array(generateInfo);
      dataBold.push(generateArrayBold);
    }
    const blob = new Blob(dataBold, { type: "application/pdf;charset=utf-8" });
    return blob;
  }

  /**
   * verify transaction status
   * @param statusTransaction Transaction Status
   */
  verifyTransactionState(statusTransaction: IStatusTransactionState) {
    if (statusTransaction.error) {
      try {
        switch (statusTransaction.error.error?.data?.code) {
          case "100296": // url ya consumida
          case "100297": // El formato de la URL no es válido.
            this.commonsService.redirectToHome();
            break;
          case "100295": // Se intentó ingresar por una URL que no existe para PSE
            this.commonsService.navigate("/signout");
            break;
          default:
            this.commonsService.navigate("/error"); // lo sentimos
            break;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("unknown error", err);
        this.commonsService.navigate("/error"); // lo sentimos
      }
    } else {
      this.transactionsStatus = statusTransaction.status;
      switch (this.transactionsStatus?.data.resultMessage?.code) {
        case "100290":
        case "100630":
          this.status = "approved";
          this.message =
            this.transactionsStatus?.data.transactionInfo.transactionTypeId ===
              "1" ||
            this.transactionsStatus?.data.transactionInfo.transactionTypeId ===
              "13"
              ? "message.params"
              : "message.recharge";
          break;
        case "100291": // rechazada
          this.status = "error";
          this.message = "message.again";
          break;
        case "100292": // progreso
          this.status = "process";
          this.message = "message.confirm";
          break;
      }
    }
    this.loading = statusTransaction.loading;
  }
  /**
   * Destroy Componentes
   */
  ngOnDestroy() {
    this.exit = true;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.clearStore();
    this.snackBar.dismiss();
  }

  /**
   * Hide notification
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }
  clearStore(): void {
    this.transactionFacade.resetStatusTransactionData();
    this.transactionFacade.resetRechargeAccount();
    localStorage.removeItem("temporalTransactionObject");
  }

  /**
   * redirectToHome
   */
  navigatetoHome(): void {
    this.commonsService.redirectToHome();
  }

  /**
   * Get select Status Transaction
   */
  get selectStatusTransaction$(): Observable<IStatusTransactionState> {
    return this.transactionFacade.selectStatusTransaction$;
  }

  get selectPospaidRechargeAccount$(): Observable<IPospaidRechargeAccount> {
    return this.transactionFacade.selectPospaidRechargeAccount$;
  }

  get selectMinimumAmountPospaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialDataFacade.selectMinimumAmountPospaid$;
  }

  get selectMinimumAmountPrepaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialDataFacade.selectMinimumAmountPrepaid$;
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
