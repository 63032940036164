import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const TemporalPasswordRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectTemporalPassword = createSelector(
  TemporalPasswordRootSelector,
  (state: SignUpModuleState) => state.signUptemporalPasswordReducer,
);
