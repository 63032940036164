import { Injectable } from "@angular/core";
import { IAddress } from "@app/modules/vehicles/models/address";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { DashboardService } from "@shared/services/dashboard.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import * as addressActions from "../actions/address.actions";

@Injectable()
/**
 * Address Effect
 */
export class AddressEffect {
  accounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.addressLoad),
      switchMap((action) =>
        this.dashboardService.getAddress(action.data).pipe(
          take(1),
          map((resp: IAddress) => addressActions.addressSave(resp.body)),
          catchError((error) => of(addressActions.addressError({ error }))),
        ),
      ),
    ),
  );

  /**
   * Constructor address Service Effect
   *
   * @param actions$ Action provider to set data en store
   * @param dashboardService Service to get Data
   */
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
