import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class TranslateService {
  http: HttpClient;
  constructor() {}
  childLoaderFactory(): TranslateLoader {
    return new TranslateHttpLoader(this.http, "assets/i18n/register/", ".json");
  }
}
