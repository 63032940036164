import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { LowBalanceNavigationService } from "../services/low-balance-navigation-service.service";
import { AccountFacade } from "../store/facade/account.facade";

@Injectable({
  providedIn: "root",
})
export class LowBalanceGuard implements CanActivate {
  constructor(
    private lowBalanceFacade: AccountFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private lowBalanceNavigationService: LowBalanceNavigationService,
  ) {}
  /**
   * CanActivate
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const data = await this.selectLowBalancePages$.pipe(take(1)).toPromise();

    if (data.pages) {
      let existUrl = false;
      for (const page of data.pages) {
        if (`/account/${page.active}` === state.url.split("?")[0]) {
          existUrl = true;
          break; // Salir del bucle una vez que se encuentra la URL.
        }
      }

      if (!existUrl) {
        if (
          data.pages[data.pages.length - 1].active.indexOf("successful") >= 0
        ) {
          this.commonsService.navigate("/home");
        } else {
          this.commonsService.navigate(
            `/account/${data.pages[data.pages.length - 1].active}`,
          );
        }
      }

      return existUrl;
    } else {
      this.commonsService.navigate("/account/low-balance");
      return false;
    }
  }

  /**
   * Clear Vehicle Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.account === true) {
      modules.account = false;
      this.lowBalanceNavigationService.clearLowBalanceStore();
      this.appFacade.setModules(modules);
    }
  }

  /**
   * Get data to facade store communication
   */
  get selectLowBalancePages$(): Observable<IPages> {
    return this.lowBalanceFacade.selectLowBalancePages$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
