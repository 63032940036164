import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const HabeasDataRootSelector = createFeatureSelector<SignUpModuleState>(
  SignUpFeatureName,
);

export const selectHabeasData = createSelector(
  HabeasDataRootSelector,
  (state: SignUpModuleState) => state.habeasDataReducer,
);
