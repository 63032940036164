import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { pageEvent } from "@app/helpers/utag.helpers";
import { CommonsService } from "@shared/services/commons.service";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-request-tags",
  templateUrl: "./request-tags.component.html",
  styleUrls: ["./request-tags.component.scss"],
})
export class RequestTagsComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private commonsService: CommonsService,
    private http: HttpClient,
  ) {}

  formSelectModality: UntypedFormGroup;
  changingModality = false;
  texts: any = {};

  modalities = [
    {
      value: "1",
      name: "Compra",
      description: "TAGs para",
      info: [
        {
          text:
            "<p class='text-item'><span>Adquiera TAGs de reserva</span>  para tener unidades disponibles por posibles daños a los que están en uso en los vehículos de su flota.</p>",
        },
        {
          text:
            "<p class='text-item'><span>Compre los TAGs que necesite,</span> cada uno a <span>$15.000.</span></p>",
        },
        {
          text:
            "<p class='text-item'><span>Sin costo de envío.</span> Los enviaremos al domicilio que registre en su compra.</p>",
        },
      ],
    },
    {
      value: "2",
      name: "Garantía",
      description: "TAGs para",
      info: [
        {
          text:
            "<p class='text-item'><span>Déjenos una PQRS,</span> ingrese sus datos de contacto y seleccione <span>'Servicio / Peajes' y 'Tipo solicitud / Reposición de TAG'.</span></p>",
        },
        {
          text:
            "<p class='text-item'><span>Evaluaremos si la garantía aplica.</span> Tiene <span>hasta 3 meses</span> para reportar fallas del TAG.</p>",
        },
        {
          text:
            "<p class='text-item'>Mantenga el TAG instalado para la solicitud. Adjunte <span>foto del panorámico y la placa.</span></p>",
        },
      ],
    },
  ];

  async ngOnInit(): Promise<void> {
    this.http
      .get("assets/i18n/vehicles/request-tags/es.json")
      .subscribe((data: any) => {
        this.texts = data;
      });
    this.changingModality = false;
    this.initForm();
  }

  initForm(): void {
    this.formSelectModality = new UntypedFormGroup({
      paymentType: new UntypedFormControl("1", [Validators.required]),
    });
  }
  openModal(id: string): void {
    this.modalService.open(id);
  }

  async selectModality(): Promise<any> {
    if (this.formSelectModality?.controls?.paymentType?.value === "1") {
      this.openModal("fp-gou-modal");
    } else {
      window.open(this.texts?.links?.assurance, "_blank");
    }
  }

  chooseModality(method: string): void {
    if (!this.changingModality) {
      this.formSelectModality?.controls?.paymentType?.setValue(method);
    }
  }

  close(route: string): void {
    this.commonsService.navigate(route);
  }

  navigateGou() {
    pageEvent("btn-fp-gou-modal", "vehicles/request-tags", "Entendido");
    this.modalService.close("fp-gou-modal", true);
    if (this.formSelectModality?.controls?.paymentType?.value === "1") {
      window.open(this.texts?.links?.buy, "_blank");
    }
  }
}
