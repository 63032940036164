import { createAction } from "@ngrx/store";
import { IAccountFinancialProduct } from "../../models/account-modality";

export const enum TypeActionsAccountFinancialProduct {
  accountFinancialProductString = "[ACCOUNT FINANCIAL PRODUCT] set information",
  accountFinancialProductResetString = "[ACCOUNT FINANCIAL PRODUCT] Reset data",
}
/**
 * Action to set accountFinancialProduct information
 */
export const accountFinancialProduct = createAction(
  TypeActionsAccountFinancialProduct.accountFinancialProductString,
  (data: IAccountFinancialProduct) => ({
    data,
  }),
);
/**
 * Action to reset data
 */
export const accountFinancialProductReset = createAction(
  TypeActionsAccountFinancialProduct.accountFinancialProductResetString,
);
