import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";

@Component({
  selector: "fp-pop-up-permission-validation",
  templateUrl: "./pop-up-permission-validation.component.html",
})
export class PopUpPermissionValidationComponent {
  @Output() emitActionPopUp = new EventEmitter<{}>();

  constructor(private modalService: ModalService, private router: Router) {}

  actionModal($event: any): void {
    if ($event.typeAction === "exit") {
      this.modalService.close("fp-pop-up-permission-validation", false);
      this.router.navigate(["/home"]);
    } else if ($event.typeAction === "accept") {
      this.emitActionPopUp.emit({ ...$event });
      this.modalService.close("fp-pop-up-permission-validation", false);
      this.router.navigate(["/home"]);
    }
  }
}
