import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as reportsActions from "../actions/index";
import { selectReportsPages } from "../selectors/reports-pages.selector";
import { ReportsModuleState } from "../state/reports.state";
@Injectable({
  providedIn: "root",
})
export class ReportsFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectReportsPages$: Observable<IPages> = this.store.pipe(
    select(selectReportsPages),
  );

  constructor(private store: Store<ReportsModuleState>) {}

  setReportsPages(pages: IPage[]) {
    this.store.dispatch(reportsActions.ReportsSetPage(pages));
  }
}
