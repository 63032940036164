import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { singUpPagesConst } from "@core/constants/pages.constant";
import { select, Store } from "@ngrx/store";
import {
  IOtpVinculation,
  IVinculationRequest,
} from "@shared-sign/generic-otp/models/otpcode";
import { IAdministrator } from "@signup/models/administrator";
import { ICompany, IProspectCompanyInvalid } from "@signup/models/company";
import { IHabeasData } from "@signup/models/habeas-data";
import { IRegister } from "@signup/models/legal-representative";
import { ITermsandConditions } from "@signup/models/termsand-conditions";
import * as signUpActions from "@signup/store/actions";
import { Observable } from "rxjs";
import { ITemporalPassword } from "../../../../models/temporal-password";
import { ITimerCode } from "../../modules/otpcode/models/otpcode";
import * as actionsHabeasData from "../actions/habeas-data.actions";
import * as otpCodeActions from "../actions/otpcode.actions";
import * as actionPassword from "../actions/temporal-password.actions";
import * as actionsTC from "../actions/terms-conditions.actions";
import { selectHabeasData } from "../selectors/habeas-data.selector";
import { selectotpVinculationCode } from "../selectors/otp-code-vinculation.selector";
import { selectotpCode } from "../selectors/otp-code.selector";
import { selectProspectCompanyInvalid } from "../selectors/prospect-company-invalid.selector";
import { selectSignUpAdministrator } from "../selectors/signup-administrator.selector";
import { selectSignUpCompany } from "../selectors/signup-company.selector";
import { selectSignUpLegalRepresentative } from "../selectors/signup-legal-representative.selector";
import { selectSignUppages } from "../selectors/signup-pages.selector";
import { selectTemporalPassword } from "../selectors/temporal-password.selector";
import { selectTermsandConditions } from "../selectors/terms-conditions.selector";
import { SignUpModuleState } from "../state/signup.state";
@Injectable({
  providedIn: "root",
})
export class SignUpFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSignUpCompany$: Observable<ICompany> = this.store.pipe(
    select(selectSignUpCompany),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSignUppages$: Observable<IPages> = this.store.pipe(
    select(selectSignUppages),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSignUpLegalRepresentative$: Observable<
    IRegister
  > = this.store.pipe(select(selectSignUpLegalRepresentative));
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectSignUpAdministrator$: Observable<
    IAdministrator
  > = this.store.pipe(select(selectSignUpAdministrator));
  public selectTermsandConditions$: Observable<
    ITermsandConditions
  > = this.store.pipe(select(selectTermsandConditions));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectHabeasData2$: Observable<IHabeasData> = this.store.pipe(
    select(selectHabeasData),
  );
  public selectTermsandConditions2$: Observable<
    ITermsandConditions
  > = this.store.pipe(select(selectTermsandConditions));

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectHabeasData$: Observable<IHabeasData> = this.store.pipe(
    select(selectHabeasData),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectProspectCompanyInvalid$: Observable<
    IProspectCompanyInvalid
  > = this.store.pipe(select(selectProspectCompanyInvalid));

  public selectOtpCodeTimer$: Observable<ITimerCode> = this.store.pipe(
    select(selectotpCode),
  );

  public selectTemporalPassword$: Observable<
    ITemporalPassword
  > = this.store.pipe(select(selectTemporalPassword));

  public selectOtpCodeVinculation$: Observable<
    IOtpVinculation
  > = this.store.pipe(select(selectotpVinculationCode));

  constructor(private store: Store<SignUpModuleState>) {}

  setSignUpPages(pages: IPage[]) {
    this.store.dispatch(signUpActions.signupSetPage(pages));
  }

  setSignUpCompany(signUpCompanyInformation: ICompany) {
    this.store.dispatch(signUpActions.signUpCompany(signUpCompanyInformation));
  }

  resetSignUpCompany() {
    this.store.dispatch(signUpActions.signUpCompanyReset());
  }

  setSignUpLegalRepresentativeInformation(
    signUpLegalRepresentativeInformation: IRegister,
  ) {
    this.store.dispatch(
      signUpActions.signUpLegalRepresentative(
        signUpLegalRepresentativeInformation,
      ),
    );
  }

  resetSignUpLegalRepresentativeInformation() {
    this.store.dispatch(signUpActions.signUpLegalRepresentativeReset());
  }

  setSignUpAdministrator(signUpAdministratorInformation: IAdministrator) {
    this.store.dispatch(
      signUpActions.signUpAdministrator(signUpAdministratorInformation),
    );
  }

  resetSignUpAdministrator() {
    this.store.dispatch(signUpActions.signUpAdministratorReset());
  }

  public setCheckTermsandConditionsState(value: boolean): void {
    this.store.dispatch(actionsTC.setTermsandConditionsCheckRequired(value));
  }
  public resetTermsandConditions(): void {
    this.store.dispatch(actionsTC.resetTermsandConditions());
  }

  public setCheckHabeasDataState(value: boolean): void {
    this.store.dispatch(actionsHabeasData.setHabeasDataCheckRequired(value));
  }

  public getTermandConditions(): void {
    this.store.dispatch(actionsTC.termsandConditionsLoad());
  }

  public setTemporalPassword(data: ITemporalPassword): void {
    this.store.dispatch(actionPassword.temporalPassword(data));
  }

  public getHabeasData(): void {
    this.store.dispatch(actionsHabeasData.habeasDataLoad());
  }

  public setCheckState(value: boolean): void {
    this.store.dispatch(actionsHabeasData.habeasDataSetCheckOptional(value));
  }

  public resetHabeasData(): void {
    this.store.dispatch(actionsHabeasData.resetHabeasData());
  }

  public setOtpCodeTimer(timer: ITimerCode): void {
    this.store.dispatch(otpCodeActions.otpTimer(timer));
  }

  public otpCodeTimerReset(): void {
    this.store.dispatch(signUpActions.otpTimerReset());
  }

  public prospectCompanyInvalidReset() {
    this.store.dispatch(signUpActions.prospectCompanyInvalidReset());
  }

  public temporalPassword(data: ITemporalPassword) {
    this.store.dispatch(signUpActions.temporalPassword(data));
  }

  public setOtpVinculation(data: IVinculationRequest): void {
    this.store.dispatch(otpCodeActions.otpCodeVinculationLoad(data));
  }

  public resetOtpVinculationData(): void {
    this.store.dispatch(otpCodeActions.resetOtpCodeVinculationData());
  }

  public resetSignUpState() {
    this.prospectCompanyInvalidReset();
    this.otpCodeTimerReset();
    this.resetSignUpCompany();
    this.resetSignUpLegalRepresentativeInformation();
    this.resetSignUpAdministrator();
    this.resetHabeasData();
    this.resetTermsandConditions();
    this.resetOtpVinculationData();
    this.setSignUpPages([singUpPagesConst[0]]);
    this.temporalPassword({ temporalPassword: null });
  }
}
