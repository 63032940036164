import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ReportsFeatureName, ReportsModuleState } from "../state/reports.state";

export const ReportsPagesRootSelector = createFeatureSelector<
  ReportsModuleState
>(ReportsFeatureName);

export const selectReportsPages = createSelector(
  ReportsPagesRootSelector,
  (state: ReportsModuleState) => state.reportsPagesReducer,
);
