import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  financialDataFeatureName,
  FinancialDataModuleState,
} from "../state/financial-data.state";

export const FinancialProductsRootSelector = createFeatureSelector<
  FinancialDataModuleState
>(financialDataFeatureName);

export const selectFinancialProducts = createSelector(
  FinancialProductsRootSelector,
  (state: FinancialDataModuleState) => state.financialProductsReducer,
);
