import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountFeatureName, AccountModuleState } from "../state/account.state";

export const AccountModalityRootSelector = createFeatureSelector<
  AccountModuleState
>(AccountFeatureName);

export const selectAccountModality = createSelector(
  AccountModalityRootSelector,
  (state: AccountModuleState) => state.accountModalityReducer,
);
