import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@app/guards/auth.guard";
import { CompleteSignUpGuard } from "./guards/complete-signup.guard";

export const completeSignUpRootRoute = "complete-signup";
const routes: Routes = [
  {
    path: "",
    redirectTo: "user",
    pathMatch: "full",
  },
  {
    path: "user",
    loadChildren: () =>
      import("./modules/user/user.module").then((m) => m.UserModule),
    canActivate: [],
  },
  {
    path: "new-password",
    loadChildren: () =>
      import("./modules/new-password/new-password.module").then(
        (m) => m.NewPasswordModule,
      ),
    canActivate: [CompleteSignUpGuard],
  },
  {
    path: "identity-verification",
    loadChildren: () =>
      import(
        // eslint-disable-next-line comma-dangle
        "./modules/identity-verification/identity-verification.module"
      ).then((m) => m.IdentityVerificationModule),
    canActivate: [CompleteSignUpGuard],
  },
  {
    path: "successful",
    loadChildren: () =>
      import("./modules/successful/successful.module").then(
        (m) => m.SuccessfulModule,
      ),
    canActivate: [CompleteSignUpGuard],
  },
  {
    path: "error",
    loadChildren: () =>
      import("./modules/error/error.module").then((m) => m.ErrorModule),
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompleteSignupRoutingModule {}
