import { createAction } from "@ngrx/store";
import { IVehicle } from "../../models/vehicle";

export const enum TypeCreateVehiclesActions {
  vehicleToCreateSetString = "[VEHICLE TO CREATE] SET vehicles",
}

/**
 * Action to detect event loaded vehicles categories data
 */
export const vehiclesToCreateSet = createAction(
  TypeCreateVehiclesActions.vehicleToCreateSetString,
  (data: IVehicle[]) => ({ data }),
);
