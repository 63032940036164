import { ICities } from "@shared/models/cities";

export const citiesFeatureName = "citiesReducer";

export const initialCitiesReducer: ICities = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
