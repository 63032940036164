<ng-container [ngSwitch]="status">
    <div *ngSwitchCase="'approved'" class="response success">
      <img src="assets/images/transactions/success-icon.svg" alt="info" />
      <span>Aprobada</span>
    </div>
    <div *ngSwitchCase="'rejected'" class="response error">
      <img src="assets/images/transactions/error-icon.svg" alt="info" />
      <span>Rechazada</span>
    </div>
    <div *ngSwitchCase="'pending'" class="response inprocess">
      <img src="assets/images/transactions/inprocess-icon.svg" alt="info" />
      <span>En validación</span>
    </div>
  </ng-container>