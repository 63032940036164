import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CitiesService {
  constructor(private http: HttpClient) {}
  /**
   * get cities from divipola
   */
  getCities(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.commons.init +
        environment.endpoints.commons.specific.cities +
        `?city_enabled=true`,
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
}
