import { ICustomerInfo } from "@app/models/customer-info";

export const customerInfoFeatureName = "customerInfoReducer";

export const initialCustomerInfoState: ICustomerInfo = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
