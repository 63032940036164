import { IPages } from "@app/models/pages";
import { ITemporalPassword } from "@app/models/temporal-password";
import { completeSignUpPagesConst } from "@core/constants/pages.constant";
import { ITimerCode } from "@otpcode/models/otpcode";
import { IUserInformation } from "../../models/complete-signup";

/**
 * Set a Name to read chart redux
 */
export const CompleteSignUpFeatureName = "CompleteSignUpState";

/**
 * Grouping to CompleteSignUpState
 */
export type CompleteSignUpModuleState = Readonly<{
  completeSignUpUserInformationReducer: IUserInformation;
  completeSignUpPagesReducer: IPages;
  completeSignUpOtpCodeTimerReducer: ITimerCode;
  completeSignUpTemporalPasswordReducer: ITemporalPassword;
}>;

/**
 * Initialize state to Vehicle plate
 */
export const initialStateCompleteSignUpUserInformation: IUserInformation = {
  identificationType: "",
  identificationNumber: "",
  name: "",
  lastname: "",
  phone: "",
  email: "",
  authorizationCheck: false,
};

export const initialStateCompleteSignupPages: IPages = {
  pages: [completeSignUpPagesConst[0]],
};

export const initialStateCompleteSignupTemporalPassword: ITemporalPassword = {
  temporalPassword: null,
};

export const initialStateCompleteSignupOtpCodeTimer: ITimerCode = {
  time: null,
};
