import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GenericComponent } from "./generic.component";

export const genericRootRoute = "generic";
const routes: Routes = [
  {
    component: GenericComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GenericRoutingModule {}
