import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import moment from "moment";
moment.locale("es-mx");
import { DaterangepickerDirective } from "ngx-daterangepicker-material";

@Component({
  selector: "fp-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class DatepickerComponent {
  @Input() maxDate: string;
  @Input() minDate: string;
  @Output() selectedDate = new EventEmitter<{
    payload: { startDate: moment.Moment; endDate: moment.Moment };
  }>();
  @ViewChild(DaterangepickerDirective, { static: true })
  picker: DaterangepickerDirective;
  selected: { startDate: moment.Moment; endDate: moment.Moment };
  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: moment.localeData().firstDayOfWeek(),
    separator: " - ",
    applyLabel: "ok",
    format: "DD/MM/YYYY",
  };
  validInput: boolean = false;
  error: boolean = false;
  constructor() {}

  choosedDate(selected: any) {
    this.validInput = selected.startDate !== null;
    this.selectedDate.emit(selected);
  }
}
