import { createAction } from "@ngrx/store";
import { ICompany } from "@signup/models/company";

export const enum TypeActionsSignUpCompany {
  signUpCompanyString = "[SIGN UP COMPANY] set information",
  signUpCompanyResetString = "[SIGN UP COMPANY] Reset data",
}
/**
 * Action to set vehicle information
 */
export const signUpCompany = createAction(
  TypeActionsSignUpCompany.signUpCompanyString,
  (data: ICompany) => ({ data }),
);
/**
 * Action to reset data
 */
export const signUpCompanyReset = createAction(
  TypeActionsSignUpCompany.signUpCompanyResetString,
);
