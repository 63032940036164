<fp-card-body [headerType]="'secondary'" [template]="'secondTemplate'" urlBack="/account/select-modality"
    backOption="true" close="true">
    <ng-template #tplRef>
        <div class="financial-body">
            <h1 class="fp-h1 financial-title">{{ 'financialproduct_title' | translate }}</h1>
            <h2 class="fp-body-one financial-subtitle">{{ 'financialproduct_subtitle' | translate }}</h2>
            <div class="form-group">
                <div class="financial-card">
                    <form class="formFinancialProduct" novalidate [formGroup]="formFinancialProduct">
                        <div class="select-content">
                            <label class="form-label" for="entitySelect">
                                {{ 'financialproduct_entity' | translate }}</label>
                            <mat-form-field appearance="fill">
                                <mat-select #entitySelect formControlName="entity" (closed)="saveAccountInfo()"
                                    (selectionChange)="entityChange($event.value)" panelClass="fp-address-select"
                                    placeholder="{{ 'financialproduct_entityplaceholder' | translate }}">
                                    <ng-container *ngFor="let entity of availableEntities">
                                        <mat-option *ngIf="entity.enabled === 'true'" class="option-select"
                                            [value]="entity.code">
                                            {{entity.entity}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                        <div class="select-content">
                            <label class="form-label" for="productSelect">{{ 'financialproduct_product' | translate
                                }}</label>
                            <mat-form-field appearance="fill">
                                <mat-select #productSelect formControlName="financialProduct"
                                    (closed)="saveAccountInfo()" (selectionChange)="productChange($event.value)"
                                    panelClass="fp-address-select"
                                    placeholder="{{ 'financialproduct_productplaceholder' | translate }}">
                                    <mat-option class="option-select" *ngFor="let product of availableProducts"
                                        [value]="product.name">
                                        {{product.name}}
                                    </mat-option>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                        <div class="options-container">
                            <ng-container *ngIf="productType && productType.indexOf('Cuenta') >= 0">
                                <div class="radio-button-generic">
                                    <label class="form-label">{{ 'financialproduct_accountType' | translate
                                        }}</label>
                                    <div class="radio-group radio-type-option" id="idTypes">
                                        <ng-container
                                            *ngFor="let accountType of productOptions[0].values, let itype = index">
                                            <div class="conten-radio-generic">
                                                <button class="input-radio-option"
                                                    [class.selected-option]="this.formFinancialProduct.controls.accountType.value === accountType"
                                                    type="button" (click)="chooseAccountType(accountType)"
                                                    id="type-{{accountType}}">
                                                    {{ accountType }}
                                                    <span class="radio-btn-container">
                                                        <fp-radiobutton formControlName="accountType" name="accountType"
                                                            [value]="accountType" inputId="accountType{{ itype }}">
                                                        </fp-radiobutton>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="productType && productType.indexOf('Cupo') >= 0 || productType && productType.indexOf('Tarjeta') >= 0">
                                <div class="card-code">
                                    <fp-input #creditCardNumber class="form-group card-number" styleClass="form-group"
                                        labelClass="form-label" nameLabelTranslate="financialproduct_cardNumber"
                                        viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                        [inputClass]="formFinancialProduct.controls['cardNumber'].valid ? 'black-border': 'input-large'"
                                        typeInput="text" nameInput="cardNumber" idInput="cardNumber" maxlength="19"
                                        formControlName="cardNumber" minlength="19"
                                        [inputValidationMessages]="accountInfoValidationMessages.cardNumber"
                                        [formControl]="formFinancialProduct.controls['cardNumber']" regexp="[0-9 ]"
                                        matchRegexp="[0-9 ]" [viewMessageError]="viewMessageError"
                                        (keyup)="updateCardNumber()" (onBlur)="inputEvent($event)"
                                        (change)="saveAccountInfo()">
                                    </fp-input>
                                    <ng-container *ngIf="productType && productType.indexOf('Tarjeta') >= 0">
                                        <fp-input class="form-group card-code" labelClass="form-label"
                                            nameLabelTranslate="financialproduct_verificationCode" viewSpaceInput="true"
                                            [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                            [inputClass]="formFinancialProduct.controls['verificationCode'].valid ? 'black-border': 'input-large'"
                                            typeInput="tel" nameInput="verificationCode" idInput="verificationCode"
                                            maxlength="4" minlength="3" formControlName="verificationCode"
                                            [inputValidationMessages]="accountInfoValidationMessages.verificationCode"
                                            [formControl]="formFinancialProduct.controls['verificationCode']"
                                            regexp="^[0-9]{0,4}$" matchRegexp="(\d+)" [matchOnInput]="false"
                                            (change)="saveAccountInfo()">
                                        </fp-input>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="productType && productType.indexOf('Tarjeta') >= 0">
                                <div class="card-info">
                                    <fp-input #expMonth class="form-group" labelClass="form-label"
                                        nameLabelTranslate="financialproduct_expirationDate" viewSpaceInput="true"
                                        [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                        [inputClass]="formFinancialProduct.controls['expirationMonth'].valid ? 'black-border': 'input-large'"
                                        typeInput="tel" nameInput="expirationMonth" idInput="expirationMonth"
                                        formControlName="expirationMonth" maxlength="2" minlength="2"
                                        [inputValidationMessages]="accountInfoValidationMessages.expirationMonth"
                                        [formControl]="formFinancialProduct.controls['expirationMonth']"
                                        regexp="^(1[0-2]|0[1-9]|\d)$" matchRegexp="^(1[0-2]|0[1-9]|\d)$"
                                        [matchOnInput]="false" placeholder="MM" (change)="saveAccountInfo()"
                                        (keyup)="addLeadingZero($event)">
                                    </fp-input>
                                    <fp-input class="form-group exp-year" labelClass="form-label"
                                        nameLabelTranslate="financialproduct_expirationYear" viewSpaceInput="true"
                                        [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                        [inputClass]="formFinancialProduct.controls['expirationYear'].valid ? 'black-border': 'input-large'"
                                        typeInput="tel" nameInput="expirationYear" idInput="expirationYear"
                                        formControlName="expirationYear" maxlength="2" minlength="2"
                                        [inputValidationMessages]="accountInfoValidationMessages.expirationYear"
                                        [formControl]="formFinancialProduct.controls['expirationYear']"
                                        regexp="^[0-9]{0,2}$" matchRegexp="(\d+)" [matchOnInput]="false"
                                        placeholder="YY" (change)="saveAccountInfo()">
                                    </fp-input>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="productType && productType.indexOf('Cupo') >= 0 || productType && productType.indexOf('Cuenta') >= 0">
                                <fp-input class="form-group" labelClass="form-label"
                                    nameLabelTranslate="financialproduct_lastDigits" viewSpaceInput="true"
                                    [viewErrorsInput]="true" [viewIconErrorInput]="true"
                                    [inputClass]="formFinancialProduct.controls['lastDigits'].valid ? 'black-border': 'input-large'"
                                    typeInput="text" nameInput="lastDigits" idInput="lastDigits" maxlength="4"
                                    minlength="4" formControlName="lastDigits"
                                    [inputValidationMessages]="accountInfoValidationMessages.lastDigits"
                                    [formControl]="formFinancialProduct.controls['lastDigits']" regexp="^[0-9]{0,4}$"
                                    matchRegexp="(\d+)" [matchOnInput]="false" (change)="saveAccountInfo()"></fp-input>
                            </ng-container>
                        </div>
                        <div class="select-content select-disabled">
                            <label class="form-label" for="productSelect">
                                {{ 'financialproduct_destinationAccount' | translate }}
                            </label>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="destinationAccount" panelClass="fp-address-select"
                                    placeholder="{{ 'financialproduct_destinationAccountplaceholder' | translate }}">
                                    <mat-option class="option-select" [value]="selectedAccount">
                                        Cuenta {{ selectedAccount }}
                                    </mat-option>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                    </form>
                </div>
                <div class="form-group-submit">
                    <div class="fp-container-btn">
                        <button class="fp-btn-primary submit-btn" id="buttonContinue"
                            [disabled]="!formAccountValidator()" (click)="continueFinancialProduct()">
                            <span class="btn-content-text">
                                {{ 'financialproduct_btn_continue' | translate }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</fp-card-body>
<ng-template #notify>
    <fp-snack-message (snackMessageEvent)="closeNotify()" [title]="'alert.title' | translate"
        [description]="errorCode 
        ? ('alert.description_error' | translate:{'code': errorCode}) 
        : ('alert.description' | translate)" type="error">
    </fp-snack-message>
</ng-template>