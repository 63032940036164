<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'fourTemplate'" urlBack="/home"
    exit="true" headerType="secondary" [showfooterBallsRight]="false" [actionType]="'logout'">
    <ng-template #tplRef>
        <section class="seccion-vehicles">
            <div class="container-vehicles">
                <h1 class="fp-h1 card-title">Reportes
                </h1>
                <h1 class="fp-body-one sub-title">Administre sus reportes</h1>
                <div class="container-accounts fp-fadeIn" *ngIf="accounts">
                    <div class="accounts-row headers container-invoices">
                        <h4>Consulte sus facturas rapido y sencillo. <span class="only-text">Utilice el botón</span> Ir a facturación <span class="only-text">para revisarlas.</span></h4>
                        <div class="subtitle-vehicles">
                            <button class="fp-btn-primary btn-view-invoices" type="button" 
                                (click)="goToInvoices()">
                                Ir a facturación
                            </button>
                        </div>
                    </div>
                    <div class="status-line">
                        <hr>
                    </div>
                    <div class="accounts-row headers">
                        <h4>Seleccione la cuenta que desea administrar</h4>
                        <div class="subtitle-vehicles" (click)="expand = !expand"
                            id="vehicles-hide-balance">Ver
                            saldo <img [class.rotar]="expand" src="assets/images/home/accounts/arrow.svg"
                                alt="icono currency" id="expand-info-accounts">
                        </div>
                    </div>
                    <div class="container-accounts-carousel">
                        <button class="fp-btn-white btn-control-carousel" [class.expand]="expand"
                            (click)="carrouselAccount.prev()" *ngIf="accounts?.length >= 4" type="button"
                            id="vehicles-carrousel-account-prev">
                            <img src="assets/images/home/arrow-carousel.svg" alt="control carousel">
                        </button>
                        <owl-carousel-o #infoDocumentCarousel [options]="customOptions" #carrouselAccount>
                            <ng-container *ngFor="let account of accounts; let i = index">
                                <ng-template carouselSlide [id]="i">
                                    <div class="items-account">
                                        <fp-account-card [formAccount]="formAccount"
                                            [select]="formAccount.controls.account.value === account.accountId"
                                            [account]="account" [expand]="!expand" (onClickItem)="chooseAccount($event)"
                                            template="templateTwo" small="true">
                                        </fp-account-card>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                        <button class="fp-btn-white btn-control-carousel right" [class.expand]="expand"
                            (click)="carrouselAccount.next()" *ngIf="accounts?.length >= 4" type="button"
                            id="vehicles-carrousel-account-next">
                            <img class="rotar" src="assets/images/common/arrow-paginator.svg" alt="control carousel">
                        </button>
                    </div>
                </div>
                <!--Empty accounts container-->
                <ng-container *ngIf="!accounts">
                    <div class="container-accounts fake">
                        <div class="accounts-row headers">
                            <div class="skeleton shadow" style="height: 16px; width: 16rem; margin-bottom: 1rem;"></div>
                            <div style="display: flex;">
                                <div class="skeleton shadow" style="height: 16px; width: 4rem;"></div>
                                <div class="skeleton shadow" style="height: 16px; width: 1rem; margin-left: 0.3rem;">
                                </div>
                            </div>
                        </div>
                        <div class="container-accounts-carousel-fake">
                            <div class="accounts-card fake" *ngFor="let item of [1,2]">
                                <div class="accounts-header-card fake ">
                                    <div class="text-header-card" style="color: transparent;">
                                        <p class="skeleton big shadow"></p>
                                        <p class="skeleton small shadow">
                                        </p>
                                    </div>
                                    <div class="skeleton shadow" style="width: 24px; height: 24px; border-radius: 50%;">
                                    </div>
                                </div>
                                <div class="accounts-body-card fake expand">
                                    <div class="skeleton shadow" style="width: 45px; height: 24px">
                                    </div>
                                    <div class="skeleton shadow"
                                        style="width: 100px; height: 24px; margin-top: 0.5rem; margin-bottom: 1rem;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <form novalidate *ngIf="!loading" [formGroup]="formAccount" class="container-detail-vehicles fp-fadeIn">
                    <div class="list-vehicles-container-filters space-vehicles-header">
                        <div class="vehicles-btn-filter subtitle-vehicle title">
                            Reporte
                            <button class="fp-btn-white fp-btn-filter"  type="button"
                                id="btn-order-license-plate">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.licensePlate"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle description" >
                            Contenido
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-numberTag">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.numberTag"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle format" >
                            Formato
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle status">
                            Estado
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle action">
                            Acción
                        </div>
                    </div>
                    <div class="item-info big" *ngIf="!loading && !reportsState?.error && reportsPaginator.length !== 0">
                        <img src="assets/images/common/icon-info.svg" alt="info">
                        <span>
                            Los reportes solicitados se eliminarán en los próximos 7 días
                        </span>
                    </div>
                    
                    <div class="list-vehicles-container">
                        <ng-container *ngIf="!loading && reportsState?.error">
                            <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                                [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'"
                                [notifyError]="'true'">
                            </fp-item-list-empty>
                            <button class="fp-btn-green btn-empty" type="button" id="btn-list-get-vehicles"
                                (click)="getReports()">
                                Volver a intentar
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!loading && reportsPaginator.length === 0 && !reportsState?.error">
                            <fp-item-list-empty iconEmpety="assets/images/report/file.svg"
                                [descriptionEmpety]="'Aun no tiene reportes'"></fp-item-list-empty>                            
                        </ng-container>
                        <ng-container *ngIf="!loading && reportsPaginator.length > 0">
                            <div class="fp-item-row j-center" *ngFor="let report of reportsPaginator; let indexEven = even">
                                <div class="fp-item-title">
                                    {{ report.reportType | type}}
                                </div>
                                <div *ngIf="report.reportType !== 5" class="fp-item-description">
                                    {{ report.startDate | date:report.reportType  }} a {{report.finalDate | date:report.reportType }}
                                </div>
                                <div *ngIf="report.reportType === 5" class="fp-item-description">
                                    {{ report.startDate | date:report.reportType }}
                                </div>
                                <div class="fp-item-format">
                                    {{ report.formatReport }}
                                </div>
                                <div class="fp-item-status">
                                    <ng-container *ngIf="report.status === 1 || report.status === 2 ">
                                        <span class="icon-process"></span>
                                    </ng-container>
                                    <ng-container *ngIf="report.status === 3">
                                        <span class="icon-ready"></span>
                                    </ng-container>
                                    <ng-container *ngIf="report.status === 4">
                                        <span class="icon-error"></span>
                                    </ng-container>
                                    <span>{{ report.status | status }}</span>
                                </div>
                                <div class="fp-item-action">
                                    <ng-container *ngIf="report.status === 3">
                                        <button class="fp-btn-green btn-action" (click)="downloadReport($event)"
                                        [id]="'btn-download-report-' + report.reportId">
                                            <span class="btn-content-text" *ngIf="!loadingReport || reportId !== report.reportId">
                                                Descargar
                                            </span>
                                            <span class="loader" *ngIf="loadingReport && reportId === report.reportId"></span>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="report.status !== 3">
                                        <button class="fp-btn-green btn-action" disabled>
                                            <span>Descargar</span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="status-line">
                        <hr>
                    </div>
                    <div class="footer-detail-vehicles" *ngIf="!loading">
                        <div class="item-info">
                            <img src="assets/images/common/icon-info.svg" alt="info">
                            <span>
                                {{totalItemsforAccount}} reportes
                            </span>
                        </div>
                        <div style="height: 24px; width: 2.813rem;">
                            <fp-select [items]="filterLimit" [disabled]="totalItemsforAccount <= 10"
                               ></fp-select>
                        </div>
                        <div class="paginator">
                            <img class="controls previos " [class.disabled]="ipageActive === 1"
                                src="assets/images/common/arrow-paginator.svg" alt="previos" >
                            <div class="pages">
                                <span [class.selected]="ipageActive === 1" >1</span>
                                <span *ngIf="ipageActive <= 4 && pagesItems >= 7" [class.selected]="ipageActive === 2"
                                    >2</span>
                                <span *ngIf="ipageActive > 4 && pagesItems >= 7">...</span>
                                <ng-container *ngFor="let page of pages">
                                    <span [class.selected]="page === ipageActive">
                                        {{page}}</span>
                                </ng-container>
                                <span *ngIf="ipageActive <  pagesItems - 3 && pagesItems >= 7">...</span>
                                <span *ngIf="ipageActive >=  pagesItems - 3 && pagesItems >= 7"
                                    [class.selected]="ipageActive === pagesItems -1"
                                    >{{pagesItems-1}}</span>
                                <span *ngIf="pagesItems >  1" [class.selected]="ipageActive === pagesItems"
                                    >{{pagesItems}}</span>
                            </div>
                            <img class="controls" [class.disabled]="ipageActive === pagesItems"
                                src="assets/images/common/arrow-paginator.svg" alt="next" >
                        </div>
                        <button class="fp-btn-primary btn-primary-footer btn-block-report" type="button" 
                            (click)="goToRequestReport()" id="button-request-report"
                            [disabled]="!permissionService.getPermission('REPMOV') && !permissionService.getPermission('REPPLA') 
                                && !permissionService.getPermission('REPPEA')">
                            Solicitar reporte
                            <span class="tooltiptext" *ngIf="!permissionService.getPermission('REPMOV') && !permissionService.getPermission('REPPLA') 
                            && !permissionService.getPermission('REPPEA')"
                            >Solicite el permiso al administrador del portal</span>
                        </button>
                    </div>
                </form>
                <ng-container *ngIf="loading">
                    <div class="container-detail-vehicles fake">
                        <div class="list-vehicles-container-filters space-vehicles-header ">
                            <div class="fake-report-title skeleton shadow header">
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="fake-content skeleton shadow header">
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="fake-format skeleton shadow header">
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="fake-status skeleton shadow header"></div>
                            <div class="fake-status skeleton shadow header"></div>
                        </div>
                        <div class="list-vehicles-container">
                            <div class="fake-item-vehicle shadow" *ngFor="let item of [1,2,3]; let indexEven = even"
                                [class.even]="indexEven">
                                <div class="fake-report-title skeleton shadow"></div>
                                <div class="fake-content skeleton shadow"></div>
                                <div class="fake-format skeleton shadow"></div>
                                <div class="fake-status skeleton shadow"></div>
                                <div class="fake-button skeleton shadow"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
        </section>
    </ng-template>
</fp-card-body>
<ng-template #alert>
    <fp-snack-message (snackMessageEvent)="closeAlert()" [title]="'Error'"
        [description]="'No fue posible descargar el reporte en este momento, intente más tarde'" type="error">
    </fp-snack-message>
</ng-template>
<fp-modal-invoice link="{{urlInvoices}}"></fp-modal-invoice>