import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackMessageComponent } from "./snack-message.component";

@NgModule({
  declarations: [SnackMessageComponent],
  exports: [SnackMessageComponent],
  imports: [CommonModule, MatSnackBarModule],
})
export class SnackMessageModule {}
