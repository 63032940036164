import { createReducer, on } from "@ngrx/store";
import * as lowBalanceAcctions from "../actions/low-balance.actions";
import { initialLowBalanceState } from "../state/app.state";

export const lowBalanceReducer = createReducer(
  initialLowBalanceState,
  on(lowBalanceAcctions.lowBalanceLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(lowBalanceAcctions.lowBalanceSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(lowBalanceAcctions.lowBalanceError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(lowBalanceAcctions.lowBalanceReset, () => ({
    ...initialLowBalanceState,
  })),
);
