import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import {
  tagActivationPagesConst,
  tagPagesConst,
} from "@core/constants/pages.constant";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { TagFacade } from "../store/facade/tag.facade";
import {
  initialAddressInfo,
  initialStateTagNumber,
} from "../store/state/tag.state";

@Injectable({
  providedIn: "root",
})
export class TagActivationGuard implements CanActivate {
  /**
   * Constructor
   * @param tagFacade TagFacade
   * @param commonsService CommonsService
   */
  constructor(
    private tagFacade: TagFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
  ) {}
  /**
   * CanActivate
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const info = await this.selectTagActivationPages$.pipe(take(1)).toPromise();
    const validate = this.commonsService.verifyPagesProcess(
      info,
      state.url.split("?")[0],
      "tag",
    );
    return validate;
  }
  /**
   * Clear Tag Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.tag === true) {
      modules.tag = false;
      this.tagFacade.setTagNumber(initialStateTagNumber);
      this.tagFacade.setTagActivationPages([tagActivationPagesConst[0]]);
      this.tagFacade.setTagPages([tagPagesConst[0]]);
      this.tagFacade.setRechargeReset();
      this.tagFacade.tagReservationDelete();
      this.tagFacade.setDeliveryInfo(initialAddressInfo);
      this.appFacade.setModules(modules);
    }
  }
  /**
   * Get data to facade store communication
   */
  get selectTagActivationPages$(): Observable<IPages> {
    return this.tagFacade.selectTagActivationPages$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
