import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountFeatureName, AccountModuleState } from "../state/account.state";

export const AutomaticRechargeRootSelector = createFeatureSelector<
  AccountModuleState
>(AccountFeatureName);

export const selectAutomaticRecharge = createSelector(
  AutomaticRechargeRootSelector,
  (state: AccountModuleState) => state?.automaticRechargeReducer,
);
