import { IModules } from "@app/models/modules";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { modulesFeatureName } from "../state/modules.state";

export const modulesRootSelector = createFeatureSelector<IModules>(
  modulesFeatureName,
);

export const selectModules = createSelector(
  modulesRootSelector,
  (state: IModules) => state,
);
