import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IAccountLowBalance } from "@app/models/accounts";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-successful-configuration",
  templateUrl: "./successful-configuration.component.html",
})
export class SuccessfulConfigurationComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  lowBalance: IAccountLowBalance;
  recharge: string | number;
  email: string;

  constructor(
    private appFacade: AppFacade,
    private router: Router,
    public translate: TranslateService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.getLowBalance();
  }

  async getLowBalance() {
    const data = await this.selectLowBalanceSelected$.pipe(take(1)).toPromise();
    this.lowBalance = data;

    const lowBalanceRecharge = data.lowBalanceRecharge;

    const email = data.emailNotification;

    const formattedValue = this.currencyPipe.transform(
      lowBalanceRecharge,
      "COP",
      "symbol",
      "1.0-0",
    );

    this.recharge = formattedValue;

    this.email = email;
  }

  goHome(): void {
    this.router.navigate(["/home"]);
  }

  get selectLowBalanceSelected$(): Observable<IAccountLowBalance> {
    return this.appFacade.selectLowBalanceSelected$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
