import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const postpaidRootRoute = "postpaid";

const routes: Routes = [
  {
    path: "",
    redirectTo: "association",
    pathMatch: "full",
  },
  {
    path: "association",
    loadChildren: () =>
      import("./modules/association/association.module").then(
        (m) => m.AssociationModule,
      ),
  },
  {
    path: "paymentmode",
    loadChildren: () =>
      import("./modules/payment-mode/payment-mode.module").then(
        (m) => m.PaymentModeModule,
      ),
  },
  {
    path: "cancelAccount",
    loadChildren: () =>
      import(
        // eslint-disable-next-line comma-dangle
        "./modules/cancel-account/cancel-account.module"
      ).then((m) => m.CancelAccountModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostpaidRoutingModule {}
