import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  IAccountFinancialProduct,
  IAccountModality,
} from "../../models/account-modality";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import * as accountActions from "../actions/index";
import { selectAccountFinancialProduct } from "../selectors/account-financial-product.selector";
import { selectAccountModality } from "../selectors/account-modality.selector";
import { selectAccountPages } from "../selectors/account-pages.selector";
import { selectAutomaticRecharge } from "../selectors/automatic-recharge.selector";
import { selectLowBalancePages } from "../selectors/low-balance-pages.selector";
import { AccountModuleState } from "../state/account.state";
@Injectable({
  providedIn: "root",
})
export class AccountFacade {
  public selectAccountPages$: Observable<IPages> = this.store.pipe(
    select(selectAccountPages),
  );

  public selectLowBalancePages$: Observable<IPages> = this.store.pipe(
    select(selectLowBalancePages),
  );

  public selectAccountModality$: Observable<IAccountModality> = this.store.pipe(
    select(selectAccountModality),
  );

  public selectAutomaticRecharge$: Observable<
    IAutomaticRecharge
  > = this.store.pipe(select(selectAutomaticRecharge));

  public selectAccountFinancialProduct$: Observable<
    IAccountFinancialProduct
  > = this.store.pipe(select(selectAccountFinancialProduct));

  /**
   * Constructor
   * @param store
   */
  constructor(private store: Store<AccountModuleState>) {}

  setAccountPages(pages: IPage[]) {
    this.store.dispatch(accountActions.AccountSetPage(pages));
  }

  setAccountModality(accountModality: string) {
    this.store.dispatch(
      accountActions.accountModality({ modalitySelected: accountModality }),
    );
  }

  resetsetAccountModality(): void {
    this.store.dispatch(accountActions.accountModalityReset());
  }

  setAutomaticRecharge(automaticRecharge: IAutomaticRecharge) {
    this.store.dispatch(
      accountActions.automaticRecharge({ ...automaticRecharge }),
    );
  }

  resetSetAutomaticRecharge(): void {
    this.store.dispatch(accountActions.automaticRechargeReset());
  }

  setAccountFinancialProduct(data: string) {
    this.store.dispatch(accountActions.accountFinancialProduct({ data }));
  }

  resetsetAccountFinancialProduct(): void {
    this.store.dispatch(accountActions.accountFinancialProductReset());
  }

  setLowBalancePages(pages: IPage[]) {
    this.store.dispatch(accountActions.LowBalanceSetPage(pages));
  }
}
