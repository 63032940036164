import { IAccountsResponseDetails } from "@app/models/accounts";
import { createAction } from "@ngrx/store";

export const enum TypeActionsAccountSelected {
  accountSelectedString = "[ACCOUNT SELECTED] set information",
  accountSelectedResetString = "[ACCOUNT SELECTED] Reset data",
}
/**
 * Action to set account as selected
 */
export const accountSelected = createAction(
  TypeActionsAccountSelected.accountSelectedString,
  (account: IAccountsResponseDetails) => ({ account }),
);
/**
 * Action to reset data
 */
export const accountSelectedReset = createAction(
  TypeActionsAccountSelected.accountSelectedResetString,
);
