import { IPages } from "@app/models/pages";
import {
  accountPagesConst,
  lowBalancePagesConst,
} from "@core/constants/pages.constant";
import {
  IAccountFinancialProduct,
  IAccountModality,
} from "../../models/account-modality";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";

/**
 * Set a Name to read chart redux
 */
export const AccountFeatureName = "AccountState";

/**
 * Grouping to AccountState
 */
export type AccountModuleState = Readonly<{
  accountModalityReducer: IAccountModality;
  accountPagesReducer: IPages;
  lowBalancePagesReducer: IPages;
  accountFinancialProductReducer: IAccountFinancialProduct;
  automaticRechargeReducer: IAutomaticRecharge;
}>;

export const initialStateAccountPages: IPages = {
  pages: [accountPagesConst[0]],
};

export const initialStateAccountModality: IAccountModality = {
  modalitySelected: null,
};

export const initialStateAccountFinancialProduct: IAccountFinancialProduct = {
  data: null,
};

export const initialStateLowBalancePages: IPages = {
  pages: [lowBalancePagesConst[0]],
};

export const initialStateAutomaticRecharge: IAutomaticRecharge = {
  rechargeValue: null,
  activeRechargeValue: null,
  email: null,
  referenceCard: null,
  brandCard: null,
  cardMask: null,
  getAllCards: true,
  fromRecharge: false,
};
