import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { CompleteSignUpNavigationService } from "../services/complete-signup-navigation.service";
import { CompleteSingUpFacade } from "../store/facade/complete-signup.facade";
@Injectable({
  providedIn: "root",
})
export class CompleteSignUpGuard implements CanActivate {
  constructor(
    private completeSingUpFacade: CompleteSingUpFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private completeSingupNavigationService: CompleteSignUpNavigationService,
  ) {}
  /**
   * CanActivate
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const info = await this.completeSingUpFacade$.pipe(take(1)).toPromise();
    const validate = await this.commonsService.verifyPagesSignProcess(
      info,
      state.url.split("?")[0],
      "complete-signup",
    );
    return validate;
  }
  /**
   * Clear Vehicle Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.completeSignup === true) {
      modules.completeSignup = false;
      this.completeSingupNavigationService.clearCompleteSignUpStore();
      this.appFacade.setModules(modules);
    }
  }

  /**
   * Get data to facade store communication
   */
  get completeSingUpFacade$(): Observable<IPages> {
    return this.completeSingUpFacade.selectCompleteSignUpPages$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
