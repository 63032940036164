import { createAction } from "@ngrx/store";
import { ITimerCode } from "@otpcode/models/otpcode";

export const enum TypeActionsOtpTimer {
  OtpTimerString = "[SIGN IN Timer] timer to loaded otp",
}

export const SignInOtpTimer = createAction(
  TypeActionsOtpTimer.OtpTimerString,
  (timer: ITimerCode) => ({ timer }),
);
