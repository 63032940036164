import { Component } from "@angular/core";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";

@Component({
  selector: "fp-not-allowed-action",
  templateUrl: "./not-allowed-action.component.html",
})
export class NotAllowedActionComponent {
  constructor(private modalService: ModalService) {}

  modalAction() {
    this.modalService.close("fp-not-allowed-action", false);
  }
}
