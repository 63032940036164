import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { IUser } from "@app/models/user";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { from, Observable } from "rxjs";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * Constructor
   * @param router Router
   * @param jwtHelperService JwtHelperService
   * @param authenticationService Autentication Service
   */
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonsService,
  ) {}
  /**
   * Activate route
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url.split("?")[0]));
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url.split("?")[0]));
  }
  /**
   * verify existing Token
   */
  async verifyToken(route: string): Promise<any> {
    let value = false;
    let token = "";
    let userCognito: IUser;
    await this.authenticationService.getUserData();
    userCognito = await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      token = userCognito.data.authenticationResult.idToken;
    }
    if (
      token &&
      token !== "" &&
      !this.authenticationService.tokenExpired(token)
    ) {
      if (
        userCognito?.data?.result_message?.code === "100840" &&
        route.includes("/complete-signup")
      ) {
        value = true;
      }
      if (
        userCognito?.data?.result_message?.code === "100840" &&
        !route.includes("/complete-signup")
      ) {
        this.commonService.navigate("/complete-signup");
      }
      if (userCognito?.data?.result_message?.code !== "100840") {
        if (route.includes("/complete-signup")) {
          this.commonService.navigate("/home");
        } else {
          value = true;
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.log("Token expirado");
      this.commonService.logOut(true, false);
    }
    return Promise.resolve(value);
  }
}
