import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminMenuComponent } from "./admin-menu.component";

export const AdminMenuRootRoute = "administration";
const routes: Routes = [
  {
    component: AdminMenuComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminMenuRoutingModule {}
