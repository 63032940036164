import {
  IAccount,
  IAccountsResponseDetails,
  ILowBalance,
  IAccountLowBalance,
} from "@app/models/accounts";
import { IDocumentTypeService } from "@app/models/form-validation-messages";
import { IRolesAdministrator } from "@app/models/roles-administrator";
import { ILanguage } from "../reducers/app.reducer";

export const AppFeatureName = "language";

export const initialState: ILanguage = {
  language: "es",
};
export const IdentificationTypesFetureName = "identificationTypes";

export const accountsFeatureName = "accountsReducer";
export const accountSelectedFeatureName = "accountSelectedReducer";
export const rolesAdministratorFeatureName = "rolesAdministratorReducer";
export const lowBalanceFeatureName = "lowBalanceReducer";
export const lowBalanceSelectedFeatureName = "lowBalanceSelectedReducer";

export interface IIdentifiTypesState {
  documenTypes: IDocumentTypeService;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialAccountState: IAccount = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialAccountSelectedState: IAccountsResponseDetails = {
  accountId: null,
  nure: null,
  balance: null,
  accountType: null,
  balanceLow: null,
  balanceMin: null,
  statusAccount: null,
  refund: null,
  balanceRefund: null,
  typeFinancialProduct: null,
  financialProduct: null,
  rejectedAssociationCode: null,
  isModalityChange: false,
};

export const initialStateRolesAdministrator: IRolesAdministrator = {
  roles: "",
};

export const initialLowBalanceState: ILowBalance = {
  data: null,
};

export const initialLowBalanceSelectedState: IAccountLowBalance = {
  accountId: null,
  balance: null,
  financialProduct: null,
  minimumValueRecharge: null,
  lowBalanceRecharge: null,
  emailNotification: null,
  accountType: null,
};
