<ng-container *ngIf="!userInfo">
    <h2 class="fp-body-one subtitle-info-user skeleton"></h2>
    <h2 class="fp-body-one subtitle-info-user skeleton large mt-1"></h2>
    <h1 class="fp-h1 fp-user-name skeleton"></h1>
</ng-container>
<ng-container *ngIf="userInfo">
    <section class="fp-fadeIn">
      <h1 class="fp-h1 fp-user-name">{{ 'hello' | translate }}, <span>{{userInfo?.userClientInfo[0].name.split(' ')[0]}}</span>
        <h2 class="fp-body-one subtitle-info-user">Última conexión:
            <time>{{userInfo?.userClientInfo[0].lastAccesDate | date }} -
                {{ userInfo?.userClientInfo[0].lastAccesDate | hours }}</time>
        </h2>
        <h2 class="fp-body-one subtitle-info-user">
            <span>
                {{clientInfo?.names}}
            </span>
        </h2>
        </h1>
    </section>
</ng-container>
