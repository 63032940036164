import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  constructor(private http: HttpClient) {}

  createAccount(clientId: string): Observable<any> {
    return this.http.post(
      environment.api_key.vinculation_process.back_url +
        environment.endpoints.accounts.init +
        environment.endpoints.accounts.specific.create +
        "?clientId=" +
        clientId,
      {},
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  getAccountStatus(executionId: string): Observable<any> {
    return this.http.get(
      environment.api_key.vinculation_process.back_url +
        environment.endpoints.accounts.init +
        environment.endpoints.accounts.specific.create +
        "?execution_id=" +
        executionId,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }
}
