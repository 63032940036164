import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const OtpCodeRootSelector = createFeatureSelector<SignUpModuleState>(
  SignUpFeatureName,
);

export const selectotpCode = createSelector(
  OtpCodeRootSelector,
  (state: SignUpModuleState) => state.signUpOtpCodeReducer,
);
