import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { IVehiclesCategoriesResponse } from "@app/modules/vehicles/models/vehicle";

@Component({
  selector: "fp-information-modal",
  templateUrl: "./information-modal.component.html",
  styleUrls: ["./information-modal.component.scss"],
})
export class InformationModalComponent {
  @Input() vehicleTypes: IVehiclesCategoriesResponse;
  @Output() itemClickActive = new EventEmitter<{}>();

  constructor(private modalService: ModalService) {}

  actionModal() {
    this.itemClickActive.emit();
  }
}
