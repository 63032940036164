import { IDetailRoles } from "@app/models/roles";
import { createAction } from "@ngrx/store";

export const enum RolesTypeActions {
  rolesLoadString = "[Rols] Loading data",
  rolesSaveString = "[Rols] Successful data loaded",
  rolesErrorString = "[Rols] Save error response",
  rolesResetString = "[Rols] Reset state",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const rolesLoad = createAction(
  RolesTypeActions.rolesLoadString,
  (personId: string) => ({ personId }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const rolesSave = createAction(
  RolesTypeActions.rolesSaveString,
  (data: IDetailRoles[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const rolesError = createAction(
  RolesTypeActions.rolesErrorString,
  (error: any) => ({ error }),
);

export const rolesReset = createAction(RolesTypeActions.rolesResetString);
