/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

export declare type fArgEmptyReturn = (arg0: any) => any;
export declare type fEmptyReturn = () => any;

@Component({
  selector: "fp-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  providers: [CHECKBOX_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() value: any;

  @Input() name: string;

  @Input() disabled: boolean;

  @Input() binary: boolean;

  @Input() label: string;

  @Input() ariaLabelledBy: string;

  @Input() tabindex: number;

  @Input() inputId: string;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() labelStyleClass: string;

  @Input() formControl: UntypedFormControl;

  @Input() checkboxIcon: string = "check-icon";

  @Input() readonly: boolean;

  @Input() required: boolean;

  @Input() chkboxId: string;

  @Input() chkboxBig: boolean = false;

  @ViewChild("cb") inputViewChild: ElementRef;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  model: any;

  focused: boolean = false;

  checked: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  onModelChange: fArgEmptyReturn = () => {};

  onModelTouched: fEmptyReturn = () => {};

  onClick(event: Event, checkbox: CheckboxComponent, focus: boolean): void {
    event.preventDefault();

    if (this.disabled || this.readonly) {
      return;
    }

    this.checked = !this.checked;
    this.updateModel(event);

    if (focus) {
      checkbox.focus();
    }
  }

  updateModel(event: Event): void {
    if (!this.binary) {
      if (this.checked) {
        this.addValue();
      } else {
        this.removeValue();
      }

      this.onModelChange(this.model);
    } else {
      this.onModelChange(this.checked);
    }

    this.onChange.emit({ checked: this.checked, originalEvent: event });
  }

  handleChange(event: KeyboardEvent): void {
    if (!this.readonly) {
      this.checked = (event.target as HTMLInputElement).checked;
      this.updateModel(event);
    }
  }

  isChecked(): boolean {
    return this.model;
  }

  removeValue(): void {
    this.model = this.model.filter((val) => val !== this.value);
  }

  addValue(): void {
    this.model = [this.value];
  }

  onFocus(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
    this.onModelTouched();
  }

  focus(): void {
    this.inputViewChild.nativeElement.focus();
  }

  writeValue(model: any): void {
    this.model = model;
    this.checked = this.isChecked();
    this.cd.markForCheck();
  }

  registerOnChange(fn: fEmptyReturn): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: fEmptyReturn): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }
}
