import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountFeatureName, AccountModuleState } from "../state/account.state";

export const AccountFinancialProductRootSelector = createFeatureSelector<
  AccountModuleState
>(AccountFeatureName);

export const selectAccountFinancialProduct = createSelector(
  AccountFinancialProductRootSelector,
  (state: AccountModuleState) => state.accountFinancialProductReducer,
);
