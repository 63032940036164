<fp-modal class="fp-invoice-modal" [typeModal]="'customNotification'" id="fp-invoice-modal" (actionModal)="navigateInvoice()"
    [btnAccept]="'Entendido'">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                Está saliendo de FacilPass
            </h1>
            <p class="fp-payment-method-message">
                Será redirigido a un sitio externo para que realice su consulta.
            </p>
        </div>
    </ng-template>
</fp-modal>