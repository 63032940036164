import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ITagInfo, IVehiclesInfo } from "@home/models/home";
import { CommonsService } from "@shared/services/commons.service";
import { IVehicleInformation } from "@vehicles/models/vehicle";
import { NgxPermissionsService } from "ngx-permissions";

export type ItemTemplate = "firstTemplate" | "secondTemplate";

@Component({
  selector: "fp-item-list-vehicle",
  templateUrl: "./item-list-vehicle.component.html",
  styleUrls: ["./item-list-vehicle.component.scss"],
})
export class ItemListVehicleComponent {
  @Input() vehicle: IVehiclesInfo;
  @Input() indexEven: boolean;
  @Input() set resetData(reset: boolean) {
    if (reset) {
      this.loading = false;
    }
  }
  @Input() typeTemplate: ItemTemplate = "firstTemplate";
  @Output() itemClickActive = new EventEmitter<ITagInfo>();
  @Output() vehicleInformation = new EventEmitter<IVehicleInformation>();
  @Output() vehicleUpdate = new EventEmitter<IVehiclesInfo>();
  @Output() openModalManagement = new EventEmitter<IVehiclesInfo>();
  @Output() openModalDelete = new EventEmitter<IVehiclesInfo>();
  @Output() itemSelected = new EventEmitter<{
    checked: boolean;
    vehicle: IVehiclesInfo;
  }>();

  @Input() chkboxId: string;
  @Input() chkboxName: string;
  @Input() formAccount: UntypedFormGroup;
  loading: boolean = false;
  moreOptions: boolean = false;
  constructor(
    private commonsService: CommonsService,
    public permissionService: NgxPermissionsService,
  ) {}

  changeStatusTag(): void {
    if (this.loading) {
      return;
    }
    this.itemClickActive.emit(this.vehicle.tagEntityConfirmation);
    this.loading = true;
  }

  deleteVehicle(): void {
    this.openModalDelete.emit(this.vehicle);
  }

  editVehicle(): void {
    this.vehicleUpdate.emit(this.vehicle);
  }

  buyTag(): void {
    this.vehicleInformation.emit({
      licensePlate: this.vehicle.vehicle.licensePlate,
      categoryValue: this.vehicle.vehicle.categoryValue,
      accountId: this.vehicle.account,
    });
  }
  /**
   * redirect to change tag
   */
  changeTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        categoryValue: this.vehicle.vehicle.categoryValue,
        accountId: this.vehicle.account,
        tagEntityConfirmationId: this.vehicle.tagEntityConfirmation.id,
      },
      "/tag/activation",
    );
  }

  /**
   * redirect to associated tag
   */
  associatedTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        categoryValue: this.vehicle.vehicle.categoryValue,
        accountId: this.vehicle.account,
      },
      "/tag/activation",
    );
  }
  toggleMoreOptions(): void {
    this.moreOptions = !this.moreOptions;
  }

  openModalMoreOptions(): void {
    this.openModalManagement.emit(this.vehicle);
  }

  changeCheckbox($event): void {
    this.itemSelected.emit({ checked: $event.checked, vehicle: this.vehicle });
  }
}
