import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { lowBalancePagesConst } from "@core/constants/pages.constant";
import { AccountFacade } from "../store/facade/account.facade";

@Injectable({
  providedIn: "root",
})
export class LowBalanceNavigationService {
  constructor(
    private router: Router,
    private lowBalanaceFacade: AccountFacade,
  ) {}
  /**
   *
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = lowBalancePagesConst[position];
    let savedPages: IPage[];
    savedPages = lowBalancePagesConst.slice(0, position + 1);
    if (position >= 1) {
      savedPages = [page];
    }
    this.lowBalanaceFacade.setLowBalancePages(savedPages);
    this.router.navigate([`/account/${page.active}`]);
  }

  clearLowBalanceStore(): void {
    this.lowBalanaceFacade.setLowBalancePages([lowBalancePagesConst[0]]);
  }
}
