import {
  IAccount,
  IAccountLowBalance,
  IAccountsResponseDetails,
  ILowBalance,
} from "@app/models/accounts";
import { ICustomerInfo } from "@app/models/customer-info";
import { ILoaderState } from "@app/models/loader";
import { IModules } from "@app/models/modules";
import { IRoles } from "@app/models/roles";
import { IRolesAdministrator } from "@app/models/roles-administrator";
import { IUserInfo } from "@app/models/user-info";
import { IAddress } from "@app/modules/vehicles/models/address";
import { rolesAdministratorReducer } from "@app/store/reducers/roles-administrator.reducer";
import { ActionReducerMap } from "@ngrx/store";
import { ICities } from "@shared/models/cities";
import { IIdentifiTypesState } from "../state/app.state";
import { accountSelectedReducer } from "./account-selected.reducer";
import { accountsReducer } from "./accounts.reducer";
import { addressReducer } from "./address.reducer";
import { appReducer, ILanguage } from "./app.reducer";
import { citiesReducer } from "./cites.reducer";
import { customerInfoReducer } from "./customer-info.reducer";
import { identificationTypesReducer } from "./identification-types.reducer";
import { loaderReducer } from "./loader.reducer";
import { lowBalanceSelectedReducer } from "./low-balance-selected.reducer";
import { lowBalanceReducer } from "./low-balance.reducer";
import { modulesReducer } from "./modules.reducer";
import { rolesReducer } from "./roles.reducer";
import { userInfoReducer } from "./user-info.reducer";

export interface IAppState {
  language: ILanguage;
  identificationTypes: IIdentifiTypesState;
  citiesReducer: ICities;
  loaderReducer: ILoaderState;
  accountsReducer: IAccount;
  modulesReducer: IModules;
  accountSelectedReducer: IAccountsResponseDetails;
  customerInfoReducer: ICustomerInfo;
  userInfoReducer: IUserInfo;
  rolesReducer: IRoles;
  rolesAdministratorReducer: IRolesAdministrator;
  lowBalanceReducer: ILowBalance;
  lowBalanceSelectedReducer: IAccountLowBalance;
  addressReducer: IAddress;
}

export const AppRootReducer: ActionReducerMap<IAppState> = {
  language: appReducer,
  identificationTypes: identificationTypesReducer,
  citiesReducer,
  loaderReducer,
  accountsReducer,
  modulesReducer,
  accountSelectedReducer,
  customerInfoReducer,
  userInfoReducer,
  rolesReducer,
  rolesAdministratorReducer,
  lowBalanceReducer,
  lowBalanceSelectedReducer,
  addressReducer,
};
