import { IPseBanks } from "@app/models/pse";

/**
 * Set a Name to read chart redux
 */
export const PaymentFeatureName = "PaymentReducer";

/**
 * Grouping to TagState
 */
export type PaymentModuleState = Readonly<{
  pseBanksReducer: IPseBanks;
}>;
/**
 * Initial state of Pse Banks
 */
export const initialStatePseBanks: IPseBanks = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
