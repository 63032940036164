import { createAction } from "@ngrx/store";
import { IMinimumAmountRequest } from "../../models/financial-data";

export const enum TypeActionsMinimumAmountPrepaid {
  minimumAmountPrepaidResetString = "[PREPAID Minimum amount] reset information",
  minimumAmountPrepaidSaveString = "[PREPAID Minimum amount] save service minimum amount",
  minimumAmountPrepaidErrorString = "[PREPAID Minimum amount] error service minimum amount",
  minimumAmountPrepaidLoadString = "[PREPAID Minimum amount] load service minimum amount",
}
/**
 * Action to set vehicle information
 */

export const minimumAmountPrepaidReset = createAction(
  TypeActionsMinimumAmountPrepaid.minimumAmountPrepaidResetString,
);

export const minimumAmountPrepaidSave = createAction(
  TypeActionsMinimumAmountPrepaid.minimumAmountPrepaidSaveString,
  (data: any) => ({ data }),
);

export const minimumAmountPrepaidLoad = createAction(
  TypeActionsMinimumAmountPrepaid.minimumAmountPrepaidLoadString,
  (dataServiceMinimumAmount: IMinimumAmountRequest) => ({
    dataServiceMinimumAmount,
  }),
);

export const minimumAmountPrepaidError = createAction(
  TypeActionsMinimumAmountPrepaid.minimumAmountPrepaidErrorString,
  (error: any) => ({ error }),
);
