import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[fpAutoFocus]",
})
export class AutofocusDirective implements OnInit {
  private autoFocus: boolean;
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.autoFocus) {
      this.el.nativeElement.focus();
    }
  }

  @Input() set fpAutoFocus(condition: boolean) {
    this.autoFocus = condition !== false;
  }
}
