import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  CompleteSignUpFeatureName,
  CompleteSignUpModuleState,
} from "../state/complete-signup.state";

export const UserInformationRootSelector = createFeatureSelector<
  CompleteSignUpModuleState
>(CompleteSignUpFeatureName);

export const selectUserInformation = createSelector(
  UserInformationRootSelector,
  (state: CompleteSignUpModuleState) =>
    state.completeSignUpUserInformationReducer,
);
