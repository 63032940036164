import { IUserInfo } from "@app/models/user-info";

export const userInfoFeatureName = "userInfoReducer";

export const initialUserInfoState: IUserInfo = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
