import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { vehiclesPagesConst } from "@core/constants/pages.constant";
import { VehiclesFacade } from "../store/facade/vehicles.facade";
import { initialStateVehicleInformationReducer } from "../store/state/vehicles.state";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class VehicleNavigationService {
  constructor(private router: Router, private vehicleFacade: VehiclesFacade) {}
  /**
   *
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = vehiclesPagesConst[position];
    let savedPages: IPage[];
    savedPages = [page];
    this.vehicleFacade.setvehiclePages(savedPages);
    this.router.navigate([`/vehicles/${page.active}`]);
  }
  /**
   * Navigate to error
   */
  navigatetoError(): void {
    this.router.navigate(["/error"]);
  }
  /**
   * Clear Vehicle Information
   */
  clearStore(): void {
    this.vehicleFacade.setVehicleInformation(
      initialStateVehicleInformationReducer,
    );
  }
}
