import { ILoaderState } from "@app/models/loader";
import { createAction } from "@ngrx/store";

export const enum TypeActions {
  loaderShowString = "[LOADER] Loader show",
  loaderHieString = "[LOADER] Loader hide",
}

/**
 * Action to detect event loading data
 */
export const loaderShow = createAction(
  TypeActions.loaderShowString,
  (loader: ILoaderState) => ({ loader }),
);

/**
 * Action to detect event loaded data
 */
export const loaderHide = createAction(TypeActions.loaderHieString);
