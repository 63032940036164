import { Component, Input } from "@angular/core";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";

@Component({
  selector: "fp-modal-invoice",
  templateUrl: "./modal-invoice.component.html",
  styleUrls: ["./modal-invoice.component.scss"],
})
export class ModalInvoiceComponent {
  @Input() link: string;

  constructor(private modalService: ModalService) {}

  navigateInvoice(): void {
    this.modalService.close("fp-invoice-modal", true);
    window.open(this.link, "_blank");
  }
}
