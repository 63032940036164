import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ValidateCardReferenceComponent } from "./validate-card-reference.component";

export const ValidateCardReference = "validate-card-reference";
const routes: Routes = [
  {
    component: ValidateCardReferenceComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValidateCardReferenceRoutingModule {}
