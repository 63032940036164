import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignOutComponent } from "./signout.component";

export const signOutRootRoute = "signout";
const routes: Routes = [
  {
    component: SignOutComponent,
    path: "",
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class SignOutRoutingModule {}
