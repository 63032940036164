import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { listReportRoot } from "./modules/list/list-report-routing.module";
import { requestReportRoot } from "./modules/request-report/request-report-routing.module";
import { ReportSucessfulRootRoute } from "./modules/successful/successful-routing.module";

export const reportsRootRoute = "reports";

const routes: Routes = [
  {
    path: "",
    redirectTo: listReportRoot,
    pathMatch: "full",
  },
  {
    path: listReportRoot,
    loadChildren: () =>
      import("./modules/list/list-report.module").then(
        (m) => m.ListReportsModule,
      ),
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "REPCON",
      },
    },
  },
  {
    path: ReportSucessfulRootRoute,
    loadChildren: () =>
      import("../reports/modules/successful/successful.module").then(
        (m) => m.SuccessfulModule,
      ),
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "REPCON",
      },
    },
  },
  {
    path: requestReportRoot,
    loadChildren: () =>
      import("../reports/modules/request-report/request-report.module").then(
        (m) => m.RequestReportModule,
      ),
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "REPCON",
      },
    },
  },
  {
    path: "**",
    redirectTo: reportsRootRoute,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
