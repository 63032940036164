<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'fourTemplate'"
    exit="true" headerType="secondary" [showfooterBallsRight]="false" [actionType]="'logout'" urlBack="/">
    <ng-template #tplRef>
        <section class="seccion-vehicles">
            <div class="container-vehicles">
                <h1 class="fp-h1 card-title">Vehículos
                </h1>
                <h1 class="fp-body-one sub-title"> Administre sus vehículos y dispositivos TAG</h1>
                <div class="container-accounts fp-fadeIn" *ngIf="accounts">
                    <div class="accounts-row headers">
                        <h4>Seleccione la cuenta que desea administrar</h4>
                        <div *ngxPermissionsOnly="['CUESALDO']" class="subtitle-vehicles" (click)="expand = !expand"
                            id="vehicles-hide-balance">Ver
                            saldo <img [class.rotar]="expand" src="assets/images/home/accounts/arrow.svg"
                                alt="icono currency" id="expand-info-accounts">
                        </div>
                    </div>
                    <div class="container-accounts-carousel">
                        <button class="fp-btn-white btn-control-carousel" [class.expand]="expand"
                            (click)="carrouselAccount.prev()" *ngIf="accounts?.length >= 4" type="button"
                            id="vehicles-carrousel-account-prev">
                            <img src="assets/images/home/arrow-carousel.svg" alt="control carousel">
                        </button>
                        <owl-carousel-o #infoDocumentCarousel [options]="customOptions" #carrouselAccount>
                            <ng-container *ngFor="let account of accounts; let i = index">
                                <ng-template carouselSlide [id]="i">
                                    <div class="items-account">
                                        <fp-account-card [formAccount]="formAccount"
                                            [select]="formAccount.controls.account.value === account.accountId"
                                            [account]="account" [expand]="!expand" (onClickItem)="chooseAccount($event)"
                                            template="templateTwo" small="true">
                                        </fp-account-card>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                        <button class="fp-btn-white btn-control-carousel right" [class.expand]="expand"
                            (click)="carrouselAccount.next()" *ngIf="accounts?.length >= 4" type="button"
                            id="vehicles-carrousel-account-next">
                            <img class="rotar" src="assets/images/common/arrow-paginator.svg" alt="control carousel">
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="!accounts">
                    <div class="container-accounts fake">
                        <div class="accounts-row headers">
                            <div class="skeleton shadow" style="height: 16px; width: 16rem; margin-bottom: 1rem;"></div>
                            <div style="display: flex;">
                                <div class="skeleton shadow" style="height: 16px; width: 4rem;"></div>
                                <div class="skeleton shadow" style="height: 16px; width: 1rem; margin-left: 0.3rem;">
                                </div>
                            </div>
                        </div>
                        <div class="container-accounts-carousel-fake">
                            <div class="accounts-card fake" *ngFor="let item of [1,2]">
                                <div class="accounts-header-card fake ">
                                    <div class="text-header-card" style="color: transparent;">
                                        <p class="skeleton big shadow"></p>
                                        <p class="skeleton small shadow">
                                        </p>
                                    </div>
                                    <div class="skeleton shadow" style="width: 24px; height: 24px; border-radius: 50%;">
                                    </div>
                                </div>
                                <div class="accounts-body-card fake expand">
                                    <div class="skeleton shadow" style="width: 45px; height: 24px">
                                    </div>
                                    <div class="skeleton shadow"
                                        style="width: 100px; height: 24px; margin-top: 0.5rem; margin-bottom: 1rem;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- CHOOSE CREATION OPTION SECTION -->
                <ng-container *ngIf="!loading && chooseCreationType && !manual">
                    <div class="cont">
                        <div class="box">
                            <form novalidate [formGroup]="formSelectModality" (ngSubmit)="selectModality()">
                                <div class="cards-payment">
                                    <div class="cards-payment-cont" id="paymentType">
                                        <ng-container>
                                            <div class="card-payment">

                                                <button *ngFor="let modality of modalities; let itype= index"
                                                    class="input-radio-option"
                                                    [class.selected-option]="this.formSelectModality.controls.paymentType.value === modality.value"
                                                    type="button" (click)="chooseModality(modality.value)"
                                                    id="type{{modality.value}}">
                                                    <div class="card-header">
                                                        <span class="radio-btn-container">
                                                            <fp-radiobutton formControlName="paymentType"
                                                                name="paymentType" [value]="modality.value"
                                                                inputId="paymentType{{modality.value}}">
                                                            </fp-radiobutton>
                                                        </span>
                                                        <div class="card-title">
                                                            {{ modality.title }}
                                                        </div>
                                                        <div>
                                                            {{ modality.subtitle }}
                                                        </div>
                                                    </div>
                                                    <div class="card-info">
                                                        <div class="card-item">
                                                            <div class="card-text">
                                                                <label>{{modality.message}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="space-inputs-small"></div>
                                </div>
                                <div class="form-group-submit">
                                    <div class="fp-container-btn">
                                        <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                                            [disabled]="!formSelectModality.valid">
                                            <span class="btn-content-text">
                                                Continuar
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>

                <!-- MANUAL CREATION SECTION -->
                <form novalidate *ngIf="manual && accountSelect" [formGroup]="formVehicles"
                    class="container-detail-vehicles formManualVehicles fp-fadeIn">
                    <div class="header-detail-vehicles"
                        [ngClass]="{'no-margin ': !permissionService.getPermission('VEHCON') && !permissionService.getPermission('VEHACT') && !permissionService.getPermission('VEHINA') }">
                        <div class="container-manual-btn-header">
                            <button class="fp-btn-white btn-detail-primary-vehicles button-icon-add" type="button"
                                [disabled]="vehiclesFormArray.controls.length === 20" id="button-inactive-selection"
                                (click)="addVehicle()">
                                <img src="assets/images/vehicle/icon-plus-blue.svg" alt="icono settings">
                                Nuevo vehículo
                            </button>
                        </div>
                    </div>

                    <div formArrayName="vehicles" class="fake-table">
                        <div class="table-row">
                            <div class="table-cell btn-filter">Placa</div>
                            <div class="table-cell btn-filter-button ">
                                <div class="btn-filter-button">
                                    Categoría
                                    <button class="fp-btn-white fp-btn-information" type="button"
                                        id="btn-order-numberTag" (click)="openModal('fp-category-modal')">
                                        <img src="assets/images/vehicle/filled-information.svg" alt="icono organizar">
                                    </button>
                                </div>
                            </div>
                            <div class="table-cell ">
                                <div class="btn-filter-button">
                                    Inf. adicional
                                    <button class="fp-btn-white fp-btn-information" type="button"
                                        id="btn-order-info-aditional" (click)="openModal('fp-information-modal')">
                                        <img src="assets/images/vehicle/filled-information.svg" alt="icono organizar">
                                    </button>
                                </div>

                            </div>
                            <div class="table-cell btn-filter ">No. TAG</div>
                            <div class="table-cell btn-filter "></div>
                        </div>
                        <div *ngFor="let vehicle of vehiclesFormArray.controls; let i = index" class="table-row"
                            [formGroupName]="i">
                            <div class="table-cell">
                                <input class="form-group input-form" fpValidatorRegExp
                                    [formControl]="vehicle.controls['plate']" styleClass="form-group" nameInput="plate"
                                    [readOnly]="validationStates[i].state !== 'IDLE'" idInput="plate" maxlength="10"
                                    formControlName="plate" regexp="^[a-zA-Z0-9]{0,10}$"
                                    oninput="this.value = this.value.toUpperCase()" matchRegexp="([a-zA-Z0-9]*)">
                            </div>
                            <div class="table-cell select-category">
                                <div class="select-content">
                                    <mat-form-field appearance="fill">
                                        <mat-select #category formControlName="category" panelClass="fp-address-select"
                                            [disabled]="validationStates[i].state !== 'IDLE'">
                                            <ng-container *ngFor="let type of vehicleTypes">
                                                <mat-option class="option-select" [value]="type.value">
                                                    {{type.shortDescription}} - {{type.description}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="table-cell">
                                <input class="form-group input-form" styleClass="form-group" nameInput="additionalInfo"
                                    [readOnly]="validationStates[i].state !== 'IDLE'" idInput="additionalInfo"
                                    formControlName="additionalInfo">
                            </div>
                            <div class="table-cell">
                                <input class="form-group input-form" styleClass="form-group" nameInput="noTag"
                                    [readOnly]="validationStates[i].state !== 'IDLE'" idInput="noTag" maxlength="15"
                                    formControlName="noTag">
                            </div>
                            <div class="table-cell">
                                <div class="table-cell-buttons">
                                    <button *ngIf="validationStates[i].state === 'IDLE'"
                                        class="fp-btn-primary btn-primary-footer button-icon" type="button"
                                        [disabled]="!vehicle.valid" (click)="validateVehicle(vehicle.value, i)">
                                        Validar
                                    </button>
                                    <button *ngIf="validationStates[i].state === 'VALIDATING'"
                                        class="fp-btn-primary btn-primary-footer button-icon" type="button" disabled>
                                        <img src="assets/images/vehicle/reload.svg" alt="icono settings">
                                        Procesando
                                    </button>
                                    <button *ngIf="validationStates[i].state === 'VERIFIED'"
                                        class="fp-btn-primary btn-primary-footer button-icon success" type="button">
                                        <img src="assets/images/vehicle/linear-green-success.svg" alt="icono settings">
                                        Verificado
                                    </button>
                                    <button *ngIf="validationStates[i].state === 'RETRY'"
                                        class="fp-btn-primary btn-primary-footer button-icon retry" type="button"
                                        [disabled]="!vehicle.valid">
                                        <img src="assets/images/vehicle/toast-warning.svg" alt="icono settings">
                                        Reintentar
                                    </button>
                                    <button *ngIf="validationStates[i].state === 'ERROR'"
                                        class="fp-btn-primary btn-primary-footer button-icon error" type="button">
                                        <img src="assets/images/vehicle/toast-warning-yellow.svg" alt="icono settings">
                                        {{validationStates[i].errorMessage}}
                                    </button>

                                    <button
                                        *ngIf="validationStates[i].state !== 'IDLE' && validationStates[i].state === 'RETRY'"
                                        class="fp-btn-white" (click)="validateVehicle(vehicle.value, i)">
                                        <img src="assets/images/vehicle/refresh.svg" alt="icono refresh">
                                    </button>

                                    <button
                                        *ngIf="validationStates[i].state !== 'IDLE' && validationStates[i].state !== 'RETRY'"
                                        [disabled]="validationStates[i].state === 'VALIDATING'" class="fp-btn-white"
                                        (click)="editVehicle(i)">
                                        <img src="assets/images/vehicle/pen-edit.svg" alt="icono edit">
                                    </button>

                                    <button
                                        *ngIf="validationStates[i].state !== 'IDLE' || vehiclesFormArray.controls.length > 1"
                                        [disabled]="validationStates[i].state === 'VALIDATING'"
                                        (click)="deleteVehicle(i)" class="fp-btn-white">
                                        <img src="assets/images/vehicle/icon-trash.svg" alt="icono delte">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="status-line">
                        <hr>
                    </div>
                    <div class="footer-manual-vehicles">
                        <button class="fp-btn-primary btn-primary-footer" type="button" id="button-create-vehicles"
                            *ngxPermissionsOnly="['VEHCRE']" [disabled]="!formVehicles.valid || VehiclesValid()"
                            (click)="CreateVehicles()">
                            Crear vehículos
                        </button>
                    </div>
                </form>

                <!-- VEHICLES LIST SECTION -->
                <form novalidate *ngIf="!loading && !chooseCreationType && !manual" [formGroup]="formAccount"
                    class="container-detail-vehicles fp-fadeIn">
                    <div class="header-detail-vehicles"
                        [ngClass]="{'no-margin ': !permissionService.getPermission('VEHCON') && !permissionService.getPermission('VEHACT') && !permissionService.getPermission('VEHINA') }">
                        <div class="container-input-search" *ngxPermissionsOnly="['VEHCON']">
                            <fp-input class="form-group" labelClass="form-label" [viewIconErrorInput]="false"
                                typeInput="text" nameInput="inputSearch" idInput="inputSearch" maxlength="10"
                                regexp="^[A-Za-z0-9 ]+$" matchRegexp="([A-Za-z0-9 ])" formControlName="inputSearch"
                                [formControl]="formAccount.controls['inputSearch']"
                                [inputClass]="formAccount.controls['inputSearch'].value ? 'input-large action':'black-border optional action'"
                                placeholder="Busque vehículos por placa, TAG ID o estado" (keyup.enter)="search()">
                            </fp-input>
                            <button class="fp-btn-ghost btn-search" type="button" id="button-search" (click)="search()">
                                <img src="assets/images/input/input-search.svg" alt="buscar">
                            </button>

                        </div>
                        <div class="container-btn-header">
                            <button *ngIf="showRequestTags" class="fp-btn-primary btn-detail-primary-vehicles"
                                type="button" (click)="navigateTo('/vehicles/request-tags')">
                                Solicita y compra TAGs
                            </button>
                        </div>
                    </div>
                    <div class="list-vehicles-container-filters space-vehicles-header">
                        <fp-checkbox *ngIf="false" chkboxId="chekboxallvehicles" name="checkallvehicles"
                            inputId="checkallvehicles" class="check" [formControl]="formAccount.controls['selectAll']"
                            binary="true" [chkboxBig]="true" (click)="selectAll()">
                        </fp-checkbox>
                        <div class="vehicles-btn-filter subtitle-vehicle row-one">
                            Mis Placa
                            <button class="fp-btn-white fp-btn-filter" (click)="order()" type="button"
                                id="btn-order-license-plate">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.licensePlate"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle row-one" (click)="orderByNumberTag()">
                            Número TAG
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-numberTag">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.numberTag"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle row-one" (click)="order('infoAditional')">
                            Información adicional
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-info-aditional">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.infoAditional"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle row-two" (click)="orderByStateOrAction()">
                            Estado
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-state">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.state"
                                    alt="icono organizar">
                            </button>
                        </div>
                        <div class="vehicles-btn-filter subtitle-vehicle" (click)="orderByStateOrAction('acction')">
                            Acción
                            <button class="fp-btn-white fp-btn-filter" type="button" id="btn-order-acction">
                                <img src="assets/images/vehicle/arrow-filter.svg" [class.rotar]="filters.acction"
                                    alt="icono organizar">
                            </button>
                        </div>
                    </div>
                    <!-- <div class="item-info big">
                        <img src="assets/images/common/icon-info.svg" alt="info">
                        <span>
                            Estamos cargando sus vehículos, 40 vehículos creados de 100
                        </span>
                    </div> -->
                    <div class="container__search__values">
                        <fp-search-value *ngFor="let searchValue of searchValues; let ivalue = index"
                            [text]="searchValue" [active]="ivalue===searchValues.length -1 ? true: false"
                            (click$)="deleteItem($event)" [index]="ivalue">
                        </fp-search-value>
                    </div>
                    <div class="list-vehicles-container">
                        <ng-container *ngIf="!loading && vehiclesState?.error">
                            <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                                [descriptionEmpety]="'Lo sentimos, tenemos un error al cargar su información'"
                                [notifyError]="'true'">
                            </fp-item-list-empty>
                            <button class="fp-btn-green btn-empty" type="button" id="btn-list-get-vehicles"
                                (click)="getVehicles()">
                                Volver a intentar
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!loading && vehiclesPaginator.length === 0 && !vehiclesState?.error">
                            <fp-item-list-empty iconEmpety="assets/images/vehicle/create-vehicle.svg"
                                [descriptionEmpety]="'Cree su primer vehículo'"></fp-item-list-empty>
                            <button class="fp-btn-primary btn-empty" type="button" id="btn-create-vehicle"
                                (click)="chooseCreationType = true" *ngxPermissionsOnly="['VEHCRE']">
                                Crear vehículo
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!loading && vehiclesPaginator.length > 0">
                            <label *ngFor="let vehicle of vehiclesPaginator[ipageActive-1]; let indexEven = even"
                                [for]="vehicle.vehicle.licensePlate">
                                <fp-item-list-vehicle [chkboxId]="vehicle.vehicle.licensePlate"
                                    [chkboxName]="vehicle.vehicle.licensePlate" [vehicle]="vehicle"
                                    (itemClickActive)="changeStatusTag($event)" (vehicleUpdate)="updateVehicle($event)"
                                    (openModalDelete)="deleteVehicleShowModal($event)" [resetData]="error"
                                    [indexEven]="indexEven" (itemSelected)="toggleSelection($event)"
                                    [formAccount]="formAccount">
                                </fp-item-list-vehicle>
                            </label>
                        </ng-container>
                    </div>
                    <div class="status-line">
                        <hr>
                    </div>
                    <div class="footer-detail-vehicles" *ngIf="!loading && vehiclesPaginator.length > 0">
                        <div class="item-info">
                            <img src="assets/images/common/icon-info.svg" alt="info">
                            <span>
                                {{totalItemsforAccount}} vehículos
                            </span>
                        </div>
                        <div style="height: 24px; width: 2.813rem;">
                            <fp-select [items]="filterLimit" [disabled]="totalItemsforAccount <= 10"
                                (itemPageActive)="changeFilterLimit($event)"></fp-select>
                        </div>
                        <a class="fp-btn-link download" *ngxPermissionsOnly="['VEHDES']"
                            (click)="downloadVehiclesExcelReport()" id="download-vehicles-excel-report">
                            <img src="assets/images/common/download.svg" alt="arrow-left">
                            <u>Descargar vehículos</u>
                        </a>
                        <div class="paginator">
                            <img class="controls previos " [class.disabled]="ipageActive === 1"
                                src="assets/images/common/arrow-paginator.svg" alt="previos" (click)="prev()">
                            <div class="pages">
                                <span [class.selected]="ipageActive === 1" (click)="changeIndexPage(1)">1</span>
                                <span *ngIf="ipageActive <= 4 && pagesItems >= 7" [class.selected]="ipageActive === 2"
                                    (click)="changeIndexPage(2)">2</span>
                                <span *ngIf="ipageActive > 4 && pagesItems >= 7">...</span>
                                <ng-container *ngFor="let page of pages">
                                    <span [class.selected]="page === ipageActive" (click)="changeIndexPage(page)">
                                        {{page}}</span>
                                </ng-container>
                                <span *ngIf="ipageActive <  pagesItems - 3 && pagesItems >= 7">...</span>
                                <span *ngIf="ipageActive >=  pagesItems - 3 && pagesItems >= 7"
                                    [class.selected]="ipageActive === pagesItems -1"
                                    (click)="changeIndexPage(pagesItems-1)">{{pagesItems-1}}</span>
                                <span *ngIf="pagesItems >  1" [class.selected]="ipageActive === pagesItems"
                                    (click)="changeIndexPage(pagesItems)">{{pagesItems}}</span>
                            </div>
                            <img class="controls" [class.disabled]="ipageActive === pagesItems && !lastEvaluatedKey"
                                src="assets/images/common/arrow-paginator.svg" alt="next" (click)="next()">
                        </div>
                        <button *ngIf="lastEvaluatedKey" class="fp-btn-white card-detail-custom-btn big"
                            (click)="loadMore()">
                            <h2 class="fp-body-one  detail-account-title-small">Ver más</h2>
                            <img src="assets/images/home/more.svg" alt="icono settings">
                        </button>
                        <button class="fp-btn-primary btn-primary-footer" type="button" id="button-create-vehicle"
                            (click)="chooseCreationType = true" *ngxPermissionsOnly="['VEHCRE']">
                            Crear vehículos
                        </button>
                    </div>
                </form>
                <!-- SKELETON SECTON -->
                <ng-container *ngIf="loading">
                    <div class="container-detail-vehicles fake">
                        <div class="header-detail-vehicles">
                            <div class="skeleton shadow" style="width: 45%; height: 48px; border-radius: 8px;">
                            </div>
                            <div class="container-btn-header"
                                style="margin-left: auto; width: 37%;align-items: center;">
                                <button class="fp-btn-primary skeleton shadow" style="height: 2.5rem;"></button>
                                <button class="fp-btn-primary skeleton shadow" style="height: 2.5rem;"></button>
                            </div>
                        </div>
                        <div class="list-vehicles-container-filters space-vehicles-header ">
                            <div class="skeleton shadow check" style="width: 18px;height: 18px; border-radius: 6px;">
                            </div>
                            <div class="vehicles-btn-filter subtitle-vehicle row-one">
                                <div class="skeleton shadow" style="color: transparent;">
                                    Mis Placa
                                </div>
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="vehicles-btn-filter subtitle-vehicle row-one">
                                <div class="skeleton shadow" style="color: transparent;">
                                    Número TAG
                                </div>
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="vehicles-btn-filter subtitle-vehicle row-one">
                                <div class="skeleton shadow" style="color: transparent;">
                                    Información adicional
                                </div>
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="vehicles-btn-filter subtitle-vehicle row-two">
                                <div class="skeleton shadow" style="color: transparent;">
                                    Estado
                                </div>
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                            <div class="vehicles-btn-filter subtitle-vehicle">
                                <div class="skeleton shadow" style="color: transparent;">
                                    Acción
                                </div>
                                <button class="fp-btn-white fp-btn-filter skeleton shadow" type="button">
                                </button>
                            </div>
                        </div>
                        <div class="list-vehicles-container">
                            <div class="fake-item-vehicle shadow" *ngFor="let item of [1,2,3]; let indexEven = even"
                                [class.even]="indexEven">
                                <div class="fake-check skeleton shadow"></div>
                                <div class="fake-placa">
                                    <div class="text skeleton shadow"></div>
                                    <div class="text small skeleton shadow"></div>
                                </div>
                                <div class="fake-number-tag skeleton shadow"></div>
                                <div class="fake-info-aditional skeleton shadow"></div>
                                <div class="fake-state skeleton shadow"></div>
                                <div class="fake-button skeleton shadow"></div>
                                <div class="fake-fap-button skeleton shadow"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </section>
    </ng-template>
</fp-card-body>
<fp-delete-vehicle [vehicleInfo]="vehicleInfo" (itemClickActionDelete)="deleteVehicleClose($event)"></fp-delete-vehicle>

<ng-template #tempError>
    <fp-snack-message (snackMessageEvent)="closeAlertActive()" [title]="'Error' " [description]="messageError"
        type="error">
    </fp-snack-message>
</ng-template>
<fp-activation-progress (itemClickActive)="closeModal('fp-activation-progress')" [titleModal]="titleModal"
    [time]="time">
</fp-activation-progress>
<fp-activate-selection (emitActionPopUp)="changeSelection($event)">
</fp-activate-selection>
<fp-new-vehicle></fp-new-vehicle>
<fp-information-modal (itemClickActive)="navigateGou()" [vehicleTypes]="vehicleTypes"></fp-information-modal>