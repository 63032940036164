import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeActivationTagpageActions {
  tagActivationPageString = "[TAG ACTIVATION] set pages",
}

/**
 * Action to set data page
 */
export const tagActivationSetPage = createAction(
  TypeActivationTagpageActions.tagActivationPageString,
  (pages: IPage[]) => ({ pages }),
);
