import { Component, Input } from "@angular/core";

@Component({
  selector: "fp-item-list-empty",
  templateUrl: "./item-list-empty.component.html",
  styleUrls: ["./item-list-empty.component.scss"],
})
export class ItemListEmptyComponent {
  @Input() iconEmpety: string;
  @Input() descriptionEmpety: string;
  @Input() notifyError: boolean = false;

  constructor() {}
}
