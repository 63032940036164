import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  VehiclesFeatureName,
  VehiclesModuleState,
} from "../state/vehicles.state";

export const VehiclesCreateSelector = createFeatureSelector<
  VehiclesModuleState
>(VehiclesFeatureName);

export const selectVehiclesToCreate = createSelector(
  VehiclesCreateSelector,
  (state: VehiclesModuleState) => {
    return state.vehiclesToCreateReducer;
  },
);
