<fp-modal class="fp-send-documents" typeModal="customNotification" [hideClose]="true" [typeModalBig]="true"
    id="fp-upload-documents" [btnAccept]="'Entendido'" [btnExit]="false" [showConfirm]="true" (actionModal)="modalAction()">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-send-documents-icon"
                src="assets/images/common/send-email.svg">
        </div>
        <div class="modal-content">
            
            <h1 class="fp-h1 fp-send-documents-title">¿Cómo enviar los documentos?</h1>
            <div class="fp-content-text">
                <ng-scrollbar class="fp-scrollbar" track="all" pointerEventsMethod="scrollbar">
                    <ol class="fp-send-documents-message main-list">
                        <li>Descargue y diligencie los siguientes documentos que encontrará en el correo electrónico del administrador del portal
                            <ul class="fp-send-documents-message item-container">
                                <li>Contrato</li>
                                <li class="download-link"><a (click)="downloadVehicleFiles()" class="fp-send-documents-message item  download-link">Guía y relación de vehículos</a></li>
                            </ul>
                        </li>
                        <li>Envíe los siguientes documentos a <a class="download-link" href="mailto:registro@facilpass.com" target="_blank">registro@facilpass.com</a>
                            <ul class="fp-send-documents-message item-container">
                                <li>Contrato firmado</li>
                                <li>Relación de vehículos diligenciada</li>
                                <li>Certificado de Existencia y Representación</li>
                                <li>Cédula representante legal</li>
                            </ul>
                        </li>
                        <li>La cuenta se activará una vez los documentos sean aprobados por parte de FacilPass</li>
                    </ol>
                    <br>
                </ng-scrollbar>
            </div>
        </div>
    </ng-template>
</fp-modal>