import { IRoles } from "@app/models/roles";

export const rolesInfoFeatureName = "rolesReducer";

export const initRolesDataState: IRoles = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
