<fp-modal [typeModal]="'custom'" id="fp-information-modal" [btnAccept]="'Entendido'">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                Información adicional
            </h1>
            <p class="fp-payment-method-message center">
                Este campo utilícelo como guía para asociar el
                vehículo que está creando con los códigos utilizados en su compañía,
                por ejemplo: número de seguimiento, inventarios, centro de costos, entre otros. Su uso no es
                obligatorio.
            </p>
        </div>
    </ng-template>
</fp-modal>

<fp-modal [typeModal]="'custom'" id="fp-category-modal" [btnAccept]="'Entendido'" class="create-vehicle-container">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                Categorías de vehículos
            </h1>
            <p class="fp-payment-method-message center">
                Selecciona el tipo de vehículo
            </p>
            <div class="form-group">
                <div style="padding-top: 16px;"> </div>
                <div class="cards-container">
                    <div class="card-container" *ngFor="let vehicleType of vehicleTypes; let itype= index">
                        <button class="vehicles-card" type="button" id="type{{vehicleType.value}}">
                            <div class="radio-btn-container">
                                <fp-radiobutton class="fp-radio-vehicle" [internalLabel]="vehicleType.shortDescription"
                                    [class.fp-radiobutton-sub-text]="vehicleType.shortDescription" name="vehicleType"
                                    [inputId]="'radio'+itype" [value]="vehicleType.value"></fp-radiobutton>
                            </div>
                            <div [class.card-img-sub-text]="vehicleType.shortDescription">
                                <img [src]="vehicleType.icon.split(',')[0]" alt="icon-vehicle">
                                <div class="card-container-sub-label"
                                    [style.visibility]="vehicleType.icon.split(',')[1] ? 'visible': 'hidden'">
                                    <label class="card-sub-label">{{vehicleType.icon.split(',')[1]}}</label>
                                </div>
                            </div>
                        </button>
                        <label [class.big-text]="vehicleType.description.length > 13" class="card-label"
                            for="vehicleType">{{vehicleType.description}}</label>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>
</fp-modal>

<fp-modal [typeModal]="'customNotification'" id="fp-gou-modal" (actionModal)="actionModal()" [btnAccept]="'Entendido'">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                Está saliendo de FacilPass.com
            </h1>
            <fp-alert-message [custom]="true" [type]="'info'">
                <p class="text-alert"> <span class="text-bold">Su primer TAG no tienen costo.</span> Solicítelo al
                    registrar su vehículo</p>
            </fp-alert-message>
            <p class="fp-payment-method-message color-description">
                Será redirigido a nuestra pasarela de pago para ordenar y comprar sus TAGs.
            </p>
            <p class="fp-payment-method-message font-size-subtitle">
                Pasarela de pagos
            </p>
            <img class="image-logo" src="assets/images/vehicle/gou-aval-logotype.svg" alt="Logo de gou y grupo AVAL">
        </div>
    </ng-template>
</fp-modal>