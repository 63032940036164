import { ICustomerInfo } from "@app/models/customer-info";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { customerInfoFeatureName } from "../state/customer-info.state";

export const CustomerInfoRootSelector = createFeatureSelector<ICustomerInfo>(
  customerInfoFeatureName,
);

export const selectCustomerInfo = createSelector(
  CustomerInfoRootSelector,
  (state: ICustomerInfo) => state,
);
