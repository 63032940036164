import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

@Injectable()
export class ExcelService {
  constructor() {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    Heading: any,
    autofilter = null,
    cols = null,
    title: string,
  ): void {
    const worksheet: XLSX.WorkSheet = {};

    XLSX.utils.sheet_add_aoa(worksheet, Heading, {
      origin: "A1",
    });
    worksheet["!cols"] = cols ? cols : null;
    worksheet["!autofilter"] = autofilter ? autofilter : null;
    XLSX.utils.sheet_add_json(worksheet, json, {
      origin: "A5",
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { [title]: worksheet },
      SheetNames: [title],
    };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }

  private toExportFileName(excelFileName: string): string {
    return `${excelFileName}_${new Date().getTime()}.xlsx`;
  }
}
