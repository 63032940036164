import { AccountFeatureName } from "@app/modules/account/store/state/account.state";
import { ReportsFeatureName } from "@app/modules/reports/store/state/reports.state";
import { rolesAdministratorFeatureName } from "@app/store/state/app.state";
import { CompleteSignUpFeatureName } from "@complete-signup/store/state/complete-signup.state";
import { environment } from "@environment";
import { ActionReducer, INIT } from "@ngrx/store";
import { PostpaidFeatureName } from "@postpaid/store/state/postpaid.state";
import { financialDataFeatureName } from "@shared-financial-data/store/state/financial-data.state";
import { SignInFeatureName } from "@signin/store/state/signin.state";
import { SignUpFeatureName } from "@signup/store/state/signup.state";
import { TagFeatureName } from "@tag/store/state/tag.state";
import { PaymentFeatureName } from "@theme/components/payment/store/state/payment.state";
import { TransactionsFeatureName } from "@transactions/store/state/statusTransaction.state";
import { ModuleUsersFeatureName } from "@user/store/state/users.state";
import { VehiclesFeatureName } from "@vehicles/store/state/vehicles.state";
import CryptoJS from "crypto-js";
import { addressFeatureName } from "../state/address.state";
import {
  accountSelectedFeatureName,
  accountsFeatureName,
  AppFeatureName,
  IdentificationTypesFetureName,
  lowBalanceFeatureName,
  lowBalanceSelectedFeatureName,
} from "../state/app.state";
import { citiesFeatureName } from "../state/cities.state";
import { customerInfoFeatureName } from "../state/customer-info.state";
import { userInfoFeatureName } from "../state/user-info.state";

// eslint-disable-next-line jsdoc/check-alignment, jsdoc/check-indentation, jsdoc/newline-after-description
/** reducers to persist **/
const config: string[] = [
  AppFeatureName,
  SignUpFeatureName,
  SignInFeatureName,
  citiesFeatureName,
  IdentificationTypesFetureName,
  PostpaidFeatureName,
  accountsFeatureName,
  accountSelectedFeatureName,
  customerInfoFeatureName,
  userInfoFeatureName,
  financialDataFeatureName,
  ModuleUsersFeatureName,
  CompleteSignUpFeatureName,
  TransactionsFeatureName,
  PaymentFeatureName,
  TagFeatureName,
  VehiclesFeatureName,
  AccountFeatureName,
  ReportsFeatureName,
  rolesAdministratorFeatureName,
  lowBalanceFeatureName,
  lowBalanceSelectedFeatureName,
  addressFeatureName,
];

export const hydrationMetaReducer = (
  reducer: ActionReducer<any>,
): ActionReducer<any> => {
  return (state, action) => {
    const nextState = reducer(state, action);
    const valores = {};
    let exist = false;
    // eslint-disable-next-line guard-for-in
    for (const key in sessionStorage) {
      if (
        key.includes("state-pj-") === true &&
        sessionStorage.getItem(key) !== null
      ) {
        valores[key.slice(9)] = JSON.parse(
          CryptoJS.AES.decrypt(
            sessionStorage.getItem(key),
            environment.facilpasskey.trim(),
          ).toString(CryptoJS.enc.Utf8),
        );
        exist = true;
      }
    }

    if (action.type === INIT) {
      if (exist) {
        try {
          return { ...nextState, ...valores };
        } catch {
          sessionStorage.removeItem("state-pj");
          for (const key in sessionStorage) {
            if (
              key.includes("state-pj-") &&
              sessionStorage.getItem(key) !== null
            ) {
              sessionStorage.removeItem(key);
            }
          }
        }
      }
    }
    config.forEach((value) => {
      if (value in nextState) {
        const valor = JSON.stringify(nextState[value]);
        const encrypt = CryptoJS.AES.encrypt(
          valor,
          environment.facilpasskey.trim(),
        ).toString();
        sessionStorage.setItem("state-pj-" + value, encrypt);
      }
    });
    return nextState;
  };
};
