import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RecurrentRechargeGuard implements CanActivate {
  /**
   * Constructor
   * @param router
   */
  constructor(private router: Router) {}
  /**
   * CanActivate
   */
  canActivate(): boolean {
    const showRecurrentRecharge = JSON.parse(
      sessionStorage.getItem("publicKey"),
    )?.config?.recurrent;

    if (showRecurrentRecharge) {
      return true;
    } else {
      this.router.navigate(["/home"]);
      return;
    }
  }
}
