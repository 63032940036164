/* eslint-disable no-console */
const debug = (...messages: Array<unknown>): void => {
  console.log(...messages);
};

const error = (...messages: Array<unknown>): void => {
  console.error(...messages);
};

export const Logger = { debug, error };
