import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@core/core.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InputModule } from "../input/input.module";
import { ModalComponent } from "./modal.component";
import { ModalService } from "./service/modal.service";

@NgModule({
  imports: [
    CommonModule,
    NgScrollbarModule,
    InputModule,
    ReactiveFormsModule,
    CoreModule,
  ],
  declarations: [ModalComponent],
  exports: [ModalComponent],
  providers: [ModalService],
})
export class ModalModule {}
