import { Component, Input, OnInit } from "@angular/core";
import { IClientInfo } from "@app/models/customer-info";
import { IUserInfoDataResult } from "@app/models/user-info";

@Component({
  selector: "fp-info-user",
  templateUrl: "./info-user.component.html",
  styleUrls: ["./info-user.component.scss"],
})
export class InfoUserComponent implements OnInit {
  greetText: string = "";
  time: number = 2000;
  @Input() clientInfo: IClientInfo;
  @Input() userInfo: IUserInfoDataResult;
  constructor() {}
  /**
   * Init state to component
   */
  ngOnInit(): void {
    const date = new Date();
    this.greetUser(date);
  }
  /**
   * Greet user
   */
  greetUser(date: Date): void {
    const hour = date.getHours();
    const minutes = date.getMinutes();

    if ((hour >= 1 && hour < 12) || (hour === 12 && minutes < 1)) {
      this.greetText = "Buenos días";
    } else if (
      (hour === 12 && minutes >= 1) ||
      (hour >= 13 && hour < 18) ||
      (hour === 18 && minutes < 1)
    ) {
      this.greetText = "Buenas tardes";
    } else if ((hour === 18 && minutes >= 1) || (hour >= 19 && hour <= 23)) {
      this.greetText = "Buenas noches";
    } else {
      this.greetText = "Buenas noches";
    }
  }
}
