<fp-card-body [template]="'secondTemplate'" headerType="secondary" close="true">
    <ng-template #tplRef>
        <div class="container-successful" *ngIf="account && !account.rejectedAssociationCode">
            <fp-page-message icon="assets/images/register/icon-check.svg" [big]="'true'" [title]="'successful.title' | translate"
                [message]=" account?.accountType === 'POS'
                ? ('successful.info.postpaid'| translate:{'accountId': account?.accountId, 'modality':'pospago', 'financialProduct':financialProduct, 'bank':bank}) 
                : ('successful.info.prepago' | translate:{'accountId': account?.accountId, 'modality':'prepago'})"
                [labelButton]="'successful.register_btn_continue' | translate" (goNext)="goToRecharge()">
            </fp-page-message>
        </div>
        <div class="container-successful" *ngIf="account && account.rejectedAssociationCode"> 
            <fp-page-message icon="assets/images/common/icon-error-circle.svg" [big]="'true'" [title]=" 'rejected.title' | translate"
                [message]="!account.isModalityChange 
                ? ('rejected.description_association' | translate:{'error': account.rejectedAssociationCode})
                : ('rejected.description_change' | translate:{'error': account.rejectedAssociationCode})"
                [labelButton]="'rejected.button' | translate" (goNext)="goHome()">
            </fp-page-message>
        </div>
    </ng-template>
</fp-card-body>