import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import { vehiclesPagesConst } from "@core/constants/pages.constant";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { VehiclesFacade } from "../store/facade/vehicles.facade";
import { initialStateVehicleInformationReducer } from "../store/state/vehicles.state";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class VehicleGuard implements CanActivate {
  constructor(
    private vehicleFacade: VehiclesFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
  ) {}
  /**
   * CanActivate
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const info = await this.vehiclePages$.pipe(take(1)).toPromise();
    const validate = await this.commonsService.verifyPagesProcess(
      info,
      state.url.split("?")[0],
      "vehicles",
    );
    return validate;
  }
  /**
   * Clear Vehicle Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.vehicle === true) {
      modules.vehicle = false;
      this.appFacade.setModules(modules);
      this.vehicleFacade.setVehicleInformation(
        initialStateVehicleInformationReducer,
      );
      this.vehicleFacade.setvehiclePages([vehiclesPagesConst[0]]);
    }
  }
  /**
   * Get data to facade store communication
   */
  get vehiclePages$(): Observable<IPages> {
    return this.vehicleFacade.selectVehiclePages$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
