import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInFeatureName, SignInModuleState } from "../state/signin.state";

export const SignInOtpCodeTimerRootSelector = createFeatureSelector<
  SignInModuleState
>(SignInFeatureName);

export const selectSignInotpCodeTimer = createSelector(
  SignInOtpCodeTimerRootSelector,
  (state: SignInModuleState) => state.signInOtpCodeTimerReducer,
);
