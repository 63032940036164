import { createAction } from "@ngrx/store";
import { ITagNumber } from "@tag/models/tag-number";

export const enum TypeActionsTagNumber {
  tagNumberString = "[TAG number] set tag Number",
}

export const tagNumber = createAction(
  TypeActionsTagNumber.tagNumberString,
  (data: ITagNumber) => ({ data }),
);
