import { IAddress } from "@app/modules/vehicles/models/address";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { addressFeatureName } from "../state/address.state";

export const AddressRootSelector = createFeatureSelector<IAddress>(
  addressFeatureName,
);

export const selectAddress = createSelector(
  AddressRootSelector,
  (state: IAddress) => state,
);
