import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SuccessfulVehicleEditComponent } from "./successful-vehicle-edit.component";

export const successfulEditRoute = "successful-edit";
const routes: Routes = [
  {
    component: SuccessfulVehicleEditComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuccessfulVehicleEditRoutingModule {}
