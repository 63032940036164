<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'secondTemplate'"
  [exit]="false" headerType="secondary" [showfooterBallsRight]="false" [actionType]="'custom'" close="true"
  (actionClose)="close('/vehicles/list')" urlBack="/vehicles/list">
  <ng-template #tplRef>
    <div class="container-section-request ">
      <div class="container-second">
        <h1 class="fp-h1 card-title"> {{texts?.title | translate}} </h1>
        <p class="description-subtitle"> <span class="text-bold"> {{texts?.description?.textBoldOne}}</span>
          {{texts?.description?.textNormalOne}}<span class="text-bold">{{texts?.description?.textBoldTwo}}</span>
          {{texts?.description?.textNormalTwo}}<a
            href="https://www.facilpass.co/wp-content/uploads/2024/08/Instrucciones-tag-link.pdf"
            target="_blank">{{texts?.description?.textLink}}</a></p>
        <fp-alert-message [custom]="true" [type]="'info'">
          <p class="text-alert"> <span
              class="text-bold">{{texts?.alert?.textBoldOne}}</span>{{texts?.alert?.textNormalOne}}
          </p>
        </fp-alert-message>
        <form novalidate [formGroup]="formSelectModality" (ngSubmit)="selectModality()">
          <div class="container-cards">
            <ng-container>
              <button *ngFor="let modality of modalities; let itype= index" class="input-radio-option"
                [class.selected-option]="this.formSelectModality.controls.paymentType.value === modality.value"
                type="button" (click)="chooseModality(modality.value)" id="type{{modality.value}}">
                <div class="card-header">
                  <span class="radio-btn-container">
                    <fp-radiobutton formControlName="paymentType" name="paymentType" [value]="modality.value"
                      inputId="paymentType{{modality.value}}">
                    </fp-radiobutton>
                  </span>
                  <div class="card-recommended">
                    {{ modality.description | translate }}
                  </div>
                  <div class="card-title">
                    {{ modality.name }}
                  </div>
                </div>
                <div class="card-info">
                  <div class="card-item" *ngFor="let item of modality.info">
                    <div class="card-icon"></div>
                    <div class="card-text">
                      <div class="container-text-item" [innerHTML]="item.text"></div>
                    </div>
                  </div>
                </div>
              </button>
            </ng-container>
            <div class="space-inputs-small"></div>
          </div>
          <div class="form-group-submit">
            <div class="fp-container-btn">
              <div class="container-button-request">
                <button [style]="'width: 300px'" class="fp-btn-primary" id="btn-modal-gou" type="submit">
                  Solicitar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</fp-card-body>

<fp-information-modal (itemClickActive)="navigateGou()"></fp-information-modal>