import { ITemporalPassword } from "@app/models/temporal-password";
import { createAction } from "@ngrx/store";

export const enum TypeActionsTemporalPassword {
  temporalPasswordString = "[Temporal Password] set temporal password",
}

export const temporalPassword = createAction(
  TypeActionsTemporalPassword.temporalPasswordString,
  (data: ITemporalPassword) => ({ data }),
);
