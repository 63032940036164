import { createAction } from "@ngrx/store";
import { IRecharge } from "@tag/models/recharge";

export const enum TypeTagRechargeActions {
  tagRechargeSaveInfo = "[TAG RECHARGE] save data",
  tagRechargeResetInfo = "[TAG RECHARGE] reset data",
}

/**
 * Action to detect event save recharge info
 */
export const tagRechargeSave = createAction(
  TypeTagRechargeActions.tagRechargeSaveInfo,
  (data: IRecharge) => ({ data }),
);

/**
 * Action to detect event save recharge info
 */
export const tagRechargeReset = createAction(
  TypeTagRechargeActions.tagRechargeResetInfo,
);
