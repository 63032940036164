import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListVehicleComponent } from "./list-vehicle.component";

export const listVehicleRoot = "list";
const routes: Routes = [
  {
    component: ListVehicleComponent,
    path: "",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListVehicleRoutingModule {}
