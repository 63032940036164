import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "descriptionCategory",
})
export class DescriptionCategoryPipe implements PipeTransform {
  transform(value: string): string {
    let description;
    switch (value) {
      case "1":
        description = "Automóvil";
        break;

      case "2":
        description = "Campero";
        break;

      case "3":
        description = "Van";
        break;

      case "4":
        description = "Bus";
        break;

      case "5":
        description = "Camión 2 ejes";
        break;

      case "6":
        description = "Camión 2 ejes";
        break;

      case "7":
        description = "Vehículo 3 o 4 ejes";
        break;

      case "8":
        description = "Vehículo 5 ejes";
        break;

      default:
        description = "Vehículo 6 ejes";
        break;
    }
    return description;
  }
}
