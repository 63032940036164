import { Component, OnDestroy } from "@angular/core";
import { pageEvent } from "@app/helpers/utag.helpers";
import { environment } from "@environment";
import { CommonsService } from "@shared/services/commons.service";
import { Subject } from "rxjs";

@Component({
  selector: "fp-new-vehicle",
  templateUrl: "./new-vehicle.component.html",
  styleUrls: ["./new-vehicle.component.scss"],
})
export class NewVehicleComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  urlTemplateGuide: string =
    environment.documents.url +
    environment.documents.init +
    environment.documents.specific.newVehicleTemplateGuide;
  urlTemplate: string =
    environment.documents.url +
    environment.documents.init +
    environment.documents.specific.folder +
    environment.documents.specific.newVehicleTemplate;

  constructor(private commonsService: CommonsService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async downloadTemplateGuide(): Promise<any> {
    pageEvent(
      "btn-download-fp-modal-new-vechicle",
      "/vehicle/list",
      "Modal de descarga de formatos de creacion de vehiculos",
    );
    await this.commonsService.generatorPromiseDownload([
      this.urlTemplateGuide,
      this.urlTemplate,
    ]);
  }
}
