import { Injectable } from "@angular/core";
import { IRolesData } from "@app/models/roles";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RolesService } from "@shared/services/roles.service";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as rolesActions from "../actions/roles.actions";
@Injectable()
export class RolesEffect {
  loadDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.rolesLoad),
      switchMap((action) =>
        this.rolesService.getRoles(action.personId).pipe(
          map((roles: IRolesData) => {
            if (roles.code === "100880") {
              return rolesActions.rolesSave(roles.body);
            } else {
              return rolesActions.rolesError({ roles });
            }
          }),
          catchError((error) => of(rolesActions.rolesError({ error }))),
        ),
      ),
    ),
  );
  constructor(private actions$: Actions, private rolesService: RolesService) {}
}
