<div class="card-item-document" [style.visibility]="infoDocument?.state === 'fake'? 'hidden':'visible'"
    [class.blocked]="infoDocument?.state === 'blocked'" [class.process]="infoDocument?.state === 'process'"
    (click)="click()" [id]="infoDocument?.key">
    <div class="container-item">
        <div class="item-mask-icon" [ngSwitch]="infoDocument?.state"
            [ngClass]="{'successful': infoDocument?.state === 'successful','process':infoDocument?.state === 'process','blocked':infoDocument?.state === 'blocked' }">
            <img *ngSwitchCase="'process'" [src]="infoDocument?.assets" alt="icono document">
            <img *ngSwitchCase="'blocked'" src="assets/images/home/blocked.svg" alt="icono blocked">
            <img *ngSwitchCase="'successful'" src="assets/images/home/successful.svg" alt="icono settings"
                class="successful">
            <img *ngSwitchDefault alt="sin icono">
        </div>
        <p class="item-text-description" [class.inactive]="infoDocument?.state !== 'process'">
            {{infoDocument?.description}}</p>
    </div>
</div>