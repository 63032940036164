import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  VehiclesFeatureName,
  VehiclesModuleState,
} from "../state/vehicles.state";

export const VehiclesUpdateSelector = createFeatureSelector<
  VehiclesModuleState
>(VehiclesFeatureName);

export const selectVehiclesToUpdate = createSelector(
  VehiclesUpdateSelector,
  (state: VehiclesModuleState) => {
    return state.vehiclesToUpdateReducer;
  },
);
