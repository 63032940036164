import { IAccountLowBalance } from "@app/models/accounts";
import { createAction } from "@ngrx/store";

export const enum LowBalanceTypeActions {
  lowBalanceLoadString = "[LowBalance] Loading data",
  lowBalanceSaveString = "[LowBalance] Successful data loaded",
  lowBalanceErrorString = "[LowBalance] Save error response",
  lowBalanceResetString = "[LowBalance] Reset State",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const lowBalanceLoad = createAction(
  LowBalanceTypeActions.lowBalanceLoadString,
  (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const lowBalanceSave = createAction(
  LowBalanceTypeActions.lowBalanceSaveString,
  (data: IAccountLowBalance[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const lowBalanceError = createAction(
  LowBalanceTypeActions.lowBalanceErrorString,
  (error: any) => ({ error }),
);

export const lowBalanceReset = createAction(
  LowBalanceTypeActions.lowBalanceResetString,
);
