import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInFeatureName, SignInModuleState } from "../state/signin.state";

export const ForgotPasswordRootSelector = createFeatureSelector<
  SignInModuleState
>(SignInFeatureName);

export const selectForgotPassword = createSelector(
  ForgotPasswordRootSelector,
  (state: SignInModuleState) => state.forgotPasswordReducer,
);
