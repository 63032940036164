import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IInfoDocuemnt } from "./models/item-document";

@Component({
  selector: "fp-item-document",
  templateUrl: "./item-document.component.html",
  styleUrls: ["./item-document.component.scss"],
})
export class ItemDocumentComponent {
  @Input() infoDocument: IInfoDocuemnt;
  @Output() clickItem = new EventEmitter<IInfoDocuemnt>();

  click() {
    this.clickItem.emit(this.infoDocument);
  }
}
