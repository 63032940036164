import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { IPseBanks } from "@app/models/pse";
import { select, Store } from "@ngrx/store";
import { ITermsandConditions } from "@signup/models/termsand-conditions";
import { IDeliveryInfo } from "@tag/models/delivery";
import { IRecharge, IRechargeState } from "@tag/models/recharge";
import { ITagNumber } from "@tag/models/tag-number";
import {
  ITagReservation,
  ITagReservationRequest,
} from "@tag/models/tag-reservation";
import { ITagTypes } from "@tag/models/tag-types";
import * as tagActions from "@tag/store/actions/index";
import { Observable } from "rxjs";
import { selectPseBanks } from "../selectors/pse-banks.selector";
import { selectTagActivationpages } from "../selectors/tag-activation.selector";
import { selectTagNumber } from "../selectors/tag-number.selector";
import {
  selectDeliveryInfo,
  selectTagpages,
} from "../selectors/tag-pages.selector";
import { selectTagRecharge } from "../selectors/tag-recharge.selector";
import { selectTagReservation } from "../selectors/tag-reservation.selector";
import { selectTagTermsandConditions } from "../selectors/tag-terms-conditions.selector";
import { selectTagTypes } from "../selectors/tag-types.selector";
import { TagModuleState } from "../state/tag.state";

@Injectable()
export class TagFacade {
  constructor(private store: Store<TagModuleState>) {}
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagPages$: Observable<IPages> = this.store.pipe(
    select(selectTagpages),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagActivationPages$: Observable<IPages> = this.store.pipe(
    select(selectTagActivationpages),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectDeliveryAddressInfo$: Observable<
    IDeliveryInfo
  > = this.store.pipe(select(selectDeliveryInfo));
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagTermsandConditions$: Observable<
    ITermsandConditions
  > = this.store.pipe(select(selectTagTermsandConditions));
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagTermsandConditions2$: Observable<
    ITermsandConditions
  > = this.store.pipe(select(selectTagTermsandConditions));
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagTypes$: Observable<ITagTypes> = this.store.pipe(
    select(selectTagTypes),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagTypes2$: Observable<ITagTypes> = this.store.pipe(
    select(selectTagTypes),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectPseBanks$: Observable<IPseBanks> = this.store.pipe(
    select(selectPseBanks),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectPseBanks2$: Observable<IPseBanks> = this.store.pipe(
    select(selectPseBanks),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectRecharge$: Observable<IRechargeState> = this.store.pipe(
    select(selectTagRecharge),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagReservation$: Observable<ITagReservation> = this.store.pipe(
    select(selectTagReservation),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagReservation2$: Observable<ITagReservation> = this.store.pipe(
    select(selectTagReservation),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectTagNumber$: Observable<ITagNumber> = this.store.pipe(
    select(selectTagNumber),
  );
  /**
   * get tag terms and conditions data
   */
  public getTagTermsandConditions(): void {
    this.store.dispatch(tagActions.tagTermsandConditionsLoad());
  }
  /**
   * get tag tag types data
   */
  public getTagTypes(): void {
    this.store.dispatch(tagActions.tagTypesLoad());
  }
  /**
   * get tag tag types data
   */
  public getPseBanks(): void {
    this.store.dispatch(tagActions.pseBanksLoad());
  }
  /**
   * set TagPages
   * @param pages tagPages
   */
  public setTagPages(pages: IPage[]) {
    this.store.dispatch(tagActions.tagSetPage(pages));
  }
  /**
   * set TagActivationPages
   * @param pages tagPages
   */
  public setTagActivationPages(pages: IPage[]) {
    this.store.dispatch(tagActions.tagActivationSetPage(pages));
  }
  /**
   * set Delivery Info
   * @param deliveryInfo info
   */
  public setDeliveryInfo(deliveryInfo: IDeliveryInfo) {
    this.store.dispatch(tagActions.setDeliveryInformation(deliveryInfo));
  }

  /**
   * set Recharge Info
   * @param rechargeInfo info
   */
  public setRechargeInfo(rechargeInfo: IRecharge) {
    this.store.dispatch(tagActions.tagRechargeSave(rechargeInfo));
  }
  /**
   * reset Recharge Info
   */
  public setRechargeReset() {
    this.store.dispatch(tagActions.tagRechargeReset());
  }
  /**
   * Set event to call service to reserve tag
   * @param tagReservationRequest request data
   */
  public setTagReservationInfo(tagReservationRequest: ITagReservationRequest) {
    this.store.dispatch(tagActions.tagReservationLoad(tagReservationRequest));
  }

  /**
   * Set event to call service to reserve tag
   */
  public tagReservationDelete() {
    this.store.dispatch(tagActions.tagReservationReset());
  }

  public setTagNumber(data: ITagNumber): void {
    this.store.dispatch(tagActions.tagNumber(data));
  }
}
