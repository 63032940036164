import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { pageEvent, pageViewEvent } from "@app/helpers/utag.helpers";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { IPage, IPages } from "@app/models/pages";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { Languages } from "@store/actions/app.actions";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { IMenuItem } from "../../models/item-menu.interface";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { RouteTrackingService } from "../../services/route-tracking/route-tracking.service";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-admin-menu",
  templateUrl: "./admin-menu.component.html",
  styleUrls: ["./admin-menu.component.scss"],
})
export class AdminMenuComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  itemsMenu: IMenuItem[] = [];
  account: IAccountsResponseDetails;
  subtitle: string = "";
  itemsAutomaticRechargeMenu: IMenuItem[] = [
    {
      permission: "CUEMOD",
      text: "modifyTheValueOfMyRecharge",
      image: "assets/images/common/recharge-money.svg",
      url: "/account/automatic-recharge-config",
      id: 3,
    },
    {
      permission: "CUEMOD",
      text: "changePaymentMethod",
      image: "assets/images/common/low-balance.svg",
      url: "/account/payment-method",
      id: 4,
    },
  ];
  /** History navigation account */
  historyNavigationAccount: IPage[] = [];
  /** Previous url */
  previousUrl: string;

  /**
   * Constructor
   * @param translate
   * @param appFacade
   * @param commonsService
   * @param accountNavigationService
   */
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private accountFacade: AccountFacade,
    private commonsService: CommonsService,
    private accountNavigationService: AccountNavigationService,
    private routeTrackingService: RouteTrackingService,
  ) {
    this.selectAccountPages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IPages) => {
        this.historyNavigationAccount = data.pages;
      });
  }

  /**
   * lifecycle ngOnInit
   */
  async ngOnInit(): Promise<any> {
    this.previousUrl = this.routeTrackingService.getPreviousUrl();
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
    this.getAccounts();
  }

  /**
   * method to get accounts
   */
  getAccounts(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccountsResponseDetails) => {
        this.fillData(action);
      });
  }
  /**
   *  function to fill the data on every value
   * @param data
   */
  fillData(data: IAccountsResponseDetails): void {
    this.account = data;
    this.subtitle =
      this.account.accountType === "PRE-REC"
        ? "subtitle_automatic_recharge"
        : "subtitle_prepaid";
    this.validateItemsMenu();
  }

  /**
   *  function to return items of menu
   */
  validateItemsMenu(): void {
    this.itemsMenu = [];
    if (this.account?.accountType === "PRE") {
      this.itemsMenu = [
        {
          permission: "CUEMOD",
          text: "item_menu_change_modality",
          image: "assets/images/common/icon-modality.svg",
          url: "/account/select-modality",
          id: 1,
        },
        {
          permission: "CUECONSALB",
          text: "item_menu_change_schedule_low",
          image: "assets/images/common/icon-sale-down.svg",
          url: "/account/low-balance",
          id: 2,
        },
        {
          permission: "CUEDEV",
          text: "item_menu_cash_back",
          image: "assets/images/common/icon-cashback.svg",
          url: "/postpaid/cancelAccount/money-back",
          id: 3,
        },
        {
          permission: "DOCCON",
          text: "item_menu_documents",
          image: "assets/images/common/icon-docs.svg",
          url: "",
          id: 4,
        },
      ];
      return;
    }
    if (this.account?.accountType === "PRE-REC") {
      this.itemsMenu = [
        {
          permission: "CUEMOD",
          text: "item_menu_change_modality",
          image: "assets/images/common/icon-modality.svg",
          url: "/account/select-modality",
          id: 1,
        },
        {
          permission: "DOCCON",
          text: "item_menu_documents",
          image: "assets/images/common/icon-docs.svg",
          url: "",
          id: 2,
        },
      ];
      return;
    }
  }
  /**
   * method to go on of url
   * @param item
   */
  async goLink(item: IMenuItem, exception = false): Promise<void> {
    const automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    this.accountFacade.setAutomaticRecharge({
      ...automaticRechargeData,
      rechargeValue: automaticRechargeData?.rechargeValue ?? null,
      activeRechargeValue: automaticRechargeData?.activeRechargeValue ?? null,
      email: automaticRechargeData?.email ?? null,
      getAllCards: false,
      fromRecharge: false,
    });
    if (this.account?.balance >= 0) {
      if (item.url !== "") {
        // Tagging click event
        pageEvent(
          "click-admin-menu-" +
            item.text
              .toLowerCase()
              .split(" ")
              .join("-"),
          "/account/administration",
          item.text,
        );
        exception
          ? this.accountNavigationService.next(item.id)
          : this.commonsService.navigate(item.url);
      }
    } else {
      this.commonsService.navigate("overdraft-account");
    }
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Select language
   * @readonly
   * @type {Observable<Languages>}
   * @memberof AdminMenuComponent
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccountsResponseDetails>}
   * @memberof AdminMenuComponent
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  /**
   * Select account pages
   * @readonly
   * @type {Observable<IPages>}
   * @memberof AdminMenuComponent
   */
  get selectAccountPages$(): Observable<IPages> {
    return this.accountFacade.selectAccountPages$;
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
