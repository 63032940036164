import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SelectModalityComponent } from "./select-modality.component";

export const SelectModalityRootRoute = "select-modality";
const routes: Routes = [
  {
    component: SelectModalityComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SelectModalityRoutingModule {}
