import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.actions";
import { IVehicle, IVehicleInformation } from "@vehicles/models/vehicle";
import { VehiclesFacade } from "@vehicles/store/facade/vehicles.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { VehicleNavigationService } from "../../services/vehicle-navigation.service";

@Component({
  selector: "fp-app-successful-vehicle-edit",
  templateUrl: "./successful-vehicle-edit.component.html",
  styleUrls: ["./successful-vehicle-edit.component.scss"],
})
export class SuccessfulVehicleEditComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpAppSuccessfulVehicleEdit =
    "fp-app-successful-vehicle-edit";
  destroy$: Subject<boolean> = new Subject<boolean>();
  plate: string;
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private vehicleFacade: VehiclesFacade,
    private vhNavigation: VehicleNavigationService,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
    this.selectVehicleToUpdate$.subscribe((vehicle) => {
      if (vehicle.plate) {
        this.plate = vehicle.plate;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goToVehiclesList(): void {
    this.vhNavigation.next(0);
  }
  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * get data of vehicle Information Selector
   */
  get selectVehicleToUpdate$(): Observable<IVehicle> {
    return this.vehicleFacade.selectVehicleToUpdate$;
  }
}
