import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagFeatureName, TagModuleState } from "../state/tag.state";

export const PseBanksRootSelector = createFeatureSelector<TagModuleState>(
  TagFeatureName,
);

export const selectPseBanks = createSelector(
  PseBanksRootSelector,
  (state: TagModuleState) => state.pseBanksReducer,
);
