import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const SignUpAdministratorRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectSignUpAdministrator = createSelector(
  SignUpAdministratorRootSelector,
  (state: SignUpModuleState) => state.signUpAdministratorReducer,
);
