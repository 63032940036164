<fp-card-body [headerType]="'secondary'" [template]="'secondTemplate'" urlBack="/reports/list" backOption="true"
    close="true">
    <ng-template #tplRef>
        <div class="request-body">
            <h1 class="fp-h1 request-title">{{ 'title' | translate }}</h1>
            <h2 class="fp-body-one request-subtitle">{{ 'subtitle' | translate:{reportLapse:reportLapse} }}</h2>
            <div class="item-info big">
                <img src="assets/images/common/icon-info.svg" alt="info">
                <span>
                    {{ 'reports_outofdaterange' | translate }} 
                    <a class="message-link" href="https://www.facilpass.co" target="_blank">{{ 'reports_mail' | translate }}</a>
                </span>
            </div>
            <div class="request-card">
                <form class="form-request" novalidate [formGroup]="formRequest">
                    <div class="report-select">
                        <div class="select-content">
                            <label class="form-label">Tipo de reporte</label>
                            <select matNativeControl class="select-reports"  
                                [formControl]="formRequest.controls['reportType']" placeholder="Selecciona un reporte">
                                <option value="" selected disabled>Selecciona una entidad</option>
                                <option *ngFor="let report of reportsType" [value]="report.reportType">
                                    {{report.name}}</option>
                            </select>
                            <img src="assets/images/select/icon.svg" alt="down">
                        </div>
                    </div>
                    <ng-container>
                        <div class="datepicker-container">
                            <fp-datepicker [maxDate]="maxDateLimit" [minDate]="minDateLimit"
                                (selectedDate)="selectedDateHandler($event)"></fp-datepicker>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'toll'">
                        <div class="options-container">
                            <div class="select-container">
                                <label class="form-label" for="productSelect">{{ 'month' | translate
                                    }}</label>
                                <mat-form-field appearance="fill">
                                    <mat-select #monthSelect formControlName="month"
                                        (selectionChange)="saveReportMonth($event.value)" panelClass="fp-address-select"
                                        placeholder="{{ 'month' | translate }}">
                                        <mat-option class="option-select" *ngFor="let month of months" [value]="month">
                                            {{ 'month_list.' + month | translate}}
                                        </mat-option>
                                    </mat-select>
                                    <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                                </mat-form-field>
                            </div>
                            <div class="select-container">
                                <label class="form-label" for="productSelect">{{ 'year' | translate
                                    }}</label>
                                <mat-form-field appearance="fill">
                                    <mat-select #yearSelect formControlName="year"
                                        (selectionChange)="saveReportYear($event)" panelClass="fp-address-select"
                                        placeholder="{{ 'year' | translate }}">
                                        <mat-option class="option-select" *ngFor="let year of years" [value]="year">
                                            {{ year }}
                                        </mat-option>
                                    </mat-select>
                                    <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                                </mat-form-field>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container>
                        <div class="radio-button-generic">
                            <label class="form-label">{{ 'report_format' | translate }}</label>
                            <div class="radio-group radio-type-option" id="formatTypes">
                                <div class="conten-radio-generic checkbox" *ngIf="reportTypeFormat.excel">
                                    <button class="input-radio-option"
                                        [class.selected-option]="this.formRequest.controls.reportFormatexcel.value === 'excel'"
                                        type="button" (click)="chooseReportFormat('xlsx')" id="type-{{'excel'}}">
                                        {{ 'report_' + 'excel' | translate }}
                                        <span class="radio-btn-container">
                                            <fp-checkbox formControlName="reportFormatexcel"
                                                checked="this.formRequest.controls.reportFormatexcel.value === 'excel'"
                                                [formControl]="formRequest.controls['reportFormatexcel']" binary="true"
                                                name="checkFormatExcel" inputId="checkFormatExcel">
                                            </fp-checkbox>
                                        </span>
                                    </button>
                                </div>
                                <div class="conten-radio-generic checkbox" *ngIf="reportTypeFormat.pdf">
                                    <button class="input-radio-option"
                                        [class.selected-option]="this.formRequest.controls.reportFormatpdf.value === 'pdf'"
                                        type="button" (click)="chooseReportFormat('pdf')" id="type-{{'pdf'}}">
                                        {{ 'report_' + 'pdf' | translate }}
                                        <span class="radio-btn-container">
                                            <fp-checkbox formControlName="reportFormatpdf"
                                                checked="this.formRequest.controls.reportFormatpdf.value === 'pdf'"
                                                [formControl]="formRequest.controls['reportFormatpdf']" binary="true"
                                                name="checkFormatPdf" inputId="checkFormatPdf">
                                            </fp-checkbox>
                                        </span>
                                    </button>
                                </div>
                                <div class="conten-radio-generic checkbox" *ngIf="reportTypeFormat.text">
                                    <button class="input-radio-option"
                                        [class.selected-option]="this.formRequest.controls.reportFormattext.value === 'text'"
                                        type="button" (click)="chooseReportFormat('text')" id="type-{{'text'}}">
                                        {{ 'report_' + 'text' | translate }}
                                        <span class="radio-btn-container">
                                            <fp-checkbox formControlName="reportFormattext"
                                                checked="this.formRequest.controls.reportFormattext.value === 'text'"
                                                [formControl]="formRequest.controls['reportFormattext']" binary="true"
                                                name="checkFormatText" inputId="checkFormatText">
                                            </fp-checkbox>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
            <div class="form-group-submit">
                <div class="fp-container-btn">
                    <button class="fp-btn-primary submit-btn" [disabled]="!formRequestValidator()"
                    [id]="'btn-request-report-' + reportType"
                        (click)="requestReport()">
                        <span class="btn-content-text" *ngIf="!loadingReport">
                            {{ 'request_report' | translate }}
                        </span>
                        <span class="loader" *ngIf="loadingReport"></span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</fp-card-body>