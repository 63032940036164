<fp-card-body
  [template]="'secondTemplate'"
  [urlBack]="!updating ? '/account/select-modality' : '/account/administration'"
  backOption="true"
  headerType="secondary"
>
  <ng-template #tplRef>
      <div class="container">
          <div class="box">
              <h1 class="fp-h1">{{ 'title_page' | translate }}</h1>
              <fp-alert-message
                [type]="alertsTypes.INFO"
                [title]="'keepInMind'"
                [message]="'theRechargeWillBeActivatedIfYourAccountBalanceIsLessThanTheLowBalanceYouHaveConfigured'"
              ></fp-alert-message>
              <form novalidate [formGroup]="formAutomaticRechargeConfig" (ngSubmit)="setDataFormConfig()">
                  <div class="container-cards">
                    <div class="card-config">
                      <fp-account-info (accountDataEmit)="setAccountData($event)" [accountDataFromParent]="accountData"></fp-account-info>
                    </div>
                    <div class="card-config card-config--content-input">
                      <div class="form-group">
                        <label
                          for="rechargeValue"
                          class="form-group__label"
                        >
                          {{ 'howMuchDoYouWantToRecharge' | translate }}
                          <img
                            src="assets/images/common/blue-question.svg"
                            alt="Icon tooltip"
                            fpTooltip
                            [tooltip]="'itIsTheValueThatWillBeRechargedEveryTimeYourAccountReachesTheBalanceYouDefine'">
                        </label>
                        <div class="form-group-input form-group-input--prefix">
                          <span class="form-group-input__prefix">$</span>
                          <input
                            id="rechargeValue"
                            type="text"
                            class="form-group-input__input form-group-input__input--prefix"
                            formControlName="rechargeValue"
                            [(ngModel)]="rechargeValue"
                            [value]="rechargeValue | formatNumber"
                            (input)="removeLetters('rechargeValue', $event)"
                            [ngClass]="{
                              'form-group-input__input--error':
                                formAutomaticRechargeConfig.get('rechargeValue').invalid &&
                                formAutomaticRechargeConfig.get('rechargeValue').touched
                              }"
                            [readonly]="!accountData || !automaticRechargeData"
                           >
                        </div>
                        <small
                          class="form-group__help-text"
                          *ngIf="
                            !formAutomaticRechargeConfig.get('rechargeValue').errors?.minValue &&
                            !formAutomaticRechargeConfig.get('rechargeValue').errors?.maxValue &&
                            formAutomaticRechargeConfig.get('rechargeValue').value !== '0'"
                        >
                          {{ 'weSuggestThatTheRechargeValueBeEqualToOrGreaterThanYourCompanyWeeklyTollExpenditure' | translate }}
                        </small>
                        <small
                          class="form-group__help-text form-group__help-text--error"
                          *ngIf="formAutomaticRechargeConfig.get('rechargeValue').errors?.maxValue">
                            {{ 'makeSureYourRechargeDoesNotExceed' | translate }} ${{ maxRechargeValue | number }}
                        </small>
                        <small
                          class="form-group__help-text form-group__help-text--error"
                          *ngIf="formAutomaticRechargeConfig.get('rechargeValue').errors?.minValue || formAutomaticRechargeConfig.get('rechargeValue').value === '0'">
                            {{ 'theRechargeMustBeEqualToOrGreaterThan' | translate }} ${{ minRecharge | number }}
                        </small>
                      </div>
                    </div>
                    <div class="card-config card-config--content-input">
                      <div class="form-group">
                        <label
                          for="activeRechargeValue"
                          class="form-group__label"
                        >
                          {{ 'setTheBalanceThatWillActivateYourAutomaticRecharge' | translate }}
                          <img
                            src="assets/images/common/blue-question.svg"
                            alt="Icon tooltip"
                            fpTooltip
                            [tooltip]="'whenYourAccountReachesThisBalanceTheAutomaticRechargeThatYouProgrammedWillTakePlace'">
                        </label>
                        <div class="form-group-input form-group-input--prefix">
                          <span class="form-group-input__prefix">$</span>
                          <input
                            id="activeRechargeValue"
                            type="currency"
                            class="form-group-input__input form-group-input__input--prefix"
                            formControlName="activeRechargeValue"
                            [(ngModel)]="activeRechargeValue"
                            [value]="activeRechargeValue | formatNumber"
                            (input)="removeLetters('activeRechargeValue', $event)"
                            [ngClass]="{
                              'form-group-input__input--error':
                                formAutomaticRechargeConfig.get('activeRechargeValue').invalid &&
                                formAutomaticRechargeConfig.get('activeRechargeValue').touched
                              }"
                            [readonly]="!accountData || !automaticRechargeData"
                          >
                        </div>
                        <small
                          class="form-group__help-text"
                          *ngIf="!formAutomaticRechargeConfig.get('activeRechargeValue').errors?.maxValue">
                            {{ 'weSuggestAValueEqualToOrGreaterThanYourCompanyTwoDayTollExpenditure' | translate }}
                        </small>
                        <small
                          class="form-group__help-text form-group__help-text--error"
                          *ngIf="formAutomaticRechargeConfig.get('activeRechargeValue').errors?.maxValue">
                          {{ 'makeSureYourRechargeDoesNotExceed' | translate }} ${{ maxRechargeValue | number }}
                        </small>
                      </div>
                      <div class="form-group">
                        <label
                          for="rechargeValue"
                          class="form-group__label"
                        >
                          {{ 'theNextRecipientWillReceiveTheNotification' | translate }}
                        </label>
                        <div class="form-group-input">
                          <input id="rechargeValue" type="email" class="form-group-input__input" formControlName="email" readonly>
                        </div>
                      </div>
                    </div>
                  </div>
                  <fp-alert-message
                    [type]="alertsTypes.INFO"
                    [title]="'keepInMind'"
                    [message]="'theRechargeIsGeneratedIfYourAccountBalanceIsLessThanTheBalanceYouSetToActivateYourRecharge'"
                    [backgroundWhite]="true"
                  ></fp-alert-message>
                  <div class="form-group-submit">
                      <div class="fp-container-btn">
                          <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                              [disabled]="!formAutomaticRechargeConfig.valid || sendingData">
                              <span class="btn-content-text" *ngIf="!sendingData">
                                  {{ (updating ? 'btn_save' : 'btn_continue') | translate }}
                              </span>
                              <span
                                class="loader"
                                *ngIf="sendingData">
                              </span>
                          </button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </ng-template>
</fp-card-body>
