import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { IAccount, IAccountsResponseDetails } from "@app/models/accounts";
import { ICustomerInfo } from "@app/models/customer-info";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { environment } from "@environment";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import { OwlOptions } from "ngx-owl-carousel-o";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { IReport } from "../../models/report";
import { ReportService } from "../../services/report.service";
import { RequesReportService } from "../../services/reques-report.service";

@Component({
  selector: "fp-list-report",
  templateUrl: "./list-report.component.html",
  styleUrls: ["./list-report.component.scss"],
})
export class ListReportComponent implements OnInit, OnDestroy {
  accounts: IAccountsResponseDetails[];
  expand: boolean = true;
  loading: boolean = true;
  loadingReport: boolean = false;
  formAccount: UntypedFormGroup;
  totalItemsforAccount: number;
  ipageActive: number = 1;
  pages: number[] = [];
  pagesItems: number = 1;
  filterLimit: number = 10;
  accountSelect: IAccountsResponseDetails;
  reportsList: IReport[] = [];
  reportsPaginator: IReport[] = [];
  reportsState: any = {
    data: [],
    loaded: true,
    loading: false,
    error: false,
  };
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    startPosition: 0,
    stagePadding: 20,
    margin: 10,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
    center: false,
  };
  filters = {
    licensePlate: false,
    numberTag: false,
    infoAditional: false,
    state: false,
    acction: false,
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportId: string;
  REPDES: boolean;
  @ViewChild("alert", { static: true }) tAlert: TemplateRef<any>;
  conversionTable = {
    0: "f",
    1: "T",
    2: "7",
    3: "a",
    4: "R",
    5: "p",
    6: "1",
    7: "y",
    8: "C",
    9: "5",
  };
  urlInvoices: string;

  constructor(
    private appFacade: AppFacade,
    private commonsService: CommonsService,
    private authenticationService: AuthenticationService,
    public permissionService: NgxPermissionsService,
    private snackBar: MatSnackBar,
    private notifyService: NotifyService,
    private reportService: ReportService,
    private requesReportService: RequesReportService,
    private modalService: ModalService,
  ) {}

  async ngOnInit(): Promise<any> {
    await this.getAccounts();
    this.formAccount = new UntypedFormGroup({
      inputSearch: new UntypedFormControl("", [
        Validators.pattern("^[a-zA-Z0-9]{4,10}$"),
      ]),
      account: new UntypedFormControl("", [Validators.required]),
      selectAll: new UntypedFormControl(false, [Validators.requiredTrue]),
      check: new UntypedFormControl(true, [Validators.requiredTrue]),
    });
    await this.setSelectedAccount();
  }

  /**
   * Destroy subscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.snackBar.dismiss();
  }

  async downloadReport(element) {
    let formatReport: string;
    this.loadingReport = true;
    this.permissionService.getPermissions();
    this.REPDES = await this.permissionService.hasPermission("REPDES");
    if (this.REPDES) {
      const report = element.target.id.split("-");
      this.reportId = report[report.length - 1];
      this.reportsPaginator.forEach((reports: any) => {
        if (reports.reportId === this.reportId) {
          formatReport = reports.formatReport;
        }
      });
      this.requesReportService
        .getUrlReport(this.reportId, formatReport.toLowerCase())
        .subscribe(
          (response: any) => {
            this.requesReportService
              .getFileReportByURLSign(response.urlReport)
              .subscribe((responseS3: any) => {
                if (responseS3.message === "Reporte permitido para descargar") {
                  this.requesReportService
                    .downloadFileBlobByURL(responseS3.body, formatReport)
                    .pipe(
                      take(1),
                      finalize(() => {
                        this.loadingReport = false;
                      }),
                    )
                    .subscribe(
                      (responseFile: any) => {
                        this.requesReportService.downloadReport(
                          responseFile,
                          this.reportId,
                          formatReport,
                        );
                      },
                      (error: any) => {
                        this.openAlert();
                        this.loadingReport = false;
                        setTimeout(() => {
                          this.closeAlert();
                        }, 5000);
                        return error.error;
                      },
                    );
                } else {
                  this.openAlert();
                  this.loadingReport = false;
                  setTimeout(() => {
                    this.closeAlert();
                  }, 5000);
                }
              });
          },
          (error: any) => {
            this.openAlert();
            this.loadingReport = false;
            setTimeout(() => {
              this.closeAlert();
            }, 5000);
            return error.error;
          },
        );
    } else {
      this.openAlert();
      this.loadingReport = false;
      setTimeout(() => {
        this.closeAlert();
      }, 5000);
    }
  }

  openAlert(): void {
    this.notifyService.showNotify(this.tAlert, "", null);
  }
  closeAlert() {
    this.snackBar.dismiss();
  }

  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();
    if (promiseAccounts?.data === null) {
      this.appFacade.getAccounts(
        await this.authenticationService.getUsername(),
      );
      this.selectAccounts2$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IAccount) => {
          if (action && action?.data !== null && action.data?.length > 0) {
            this.accounts = action.data.filter(
              (x) => x.accountType && x.statusAccount === "Activo",
            );
          }
          if (action && action.error) {
            this.commonsService.navigate("error");
          }
        });
    } else {
      this.accounts = promiseAccounts?.data.filter((x) => x.accountType);
    }
  }

  async getReports(): Promise<any> {
    const accountId = this.accountSelect?.accountId;
    this.reportsList = [];
    this.reportsPaginator = [];
    let reportFilter = [];
    try {
      this.reportService.getReports(accountId).subscribe(
        (reports) => {
          reportFilter = reports.body.filter(
            (accountReport) => accountReport.accountId === accountId,
          );
          this.reportsList = reportFilter;
          this.reportsPaginator = this.reportsList;
          this.totalItemsforAccount = this.reportsList?.length;
          this.loading = false;
          this.reportsState.error = false;
        },
        (error) => {
          this.loading = false;
          this.reportsState.error = true;
        },
      );
    } catch (error) {
      this.commonsService.navigate("error");
    }
  }

  chooseAccount(data: {
    account: IAccountsResponseDetails;
    flag: boolean;
  }): void {
    this.formAccount.controls.account.setValue(data.account?.accountId);
    this.appFacade.setAccountSelected(data.account);
    this.accountSelect = { ...data.account };
    this.getReports();
    // if (!data.flag) {
    //   this.changeFilterLimit(10);
    // }
  }

  async setSelectedAccount(): Promise<any> {
    this.reportsPaginator = [];
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();
    if (promiseAccountSelected) {
      const data = {
        account: promiseAccountSelected,
        flag: true,
      };
      this.chooseAccount(data);
    } else if (this.accounts?.length > 0) {
      const data = {
        account: this.accounts[0],
        flag: true,
      };
      this.chooseAccount(data);
    }
  }

  goToRequestReport() {
    this.commonsService.navigate("/reports/request");
  }

  getEncryptionData(data: string): string {
    if (data.length == 0) return "";

    let dataEncryption = "";
    for (let value of data) {
      dataEncryption += this.conversionTable[value];
    }

    return dataEncryption;
  }

  calculateSHA384Hash(text: string) {
    const hash = CryptoJS.SHA384(text);
    return hash.toString(CryptoJS.enc.Hex);
  }

  /**
   * Open modal
   * @param id string id elemnt Html
   */
  openModal(id: string): void {
    this.modalService.open(id);
  }

  async goToInvoices(): Promise<any> {
    let accountId;
    let nit;
    let unix = moment().unix();
    let scr = environment.invoicesKey;
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IAccountsResponseDetails) => {
        accountId = data.accountId;
      });
    const customerInfo = await this.selectCustomerInfo$
      .pipe(take(1))
      .toPromise();
    nit = customerInfo.data.idNumber;
    const idEncryption = this.getEncryptionData(accountId);
    const trkEncryption = this.getEncryptionData(nit);
    const nxEncryption = this.getEncryptionData(String(unix));
    const thash = `LNK-${accountId}-TAS-${nit}-COL-${unix}-US-${scr}`;

    const tHashEncrypt = this.calculateSHA384Hash(thash);
    this.urlInvoices = `https://facilpass.tas-la.com/getin.php?id=${idEncryption}&trk=${trkEncryption}&nx=${nxEncryption}&thash=${tHashEncrypt}`;

    this.openModal("fp-invoice-modal");
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectAccounts2$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
  /**
   * get selectedAccount from selector
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }
}
