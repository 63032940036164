<div [ngStyle]="style" [class.big]="chkboxBig" [ngClass]="{'ui-chkbox ui-widget': true,'ui-chkbox-readonly': readonly}"
    [class]="styleClass">
    <div class="ui-helper-hidden-accessible">
        <input #cb type="checkbox" [attr.id]="inputId" [attr.name]="name" [readonly]="readonly" [value]="value"
            [checked]="checked" (focus)="onFocus()" (blur)="onBlur()" [ngClass]="{'ui-state-focus':focused}"
            (change)="handleChange($event)" [disabled]="disabled" [attr.tabindex]="tabindex"
            [attr.aria-labelledby]="ariaLabelledBy" [attr.required]="required">
    </div>
    <div [class.big]="chkboxBig" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
        (click)="onClick($event,cb,true)"
        [ngClass]="{'ui-state-active':checked,'ui-state-disabled':disabled,'ui-state-focus':focused}" role="checkbox"
        [attr.aria-checked]="checked" [attr.id]="chkboxId">
        <span class="ui-chkbox-icon ui-clickable" [ngClass]="checked ? checkboxIcon : null"></span>
    </div>
</div>
<label (click)="onClick($event,cb,true)" [class]="labelStyleClass"
    [ngClass]="{'ui-chkbox-label': true, 'ui-label-active':checked, 'ui-label-disabled':disabled, 'ui-label-focus':focused}"
    *ngIf="label" [attr.for]="inputId">{{label}}</label>