import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountFeatureName, AccountModuleState } from "../state/account.state";

export const LowBalancePagesRootSelector = createFeatureSelector<
  AccountModuleState
>(AccountFeatureName);

export const selectLowBalancePages = createSelector(
  LowBalancePagesRootSelector,
  (state: AccountModuleState) => state.lowBalancePagesReducer,
);
