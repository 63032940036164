import { CurrencyPipe } from "@angular/common";
import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";

@Directive({ selector: "[fpValidatorRegExp]" })
export class ValidatorRegExpDirective {
  previousValue: string = "";

  // --------------------------------------
  //  Regular expressions
  @Input()
  regexp: string = "^[0-9]*$";

  @Input()
  matchRegexp: string;

  @Input()
  matchOnInput: boolean = true;

  @Input()
  matchOnBlur: boolean = true;

  @Input()
  formControl: UntypedFormControl = new UntypedFormControl("", [
    Validators.required,
  ]);

  @Input()
  currencyMode: boolean = false;

  /**
   * Class constructor
   * @param hostElement
   */
  constructor(
    private hostElement: ElementRef,
    private currencyPipe: CurrencyPipe,
  ) {}

  /**
   * Event handler for host's change event
   * @param e
   */
  @HostListener("change", ["$event"]) onChange(e: Event): void {
    const nativeValue = "value";

    if (this.currencyMode) {
      const inputVal = this.hostElement.nativeElement[nativeValue];
      let cleanVal = inputVal.trim().split("$");
      if (cleanVal[1]) {
        cleanVal = cleanVal[1].replace(/\./g, "");
        this.formControl.setValue(
          "$ " + this.currencyPipe.transform(cleanVal, "COP", "", "1.0-0"),
        );
      }
      return;
    }
    const value = this.matchValue(this.formControl.value);
    this.formControl.setValue(value);
    this.hostElement.nativeElement[nativeValue] = value;
  }

  /**
   * Event handler for host's change event
   * @param e
   */
  @HostListener("blur", ["$event"]) onBlur(e: Event): void {
    let value = this.formControl.value || this.hostElement.nativeElement.value;
    const valid: boolean = new RegExp(this.regexp).test(value);
    if ((this.formControl.invalid && !valid) || !this.formControl.value) {
      if (this.matchOnBlur) {
        value = this.matchValue(value);
      }
      this.formControl.setValue(value);
      const nativeValue = "value";
      this.hostElement.nativeElement[nativeValue] = value;
    }
  }

  /**
   * Event handler for host's paste event
   * @param e
   */
  @HostListener("paste", ["$event"]) onPaste(e: ClipboardEvent): void {
    // get and validate data from clipboard
    const value = e.clipboardData.getData("text/plain");
    const isValidValue: boolean = new RegExp(this.regexp).test(value);
    if (!isValidValue) {
      e.preventDefault();
    } else {
      if (this.currencyMode) {
        e.preventDefault();
        this.formControl.setValue(
          "$ " + this.currencyPipe.transform(value, "COP", "", "1.0-0"),
        );
      }
    }
  }

  /**
   * Event handler for host's paste event
   * @param e
   */
  @HostListener("input", ["$event"]) onInput(e: InputEvent): void {
    // get and validate data from clipboard
    if (e.data) {
      const nativeValue = "value";
      let inputVal =
        this.hostElement.nativeElement[nativeValue] || this.formControl.value;
      if (this.matchOnInput) {
        inputVal = this.matchValue(inputVal);
      }
      const maxlength = this.hostElement.nativeElement.maxLength;
      if (maxlength && maxlength > 0 && maxlength <= inputVal.length) {
        e.preventDefault();
      } else {
        this.hostElement.nativeElement[nativeValue] = inputVal;

        if (this.currencyMode) {
          let cleanVal = inputVal.trim().split("$");
          if (cleanVal.length > 1) {
            cleanVal = cleanVal[1].replace(/\./g, "");
            if (cleanVal !== "" && !isNaN(Number(cleanVal))) {
              this.formControl.setValue(
                "$ " +
                  this.currencyPipe.transform(cleanVal, "COP", "", "1.0-0"),
              );
            }
          } else {
            if (cleanVal[0] !== "" && !isNaN(Number(cleanVal[0]))) {
              this.formControl.setValue(
                "$ " +
                  this.currencyPipe.transform(cleanVal[0], "COP", "", "1.0-0"),
              );
            }
          }
        } else {
          this.formControl.setValue(inputVal);
        }
      }
    }
  }

  /**
   * Event handler for host's keydown event
   * @param event
   */
  @HostListener("keydown", ["$event"]) onKeyDown(e: KeyboardEvent): void {
    const numberValue = "value";
    const originalValue: string = e.target[numberValue];
    const key: string = e.key;
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;

    // allowed keys apart from numeric characters
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Escape",
      "Tab",
    ];

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === "a" && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === "c" && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === "v" && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === "x" && controlOrCommand)
    ) {
      // const it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow characters only
    const isValidKey = new RegExp(this.regexp).test(key);
    if (isValidKey) {
      const value = this.formControl.value + key;
      const maxlength = this.hostElement.nativeElement.maxLength;
      if (!maxlength || maxlength <= 0 || maxlength >= value.length) {
        const isValidValue: boolean = new RegExp(this.regexp).test(value);
        if (!isValidValue) {
          e.preventDefault();
        }
      }
      return;
    } else {
      e.preventDefault();
    }
  }

  /**
   * Test whether value is a valid match
   * @param value
   */
  matchValue(value: string): string {
    if (this.matchRegexp) {
      const match = value.match(new RegExp(this.matchRegexp, "g")) || [];
      return match.join("");
    }
    return value;
  }
}
