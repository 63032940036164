/* eslint-disable @angular-eslint/no-output-on-prefix */
import { AccountRequestsService } from "@account/services/account-requests/account-requests.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IAccountsResponseDetails } from "@app/models/accounts";

@Component({
  selector: "fp-account-card",
  templateUrl: "./account-card.component.html",
  styleUrls: ["./account-card.component.scss"],
})
export class AccountCardComponent {
  @Input() account: IAccountsResponseDetails;
  @Input() status: string;
  @Input() expand: boolean;
  @Input() small: boolean = false;
  @Input() select: boolean;
  @Input() formAccount: UntypedFormGroup;
  @Input() currentStatus: string = "";
  @Input() template: "templateOne" | "templateTwo" = "templateOne";
  @Output() onClickItem = new EventEmitter<{
    account: IAccountsResponseDetails;
    flag: boolean;
  }>();
  constructor(private accountRequestsService: AccountRequestsService) {}

  chooseAccount(account: IAccountsResponseDetails, flag: boolean) {
    this.onClickItem.emit({ account, flag });
    this.sendAccountData(account);
  }

  sendAccountData(dataAccount: IAccountsResponseDetails) {
    this.accountRequestsService.changeData(dataAccount);
  }
}
