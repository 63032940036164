import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  VehiclesFeatureName,
  VehiclesModuleState,
} from "../state/vehicles.state";

export const VehiclesCategoriesRootSelector = createFeatureSelector<
  VehiclesModuleState
>(VehiclesFeatureName);

export const selectVehiclesCategories = createSelector(
  VehiclesCategoriesRootSelector,
  (state: VehiclesModuleState) => state.vehiclesCategoriesReducer,
);

export const selectVehiclesInformation = createSelector(
  VehiclesCategoriesRootSelector,
  (state: VehiclesModuleState) => state.vehicleInformationReducer,
);
