import { createAction } from "@ngrx/store";

export const enum TypeActionsFinancialProducts {
  financialProductsLoadString = "[financialProducts] Loading data",
  financialProductsSaveString = "[financialProducts] Successful data loaded",
  financialProductsErrorString = "[financialProducts] Save error response",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const financialProductsLoad = createAction(
  TypeActionsFinancialProducts.financialProductsLoadString,
  // (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const financialProductsSave = createAction(
  TypeActionsFinancialProducts.financialProductsSaveString,
  (data: any) => ({ data }),
);
/**
 * Action to set data error
 */
export const financialProductsError = createAction(
  TypeActionsFinancialProducts.financialProductsErrorString,
  (error: any) => ({ error }),
);
