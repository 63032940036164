import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { CoreModule } from "@core/core.module";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { InputModule } from "../input/input.module";
import { AddressComponent } from "./address.component";

@NgModule({
  declarations: [AddressComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    CoreModule,
    MatSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateService,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [AddressComponent],
})
export class AddressModule {}
