<fp-modal class="fp-payment-method" (actionModal)="modalAction($event)" typeModal="customNotification"
    [hideClose]="'false'" [id]="'fp-modal-payment-confirm-pos'" [btnAccept]="'modal.continue' | translate"
    [btnExit]="'modal.cancel' | translate" [showConfirm]="true">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-payment-method-icon"
                src="assets/images/postpaid/payment-method/icon-question.svg">
        </div>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">{{title | translate}}</h1>
            <div class="info-bubble">
                <div class="info-bubble-icon"></div>
                <span class="info-bubble-text">{{infoBubble | translate}}</span>
            </div>
            <ul *ngIf="!accountType" class="fp-payment-method-message-list">
                <li class="fp-payment-method-message">{{'modal.text_confirm_pos_1' | translate}}</li>
                <li class="fp-payment-method-message">{{'modal.text_confirm_pos_2' | translate}}</li>
            </ul>
            <ol *ngIf="accountType" class="fp-payment-method-message-list">
                <li class="fp-payment-method-message">{{'modal.text_1' | translate}}</li>
                <li class="fp-payment-method-message">{{'modal.text_2' | translate}}</li>
                <li class="fp-payment-method-message">{{'modal.text_3' | translate}}</li>
            </ol>
        </div>
    </ng-template>
</fp-modal>

<fp-modal class="fp-payment-method" (actionModal)="modalAction($event)" typeModal="customNotification"
  [id]="'fp-modal-payment-confirm-pre'" [btnAccept]="'modal.understood' | translate" [showConfirm]="true">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-payment-method-icon"
                src="assets/images/postpaid/payment-method/icon-question.svg">
        </div>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title prepaid">{{title | translate}}</h1>
            <ul class="fp-payment-method-message">
              <li>Al realizar este cambio, el servicio podría presentar <strong>intermitencias en las siguientes 6 horas</strong> después de efectuar la proxima recarga.</li>
              <li>Realice su recarga a través de PSE o con su tarjeta de crédito preferida en Aval Pay Center.</li>
            </ul>
        </div>
    </ng-template>
</fp-modal>

<fp-modal class="fp-payment-method" (actionModal)="modalAction($event)" typeModal="customNotification"
    [id]="'fp-modal-payment-confirm-aut'" [btnAccept]="'modal.understood' | translate"
    [showConfirm]="true">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-payment-method-icon"
                src="assets/images/postpaid/payment-method/icon-question.svg">
        </div>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title prepaid">{{ title | translate }}</h1>
            <ol class="fp-payment-method-message fp-payment-method-message--numbered-list">
              <li class="fp-payment-method-message__item-list">
                {{ 'modal.text_confirm_aut_1' | translate }}
              </li>
              <li class="fp-payment-method-message__item-list">
                {{ 'modal.text_confirm_aut_2' | translate }}
              </li>
              <li class="fp-payment-method-message__item-list">
                {{ 'modal.text_confirm_aut_3' | translate }}
              </li>
            </ol>
        </div>
    </ng-template>
</fp-modal>

<fp-modal class="fp-payment-method" (actionModal)="modalAction($event)" typeModal="customNotification"
    [id]="'fp-modal-confirm-add-payment-method'" [btnAccept]="'understood' | translate"
    [showConfirm]="true" [btnCustom]="'questionsAboutGOU' | translate">
    <ng-template>
        <div class="modal-icon">
            <img alt="icon confirm modal" class="fp-payment-method-icon"
                src="assets/images/common/credit-card-icon.svg">
        </div>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title prepaid">{{ title | translate }}</h1>
            <ul class="fp-payment-method-message fp-payment-method-message--numbered-list">
              <li class="fp-payment-method-message__item-list">
                {{ 'rechargeWithACreditOrDebitCardWithVerificationCode' | translate }}
                {{ 'youWillBeRedirectedToTheGOUPaymentGateway' | translate }}
              </li>
            </ul>
            <img src="assets/images/common/logo-gou-aval.svg" alt="Logo GOU Aval" class="modal-content__image">
        </div>
    </ng-template>
</fp-modal>

<fp-modal class="fp-payment-method" (actionModal)="modalAction($event)" typeModal="customNotification"
    [id]="'fp-modal-confirm-exit-facilpass'" [btnAccept]="'continuar' | translate | titlecase"
    [showConfirm]="true" [btnCustom]="'Dudas sobre GOU' | translate">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title prepaid">{{ 'Está saliendo de facilpass.com' | translate }}</h1>
            <p class="fp-payment-method-message fp-payment-method-message--padding">
              {{ 'Será redirigido a nuestra pasarela de pago para recargar su cuenta de FacilPass.' | translate }}
            </p>
            <p class="fp-payment-method-message fp-payment-method-message--padding">
              {{ 'Pasarela de pago' | translate }}
            </p>
            <img src="assets/images/common/logo-gou-aval.svg" alt="Logo GOU Aval" class="modal-content__image">
        </div>
    </ng-template>
</fp-modal>

<fp-modal
    class="fp-payment-method"
    (actionModal)="modalAction($event)" typeModal="customNotification"
    [id]="'fp-modal-confirm-change-payment-method'"
    [btnAccept]="'yesChange' | translate"
    [btnExit]="'btn_cancel' | translate"
    [showConfirm]="true"
    *ngIf="accountId">
    <ng-template>
        <div class="modal-content">
          <h1 class="fp-h1 fp-payment-method-title fp-payment-method-title--align-left">
            {{ 'thePaymentMethodIsChanging' | translate }}
          </h1>
          <p class="fp-payment-method-message">
            {{ 'automaticRechargesWillNowBeChargedToThisPaymentMethod' | translate: { accountId: accountIdMasked } }}
          </p>
          <p class="fp-payment-method-message">
            <ng-content select=".card-selected-info"></ng-content>
          </p>
        </div>
    </ng-template>
</fp-modal>

<fp-modal
    class="fp-payment-method"
    (actionModal)="modalAction($event)" typeModal="customNotification"
    [id]="'fp-modal-delete-payment-method'"
    [btnAccept]="(!accountIdIsArray ? 'goToManageMyAccount' : 'goToStart') | translate: { accountId: accountIdMasked }"
    [btnExit]="'btn_cancel' | translate"
    [showConfirm]="true"
    *ngIf="accountId">
    <ng-template>
      <div class="modal-icon">
        <img alt="icon confirm modal" class="fp-payment-method-icon" src="assets/images/common/warning-icon.svg">
      </div>
      <div class="modal-content">
        <h1 class="fp-h1 fp-payment-method-title fp-payment-method-title--align-left">¡{{ 'careful' | translate }}!</h1>
        <p class="fp-payment-method-message" *ngIf="!accountIdIsArray">
          <span class="fp-payment-method-message fp-payment-method-message--feature">
            {{ 'youAreDeletingPaymentMethodForAccount' | translate: { accountId: accountIdMasked } }}.
          </span>
          {{ 'toDeleteYourCardYouCan' | translate }}.
        </p>
        <p class="fp-payment-method-message fp-payment-method-message--feature" *ngIf="accountIdIsArray">
          {{ 'youAreRemovingThePaymentMethodForTheFollowingAccounts' | translate }}.
        </p>
        <ng-container *ngIf="accountIdIsArray">
          <p class="fp-payment-method-message fp-payment-method-message--feature" *ngFor="let account of accountIdMaskedArray">
            ***{{ account }} {{ 'withAutomaticRecharge' | translate }}.
          </p>
        </ng-container>
        <p class="fp-payment-method-message" *ngIf="accountIdIsArray">
          {{ 'toRemoveYourCardFromTheAccountOrEachOfYourAccountsYouCan' | translate }}.
        </p>
        <p class="fp-payment-method-message fp-payment-method-message--feature">1. &nbsp;{{ 'addOrChangePaymentMethod' | translate }}.</p>
        <p class="fp-payment-method-message fp-payment-method-message--feature">2. {{ 'changeTheModeToPrepaidRecharge' | translate }}.</p>
      </div>
    </ng-template>
</fp-modal>
