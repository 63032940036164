import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IModules } from "@app/models/modules";
import { IPages } from "@app/models/pages";
import { CommonsService } from "@shared/services/commons.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { SignInNavigationService } from "../services/signin-navigation.service";
import { SignInFacade } from "../store/facade/signin.facade";
@Injectable({
  providedIn: "root",
})
export class SignInGuard implements CanActivate {
  constructor(
    private signInFacade: SignInFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private signInNavigationService: SignInNavigationService,
  ) {}
  /**
   * CanActivate
   * @param route ActivatedRouteSnapshot
   * @param state State route of path to try navigate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await this.clearData();
    const info = await this.selectSingInPages$.pipe(take(1)).toPromise();
    const validate = await this.commonsService.verifyPagesSignProcess(
      info,
      state.url.split("?")[0],
      "signin",
    );
    return validate;
  }
  /**
   * Clear Vehicle Data
   */
  async clearData(): Promise<any> {
    const modules: IModules = JSON.parse(
      JSON.stringify(await this.selectModules$.pipe(take(1)).toPromise()),
    );
    if (modules.signin === true) {
      modules.signin = false;
      this.signInNavigationService.clearSignInStore();
      this.appFacade.setModules(modules);
    }
  }

  /**
   * Get data to facade store communication
   */
  get selectSingInPages$(): Observable<IPages> {
    return this.signInFacade.selectSignInPages$;
  }
  /**
   * Get Modules Information
   */
  get selectModules$(): Observable<IModules> {
    return this.appFacade.selectModules$;
  }
}
