import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeCompleteSignUpPagesActions {
  completeSignUpPageString = "[COMPLETE SIGNUP] set pages",
}

/**
 * Action to set data page
 */
export const CompleteSignUpSetPage = createAction(
  TypeCompleteSignUpPagesActions.completeSignUpPageString,
  (pages: IPage[]) => ({ pages }),
);
