import { createAction } from "@ngrx/store";
export const enum CustomerInfoTypeActions {
  customerInfoLoadString = "[Customer Info] Loading data",
  customerInfoSaveString = "[Customer Info] Successful data loaded",
  customerInfoErrorString = "[Customer Info] Save error response",
  customerInfoResetString = "[Customer Info] Reset State",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const customerInfoLoad = createAction(
  CustomerInfoTypeActions.customerInfoLoadString,
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const customerInfoSave = createAction(
  CustomerInfoTypeActions.customerInfoSaveString,
  (data: any) => ({ data }),
);
/**
 * Action to set data error
 */
export const customerInfoError = createAction(
  CustomerInfoTypeActions.customerInfoErrorString,
  (error: any) => ({ error }),
);

export const customerInfoReset = createAction(
  CustomerInfoTypeActions.customerInfoResetString,
);
