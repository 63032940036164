import { createAction } from "@ngrx/store";
import { IVehiclesCategoriesResponse } from "@vehicles/models/vehicle";

export const enum TypeActions {
  vehiclesCategoriesLoadString = "[VEHICLES CATEGORIES] Loading data",
  vehiclesCategoriesSaveString = "[VEHICLES CATEGORIES] Successful data loaded",
  vehiclesCategoriesErrorString = "[VEHICLES CATEGORIES] Save error response",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const vehiclesCategoriesLoad = createAction(
  TypeActions.vehiclesCategoriesLoadString,
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const vehiclesCategoriesSave = createAction(
  TypeActions.vehiclesCategoriesSaveString,
  (data: IVehiclesCategoriesResponse[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const vehiclesCategoriesError = createAction(
  TypeActions.vehiclesCategoriesErrorString,
  (error: any) => ({ error }),
);
