/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addRequestInterceptor,
  addResponseInterceptor,
} from "@app/modules/account/services/interceptors/Interceptor";
import { Logger } from "@app/utils/logger";
import { Cipher } from "@avaldigitallabs/adl-commons-lib-e2e-frontend-cypher";
import { environment } from "@environment";
import axios from "axios";

/** URL API Commons */
const urlApiCommons = `${environment.api_key.encryptedRequest.back_url}${environment.endpoints.encryptedRequest.init}`;

/** Timeout connection */
const CONNECT_TIMEOUT = 10000;

/** Axios client */
const client = axios.create({ baseURL: "", timeout: CONNECT_TIMEOUT });

/** Http error interface */
interface IHttpError {
  response: { data: { message: string; errorCode?: number }; status: number };
}

/** Consume service options interface */
interface IConsumeServiceOptions {
  url: string;
  method?: string;
  body?;
  headers?;
  readTimeout?: number;
  extraConfig?;
  encrypt?: boolean;
}

/** Message error interface */
interface IMessageError {
  message: string;
}

/** Object error interface */
interface IObjectError {
  response?: {
    data?: IMessageError[] | string;
    status?: number;
  };
}

/**
 * Consumes a service
 * @param param0
 */
const consumeService = async ({
  url,
  method = "POST",
  body = undefined,
  headers = null,
  readTimeout = 35000,
  extraConfig = {},
}: IConsumeServiceOptions): Promise<{ status; data }> => {
  await addRequestInterceptor(client);
  addResponseInterceptor(client);
  let result: { status; data };
  Logger.debug("sending", method, url, body);
  let cipher: Cipher;
  let data: any;
  try {
    data = JSON.parse(sessionStorage.getItem("publicKey"));
    if (!data) {
      const bodyKey = { timestamp: Date.now() };
      Logger.debug(
        "sending",
        "POST",
        `${urlApiCommons}${environment.endpoints.encryptedRequest.specific.publicKey}`,
        bodyKey,
      );
      data = await client.post(
        `${urlApiCommons}${environment.endpoints.encryptedRequest.specific.publicKey}`,
        bodyKey,
      );
      data = data.data;
      Logger.debug(
        "received",
        "POST",
        `${urlApiCommons}${environment.endpoints.encryptedRequest.specific.publicKey}`,
        data,
      );
      sessionStorage.setItem("publicKey", JSON.stringify(data));
    }
    if (data?.config?.encryption) {
      cipher = new Cipher(data.publicKey, data.timestamp);
      body = cipher.encryptedObjectToSend(body);
    }

    if (!headers) {
      headers = {};
    }
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
      headers["Accept"] = "application/json";
    }
    if (method == "POST") {
      result = await client.post(url, body, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else if (method == "GET") {
      result = await client.get(url, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else if (method == "PUT") {
      result = await client.put(url, body, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else if (method == "DELETE") {
      result = await client.delete(url, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else if (method == "PATCH") {
      result = await client.patch(url, body, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else if (method == "OPTIONS") {
      result = await client.options(url, {
        ...extraConfig,
        headers,
        timeout: readTimeout,
      });
    } else {
      throw new Error(`Método ${method} no soportado`);
    }

    if (data?.config?.encryption && result.data.data) {
      const txt = cipher.decryptMessage(result.data.data);
      if (typeof txt == "string") {
        result.data = JSON.parse(txt);
      } else {
        throw new Error(JSON.stringify(txt, null, 2));
      }
    }

    Logger.debug("received", method, url, result.data);
    return result;
  } catch (error) {
    if (data.config.encryption && error.response?.data && cipher) {
      const txt = cipher.decryptMessage(error.response.data.data);
      if (typeof txt == "string") {
        const data = JSON.parse(txt);
        const errorNew: any = new Error("");
        errorNew.response = error.response;
        errorNew.response.data = data;
        Logger.debug("received Error", {
          status: errorNew.response.status,
          data: errorNew.response.data,
        });
        throw errorNew;
      } else {
        Logger.debug("received Error!");
        throw new Error(JSON.stringify(txt, null, 2));
      }
    }
    Logger.debug("received Error!");
    throw error;
  }
};

/**
 * Error
 * @param errorMessage IHttpError
 */
const error = (errorMessage: IHttpError): void => {
  Logger.error(errorMessage);
  const messagesDefault =
    "No es posible realizar la operación en este momento. Por favor intenta más tarde";
  if (errorMessage.response) {
    Logger.debug("error", {
      status: errorMessage.response.status,
      data: errorMessage.response.data,
    });
    if (errorMessage.response.status === 504) {
      throw new Error(messagesDefault);
    }
    const error = new Error(errorMessage.response.data.message);
    if (errorMessage.response.data.errorCode) {
      (error as any).code = errorMessage.response.data.errorCode;
    }
    throw error;
  }
  throw new Error(messagesDefault);
};

/**
 * Get message array
 * @param messages IMessageError[]
 * @returns string[]
 */
const getMessageArray = (messages: IMessageError[] | string): string[] => {
  if (Array.isArray(messages)) {
    return messages.map((m) => m.message);
  }
  return [messages];
};

/**
 * Parse error object
 * @param errorMessage IObjectError
 * @param messageDefault string
 */
const parseErrorObject = (
  errorMessage: IObjectError,
  messageDefault: string,
): void => {
  if (errorMessage.response) {
    if (errorMessage.response.status === 504) {
      throw JSON.stringify([messageDefault]);
    }
    const responseError = errorMessage.response.data
      ? getMessageArray(errorMessage.response.data)
      : [errorMessage.response.data];
    throw JSON.stringify(responseError);
  }
  throw JSON.stringify([messageDefault]);
};

/**
 * Wait
 * @param millis number
 */
export const wait = async (millis: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, millis));
};

/** Http exports */
export const Http = { consumeService, error, parseErrorObject };
