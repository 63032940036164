import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import { IProductToAssociate } from "../models/financial-product";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class FinancialProductsService {
  constructor(private http: HttpClient) {}
  /**
   * get available Entities and Financial Products
   * @param productToAssociate
   */
  postAssociateProduct(
    productToAssociate: IProductToAssociate,
  ): Observable<any> {
    return this.http.post(
      environment.api_key.publicPN.back_url +
        environment.endpoints.account.init +
        environment.endpoints.account.specific.assignModality,
      productToAssociate,
      {
        params: new CustomHttpParams("api-pn-public-apiKey-token-noctype"),
      },
    );
  }

  getAssociateProduct(executionId: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.account.init +
        environment.endpoints.account.specific.assignModality +
        "?execution_id=" +
        executionId,
      {
        params: new CustomHttpParams("api-pn-public-apiKey-token-noctype"),
      },
    );
  }

  postChangeModality(productToAssociate: IProductToAssociate): Observable<any> {
    return this.http.post(
      environment.api_key.publicPN.back_url +
        environment.endpoints.account.init +
        environment.endpoints.account.specific.changeModality,
      productToAssociate,
      {
        params: new CustomHttpParams("api-pn-public-apiKey-token-noctype"),
      },
    );
  }

  getChangeModality(executionId: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.account.init +
        environment.endpoints.account.specific.changeModality +
        "?execution_id=" +
        executionId,
      {
        params: new CustomHttpParams("api-pn-public-apiKey-token-noctype"),
      },
    );
  }
}
