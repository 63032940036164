import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { completeSignUpPagesConst } from "@core/constants/pages.constant";
import { CompleteSingUpFacade } from "../store/facade/complete-signup.facade";
import {
  initialStateCompleteSignupOtpCodeTimer,
  initialStateCompleteSignupTemporalPassword,
} from "../store/state/complete-signup.state";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CompleteSignUpNavigationService {
  constructor(
    private router: Router,
    private completeSignUpFacade: CompleteSingUpFacade,
  ) {}

  typeInternalUser: false;

  /**
   *
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = completeSignUpPagesConst[position];
    let savedPages: IPage[];
    savedPages = completeSignUpPagesConst.slice(0, position + 1);
    if (position >= 2) {
      savedPages = [page];
    }
    this.completeSignUpFacade.setCompleteSignUpPages(savedPages);
    this.router.navigate([`/complete-signup/${page.active}`]);
  }
  /**
   * Navigate to error
   */
  navigatetoError(): void {
    this.router.navigate(["/error"]);
  }
  /**
   * Clear Complete SignUp
   */
  clearCompleteSignUpStore(): void {
    this.completeSignUpFacade.resetsetUserInformation();
    this.completeSignUpFacade.setCompleteSignUpPages([
      completeSignUpPagesConst[0],
    ]);
    this.completeSignUpFacade.setTemporalPassword(
      initialStateCompleteSignupTemporalPassword,
    );
    this.completeSignUpFacade.setOtpCodeTimer(
      initialStateCompleteSignupOtpCodeTimer,
    );
  }

  async setType(type) {
    this.typeInternalUser = type;
  }

  getType(): boolean {
    return this.typeInternalUser;
  }
}
