import { createAction } from "@ngrx/store";
import { IDataTermsandConditionsResponse } from "@signup/models/termsand-conditions";

export const enum TypeTagTermsandConditionsActions {
  tagTermsandConditionsLoadString = "[TAG TERMS AND CONDITIONS] Loading data",
  tagTermsandConditionsSaveString = "[TAG TERMS AND CONDITIONS] Successful data loaded",
  tagTermsandConditionsErrorString = "[TAG TERMS AND CONDITIONS] Save error response",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const tagTermsandConditionsLoad = createAction(
  TypeTagTermsandConditionsActions.tagTermsandConditionsLoadString,
);
/**
 * Action to detect event loaded terms and conditions data
 */
export const tagTermsandConditionsSave = createAction(
  TypeTagTermsandConditionsActions.tagTermsandConditionsSaveString,
  (data: IDataTermsandConditionsResponse[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const tagTermsandConditionsError = createAction(
  TypeTagTermsandConditionsActions.tagTermsandConditionsErrorString,
  (error: any) => ({ error }),
);
