import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "./tooltip.component";
import { TooltipDirective } from "./tooltip.directive";

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule, TranslateModule],
  exports: [TooltipDirective],
})
export class TooltipModule {}
