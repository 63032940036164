import { createReducer, on } from "@ngrx/store";
import * as citiesActions from "../actions/cities.actions";
import { initialCitiesReducer } from "../state/cities.state";

export const citiesReducer = createReducer(
  initialCitiesReducer,
  on(citiesActions.citiesLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(citiesActions.citiesSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(citiesActions.citiesError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
);
