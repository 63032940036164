import { IAccount } from "@app/models/accounts";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { accountsFeatureName } from "../state/app.state";

export const AccountsRootSelector = createFeatureSelector<IAccount>(
  accountsFeatureName,
);

export const selectAccounts = createSelector(
  AccountsRootSelector,
  (state: IAccount) => state,
);
