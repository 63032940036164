import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxPermissionsModule } from "ngx-permissions";
import { InfoMessageModule } from "../info-message/info-message.module";
import { RadioButtonModule } from "../radiobutton/radiobutton.module";
import { TooltipModule } from "../tooltip/tooltip.module";
import { AccountCardComponent } from "./account-card.component";

export function childLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "assets/i18n/account/account-card/",
    ".json",
  );
}

@NgModule({
  declarations: [AccountCardComponent],
  imports: [
    CommonModule,
    InfoMessageModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    TooltipModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: childLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [AccountCardComponent],
})
export class AccountCardModule {}
