import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "fp-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent {
  showOverlayMask: boolean = false;
  @Input() id: string = "select-generic";
  @Input() options: number[] = [10, 30, 50];
  @Input() width: string = "100%";
  @Input() label: boolean = true;
  @Input() items: number;
  @Output() itemPageActive = new EventEmitter<number>();
  @Input() disabled: boolean = false;
  constructor() {}

  openSelect() {
    if (this.disabled) {
      return;
    }
    document.body.classList.add("fp-modal-open");
    this.showOverlayMask = true;
  }

  closeSelect(option?: number) {
    this.showOverlayMask = false;
    document.body.classList.remove("fp-modal-open");
    if (option) {
      this.items = option;
      this.itemPageActive.emit(option);
    }
  }
}
