import { createAction } from "@ngrx/store";
import { IAdministrator } from "@signup/models/administrator";

export const enum TypeActionsSignUpAdministrator {
  signUpAdministratorString = "[SIGN UP ADMINISTRATOR] set information",
  signUpAdministratorResetString = "[SIGN UP ADMINISTRATOR] Reset data",
}
/**
 * Action to set vehicle information
 */
export const signUpAdministrator = createAction(
  TypeActionsSignUpAdministrator.signUpAdministratorString,
  (data: IAdministrator) => ({ data }),
);
/**
 * Action to reset data
 */
export const signUpAdministratorReset = createAction(
  TypeActionsSignUpAdministrator.signUpAdministratorResetString,
);
