import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ICities } from "@shared/models/cities";
import * as accountSelectedActions from "@store/actions/account-selected.actions";
import * as accountsActions from "@store/actions/accounts.actions";
import {
  changeLanguaje,
  Languages,
  resetDataStore,
} from "@store/actions/app.actions";
import * as citiesActions from "@store/actions/cities.actions";
import * as customerInfoActions from "@store/actions/customer-info.actions";
import * as documentTypeActions from "@store/actions/identification-types.actions";
import * as loaderActions from "@store/actions/loader.actions";
import * as lowBalanceSelectedActions from "@store/actions/low-balance-selected.actions";
import * as lowBalanceActions from "@store/actions/low-balance.actions";
import * as modulesActions from "@store/actions/modules.actions";
import * as rolesAdministratorActions from "@store/actions/roles-administrator.action";
import * as rolesActions from "@store/actions/roles.actions";
import * as userInfoActions from "@store/actions/user-info.actions";
import { IAppState } from "@store/reducers/index.app.reducer";
import { selectAccountSelected } from "@store/selectors/account-selected.selector";
import { selectAccounts } from "@store/selectors/accounts.selector";
import { selectLanguage } from "@store/selectors/app.selector";
import { selectCities } from "@store/selectors/cities.selector";
import { selectCustomerInfo } from "@store/selectors/customer-info.selector";
import { selectIdentificationTypes } from "@store/selectors/identification-types.selector";
import { selectLoader } from "@store/selectors/loader.selector";
import { selectLowBalance } from "@store/selectors/low-balance.selector";
import { selectModules } from "@store/selectors/modules.selector";
import { selectRolesAdministrator } from "@store/selectors/roles-administrator.selector";
import { selectRoles } from "@store/selectors/roles.selector";
import { selectUserInfo } from "@store/selectors/user-info.selector";
import { IIdentifiTypesState } from "@store/state/app.state";
import { Observable } from "rxjs";
import {
  IAccount,
  IAccountLowBalance,
  IAccountsResponseDetails,
  ILowBalance,
} from "./models/accounts";
import { ICustomerInfo } from "./models/customer-info";
import { ILoaderState } from "./models/loader";
import { IModules } from "./models/modules";
import { IRoles } from "./models/roles";
import { IRolesAdministrator } from "./models/roles-administrator";
import { IUserInfo } from "./models/user-info";
import { IAddress } from "./modules/vehicles/models/address";
import * as addressActions from "./store/actions/address.actions";
import { selectAddress } from "./store/selectors/address.selector";
import { selectLowBalanceSelected } from "./store/selectors/low-balance-selected.selector";

@Injectable()
export class AppFacade {
  public selectLanguage$: Observable<Languages> = this.store.pipe(
    select(selectLanguage),
  );

  public selectIdentificationType$: Observable<
    IIdentifiTypesState
  > = this.store.pipe(select(selectIdentificationTypes));

  public selectIdentificationType2$: Observable<
    IIdentifiTypesState
  > = this.store.pipe(select(selectIdentificationTypes));

  public selectCities$: Observable<ICities> = this.store.pipe(
    select(selectCities),
  );

  public selectCities2$: Observable<ICities> = this.store.pipe(
    select(selectCities),
  );

  public selectAccounts$: Observable<IAccount> = this.store.pipe(
    select(selectAccounts),
  );
  public selectAccounts2$: Observable<IAccount> = this.store.pipe(
    select(selectAccounts),
  );
  public selectRoles$: Observable<IRoles> = this.store.pipe(
    select(selectRoles),
  );

  public selectRolesInfo$: Observable<IRoles> = this.store.pipe(
    select(selectRoles),
  );

  public selectLoader$: Observable<ILoaderState> = this.store.pipe(
    select(selectLoader),
  );

  public selectModules$: Observable<IModules> = this.store.pipe(
    select(selectModules),
  );

  public selectCustomerInfo$: Observable<ICustomerInfo> = this.store.pipe(
    select(selectCustomerInfo),
  );
  public selectCustomerInformation$: Observable<
    ICustomerInfo
  > = this.store.pipe(select(selectCustomerInfo));

  public selectUserInfo$: Observable<IUserInfo> = this.store.pipe(
    select(selectUserInfo),
  );
  public selectUserInfo2$: Observable<IUserInfo> = this.store.pipe(
    select(selectUserInfo),
  );

  public selectRolesAdministrator$: Observable<
    IRolesAdministrator
  > = this.store.pipe(select(selectRolesAdministrator));

  public selectAccountSelected$: Observable<
    IAccountsResponseDetails
  > = this.store.pipe(select(selectAccountSelected));

  public selectLowBalance$: Observable<ILowBalance> = this.store.pipe(
    select(selectLowBalance),
  );

  public selectLowBalanceSelected$: Observable<
    IAccountLowBalance
  > = this.store.pipe(select(selectLowBalanceSelected));

  public selecAddress$: Observable<IAddress> = this.store.pipe(
    select(selectAddress),
  );

  constructor(private store: Store<IAppState>) {}

  changeLanguaje(language: Languages) {
    this.store.dispatch(changeLanguaje(language));
  }
  public getCities(): void {
    this.store.dispatch(citiesActions.citiesLoad());
  }

  public getAccounts(nickname: string): void {
    this.store.dispatch(accountsActions.accountsLoad(nickname));
  }

  public getCustomerInfo(): void {
    this.store.dispatch(customerInfoActions.customerInfoLoad());
  }

  public getUserInfo(nickname: string): void {
    this.store.dispatch(userInfoActions.userInfoLoad(nickname));
  }

  public getLowBalance(nickname: string): void {
    this.store.dispatch(lowBalanceActions.lowBalanceLoad(nickname));
  }

  public getAddressInfo(clientId: string): void {
    this.store.dispatch(addressActions.addressLoad(clientId));
  }

  /**
   * set event to show loader
   * @param loaderObj loader Object
   */
  public setLoaderShow(loaderObj: ILoaderState): void {
    this.store.dispatch(loaderActions.loaderShow(loaderObj));
  }
  /**
   * set event to hide loader
   */
  public setLoaderHide(): void {
    this.store.dispatch(loaderActions.loaderHide());
  }

  public getIdentificationTypes(): void {
    this.store.dispatch(documentTypeActions.identificationTypesLoad());
  }
  /**
   * Set modules
   * @param modules modules
   */
  public setModules(modules: IModules): void {
    this.store.dispatch(modulesActions.setModules(modules));
  }

  public setAccountSelected(accountSelected: IAccountsResponseDetails): void {
    this.store.dispatch(
      accountSelectedActions.accountSelected(accountSelected),
    );
  }

  public setLowBalanceSelected(lowBalanceSelected: IAccountLowBalance): void {
    this.store.dispatch(
      lowBalanceSelectedActions.lowBalanceSelected(lowBalanceSelected),
    );
  }

  public resetAccountSelected() {
    this.store.dispatch(accountSelectedActions.accountSelectedReset());
  }

  public resetLowBalanceSelected() {
    this.store.dispatch(lowBalanceSelectedActions.lowBalanceSelectedReset());
  }

  public resetDataStore() {
    this.store.dispatch(resetDataStore());
  }

  public getRolestDataStore(personId: string) {
    this.store.dispatch(rolesActions.rolesLoad(personId));
  }

  public resetRolestDataStore() {
    this.store.dispatch(rolesActions.rolesReset());
  }

  public setRolesAdministrator(data: IRolesAdministrator): void {
    this.store.dispatch(rolesAdministratorActions.rolesAdministrator(data));
  }
}
