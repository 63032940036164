import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@core/core.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateService } from "../input/service/translate.service";
import { RadioButtonComponent } from "./radiobutton.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateService,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    CoreModule,
  ],
  exports: [RadioButtonComponent],
  declarations: [RadioButtonComponent],
})
export class RadioButtonModule {}
