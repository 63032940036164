import { IUserGenericInformation } from "../../models/user-generic-info";

/**
 * Set a Name to read chart redux
 */
export const ModuleUsersFeatureName = "ModuleUsers";

/**
 * Grouping to InfoUserGenericState
 */
export type InfoUsersModuleState = Readonly<{
  userGenericInformationReducer: IUserGenericInformation;
}>;

/**
 * Initialize state to Vehicle plate
 */
export const initialStateUserGenericInformation: IUserGenericInformation = {
  identificationType: "",
  identificationNumber: "",
  email: "",
  roles: "",
  accounts: "",
};
