import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequesReportService {
  constructor(private http: HttpClient) {}

  setRequestReport(
    reportType: number,
    formatReport: string,
    startDate: string,
    finalDate: string,
    accountId: string,
  ): Observable<any> {
    const data = {
      reportType,
      formatReport,
      startDate,
      finalDate,
      accountId,
    };
    return this.http.post(
      environment.api_key.publicPN.back_url +
        environment.endpoints.requesReport.init +
        environment.endpoints.requesReport.specific.report,
      data,
      {
        params: new CustomHttpParams("api-pj-security-token"),
      },
    );
  }

  getUrlReport(reportId: string, formatReport: string): Observable<any> {
    return this.http.get(
      environment.api_key.report_request.back_url +
        environment.endpoints.presignedUrl.init +
        environment.endpoints.presignedUrl.specific.preSign +
        `?reportId=${reportId}&formatReport=${formatReport}`,
      {
        params: new CustomHttpParams("api-pj-security-apiKey-token-noctype"),
      },
    );
  }

  getFileReportByURLSign(urlFile: string): Observable<any> {
    return this.http.get(urlFile);
  }

  getTypeFile(formatReport: string) {
    let fileType: string;
    const format = formatReport.toLowerCase();
    switch (format) {
      case "xlsx":
        fileType = "application/vnd.ms-excel";
        break;
      case "csv":
        fileType = "text/csv";
        break;
      case "pdf":
        fileType = "application/pdf";
        break;
      default:
        fileType = "text/plain";
        break;
    }
    return fileType;
  }

  downloadFileBlobByURL(url: string, formatReport: string) {
    const typeFile = this.getTypeFile(formatReport);
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", typeFile);
    headers = headers.set("Accept", typeFile);
    return this.http.get(url, {
      headers,
      responseType: "blob",
    });
  }

  downloadReport(response: any, reportId: string, formatReport: string) {
    const blob2 = new Blob([response], {
      type: this.getTypeFile(formatReport),
    });
    const format = formatReport.toLowerCase();
    const result = window.URL.createObjectURL(blob2);
    saveAs(result, `${reportId}.${format}`);
  }
}
