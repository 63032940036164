import { Component, Input } from "@angular/core";

@Component({
  selector: "fp-transaction-method",
  templateUrl: "./transaction-method.component.html",
  styleUrls: ["./transaction-method.component.scss"],
})
export class TransactionMethodComponent {
  @Input() paymentMethod: string;
  @Input() brand: string;
  @Input() cardMask: string;
  @Input() status: string;

  getCardBrandImage(brand: string): string {
    const brandImages = {
      visa: "assets/images/transactions/credit-card-brands/visa.svg",
      amex: "assets/images/transactions/credit-card-brands/amex.svg",
      master: "assets/images/transactions/credit-card-brands/master.svg",
      diners: "assets/images/transactions/credit-card-brands/diners.svg",
      default: "assets/images/transactions/credit-card-brands/gou.svg",
    };
    return brandImages[brand] || brandImages.default;
  }
}
