<fp-card-body
  [template]="'secondTemplate'"
  backOption="true"
  [showfooterBallsRight]="false"
  [urlBack]="historyNavigationAccount?.length <= 0 ? this.previousUrl : '/home'"
  headerType="secondary">
  <ng-template #tplRef>
    <div class="admin-menu-body">
      <h1 class="fp-h1">{{ "title" | translate }}</h1>
      <h2 class="fp-body-one subtitle"> {{ subtitle | translate}} <strong>Nº {{account?.accountId}} </strong></h2>
      <div class="form-group">
        <div *ngFor="let item of itemsMenu" >
          <div class="items-menu" (click)="goLink(item)" *ngxPermissionsOnly="[item.permission]">
            <div class="circle">
              <img [src]="item.image" alt="icon image">
            </div>
            <div class="text">
              <a> {{item.text | translate }}</a>
            </div>
            <div class="right-arrow">
              <img src="assets/images/common/arrow-left.svg" alt="arrow-left">
            </div>
          </div>
        </div>
        <div class="automatic-recharge-menu-container" *ngIf="account.accountType === 'PRE-REC'">
          <h4 class="automatic-recharge-menu-container__title">{{ 'automaticRecharge' | translate }}</h4>
          <div *ngFor="let item of itemsAutomaticRechargeMenu" class="automatic-recharge-menu">
            <div class="items-menu" (click)="goLink(item, true)" *ngxPermissionsOnly="[item.permission]">
              <div class="circle">
                <img [src]="item.image" alt="icon image">
              </div>
              <div class="text">
                <a> {{item.text | translate }}</a>
              </div>
              <div class="right-arrow">
                <img src="assets/images/common/arrow-left.svg" alt="arrow-left">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</fp-card-body>
