import { IDataPseBanks } from "@app/models/pse";
import { createAction } from "@ngrx/store";

export const enum TypePseBanksActions {
  pseBanksLoadString = "[PSE BANKS] Loading data",
  pseBanksSaveString = "[PSE BANKS] Successful data loaded",
  pseBanksErrorString = "[PSE BANKS] Save error response",
}
/**
 * Action to detect event loading terms and conditions data
 */
export const pseBanksLoad = createAction(
  TypePseBanksActions.pseBanksLoadString,
);
/**
 * Action to detect event loaded terms and conditions data
 */
export const pseBanksSave = createAction(
  TypePseBanksActions.pseBanksSaveString,
  (data: IDataPseBanks[]) => ({ data }),
);
/**
 * Action to set data error
 */
export const pseBanksError = createAction(
  TypePseBanksActions.pseBanksErrorString,
  (error: any) => ({ error }),
);
