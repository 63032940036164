import { Component, EventEmitter, Output } from "@angular/core";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-activate-selection",
  templateUrl: "./activate-selection.component.html",
})
export class ActivateSelectionComponent {
  @Output() emitActionPopUp = new EventEmitter<{}>();
  constructor(private modalService: ModalService) {}

  actionModal($event: any): void {
    if ($event.typeAction === "exit") {
      this.modalService.close("fp-change-selection-status", false);
    } else if ($event.typeAction === "accept") {
      this.emitActionPopUp.emit({ ...$event });
      this.modalService.close("fp-change-selection-status", false);
    }
  }
}
