import { accountRootRoute } from "@account/account-routing.module";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { completeSignUpRootRoute } from "@complete-signup/complete-signup-routing.module";
import { genericRootRoute } from "@generic/generic-routing.module";
import { genericErrorRootRoute } from "@generic-error/generic-error-routing.module";
import { homeRootRoute } from "@home/home-routing.module";
import { PostpaidGuard } from "@postpaid/guards/postpaid.guard";
import { postpaidRootRoute } from "@postpaid/postpaid-routing.module";
import { reportsRootRoute } from "@reports/reports-routing.module";
import { signInRootRoute } from "@signin/signin-routing.module";
import { signOutRootRoute } from "@signout/signout.routing.module";
import { tagRootRoute } from "@tag/tag-routing.module";
import { transactionsRootRoute } from "@transactions/transactions-routing.module";
import { userRootRoute } from "@user/user-routing.module";
import { vehiclesRootRoute } from "@vehicles/vehicles-routing.module";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ActiveSessionGuard } from "./guards/active-session.guard";
import { AuthGuard } from "./guards/auth.guard";
import { OverdraftRootRoute } from "./modules/overdraft-account/overdraft-account-routing.module";
const routes: Routes = [
  {
    path: "",
    redirectTo: homeRootRoute,
    pathMatch: "full",
  },
  {
    path: "signup",
    loadChildren: () =>
      import("@signup/signup.module").then((m) => m.SingUpModule),
    canActivate: [ActiveSessionGuard],
  },
  {
    path: signInRootRoute,
    loadChildren: () =>
      import("@signin/signin.module").then((m) => m.SigninModule),
    canActivate: [ActiveSessionGuard],
  },
  {
    path: postpaidRootRoute,
    loadChildren: () =>
      import("@postpaid/postpaid.module").then((m) => m.PostpaidModule),
    canActivate: [PostpaidGuard],
  },
  {
    path: genericRootRoute,
    loadChildren: () =>
      import("./modules/generic/generic-routing.module").then(
        (m) => m.GenericRoutingModule,
      ),
  },
  {
    path: homeRootRoute,
    loadChildren: () => import("@home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: completeSignUpRootRoute,
    loadChildren: () =>
      import("@complete-signup/complete-signup.module").then(
        (m) => m.CompleteSignupModule,
      ),
    canActivateChild: [AuthGuard],
  },
  {
    path: userRootRoute,
    loadChildren: () =>
      import("./modules/user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "USUCRE",
      },
    },
  },
  {
    path: signOutRootRoute,
    loadChildren: () =>
      import("@signout/signout.module").then((m) => m.SignOutModule),
  },
  {
    path: accountRootRoute,
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard],
  },
  {
    path: "account", // Ruta "padre" para `account`
    loadChildren: () =>
      import("./modules/transactions/transactions.module").then(
        (m) => m.TransactionsModule,
      ),
  },
  {
    path: OverdraftRootRoute,
    loadChildren: () =>
      import("./modules/overdraft-account/overdraft-account.module").then(
        (m) => m.OverdraftModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: transactionsRootRoute,
    loadChildren: () =>
      import("@transactions/transactions.module").then(
        (m) => m.TransactionsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard],
  },
  {
    path: vehiclesRootRoute,
    loadChildren: () =>
      import("@vehicles/vehicles.module").then((m) => m.VehiclesModule),
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: "VEHCON",
      },
    },
  },
  {
    path: tagRootRoute,
    loadChildren: () => import("@tag/tag.module").then((m) => m.TagModule),
    canActivate: [AuthGuard],
  },
  {
    path: genericErrorRootRoute,
    loadChildren: () =>
      import("@generic-error/generic-error.module").then(
        (m) => m.GenericErrorModule,
      ),
  },
  {
    path: reportsRootRoute,
    loadChildren: () =>
      import("@reports/reports.module").then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "REPCON",
      },
    },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      useHash: true,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
})
export class AppRoutingModule {}
