import { IRolesAdministrator } from "@app/models/roles-administrator";
import { createAction } from "@ngrx/store";

export const enum TypeActionsRolesAdministrator {
  rolesAdministrator = "[Roles Administrator] Save data",
}

export const rolesAdministrator = createAction(
  TypeActionsRolesAdministrator.rolesAdministrator,
  (data: IRolesAdministrator) => ({ data }),
);
