import { createAction } from "@ngrx/store";
export const enum AddressTypeActions {
  addressLoadString = "[Address] Loading data",
  addressSaveString = "[Address] Successful data loaded",
  addressErrorString = "[Address] Save error response",
  addressResetString = "[Address] Reset State",
}
/**
 * Action to detect event loading vehicles categories data
 */
export const addressLoad = createAction(
  AddressTypeActions.addressLoadString,
  (data: string) => ({ data }),
);
/**
 * Action to detect event loaded vehicles categories data
 */
export const addressSave = createAction(
  AddressTypeActions.addressSaveString,
  (data: any) => ({ data }),
);
/**
 * Action to set data error
 */
export const addressError = createAction(
  AddressTypeActions.addressErrorString,
  (error: any) => ({ error }),
);

export const addressReset = createAction(AddressTypeActions.addressResetString);
