<div class="info-account">
  {{ 'account' | translate }} FacilPass
  <span class="skeleton skeleton-account-number" *ngIf="!accountData"></span>
  <span class="info-account__strong" *ngIf="accountData">No. {{ accountData.accountId }}</span>
  <br>
  <span class="skeleton skeleton-account-nure" *ngIf="!accountData"></span>
  <ng-container *ngIf="accountData">
    {{ (accountData.accountType === 'PRE' ? 'prepaid' : 'automatic') | translate }} - NURE {{ accountData.nure }}
  </ng-container>
</div>
