import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InfoMessageComponent } from "./info-message.component";

@NgModule({
  declarations: [InfoMessageComponent],
  imports: [CommonModule],
  exports: [InfoMessageComponent],
})
export class InfoMessageModule {}
