import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "type",
})
export class TypePipe implements PipeTransform {
  transform(value: number): string {
    let type;
    switch (value) {
      case 1:
        type = "Utilizaciones";
        break;

      case 2:
        type = "Recargas";
        break;

      case 3:
        type = "Compras TAG";
        break;

      case 4:
        type = "Detalle de Movimiento";
        break;

      case 8:
        type = "Detalle Facturación";
        break;

      default:
        type = "Consolidado Peajes";
        break;
    }
    return type;
  }
}
