import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { IAccount } from "@app/models/accounts";
import { AlertsTypes } from "@app/modules/shared/models/alert-message.enum";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { StatusCardValidated } from "../../models/status-card-validated.enum";
import { CardValidationResponse } from "../../models/status-card-validated.interface";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { AccountRequestsService } from "../../services/account-requests/account-requests.service";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-validate-card-reference",
  templateUrl: "./validate-card-reference.component.html",
  styleUrls: ["./validate-card-reference.component.scss"],
})
export class ValidateCardReferenceComponent implements OnInit, OnDestroy {
  /** Snackbar message */
  @ViewChild("snackbarMsg", { static: true }) snackbarMsg: TemplateRef<any>;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Status card reference */
  statusCardValidated: CardValidationResponse | undefined;
  /** Status card validated enum */
  statusCardValidatedEnum = StatusCardValidated;
  /** Alerts types enum */
  alertsTypes = AlertsTypes;
  /** Account data */
  accountId: string = "";
  /** Brands cards */
  cardsBrands = {
    visa: "assets/images/common/visa-logo.svg",
    master: "assets/images/common/master-card-logo.svg",
  };
  /** Status icons */
  statusIcons = {
    [StatusCardValidated.VALIDATED]: "assets/images/common/toast-success.svg",
    [StatusCardValidated.NO_VALIDATE]:
      "assets/images/common/filled-yellow-warning.svg",
    [StatusCardValidated.ON_CREATION]:
      "assets/images/common/filled-yellow-warning.svg",
    [StatusCardValidated.REJECTED]: "assets/images/common/toast-error.svg",
    [StatusCardValidated.DUPLICATED]: "assets/images/common/toast-error.svg",
    [StatusCardValidated.ABANDONED]: "assets/images/common/toast-error.svg",
  };
  /** Titles */
  titles = {
    [StatusCardValidated.VALIDATED]: "yourCardWasApproved",
    [StatusCardValidated.NO_VALIDATE]: "yourCardIsBeingValidated",
    [StatusCardValidated.ON_CREATION]: "yourCardIsBeingValidated",
    [StatusCardValidated.REJECTED]: "yourCardWasDeclined",
    [StatusCardValidated.ABANDONED]: "yourCardWasDeclined",
    [StatusCardValidated.DUPLICATED]: "yourCardIsDuplicated",
  };

  /**
   * Constructor
   * @param appFacade
   * @param translate
   * @param authenticationService
   * @param accountNavigationService
   * @param accountFacade
   * @param activatedRoute
   */
  constructor(
    private appFacade: AppFacade,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private accountRequestsService: AccountRequestsService,
    private accountNavigationService: AccountNavigationService,
    private notifyService: NotifyService,
    private accountFacade: AccountFacade,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const ref = params["ref"];
      this.selectAccounts$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IAccount) => {
          if (action && action.data !== null && action.data?.length > 0) {
            this.accountId = action.data[0].accountId.replace(
              /.+(.{4})$/,
              "** $1",
            );
          }
        });
      this.validateCardReference(ref);
    });
  }

  async validateCardReference(ref: string): Promise<void> {
    const automaticRechargeData = await this.selectAutomaticRecharge$
      ?.pipe(take(1))
      ?.toPromise();

    const request = await this.accountRequestsService.validateCardReference(
      ref,
    );

    request.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data) {
        this.statusCardValidated = response.data;
        if (
          this.statusCardValidated?.status ===
            this.statusCardValidatedEnum.VALIDATED ||
          this.statusCardValidated?.status ===
            this.statusCardValidatedEnum.NO_VALIDATE ||
          this.statusCardValidated?.status ===
            this.statusCardValidatedEnum.ON_CREATION
        ) {
          this.notifyService.showNotify(this.snackbarMsg, "", null);
        }
        pageEvent(
          "click-on-return-to-the-store-from-gou",
          "/account/validate-card-reference",
          "final de la tokenización",
        );

        this.accountFacade.setAutomaticRecharge({
          ...automaticRechargeData,
          temporalReferenceCard:
            this.statusCardValidated?.status ===
            this.statusCardValidatedEnum.VALIDATED
              ? this.statusCardValidated.id
              : null,
        });
      }
    });
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Go to payment methods
   */
  goPaymentMethods() {
    this.closeMessage();
    this.accountNavigationService.next(4);
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Get select accounts
   * @readonly
   * @type {Observable<IAccount>}
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
