import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignUpFeatureName, SignUpModuleState } from "../state/signup.state";

export const SignUpProspectCompanyRootSelector = createFeatureSelector<
  SignUpModuleState
>(SignUpFeatureName);

export const selectProspectCompanyInvalid = createSelector(
  SignUpProspectCompanyRootSelector,
  (state: SignUpModuleState) => state.prospectCompanyInvalid,
);
