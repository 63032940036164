import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { pageViewEvent } from "@app/helpers/utag.helpers";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { IClientInfo, ICustomerInfo } from "@app/models/customer-info";
import {
  IVehiclesList,
  IVehiclesListResponse,
} from "@app/modules/vehicles/models/vehicle";
import { VehiclesFacade } from "@app/modules/vehicles/store/facade/vehicles.facade";
import { AuthenticationService } from "@shared/services/authentication.service";
import { DashboardService } from "@shared/services/dashboard.service";
import { ExcelService } from "@shared/services/excel.service";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { resetDataStore } from "../../../store/actions/app.actions";
import { CommonsService } from "../../shared/services/commons.service";
import { NotifyService } from "../../shared/services/notify.service";
import { ModalService } from "../../theme/components/modal/service/modal.service";
import { FilterServicePipe } from "../components/pipes/filter-service.pipe";
import { IServiceBody } from "../models/home";

@Component({
  selector: "fp-detail-account",
  templateUrl: "./detail-account.component.html",
  styleUrls: ["./detail-account.component.scss"],
})
export class DetailAccountComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  accountSelectd: IAccountsResponseDetails;
  options: string[] = ["Hoy", "7 días", "15 días", "30 días"];
  textFilterGeneral = this.options[3];
  textFilterSpecific = this.options[3];
  total = 0;
  dataTolls: [IServiceBody[]];
  nuevos: any = [];
  listMovements: {
    data: any;
    error: any;
    loading: boolean;
  } = {
    data: null,
    error: null,
    loading: true,
  };
  username: string;
  vehiclesState: IVehiclesList = {
    data: null,
    error: null,
    loading: true,
    loaded: false,
  };
  vehicles: IVehiclesListResponse[] = [];
  vehiclesStatusNum = {
    activos: 0,
    inactivos: 0,
    sinTag: 0,
  };
  clientInfo: IClientInfo;

  constructor(
    private appFacade: AppFacade,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private excelService: ExcelService,
    private filterServicePipe: FilterServicePipe,
    private vehiclesFacade: VehiclesFacade,
    private commonsService: CommonsService,
    private modalService: ModalService,
    private notifyService: NotifyService,
  ) {}

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Init state to component
   */
  ngOnInit(): void {
    this.getSelectedAccount();
  }

  /**
   * get account selected
   */
  getSelectedAccount(): any {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccountsResponseDetails) => {
        if (action.accountId) {
          if (!this.accountSelectd) {
            this.accountSelectd = action;
            this.getMovementsDashboard();
            this.getVehicles();
            this.getCustomerInfo();
          } else {
            if (this.accountSelectd.accountId !== action.accountId) {
              this.accountSelectd = action;
              this.getMovementsDashboard();
              this.getVehicles();
              this.getCustomerInfo();
            }
          }
        }
        if (!action.accountId) {
          this.listMovements.data = [];
          this.listMovements.loading = false;
        }
      });
  }

  async getVehicles(): Promise<void> {
    this.username = await this.authenticationService.getUsername();
    this.vehiclesFacade.getVehiclesList(this.username);
    this.selectVehiclesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IVehiclesList) => {
        this.clearStatus();
        this.vehiclesState = action;
        if (action.data) {
          const result = action.data.data.filter(
            (data) => data.account === this.accountSelectd.accountId,
          );
          this.vehicles = JSON.parse(JSON.stringify(result));
          this.vehicles.forEach((vehicle) => {
            if (vehicle.tagEntityConfirmation) {
              vehicle.tagEntityConfirmation.currentStatus === 1
                ? (this.vehiclesStatusNum.activos += 1)
                : (this.vehiclesStatusNum.inactivos += 1);
            } else {
              this.vehiclesStatusNum.sinTag += 1;
            }
          });
        }
      });
  }

  changeFilterGeneralLimit(event: string) {
    this.textFilterGeneral = event;
  }

  clearStatus() {
    this.vehiclesStatusNum.activos = 0;
    this.vehiclesStatusNum.inactivos = 0;
    this.vehiclesStatusNum.sinTag = 0;
  }

  changeFilterSpecificLimit(event: string) {
    this.textFilterSpecific = event;
  }

  async getMovementsDashboard() {
    this.listMovements.loading = true;
    const dateEnd = moment(new Date()).format("YYYY-MM-DD");
    const dateStart = moment(dateEnd)
      .subtract(30, "d")
      .format("YYYY-MM-DD");
    try {
      const data = {
        personId: await this.authenticationService.getUsername(),
        account: this.accountSelectd.accountId,
        dateStart,
        dateEnd,
        services: ["peaje"],
      };
      const dataResponse = await this.dashboardService
        .getMovementsDashboard(data)
        .toPromise();
      this.listMovements.data = dataResponse.body[0];
    } catch ({ error }) {
      if (error?.data?.code === "100773") {
        this.listMovements.data = [];
      } else {
        this.listMovements.data = null;
        this.listMovements.error = error;
      }
    } finally {
      this.listMovements.loading = false;
    }
  }

  getCustomerInfo(): any {
    this.appFacade.getCustomerInfo();
    this.selectCustomerInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ICustomerInfo) => {
        if (action && action.data !== null) {
          this.clientInfo = action.data;
        }
      });
  }

  async downloadStationsReport() {
    const dataExport: any = [];
    const dataTransform = this.filterServicePipe.transform(
      this.listMovements.data,
      this.textFilterSpecific,
      "peaje",
      true,
      "report",
    );

    dataTransform.forEach((element) => {
      element.pt.forEach((estacion) => {
        dataExport.push({
          ["Fecha"]: element.date,
          ["Servicio"]: element.name,
          ["Punto de servicio"]: estacion.name ? estacion.name : "",
          ["Descripción"]: element.tx[0].name ? element.tx[0].name : "",
          ["Tipo de transacción"]: element.tx[0].type ? element.tx[0].type : "",
          ["Valor"]: estacion.total,
        });
      });
    });
    this.excelService.exportAsExcelFile(
      dataExport,
      `movimientos_cuenta_${this.accountSelectd.accountId}`,
      [
        [
          "Cliente",
          this.clientInfo.names,
          "",
          "",
          "Fecha",
          moment().format("DD/MM/YYYY"),
        ],
        [
          "Identificación",
          this.clientInfo.idNumber,
          "",
          "",
          "Cuenta ",
          this.accountSelectd.accountId,
        ],
      ],
      { ref: "A5:F5" },
      [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ],
      "Movimientos",
    );
  }

  /**
   * Close Message
   */
  closeAlertActive(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Open modal
   * @param id string id element Html
   */
  openModal(id: string): void {
    this.closeAlertActive();
    this.modalService.open(id);
    pageViewEvent(`/vehicles/list/${id}`);
  }

  navigateTo(route: string) {
    this.commonsService.navigate(route);
  }

  navigateParam(route: string, param: string) {
    this.commonsService.navigateParam(route, param);
  }

  /**
   * get account select from Selector
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  /**
   * get vehicles from Selector
   */
  get selectVehiclesList$(): Observable<IVehiclesList> {
    return this.vehiclesFacade.selectVehiclesList$;
  }

  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }
}
