import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SuccessChangeModalityComponent } from "./success-change-modality.component";

export const SuccessChangeModality = "success-change-modality";
const routes: Routes = [
  {
    path: "",
    component: SuccessChangeModalityComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuccessChangeModalityRoutingModule {}
