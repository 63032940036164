import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IPage } from "@app/models/pages";
import { reportsPagesConst } from "@core/constants/pages.constant";
import { ReportsFacade } from "../store/facade/reports.facade";

@Injectable({
  providedIn: "root",
})
export class ReportsNavigationService {
  constructor(private router: Router, private reportsFacade: ReportsFacade) {}
  /**
   *
   * @param position Number
   */
  next(position: number): void {
    const page: IPage = reportsPagesConst[position];
    let savedPages: IPage[];
    savedPages = reportsPagesConst.slice(0, position + 1);
    this.reportsFacade.setReportsPages(savedPages);
    this.router.navigate([`/reports/${page.active}`]);
  }

  clearReportsPagesStore(): void {
    this.reportsFacade.setReportsPages([reportsPagesConst[0]]);
  }
}
