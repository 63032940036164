import { IPages } from "@app/models/pages";
import { ITemporalPassword } from "@app/models/temporal-password";
import { signInPagesConst } from "@core/constants/pages.constant";
import { ITimerCode } from "@otpcode/models/otpcode";
import { IForgotPassword, ITimestamp } from "@signin/models/forgot-password";

/**
 * Set a Name to read chart redux
 */
export const SignInFeatureName = "SignInState";

/**
 * Grouping to SignInState
 */
export type SignInModuleState = Readonly<{
  forgotPasswordReducer: IForgotPassword;
  singInPagesReducer: IPages;
  signInOtpCodeTimerReducer: ITimerCode;
  signInTemporalPasswordReducer: ITemporalPassword;
  signInTimestampReducer: ITimestamp;
}>;

/**
 * Initialize state to Vehicle plate
 */
export const initialStateForgotPassword: IForgotPassword = {
  nit: "",
  identificationType: "1",
  identificationNumber: "",
  destination: "",
  dv: "",
};

export const initialStateSignInPages: IPages = {
  pages: [signInPagesConst[0]],
};

export const initialStateTemporalPassword: ITemporalPassword = {
  temporalPassword: null,
};

export const initialStateTimestamp: ITimestamp = {
  timestamp: null,
};

export const initialStateOtpCodeTimer: ITimerCode = {
  time: null,
};
