import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IAccount, IAccountsResponseDetails } from "@app/models/accounts";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { IFinancialProducts } from "@app/modules/shared-financial-data/models/financial-data";
import { TranslateService } from "@ngx-translate/core";
import { SharedFinancialDataFacade } from "@shared-financial-data/store/facade/shared-financial-data.facade";
import { Languages } from "@store/actions/app.actions";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IEntity } from "../../models/financial-product";

@Component({
  selector: "fp-successful-association",
  templateUrl: "./successful-association.component.html",
})
export class SuccessfulAssociationComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  account: IAccountsResponseDetails;
  accountList: IAccountsResponseDetails[];
  modality: string;
  bank: string;
  financialProducts: IEntity[];
  financialProduct: string = "";
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedFinancialFacade: SharedFinancialDataFacade,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.getFinancialProducts();
    await this.getAccountList();
    this.modality = "prepago";
  }
  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async getFinancialProducts() {
    this.selectFinancialProducts$
      .pipe(take(1))
      .subscribe((financialProducts) => {
        this.financialProducts = financialProducts?.data;
      });
  }

  async getAccountList() {
    this.appFacade.getAccounts(await this.authenticationService.getUsername());
    this.selectAccounts$.pipe(take(2)).subscribe((action: IAccount) => {
      if (action && action.data) {
        this.accountList = action.data;
        this.getAccount();
      }
    });
  }

  getAccount(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccountsResponseDetails) => {
        this.account = {
          ...this.accountList?.find(
            (account) => account.accountId === action?.accountId,
          ),
        };
        this.account.rejectedAssociationCode = action?.rejectedAssociationCode;
        this.account.isModalityChange = action?.isModalityChange;
      });
  }

  /**
   * navigate to login before a successful recovery password process
   */
  goToRecharge(): void {
    this.router.navigate(["/transactions/recharge"]);
  }

  goHome(): void {
    this.router.navigate(["/home"]);
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts2$;
  }

  get selectFinancialProducts$(): Observable<IFinancialProducts> {
    return this.sharedFinancialFacade.selectFinancialProducts$;
  }
}
