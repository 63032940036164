import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListReportComponent } from "./list-report.component";

export const listReportRoot = "list";
const routes: Routes = [
  {
    component: ListReportComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListReportsRoutingModule {}
