import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { environment } from "@environment";
@Injectable({
  providedIn: "root",
})
export class PostpaidGuard implements CanActivate {
  canActivate(): boolean {
    if (environment.production) {
      return false;
    } else {
      return true;
    }
  }
}
