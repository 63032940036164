<fp-card-body [template]="'secondTemplate'" close="true" exit="true" headerType="tertiary" *ngIf="!entityError">
    <ng-template #tplRef>
        <div class="fp-status-container panel-container" *ngIf="!loading">
            <div class="fp-status-row j-center" [ngSwitch]="status">
                <img *ngSwitchCase="'approved'" class="icon-status" src="assets/images/register/icon-check.svg"
                    alt="icono tipo transaccion">
                <img *ngSwitchCase="'process'" class="icon-status" src="assets/images/transactions/status-process.svg"
                    alt="icono tipo transaccion">
                <img *ngSwitchCase="'error'" class="icon-status" src="assets/images/transactions/status-error.svg"
                    alt="icono tipo transaccion">
            </div>
            <h1 class="fp-h1 fp-status-title" [ngSwitch]="status">
                <span *ngSwitchCase="'approved'">{{ 'title_approved' | translate }}</span>
                <span *ngSwitchCase="'process'">{{ 'title_process' | translate }}</span>
                <span *ngSwitchCase="'error'">{{ 'title_error' | translate }}</span>
            </h1>
            <h2 class="fp-body-one fp-status-subtitle"
                [style.visibility]=" status === 'process' || temporalTransactionObject ? 'hidden':'visible'">
                {{transactionsStatus?.data.transactionInfo.transactionDate | date:'d MMMM y'}}
                / {{transactionsStatus?.data.transactionInfo.transactionDate | date:'shortTime'}}
            </h2>
            <fp-info-message [message]="messageTime" typeClass="info" [showClose]="false">
            </fp-info-message>
            <div *ngIf="!temporalTransactionObject" class="fp-card-status"
                [ngClass]="{'fp-card-status-nocollapse': !collapsed}">
                <div class="fp-status-container card-detail">
                    <div class="fp-status-row j-between ">
                        <span class="title-card-status">{{ 'number_transaction' | translate }}</span>
                        <span
                            class="value-card-status">{{transactionsStatus?.data.transactionInfo.transactionNumber}}</span>
                    </div>
                    <div class="fp-status-row j-between">
                        <span class="title-card-status">{{ transactionsStatus?.data.transactionInfo
                            .transactionTypeId === "1" ? ('product' | translate) : ('acount_facilpass' |
                            translate)}}</span>
                        <span class="value-card-status">
                            {{ transactionsStatus?.data.transactionInfo
                            .transactionTypeId === "1" ? ('device_tag' | translate) :
                            '****'+transactionsStatus?.data.transactionInfo.accountId.substr(-4)}}
                        </span>
                    </div>
                    <div class="fp-status-row j-between">
                        <span class="title-card-status">{{ 'currency' | translate }}</span>
                        <span class="value-card-status">{{ transactionsStatus?.data.transactionInfo.transactionAmount |
                            currency : 'COP' : 'symbol' : '1.0-0' }}</span>
                    </div>
                    <div class="fp-status-row j-between">
                        <span class="title-card-status title-reduce">{{ 'cost' | translate }}<br class="hide-desktop">{{
                            'transaction' | translate }}</span>
                        <span class="value-card-status">{{ transactionsStatus?.data.transactionInfo.transactionCost |
                            currency : 'COP' : 'symbol' : '1.0-0' }}</span>
                    </div>
                    <div class="fp-status-row j-between">
                        <span class="title-card-status">{{ 'payment' | translate }}</span>
                        <span
                            class="value-card-status">{{transactionsStatus?.data.transactionInfo.bankDescription}}</span>
                    </div>
                    <ng-container
                        *ngIf="transactionsStatus?.data.transactionInfo.transactionAmount !== 0  && status !== 'process'">
                        <div class="status-line">
                            <hr>
                        </div>
                        <div class="status-container-btn-ghost">
                            <button class="fp-btn-ghost btn-dowload" (click)="downloadPdfReceipt()">
                                <span class="btn-content-text" *ngIf="!loadingDownloadPdf">
                                    {{ 'download' | translate }}
                                </span>
                                <span class="loader" *ngIf="loadingDownloadPdf">
                                </span>
                            </button>
                        </div>
                    </ng-container>
                    <div class="fp-modal-blur" *ngIf="collapsed" (click)="collapsed = !collapsed;">
                        <div class="container-collapsed">
                            <img src="assets/images/transactions/icon-interface-arrow-down-small.svg"
                                alt="arrow-down icon">
                        </div>
                    </div>
                    <div class="fp-modal-blur2" *ngIf="!collapsed">
                        <img src="assets/images/transactions/icon-interface-arrow-up-small.svg" alt="arrow-down icon"
                            (click)="collapsed = !collapsed;">
                    </div>
                </div>
            </div>
            <div *ngIf="temporalTransactionObject" class="fp-card-status fp-card-status-nocollapse recharge-postpaid">
                <div class="fp-status-container card-detail">
                    <div class="fp-status-row j-between ">
                        <span class="title-card-status">Cuenta FacilPass</span>
                        <span class="value-card-status">{{'****'+temporalTransactionObject.accountId.substr(-4)}}</span>
                    </div>
                    <div class="fp-status-row j-between ">
                        <span class="title-card-status">Valor</span>
                        <span class="value-card-status">{{temporalTransactionObject.amount | currency : 'COP' : 'symbol'
                            : '1.0-0'}}</span>
                    </div>
                    <div class="fp-status-row j-between ">
                        <span class="title-card-status">Medio de pago</span>
                        <span class="value-card-status">{{temporalTransactionObject.name}}</span>
                    </div>
                </div>
            </div>
            <div class="fp-container-status">
                <button class="fp-btn-primary" type="submit" id="btn-transactions-continue" (click)="navigatetoHome()">
                    <span class="btn-content-text" id="text-transactions-status">
                        {{status === 'error'? ('back' | translate): ('go_home' | translate)}}
                    </span>
                </button>
            </div>
        </div>
        <div class="fp-status-container panel-container" *ngIf="loading">
            <div class="fp-status-row j-center">
                <div class="skeleton icon-status circle-fake"></div>
            </div>
            <h1 class="fp-h1 fp-status-title">
                <div class="skeleton title-fake"></div>
            </h1>
            <div class="skeleton subtitle-fake fp-status-subtitle"></div>
            <div class="skeleton message-fake"></div>

            <div class="skeleton card-body-fake">
            </div>
            <div class="fp-container-status">
                <div class="skeleton button-fake"></div>
            </div>
        </div>
    </ng-template>
</fp-card-body>

<fp-card-body [template]="'secondTemplate'" headerType="secondary" close="true" *ngIf="entityError">
    <ng-template #tplRef>
        <div class="container-successful"> 
            <fp-page-message icon="assets/images/common/icon-error-circle.svg" [big]="'true'" [title]=" 'rejected.title' | translate"
                [message]="'rejected.description' | translate:{'error': entityError}"
                [labelButton]="'rejected.button' | translate" (goNext)="navigatetoHome()">
            </fp-page-message>
        </div>
    </ng-template>
</fp-card-body>

<ng-template #notifi>
    <fp-snack-message [title]="'alert.title' | translate" [description]="'alert.description' | translate" type="error"
        (snackMessageEvent)="closeMessage()">
    </fp-snack-message>
</ng-template>