import { Component } from "@angular/core";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { environment } from "@environment";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-pop-up-documents-upload",
  templateUrl: "./pop-up-documents-upload.component.html",
  styleUrls: ["./pop-up-documents-upload.component.scss"],
})
export class PopUpDocumentsUploadComponent {
  accountSelected: IAccountsResponseDetails;
  urlTemplateGuide: string =
    environment.documents.url +
    environment.documents.init +
    environment.documents.specific.newVehicleTemplateGuide;
  urlTemplate: string =
    environment.documents.url +
    environment.documents.init +
    environment.documents.specific.folder +
    environment.documents.specific.newVehicleTemplate;
  constructor(
    private modalService: ModalService,
    private commonsService: CommonsService,
  ) {}

  modalAction() {
    this.modalService.close("fp-upload-documents", false);
  }

  async downloadVehicleFiles() {
    await this.commonsService.generatorPromiseDownload([
      this.urlTemplateGuide,
      this.urlTemplate,
    ]);
  }
}
