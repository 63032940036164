import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "date",
})
export class DatePipe implements PipeTransform {
  transform(value: string, type: number): string {
    const arrayDate = value.split("-");
    let newDate;
    if (type !== 5) {
      newDate = `${arrayDate[2]}/${this.shortMonth(arrayDate[1])}/${
        arrayDate[0]
      }`;
    } else {
      newDate = `${this.largeMonth(arrayDate[1])} ${arrayDate[2]}`;
    }
    return newDate;
  }

  shortMonth(value: string): string {
    let short = "Dic";
    switch (value) {
      case "01":
        short = "Ene";
        break;
      case "02":
        short = "Feb";
        break;
      case "03":
        short = "Mar";
        break;
      case "04":
        short = "Abr";
        break;
      case "05":
        short = "May";
        break;
      case "06":
        short = "Jun";
        break;
      case "07":
        short = "Jul";
        break;
      case "08":
        short = "Ago";
        break;
      case "09":
        short = "Sep";
        break;
      case "10":
        short = "Oct";
        break;
      case "11":
        short = "Nov";
        break;
      default:
        break;
    }
    return short;
  }

  largeMonth(value: string): string {
    let short = "Diciembre";
    switch (value) {
      case "01":
        short = "Enero";
        break;
      case "02":
        short = "Febrero";
        break;
      case "03":
        short = "Marzo";
        break;
      case "04":
        short = "Abril";
        break;
      case "05":
        short = "Mayo";
        break;
      case "06":
        short = "Junio";
        break;
      case "07":
        short = "Julio";
        break;
      case "08":
        short = "Agosto";
        break;
      case "09":
        short = "Septiembre";
        break;
      case "10":
        short = "Octubre";
        break;
      case "11":
        short = "Noviembre";
        break;
      default:
        break;
    }
    return short;
  }
}
