import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { ITemporalPassword } from "@app/models/temporal-password";
import { IUserInformation } from "@complete-signup/models/complete-signup";
import { select, Store } from "@ngrx/store";
import { ITimerCode } from "@signup/modules/otpcode/models/otpcode";
import { Observable } from "rxjs";
import * as completeSingUpActions from "../actions/index";
import { selectCompleteSignUpOtpCodeTimer } from "../selectors/complete-signup-otp-timer.selector";
import { selectCompleteSignUpPages } from "../selectors/complete-signup-pages.selector";
import { selectCompleteSignUpTemporalPassword } from "../selectors/complete-signup-temporal-password.selector";
import { selectUserInformation } from "../selectors/user-information.selector";
import { CompleteSignUpModuleState } from "../state/complete-signup.state";
@Injectable({
  providedIn: "root",
})
export class CompleteSingUpFacade {
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectUserInformation$: Observable<IUserInformation> = this.store.pipe(
    select(selectUserInformation),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectCompleteSignUpPages$: Observable<IPages> = this.store.pipe(
    select(selectCompleteSignUpPages),
  );
  public selectCompleteSignUpOtpCodeTimer$: Observable<
    ITimerCode
  > = this.store.pipe(select(selectCompleteSignUpOtpCodeTimer));

  public selectCompleteSignUpTemporalPassword$: Observable<
    ITemporalPassword
  > = this.store.pipe(select(selectCompleteSignUpTemporalPassword));

  constructor(private store: Store<CompleteSignUpModuleState>) {}

  setCompleteSignUpPages(pages: IPage[]) {
    this.store.dispatch(completeSingUpActions.CompleteSignUpSetPage(pages));
  }

  setUserInformation(userInformation: IUserInformation) {
    this.store.dispatch(completeSingUpActions.userInformation(userInformation));
  }

  resetsetUserInformation(): void {
    this.store.dispatch(completeSingUpActions.userInformationReset());
  }

  public setOtpCodeTimer(timer: ITimerCode): void {
    this.store.dispatch(completeSingUpActions.CompleteSignupOtpTimer(timer));
  }

  public setTemporalPassword(data: ITemporalPassword): void {
    this.store.dispatch(
      completeSingUpActions.CompleteSignUpTemporalPassword(data),
    );
  }
}
