import { Component, Input } from "@angular/core";
import { ModalService } from "@theme/components/modal/service/modal.service";

@Component({
  selector: "fp-comfirm-create-account",
  templateUrl: "./comfirm-create-account.component.html",
})
export class ComfirmCreateAccountComponent {
  @Input() accountId: string;

  constructor(private modalService: ModalService) {}

  actionModal($event: any): void {
    this.modalService.close("fp-confirm-create-account", false);
  }
}
