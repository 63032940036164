<ng-container *ngIf="loading">
    <nav class="navigation-links" aria-label="Menu fake">
        <div *ngFor="let item of [1,2,3,4]">
            <button class="fp-btn-fap skeleton">
            </button>
            <span class="subtitle-info-user skeleton shadow small"></span>
        </div>
    </nav>
    <div class="row space">
        <h2 class="fp-body-one subtitle-accounts skeleton"></h2>
        <div class="row">
            <h2 class="fp-body-one subtitle-accounts skeleton big"></h2>
            <div class="row subtitle-accounts-small skeleton" style="margin-left: 0.6rem;">
            </div>
        </div>
    </div>
    <section class="colum">
        <div class="container-accounts-cards">
            <div class="accounts-card fake">
                <div class="accounts-header-card fake ">
                    <button class="fp-btn-white card-custom-btn skeleton">
                    </button>
                    <div class="text-header-card" style="color: transparent;">
                        <p class="skeleton big shadow"></p>
                        <p class="skeleton small shadow">
                        </p>
                    </div>
                    <div class="skeleton shadow" style="width: 24px; height: 24px; border-radius: 50%;">
                    </div>
                </div>
                <div class="accounts-body-card fake expand">
                    <div class="colum left">
                        <span class="title-balance skeleton"></span>
                        <span class="detail-balance skeleton shadow"></span>
                    </div>
                    <div class="row mt-1">
                        <div class="detail-content">
                            <span class="title-balance skeleton shadow big"></span>
                            <span class="detail-balance small skeleton"></span>
                        </div>
                        <div class="detail-content m-left">
                            <span class="title-balance skeleton shadow big"></span>
                            <span class="detail-balance small skeleton big"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="fp-btn-tertiary generic-btn skeleton" type="button">
        </button>
    </section>
</ng-container>

<ng-container *ngIf="!loading">
    <section class="colum fp-fadeIn">
        <nav class="navigation-links" aria-label="Opciones para cuentas">
            <ng-container *ngIf="showPaymentsMethods">
              <div *ngxPermissionsOnly="['CUESEL', 'CUEMOD']">
                  <button class="fp-btn-fap" [disabled]="!accountSelect?.accountType" id="dashboard-reports"
                  (click)="accountSelect?.accountType? navigateTo('/account/payment-method'): null">
                      <img src="assets/images/home/user-actions/payment-methods.svg" alt="icono payment methods" class="fp-btn-fap__icon fp-btn-fap__icon--small">
                  </button>
                  <span class="subtitle-info-user bold" [class.disabled]="!accountSelect?.accountType">Métodos de pago</span>
              </div>
            </ng-container>
            <div *ngxPermissionsOnly="['CUEASI']">
              <button class="fp-btn-fap" id="dashboard-recharge" [disabled]="!accountSelect?.accountType"
                  (click)="accountSelect?.accountType? navigateTo('/transactions/recharge'): null">
                  <img src="assets/images/home/user-actions/currency.svg" alt="icono currency" class="fp-btn-fap__icon">
              </button>
              <span class="subtitle-info-user bold" [class.disabled]="!accountSelect?.accountType">Recargar</span>
            </div>
            <div *ngxPermissionsOnly="['USUCRE']">
                <button class="fp-btn-fap" (click)="navigateTo('/user/create')" id="dashboard-users">
                    <img src="assets/images/home/user-actions/users.svg" alt="icono user" class="fp-btn-fap__icon">
                </button>
                <span class="subtitle-info-user bold">Usuarios</span>
            </div>
            <div *ngxPermissionsOnly="['CUECONSALB']">
              <button [disabled]="!accountSelect?.accountType" class="fp-btn-fap" id="dashboard-transfer"
              (click)="accountSelect?.accountType? navigateTo('/account/low-balance'): null">
                  <img src="assets/images/home/user-actions/alerts.svg" alt="icono alert" class="fp-btn-fap__icon">
              </button>
              <span class="subtitle-info-user bold" [class.disabled]="!accountSelect?.accountType">Alertas</span>
          </div>
        </nav>
        <ng-container *ngxPermissionsOnly="['CUESEL', 'CUEMOD']">
          <div class="banner-automatic-recharge" (click)="goAutomaticRechargeConfig()" *ngIf="accountSelect?.accountType === 'PRE'">
            <p class="banner-automatic-recharge__title">{{ 'bannerAutomaticRecharge.title' | translate }}</p>
            <p class="banner-automatic-recharge-description">
              <span class="banner-automatic-recharge-description__outstanding">
                {{ 'bannerAutomaticRecharge.descriptionOutstanding1' | translate }}
              </span>
              {{ 'bannerAutomaticRecharge.description' | translate }}
              <span class="banner-automatic-recharge-description__outstanding">
                {{ 'bannerAutomaticRecharge.descriptionOutstanding2' | translate }}
              </span>
            </p>
          </div>
        </ng-container>
        <div class="container-cards">
            <div class="container-accounts-header">
                <div class="row space fp-fadeIn">
                    <div class="blur-header-titles">

                    </div>
                    <h2 class="fp-body-one subtitle-accounts">Cuenta</h2>
                    <div *ngxPermissionsOnly="['CUESALDO']" class="row subtitle-accounts-small" (click)="expand = !expand" id="dashboard-hide-balance">
                        Ocultar saldo <img [class.rotar]="!expand" src="assets/images/home/accounts/arrow.svg"
                            alt="icono currency">
                    </div>
                </div>
            </div>
            <form novalidate [formGroup]="formAccount">
                <div class="container-accounts-cards" [ngClass]="{ 'empty' : emptyAccountList}">
                    <ng-scrollbar class="fp-scrollbar" track="all" pointerEventsMethod="scrollbar">
                        <div style="margin-top: 4.2rem"></div>
                        <ng-container *ngFor="let account of accounts">
                            <fp-account-card *ngIf="accounts" [formAccount]="formAccount"
                                [select]="formAccount.controls.account.value === account.accountId" [account]="account" [status]="statusAccounts ? statusAccounts[account.accountId] : ''"
                                [expand]="expand" (onClickItem)="chooseAccount($event)">
                            </fp-account-card>
                        </ng-container>
                    </ng-scrollbar>
                </div>
            </form>
            <div class="account-container-btn">
                <div class="blur-button">
                </div>
                <button *ngxPermissionsOnly="['CUECRE']" class="fp-btn-green generic-btn" type="button" id="dashboard-btn-create-account"
                    (click)="createAccount()">
                    <span *ngIf="!loadingCreate">
                        Crear cuenta
                    </span>
                    <span class="loader" *ngIf="loadingCreate">
                    </span>
                </button>
            </div>
        </div>
    </section>
</ng-container>
<fp-comfirm-create-account [accountId]="accountId"></fp-comfirm-create-account>
<ng-template #notifyError>
    <fp-snack-message [title]="'Error'" [description]="'No es posible crear la cuenta en este momento'" type="error">
    </fp-snack-message>
</ng-template>
<fp-pop-up-modality-validation>
</fp-pop-up-modality-validation>
<fp-pop-up-permission-validation>
</fp-pop-up-permission-validation>
