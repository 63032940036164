<div [ngStyle]="style" [ngClass]="{
        'fp-radiobutton fp-component': true,
        'fp-radiobutton-checked': checked,
        'fp-radiobutton-disabled': disabled,
        'fp-radiobutton-focused': focused
      }" [class]="styleClass">
  <div class="fp-hidden-accessible">
    <input #rb type="radio" [attr.id]="inputId" [attr.name]="name" [attr.value]="value" [attr.tabindex]="tabindex"
      [attr.aria-labelledby]="ariaLabelledBy" [checked]="checked" (change)="onChange($event)"
      (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" [disabled]="disabled" />
  </div>
  <div (click)="handleClick($event, rb, true)" role="radio" [attr.aria-checked]="checked" [ngClass]="{
          'fp-radiobutton-box': true,
          'fp-highlight': checked,
          'fp-disabled': disabled,
          'fp-focus': focused
        }">
    <span class="fp-radiobutton-icon" [ngClass]="{'fp-disabled': disabled}">{{internalLabel}}</span>
  </div>
</div>
<label (click)="select($event)" [class]="labelStyleClass" [ngClass]="{
        'fp-radiobutton-label': true,
        'fp-radiobutton-label-active': rb.checked,
        'fp-disabled': disabled,
        'fp-radiobutton-label-focus': focused
      }" *ngIf="label" [attr.for]="inputId">{{ label }}</label>