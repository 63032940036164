import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { IAccountsResponseDetails } from "@app/models/accounts";
import { IPages } from "@app/models/pages";
import { AlertsTypes } from "@app/modules/shared/models/alert-message.enum";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, take, takeUntil } from "rxjs/operators";
import { AccountModalityTypes } from "../../models/account-modality.enum";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { Card } from "../../models/card.interface";
import { StatusCardValidated } from "../../models/status-card-validated.enum";
import { UrlTokenization } from "../../models/url-tokenization.interface";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { AccountRequestsService } from "../../services/account-requests/account-requests.service";
import { RouteTrackingService } from "../../services/route-tracking/route-tracking.service";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  /** Snackbar message */
  @ViewChild("snackbarMsg", { static: true }) snackbarMsg: TemplateRef<any>;
  /** Snackbar message */
  @ViewChild("snackbarMsgError", { static: true })
  snackbarMsgError: TemplateRef<any>;
  /** Selected modality */
  formPaymentMethod: UntypedFormGroup;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Account data */
  accountData: IAccountsResponseDetails;
  /** Account data */
  automaticRechargeData: IAutomaticRecharge;
  /** Cards */
  cards: Card[] = [];
  /** Brands cards */
  cardsBrands = {
    visa: "assets/images/common/visa-logo.svg",
    master: "assets/images/common/master-card-logo.svg",
  };
  /** Loading */
  loading = false;
  /** Sending data */
  sendingData = false;
  /** Get url tokenization flag */
  getUrlTokenization = false;
  /** Updating data flag */
  updating = false;
  /** Credit card selected */
  creditCardSelected: Card;
  /** Previous url */
  previousUrl: string;
  /** Exceptions urls */
  exceptionsUrls = ["/", "/home"];
  /** Alerts types enum */
  alertsTypes = AlertsTypes;
  /** Linked accounts */
  linkedAccounts: string[] = [];
  /** Message success */
  messageSuccess = "";
  /** Message error */
  messageError = "";
  /** Card selected to delete */
  cardSelectedToDelete = "";
  /** Current card */
  currentCard = "";
  /** Status card validated enum */
  statusCardValidatedEnum = StatusCardValidated;
  /** Selected card blocked */
  selectedCardBlocked = false;

  /**
   * Constructor
   * @param appFacade
   * @param translate
   */
  constructor(
    private appFacade: AppFacade,
    private translate: TranslateService,
    private accountRequestsService: AccountRequestsService,
    private modalService: ModalService,
    private accountFacade: AccountFacade,
    private accountNavigationService: AccountNavigationService,
    private authenticationService: AuthenticationService,
    private routeTrackingService: RouteTrackingService,
    private notifyService: NotifyService,
    private router: Router,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    this.getAccount();
    this.loading = true;
    this.previousUrl = this.routeTrackingService.getPreviousUrl();
    this.initForm();
    this.automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    this.setAccountData();
    if (this.automaticRechargeData?.getAllCards) {
      this.getCards();
    }
  }

  /**
   * Init form
   */
  async initForm(): Promise<void> {
    this.formPaymentMethod = new UntypedFormGroup({
      paymentMethod: new UntypedFormControl("", [Validators.required]),
    });
    this.formPaymentMethod.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.creditCardSelected = this.cards.find(
            (card) =>
              card.id === this.formPaymentMethod.get("paymentMethod").value,
          );
        },
      });
  }

  getAccount() {
    this.accountRequestsService.currentData$.subscribe(
      (data: IAccountsResponseDetails) => {
        this.accountData = data;
      },
    );
  }

  /**
   * Set account data
   */
  async setAccountData(): Promise<void> {
    this.accountData = await this.selectAccountSelected$
      ?.pipe(take(1))
      ?.toPromise();
    this.updating = this.accountData?.financialProduct
      ? Object.keys(this.accountData?.financialProduct)?.length > 0
      : false;
    if (!this.automaticRechargeData?.getAllCards) {
      this.getCards();
    }
  }

  /**
   * Set data form config
   */
  async sendDataForm() {
    this.closeMessage();
    if (this.automaticRechargeData?.getAllCards) {
      this.gotToSpecificPage();

      return;
    }

    if (
      this.formPaymentMethod.valid &&
      this.currentCard !== this.formPaymentMethod.get("paymentMethod").value &&
      !this.selectedCardBlocked
    ) {
      this.closeModalPaymentMethod();
      this.sendingData = true;
      let activeRechargeValue = "";
      let rechargeValue = "";

      if (this.updating) {
        activeRechargeValue = this.accountData?.financialProduct?.lowBalance;
        rechargeValue = this.accountData?.financialProduct?.rechargeBalance;
      } else {
        activeRechargeValue = this.automaticRechargeData?.activeRechargeValue;
        rechargeValue = this.automaticRechargeData?.rechargeValue;
      }

      activeRechargeValue = activeRechargeValue?.replace(/\./g, "");
      rechargeValue = rechargeValue?.replace(/\./g, "");

      const email = await this.getUserEmail();

      const payload = {
        modality: AccountModalityTypes.AUTOMATIC,
        accountId: this.accountData?.accountId,
        activeRechargeValue,
        rechargeValue,
        refCard: this.formPaymentMethod.get("paymentMethod").value,
      };
      const request =
        this.updating &&
        this.formPaymentMethod.get("paymentMethod").value &&
        this.formPaymentMethod.get("paymentMethod").value !== ""
          ? await this.accountRequestsService.changePaidMethod(payload)
          : await this.accountRequestsService.changeModality(
              payload,
              this.updating,
            );

      request?.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.sendingData = false;
          const cardSelected = this.cards.find(
            (card) => card.id === payload.refCard,
          );
          this.accountFacade.setAutomaticRecharge({
            activeRechargeValue,
            rechargeValue,
            email,
            referenceCard: cardSelected?.id,
            brandCard: cardSelected?.brand,
            cardMask: cardSelected?.cardMask,
            temporalReferenceCard: null,
          });
          this.accountNavigationService.next(this.updating ? 6 : 5);
          // Tagging click event
          pageEvent(
            "click-btn-continue-payment-method",
            "/account/payment-method",
            "Payment method selected",
          );
        },
        error: () => {
          this.sendingData = false;
        },
      });
    }
  }

  /**
   * Get url redirect add payment method
   */
  getUrlRedirectAddPaymentMethod() {
    this.closeMessage();
    if (!this.getUrlTokenization) {
      this.getUrlTokenization = true;
      this.accountRequestsService
        .getUrlsTokenization()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: UrlTokenization) => {
            pageEvent(
              "click-btn-add-payment-method",
              "/account/payment-method",
              "Redirect add payment method",
            );
            location.href = response.processUrl;
            this.closeModal();
            this.getUrlTokenization = false;
          },
          error: () => {
            this.getUrlTokenization = false;
          },
        });
    }
  }

  /**
   * Get cards
   */
  async getCards() {
    this.loading = true;
    this.accountRequestsService
      .getCards(
        this.automaticRechargeData?.getAllCards ||
          this.accountData?.accountType !== "PRE-REC"
          ? ""
          : this.accountData?.accountId,
      )
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.loading = false;
          return of([]);
        }),
      )
      .subscribe((response: Card[]) => {
        this.loading = false;
        this.cards = response;
        const temporalCards = this.cards;
        this.cards.forEach((card, index) => {
          if (card.id === this.automaticRechargeData?.temporalReferenceCard) {
            if (
              !this.automaticRechargeData?.getAllCards &&
              this.previousUrl.includes("/validate-card-reference")
            ) {
              this.messageSuccess = "cardHasBeenSuccessfullyAdded";
              this.notifyService.showNotify(this.snackbarMsg, "", null);
              setTimeout(() => {
                this.closeMessage();
              }, 5000);
            }
            if (!this.automaticRechargeData?.getAllCards) {
              this.formPaymentMethod.get("paymentMethod").setValue(card.id);
              this.setFlagBlockedCard(card.id);
              temporalCards.splice(index, 1);
              temporalCards.unshift(card);
              return;
            }
          }
          if (
            card.linkedAccounts &&
            this.accountData?.accountId in card.linkedAccounts &&
            !this.automaticRechargeData?.getAllCards
          ) {
            this.formPaymentMethod.get("paymentMethod").setValue(card.id);
            this.setFlagBlockedCard(card.id);
            this.currentCard = card.id;
            temporalCards.splice(index, 1);
            temporalCards.unshift(card);
          }
        });
        this.cards = temporalCards;
        if (
          this.formPaymentMethod.get("paymentMethod").value === "" &&
          !this.automaticRechargeData?.getAllCards
        ) {
          this.formPaymentMethod
            .get("paymentMethod")
            .setValue(this.cards[0]?.id);
          this.setFlagBlockedCard(this.cards[0]?.id);
        }
      });
  }

  /**
   * set flag blocked card
   * @param id string
   */
  setFlagBlockedCard(id: string) {
    this.selectedCardBlocked =
      this.cards.find((card) => card.id === id)?.status ===
      this.statusCardValidatedEnum.BLOCKED;
  }

  //
  /**
   * Tagging click event select card
   * @param ref
   */
  tagSelectCard(id: string) {
    this.closeMessage();
    if (!this.sendingData) {
      this.formPaymentMethod.get("paymentMethod").setValue(id);
      this.setFlagBlockedCard(id);
      // Tagging click event
      pageEvent(
        "click-select-card",
        "/account/payment-method",
        "Select card with reference " + id,
      );
    }
  }

  /**
   * Open modal
   */
  openModal(): void {
    this.closeMessage();
    if (!this.sendingData && !this.loading) {
      this.modalService?.open("fp-modal-confirm-add-payment-method");
    }
  }

  /**
   * Open modal confirm payment method
   */
  openModalPaymentMethod(): void {
    this.closeMessage();
    if (this.formPaymentMethod.valid && !this.selectedCardBlocked) {
      this.modalService?.open("fp-modal-confirm-change-payment-method");
    }
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.closeMessage();
    if (!this.getUrlTokenization) {
      this.modalService?.close("fp-modal-confirm-add-payment-method", false);
    }
  }

  /**
   * Close modal confirm payment method
   */
  closeModalPaymentMethod(): void {
    this.closeMessage();
    this.modalService?.close("fp-modal-confirm-change-payment-method", false);
  }

  /**
   * Close modal delete payment method
   */
  closeModalDeleteCard(): void {
    this.closeMessage();
    this.modalService?.close("fp-modal-delete-payment-method", false);
  }

  /**
   * Get user email
   */
  async getUserEmail(): Promise<any> {
    const userEmail = (
      await this.authenticationService.getUserData()
    )?.email?.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]{2,3})/gi);
    return userEmail ? userEmail[0] : "";
  }
  /**
   * Delete payment method
   * @param cardId
   */
  deletePaymentMethod(cardId: string) {
    this.closeMessage();
    if (!this.sendingData && this.cardSelectedToDelete === "") {
      const selectedCard = this.cards.find((card) => card.id === cardId);
      this.linkedAccounts = selectedCard?.linkedAccounts
        ? Object.values(selectedCard?.linkedAccounts)
        : [];
      if (this.linkedAccounts?.length > 0) {
        this.modalService?.open("fp-modal-delete-payment-method");
      } else {
        this.sendingData = true;
        this.cardSelectedToDelete = cardId;
        this.accountRequestsService
          .deleteCard(cardId)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.messageSuccess =
                "youHaveJustDeletedYourPaymentMethodSuccessfully";
              this.notifyService.showNotify(this.snackbarMsg, "", null);
              setTimeout(() => {
                this.closeMessage();
              }, 5000);
              this.sendingData = false;
              this.cardSelectedToDelete = "";
              this.getCards();
            },
            error: (error) => {
              this.messageError = error.message || error.error?.message;
              this.notifyService.showNotify(this.snackbarMsgError, "", null);
              setTimeout(() => {
                this.closeMessage();
              }, 5000);
              this.sendingData = false;
            },
          });
      }
    }
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Go to specific page
   */
  gotToSpecificPage(): void {
    let url = "";
    this.closeMessage();
    if (this.linkedAccounts?.length > 1) {
      url = "/home";
    } else {
      url = "/account/administration";
      this.accountFacade.setAccountPages([]);
    }
    this.router.navigate([url]);
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }

  /**
   * Get data to facade store communication
   */
  get selectAccountPages$(): Observable<IPages> {
    return this.accountFacade.selectAccountPages$;
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccountsResponseDetails>}
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }
}
