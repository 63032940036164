import { IPage } from "@app/models/pages";
import { createAction } from "@ngrx/store";

export const enum TypeAccountPagesActions {
  accountPageString = "[ACCOUNT] set pages",
}

/**
 * Action to set data page
 */
export const AccountSetPage = createAction(
  TypeAccountPagesActions.accountPageString,
  (pages: IPage[]) => ({ pages }),
);
