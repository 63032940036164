import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import { ILowBalanceToSave } from "../models/low-balance";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class LowBalanceService {
  constructor(private http: HttpClient) {}
  /**
   * get available Entities and Financial Products
   * @param productToAssociate
   */
  updateLowBalance(data: ILowBalanceToSave): Observable<any> {
    return this.http.put(
      environment.api_key.publicPN.back_url +
        environment.endpoints.lowBalance.init +
        environment.endpoints.lowBalance.specific.lowBalance,
      data,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }
}
