<ng-container [ngSwitch]="paymentMethod">
    <img *ngSwitchCase="'PSE'" src="assets/images/common/pse.svg" alt="logo pse">

    <ng-container *ngSwitchCase="'TC'">
      <div *ngIf="status !== 'pending'; else defaultCard">
        <img [src]="getCardBrandImage(brand)" [alt]="brand">
        <span *ngIf="cardMask">****{{ cardMask }}</span>
      </div>
      <ng-template #defaultCard>
        <img src="assets/images/transactions/credit-card-brands/gou.svg" alt="logo gou">
      </ng-template>
    </ng-container>
  </ng-container>