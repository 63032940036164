import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.actions";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonsService } from "../shared/services/commons.service";

@Component({
  selector: "fp-generic-error",
  templateUrl: "./generic-error.component.html",
  styleUrls: ["./generic-error.component.scss"],
})
export class GenericErrorComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpGenericError = "fp-generic-error";
  destroy$: Subject<boolean> = new Subject<boolean>();
  bodyText: string;
  urlAction: string = "http://www.facilpass.com";
  /**
   * Constructor
   * @param translate Instance to translateService
   * @param appFacade Instance to AppFacade
   * @param router Instance to Route
   */
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private commonsService: CommonsService,
  ) {}
  /**
   * Init state to set translate file
   */
  ngOnInit(): void {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }
  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * button message action
   */
  handleSuccess(): void {
    this.commonsService.navigate("/home");
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
